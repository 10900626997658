import { createStore } from 'zustand';
import { fetchData } from '../helpers/apis/apiRequest';
import { ApiRoutes } from '../constants/routesApi';

export interface IRealtimeSettingsDtoCache {
  websocketSettings: {
    pingInterval: number;
    reconnectDelayStart: number;
    reconnectDelayMax: number;
  };
  realtimeSettings: {
    thumbnailPollDelay: number;
    messageDelayThreshold: number;
    messageTimeoutThreshold: number;
    lastSeenThreshold: number;
    thumbnailFeatureFlag: boolean;
    realtimePollDelay: number;
    realtimePollAfterError: number;
    screenshotFeatureFlag: boolean;
  };
}

const realtimeSettingsDtoCache = createStore<IRealtimeSettingsDtoCache>(
  () => null
);

/**
 * Returns the current state of the cache. If the cache is empty, it fetches the data from the API.
 * Caches hold the raw data from the API and should not be used directly outside of stores.
 */

export const getRealtimeSettingsDtoCache =
  async (): Promise<IRealtimeSettingsDtoCache> => {
    const current = realtimeSettingsDtoCache.getState();

    if (current !== null) {
      return current;
    }

    const response = await fetchData<IRealtimeSettingsDtoCache>({
      path: ApiRoutes.settings.realtime
    });

    realtimeSettingsDtoCache.setState(response);

    return response;
  };

/**
 * Resets the cache to null.
 */

export const resetRealtimeSettingsDtoCache = (): void => {
  realtimeSettingsDtoCache.setState(null);
};
