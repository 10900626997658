import { styled } from '@mui/material/styles';
import { Skeleton } from '@mui/material';
import {
  gray1,
  gray2,
  gray4,
  mediumFontWeight,
  primaryFontSize
} from '../../constants';
import {
  BaseGridContainerProps,
  GridCellSCProps,
  GridRowBodySCProps
} from './CustomGrid.props';

type LoaderContainerProps = { loaderHeight: string };
export const LoaderContainer = styled('div')<LoaderContainerProps>(
  ({ loaderHeight }) => ({
    width: '100%',
    border: `1px solid ${gray4}`,
    borderRadius: '3px',
    overflow: 'hidden',
    height: loaderHeight || 'auto'
  })
);

export const SkeletonSC = styled(Skeleton)`
  height: 31px !important;
`;

export const SkeletonLoaderRowsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${gray4}`
});

export const SkeletonLoaderCheckboxContainer = styled('div')({
  width: '23px',
  margin: '5px 20px'
});

export const SkeletonLoaderContainer = styled('div')({
  flex: 1,
  margin: '5px 20px'
});

export const GridContainerSC = styled('div')({
  display: 'block',
  border: `1px solid ${gray4}`,
  borderRadius: '4px'
});

export const BaseGridContainer = styled('div')<BaseGridContainerProps>(
  ({ shouldNotScroll }) => ({
    overflowX: 'auto',
    overflowY: shouldNotScroll ? 'none' : 'auto',
    maxHeight: shouldNotScroll ? '100%' : '65vh',
    variants: [] // Add the missing variants property
  })
);

export const GridRowHeaderSC = styled('div')({
  display: 'flex',
  height: '56px',
  margin: 'inherit',
  alignItems: 'center',
  fontSize: primaryFontSize,
  fontWeight: mediumFontWeight,
  whiteSpace: 'nowrap',
  borderBottom: `1px solid ${gray4}`,
  padding: '0 16px',
  '& > div': {
    position: 'relative',
    alignItems: 'center'
  },
  '& p': {
    fontWeight: mediumFontWeight
  }
});

export const GridRowBodySC = styled('div')<GridRowBodySCProps>({
  display: 'flex',
  position: 'relative',
  height: '52px',
  margin: 'inherit',
  alignItems: 'center',
  borderBottom: `1px solid ${gray4}`,
  padding: '0 16px',
  '& > div': {
    alignItems: 'center',
    padding: '0 16px',
    minWidth: 0
  },
  '&.selected': {
    backgroundColor: 'rgba(3, 218, 197, 0.1)'
  },
  '&.row-disabled': {
    backgroundColor: gray2,
    cursor: 'not-allowed',

    '& > div': {
      opacity: 0.4
    },

    '&:active, button': {
      pointerEvents: 'none'
    },
    '&:hover': {
      boxShadow: 'initial'
    }
  },
  '&.row-disabled.selected': {
    backgroundColor: gray2
  }
});

export const ClickableRow = styled(GridRowBodySC)`
  cursor: pointer;
  &:hover {
    background-color: ${gray1};
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  }
`;

export const GridCellSC = styled('div')<GridCellSCProps>(({ cellwidth }) => ({
  padding: '0 16px',
  display: 'inline-flex',
  alignItems: 'center',
  zIndex: 1,
  width: cellwidth || 'auto'
}));

export const GridCellHeaderSC = styled(GridCellSC)`
  /*  TODO: turn these on when ready
  height: 100%;
  &:not(:last-child) {
    border-right: 1px solid ${gray4};
  } */
  & span {
    position: relative;
  }
  & .at-icon-arrow {
    position: absolute;
    left: -18px;
  }
  &.sortable {
    cursor: pointer;
  }
`;
