import React, { createRef, MutableRefObject, useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  CircularProgress
} from '@mui/material';
import { IInsightsCategory, ICoreCategory } from '../../models';
import { SearchBar } from '../../../common/components/SearchBar';
import { useCategoriesState } from '../../hooks/useCategoriesState';
import PercentInput from './PercentInput';
import CategoryCheckboxList from './CategoryCheckboxList';
import {
  BorderBoxDiv,
  DialogContentStyled,
  PillBoxText,
  StyledSearchBarContainer,
  StyledBoxContainer,
  TotCss
} from '../../styles/TimeOnTask.view.styles';
import DialogActionsBox from './DialogActionsBox';
import CategoryTextContainer from './CategoryTextContainer';
import { handleDropDownSearchChange } from '../../utils/TimeOnTask.view.utils';

type ComponentProps = {
  open: boolean;
  onClose: () => void;
  editCoreCategoryRef: MutableRefObject<ICoreCategory>;
  onEditSaveConfiguration: () => void;
};

export const EditDialog = (props: ComponentProps) => {
  const { open, onClose, editCoreCategoryRef, onEditSaveConfiguration } = props;

  const { init, categories, isLoading } = useCategoriesState();

  useEffect(() => {
    if (open) {
      init(editCoreCategoryRef.current.groupId);
    }
  }, [editCoreCategoryRef, init, open]);

  const [percentValue, setPercentValue] = useState<number>(null);
  const [checkedItems, setCheckedItems] = useState<IInsightsCategory[]>([]);
  const [filtered, setFiltered] = useState<IInsightsCategory[]>([]);
  const textInputRef = createRef<HTMLInputElement>();

  const [originalCategoryNamesSorted, setOriginalCategoryNamesSorted] =
    useState<string[]>([]);
  const [isCatsChanged, setIsCatsChanged] = useState<boolean>(false);
  const [isPercentChanged, setIsPercentChanged] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');

  useEffect(() => {
    if (open) {
      if (categories.length > 0) {
        setFiltered(categories);
      }
      setOriginalCategoryNamesSorted(
        editCoreCategoryRef.current.categories.map((c) => c.category).sort()
      );
      setCheckedItems(editCoreCategoryRef.current.categories);
      setPercentValue(editCoreCategoryRef.current.targetPercentage);
      setIsCatsChanged(false);
      setIsPercentChanged(false);
    }
  }, [categories, editCoreCategoryRef, open]);

  useEffect(() => {
    if (open && checkedItems) {
      const sortedCheckedItems = checkedItems.map((ci) => ci.category).sort();
      setIsCatsChanged(
        JSON.stringify(originalCategoryNamesSorted) !==
          JSON.stringify(sortedCheckedItems)
      );
    }
  }, [open, checkedItems, originalCategoryNamesSorted]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleDropDownSearchChange(event, categories, setFiltered, setSearchString);
  };

  const handlePercentBlur = (value: number | null) => {
    setPercentValue(value);
    setIsPercentChanged(value !== editCoreCategoryRef.current.targetPercentage);
  };

  const onSave = () => {
    editCoreCategoryRef.current.categories = checkedItems;
    editCoreCategoryRef.current.targetPercentage = percentValue;
    onEditSaveConfiguration();
  };

  const close = () => {
    setFiltered([]);
    onClose();
  };

  return (
    open && (
      <Dialog open={open} onClose={close}>
        <Box width={606} maxHeight={760}>
          <DialogTitle>Edit Activity Alignment</DialogTitle>
          <DialogContentStyled>
            <Box>
              <Typography sx={{ ...TotCss.editHeader, marginBottom: '10px' }}>
                Team
              </Typography>
              <BorderBoxDiv>
                <PillBoxText>
                  {editCoreCategoryRef.current.groupName}
                </PillBoxText>
              </BorderBoxDiv>
            </Box>
            <Box>
              <Typography sx={TotCss.editHeader}>Core Categories</Typography>
              <CategoryTextContainer
                isHideable={false}
                checkedItems={checkedItems}
                setCheckedItems={setCheckedItems}
              />
            </Box>

            {isLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '20px'
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <StyledBoxContainer>
                <StyledSearchBarContainer>
                  <SearchBar
                    placeholder={'Search Categories, Websites, or Applications'}
                    fieldRef={textInputRef}
                    onClick={(e) => e.stopPropagation()}
                    handleDropDownSearchChange={(e) => handleSearchChange(e)}
                  />
                </StyledSearchBarContainer>
                <CategoryCheckboxList
                  filtered={filtered}
                  checkedItems={checkedItems}
                  setCheckedItems={setCheckedItems}
                  searchString={searchString}
                />
              </StyledBoxContainer>
            )}
            <Box>
              <Typography sx={TotCss.editHeader}>
                Core Activity Time Goal
              </Typography>
              <PercentInput
                defaultValue={editCoreCategoryRef.current.targetPercentage}
                onBlur={handlePercentBlur}
              />
            </Box>
          </DialogContentStyled>
          <DialogActions>
            <DialogActionsBox
              close={close}
              onClick={onSave}
              disabled={
                (!isPercentChanged && !isCatsChanged) ||
                checkedItems.length === 0 ||
                !percentValue
              }
              confirmText={'Save configuration'}
            />
          </DialogActions>
        </Box>
      </Dialog>
    )
  );
};
