export enum ExportValidationResult {
  Valid = 'valid',
  InvalidExport = 'invalid_export',
  InvalidProp = 'invalid_prop',
  InvalidRequiredProp = 'invalid_required_prop',
  InvalidOptionalProp = 'invalid_optional_prop',
  InvalidArrayOfExports = 'invalid_array_of_exports',
  InvalidExportInArray = 'invalid_export_in_array',
  InvalidReportPayload = 'invalid_report_payload',
  InvalidReportNoData = 'invalid_report_no_data',
  InvalidDestination = 'invalid_destination'
}
