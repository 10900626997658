import { ApiRoutes } from '../constants/routesApi';
import { fetchData } from '../helpers/apis/apiRequest';
import { IApiDomainStore } from '../models/IApiDomainStore';
import { IRegionUrls } from '../models/IAppLoad';
import { startsWith } from 'lodash';

export const buildWss = (url) => {
  if (!startsWith(url, 'wss://')) {
    url = 'wss://' + url;
  }
  return url + '/ws/client/v1';
};

export const buildHttps = (url) => {
  if (!startsWith(url, 'https://') && !startsWith(url, 'http://')) {
    return 'https://' + url;
  }
  return url;
};

export const mapUrls = (urls: IRegionUrls): Partial<IApiDomainStore> => {
  return {
    frontendApi: buildHttps(urls.apiURL),
    identityService: buildHttps(urls.identityServiceUrl),
    reportingService: buildHttps(urls.reportingServiceUrl),
    websocket: buildWss(urls.wsURL),
    websocketHttps: buildHttps(urls.wsURL),
    adminApi: buildHttps(urls.webApiUrl)
  };
};

export const fetchUrls = async (): Promise<Partial<IApiDomainStore>> => {
  const urls = await fetchData<IRegionUrls>({
    path: ApiRoutes.account.urls
  });

  return mapUrls(urls);
};
