import React, { useEffect, useState } from 'react';
import { ReactivTrakComponentWrapper } from '../../../ReactivTrakComponentWrapper';

import { IReportFilters } from '../../../common/components/ReportFilters/models/IReportFilters';
import { useReportFilterStore } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import { useRefreshState } from '../../../common/components/ReportFilters/hooks';
import { ActivityLogHeader } from '../components/activityLogHeader';
import { ActivityLogWrapper } from '../utils/activityLogWrapper';
import { ActivityLogWrapperProps } from '../models/ActivityLogTypes';
import { resetExportParamsStore } from '../../../common/stores/exportParamsStore';

const ActivityLogView = (props: ActivityLogWrapperProps) => {
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const reportFilters: IReportFilters = useReportFilterStore((s) => s);

  const { refreshTimestamp, handleRefresh } = useRefreshState();

  const { activityTimeSettings } = props;

  useEffect(() => {
    return resetExportParamsStore;
  }, []);

  useEffect(() => {
    if (refreshTimestamp) {
      setIsRefreshing(true);
      setTimeout(() => {
        setIsRefreshing(false);
      }, 5000);
    }
  }, [refreshTimestamp]);

  return (
    <>
      <ActivityLogHeader
        reportFilters={reportFilters}
        onRefresh={handleRefresh}
        isRefreshing={isRefreshing}
        refreshTimestamp={refreshTimestamp}
      />
      <ActivityLogWrapper
        reportFilters={reportFilters}
        refreshTimestamp={refreshTimestamp}
        activityTimeSettings={activityTimeSettings}
      />
    </>
  );
};

export const ActivityLogViewComponent = (props: ActivityLogWrapperProps) => {
  return (
    <ReactivTrakComponentWrapper>
      <ActivityLogView {...props} />
    </ReactivTrakComponentWrapper>
  );
};
