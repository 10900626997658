import React from 'react';
import { ColDef, ITooltipParams } from 'ag-grid-community';
import { BundleFlag } from '../../../common/enums/BundleFlag';
import { FeatureFlag } from '../../../common/enums/FeatureFlag';
import { formatDuration, formatTimeStamp } from '../../../common/helpers';
import { formatDate } from '../../../common/utils/datetime/datetimeFormatters';
import authorization from '../../../common/helpers/authorization';
import { LocationHeader } from '../components/LocationHeader';
import { OnlineMeetingsHeader } from '../components/OfflineMeetingsHeader';
import Typography from '@mui/material/Typography';
import { CustomHeaderTemplate } from '../../../common/components/AtGrid/components/CustomHeaderTemplate';
import { isTodayByTimezone } from '../../../common/utils/datetime/isToday';
import {
  generateLinkedItem,
  getAccessForFeature,
  getProductivityTimeTemplate
} from './workingHoursReport.utils';
import { smallFontSize } from '../../../common/constants';

  //NOTE: Because the Columns Selector uses the 'hide' property to determine if a column is visible, a 'hasAccess' property is used to separately determine if a column should be included in the total list of columns to display.
export const getScheduledColumns = (): ColDef[] => {
  const cols = [
    {
      colId: 'date',
      field: 'date',
      headerName: 'Date',
      type: 'string',
      sortable: false,
      valueFormatter: (params) => formatDate(params?.value),
      minWidth: 150
    },
    {
      colId: 'user',
      field: 'user',
      headerName: 'User',
      type: 'string',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return generateLinkedItem(dataItem.user, 'user');
      },
      tooltipValueGetter: (params: ITooltipParams) => params?.value,
      minWidth: 180
    },
    {
      colId: 'location',
      field: 'location',
      headerName: 'Location',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: <LocationHeader />
      },
      type: 'string',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;

        if (!dataItem) return '';

        const hasFeatureEnabled = authorization.hasFeature(
          BundleFlag.LocationData
        );
        const isTodayDate = isTodayByTimezone(dataItem.date);
        if (!dataItem.location || !hasFeatureEnabled || isTodayDate) return '';

        return dataItem.location;
      },
      minWidth: 130,
      cellStyle: { textAlign: 'center' },
      hasAccess: getAccessForFeature(
        BundleFlag.LocationData,
        BundleFlag.LocationPromo,
        FeatureFlag.ShowLocationData
      )
    },
    {
      colId: 'shiftTimes.productiveTime',
      field: 'shiftTimes.productiveTime',
      headerName: 'Productive Time (Scheduled Hours)',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'center' }}>
            Productive Time
            <br />
            <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(
          dataItem.shiftTimes.productiveTime,
          1
        );
      },
      minWidth: 175
    },
    {
      colId: 'shiftTimes.productiveActiveTime',
      field: 'shiftTimes.productiveActiveTime',
      headerName: 'Productive Active (Scheduled Hours)',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'center' }}>
            Productive Active
            <br />
            <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(
          dataItem.shiftTimes.productiveActiveTime,
          1
        );
      },
      minWidth: 200
    },
    {
      colId: 'shiftTimes.productivePassiveTime',
      field: 'shiftTimes.productivePassiveTime',
      headerName: 'Productive Passive (Scheduled Hours)',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'center' }}>
            Productive Passive
            <br />
            <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(
          dataItem.shiftTimes.productivePassiveTime,
          2
        );
      },
      minWidth: 200
    },
    {
      colId: 'shiftTimes.unproductiveTime',
      field: 'shiftTimes.unproductiveTime',
      headerName: 'Unproductive Time (Scheduled Hours)',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'center' }}>
            Unproductive Time
            <br />
            <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(
          dataItem.shiftTimes.unproductiveTime,
          -1
        );
      },
      minWidth: 200
    },
    {
      colId: 'shiftTimes.unproductiveActiveTime',
      field: 'shiftTimes.unproductiveActiveTime',
      headerName: 'Unproductive Active (Scheduled Hours)',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'center' }}>
            Unproductive Active
            <br />
            <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(
          dataItem.shiftTimes.unproductiveActiveTime,
          -1
        );
      },
      minWidth: 200
    },
    {
      colId: 'shiftTimes.unproductivePassiveTime',
      field: 'shiftTimes.unproductivePassiveTime',
      headerName: 'Unproductive Passive (Scheduled Hours)',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'center' }}>
            Unproductive Passive
            <br />
            <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(
          dataItem.shiftTimes.unproductivePassiveTime,
          -2
        );
      },
      minWidth: 200
    },
    {
      colId: 'shiftTimes.undefinedTime',
      field: 'shiftTimes.undefinedTime',
      headerName: 'Undefined Time (Scheduled Hours)',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'center' }}>
            Undefined Time
            <br />
            <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(
          dataItem.shiftTimes.undefinedTime,
          0
        );
      },
      minWidth: 200
    },
    {
      colId: 'shiftTimes.undefinedActiveTime',
      field: 'shiftTimes.undefinedActiveTime',
      headerName: 'Undefined Active (Scheduled Hours)',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'center' }}>
            Undefined Active
            <br />
            <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(
          dataItem.shiftTimes.undefinedActiveTime,
          0
        );
      },
      minWidth: 200
    },
    {
      colId: 'shiftTimes.undefinedPassiveTime',
      field: 'shiftTimes.undefinedPassiveTime',
      headerName: 'Undefined Passive (Scheduled Hours)',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'center' }}>
            Undefined Passive
            <br />
            <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(
          dataItem.shiftTimes.undefinedPassiveTime,
          -3
        );
      },
      minWidth: 200
    },
    {
      colId: 'productiveGoalAchievement',
      field: 'productiveGoalAchievement',
      headerName: 'Productivity Goal (Scheduled Hours)',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'center' }}>
            Productivity Goal
            <br />
            <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return `${Math.floor(dataItem.productiveGoalAchievement)}%`;
      },
      minWidth: 200
    },
    {
      colId: 'productiveHoursGoal',
      field: 'productiveHoursGoal',
      headerName: 'Productive Hours Goal',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'center' }}>
            Productive
            <br />
            Hours Goal
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      minWidth: 175
    },
    {
      colId: 'firstActivity',
      field: 'firstActivity',
      headerName: 'First Activity',
      type: 'rightAligned',
      sortable: false,
      valueFormatter: (params) => formatTimeStamp(params?.value, 'hh:mm:ss A'),
      minWidth: 150
    },
    {
      colId: 'lastActivity',
      field: 'lastActivity',
      headerName: 'Last Activity',
      type: 'rightAligned',
      sortable: false,
      valueFormatter: (params) => formatTimeStamp(params?.value, 'hh:mm:ss A'),
      minWidth: 150
    },
    {
      colId: 'shiftPunctuality',
      field: 'shiftPunctuality',
      headerName: 'Punctuality (Scheduled Hours)',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'center' }}>
            Punctuality
            <br />
            <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return formatDuration(dataItem.shiftPunctuality);
      },
      minWidth: 200
    },
    {
      colId: 'earlyDeparture',
      field: 'earlyDeparture',
      headerName: 'Early Clock-Out',
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return formatDuration(dataItem.earlyDeparture);
      },
      minWidth: 200
    },
    {
      colId: 'nonShiftTimes.productiveTime',
      field: 'nonShiftTimes.productiveTime',
      headerName: 'Productive Time (Non-Scheduled Hours)',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'center' }}>
            Productive Time
            <br />
            <span style={{ fontSize: smallFontSize }}>
              (Non-Scheduled Hours)
            </span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(
          dataItem.nonShiftTimes.productiveTime,
          1
        );
      },
      minWidth: 200
    },
    {
      colId: 'screenTime',
      field: 'screenTime',
      headerName: 'Screen Time',
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return formatDuration(dataItem.screenTime);
      },
      minWidth: 150
    },
    {
      colId: 'totalTimeOffline',
      field: 'totalTimeOffline',
      headerName: 'Offline Meetings',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: <OnlineMeetingsHeader />
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        const hasFeatureEnabled = authorization.hasFeature(
          BundleFlag.OfflineMeetingsData
        );
        const isTodayDate = isTodayByTimezone(dataItem.date);
        if (!hasFeatureEnabled || isTodayDate) return '';

        return getProductivityTimeTemplate(dataItem.totalTimeOffline || 0, -4);
      },
      minWidth: 150,
      hasAccess: getAccessForFeature(
        BundleFlag.OfflineMeetingsData,
        BundleFlag.OfflineMeetingsPromo,
        FeatureFlag.ShowOfflineMeetingData
      )
    }
  ];
  const filteredCols = cols.filter((col) => col.hasAccess !== false);

  //remove the 'hasAccess' property from the columns to make typescript happy
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return filteredCols.map(({ hasAccess, ...rest }) => rest);
};
