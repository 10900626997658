import { useCallback, useRef, useState } from 'react';
import { AuthLevel, NotificationType } from '../../common/enums';
import { ITimezoneDto, ITimezoneService } from '../../common/services';
import { ITimezoneState } from '../models';
import { useDateSettingsStore } from '../../common/hooks/useDateSettingsStore';
import authorization from '../../common/helpers/authorization';
import { setDateSettingsStore } from '../../common/stores/dateSettingsStore';

type TimezoneStateProps = {
  service: ITimezoneService;
};

export const useTimezoneState = ({
  service
}: TimezoneStateProps): ITimezoneState => {
  const { fetchAllTimezones, postAccountTimezone, postShowLocalTimezone } =
    service;

  const {
    timezoneLabel,
    showLocalTimezone,
    isDateFormatDDMM,
    isTimeFormat24Hour
  } = useDateSettingsStore();

  const [allTimezones, setAllTimezones] = useState<ITimezoneDto[]>();
  const [timezoneNotification, setTimezoneNotification] =
    useState<NotificationType>();

  const isFormDisabled = useRef<boolean>(
    !authorization.hasAuthorizationLevel(
      [AuthLevel.Edit],
      'app.settings.timezone'
    )
  );

  const getTimezones = useCallback(
    async (): Promise<void> => {
      try {
        const timezones: ITimezoneDto[] = await fetchAllTimezones();
        setAllTimezones(timezones);
      } catch (error) {
        setTimezoneNotification({
          msg: 'Unable to load list of timezones',
          type: 'error'
        });
        console.error('ActivTrak Error: Unable to load list of timezones');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const updateAccountTimezone = useCallback(
    async (payload): Promise<void> => {
      try {
        const timeZoneChanged = payload?.timeZone !== timezoneLabel;
        payload = { ...payload, timeZoneChanged: timeZoneChanged };
        await postAccountTimezone(payload);
        setDateSettingsStore({
          timezoneLabel: payload.timeZone,
          isTimeFormat24Hour: payload.isTimeFormat24Hour === 'true',
          isDateFormatDDMM: payload.isDateFormatDDMM === 'true'
        });

        setTimezoneNotification({
          msg: 'Time zone settings saved!',
          type: 'success'
        });
      } catch (error) {
        const msg = error?.message || 'Unable to update Account Timezone';
        setTimezoneNotification({
          msg: msg,
          type: 'error'
        });
        console.error(`ActivTrak Error: ${msg}`, error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timezoneLabel]
  );

  const updateShowLocalTimezone = useCallback(
    async (value): Promise<void> => {
      try {
        value = value == 'true' ? true : false;
        const updatedActivityTz = await postShowLocalTimezone({
          showLocalTimezone: value
        });
        if (updatedActivityTz.success) {
          setDateSettingsStore({ showLocalTimezone: value });
          setTimezoneNotification({
            msg: 'Successfully saved activity time and time zone preference!',
            type: 'success'
          });
        }
      } catch (error) {
        setTimezoneNotification({
          msg: 'Unable to save activity time and time zone preference. Please try again later.',
          type: 'error'
        });
        console.error(
          'ActivTrak Error: Unable to update Account Timezone',
          error
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const init = useCallback(async (): Promise<void> => {
    await getTimezones();
  }, [getTimezones]);

  return {
    allTimezones,
    timezoneLabel,
    isDateFormatDDMM,
    isTimeFormat24Hour,
    showLocalTimezone,
    timezoneNotification,
    isFormDisabled,
    init,
    setTimezoneNotification,
    updateAccountTimezone,
    updateShowLocalTimezone
  };
};
