import { ColumnState } from 'ag-grid-community';
import { IScheduledTimelineFilters } from '../models/ScheduledTimeline.types';

export const SCHEDULED_TIMELINE_DEFAULT_FILTERS: IScheduledTimelineFilters = {
  shiftStart: '09:00',
  shiftEnd: '17:00'
};

export const SCHEDULED_TIMELINE_STORE_KEY: string =
  'activTrak.scheduleAdherenceTimeline';

export const SCHEDULE_SUMMARY_COLUMNS_DEFAULT: ColumnState[] = [
  { colId: 'date', hide: false },
  { colId: 'user', hide: false },
  { colId: 'location', hide: false },
  { colId: 'shiftTimes.productiveTime', hide: false },
  { colId: 'shiftTimes.productiveActiveTime', hide: true },
  { colId: 'shiftTimes.productivePassiveTime', hide: true },
  { colId: 'shiftTimes.unproductiveTime', hide: true },
  { colId: 'shiftTimes.unproductiveActiveTime', hide: true },
  { colId: 'shiftTimes.unproductivePassiveTime', hide: true },
  { colId: 'shiftTimes.undefinedTime', hide: true },
  { colId: 'shiftTimes.undefinedActiveTime', hide: true },
  { colId: 'shiftTimes.undefinedPassiveTime', hide: true },
  { colId: 'productiveGoalAchievement', hide: false },
  { colId: 'productiveHoursGoal', hide: true },
  { colId: 'firstActivity', hide: true },
  { colId: 'lastActivity', hide: true },
  { colId: 'shiftPunctuality', hide: true },
  { colId: 'earlyDeparture', hide: true },
  { colId: 'nonShiftTimes.productiveTime', hide: true },
  { colId: 'screenTime', hide: true },
  { colId: 'totalTimeOffline', hide: true }
];

export const SCHEDULE_DETAILED_COLUMNS_DEFAULT: ColumnState[] = [
  { colId: 'date', hide: false },
  { colId: 'user', hide: false },
  { colId: 'location', hide: false },
  { colId: 'shiftTimes.productiveTime', hide: false },
  { colId: 'shiftTimes.productiveActiveTime', hide: true },
  { colId: 'shiftTimes.productivePassiveTime', hide: true },
  { colId: 'shiftTimes.unproductiveTime', hide: true },
  { colId: 'shiftTimes.unproductiveActiveTime', hide: true },
  { colId: 'shiftTimes.unproductivePassiveTime', hide: true },
  { colId: 'shiftTimes.undefinedTime', hide: true },
  { colId: 'shiftTimes.undefinedActiveTime', hide: true },
  { colId: 'shiftTimes.undefinedPassiveTime', hide: true },
  { colId: 'productiveGoalAchievement', hide: false },
  { colId: 'productiveHoursGoal', hide: true },
  { colId: 'firstActivity', hide: false },
  { colId: 'lastActivity', hide: false },
  { colId: 'shiftPunctuality', hide: false },
  { colId: 'earlyDeparture', hide: false },
  { colId: 'nonShiftTimes.productiveTime', hide: false },
  { colId: 'screenTime', hide: false },
  { colId: 'totalTimeOffline', hide: false }
];
