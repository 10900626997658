import React, { useState } from 'react';
import { IComputer } from '../models';

import { FormControlLabelSC } from '../styles';
import { useAuthorization } from '../../common/services/Authorization';
import { BundleFlag } from '../../common/enums/BundleFlag';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { getAccountSettings } from '../../common/helpers/accountSettings/accountSettingsStore';

type ComponentProps = {
  open: boolean;
  computer: IComputer;
  onClose: () => void;
  onDelete?: () => void;
};

export const UninstallComputersConfirmationModal = (props: ComponentProps) => {
  const { open, onClose, onDelete = () => {}, computer } = props;
  const [checked, setChecked] = useState(false);
  const { computer: computerName } = computer || {};

  const authorization = useAuthorization();
  const softDeletesEnabled = authorization.hasFeature(BundleFlag.SoftDeletes);
  const { delayedDelete } = getAccountSettings();

  const close = () => {
    onClose();
    setChecked(false);
  };

  const handleChecked = () => {
    setChecked(!checked);
  };

  const handleDelete = async (): Promise<void> => {
    onDelete();
    close();
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle id="delete-agent-dialog-title">Uninstall Agent</DialogTitle>
      <DialogContent>
        <label>
          All data associated with the computer {computerName} will be
          permanently deleted during the uninstall process. This action cannot
          be reversed.
        </label>
        <div id="uninstall-acknowledgment">
          <FormControlLabelSC
            control={
              <Checkbox
                color="primary"
                checked={checked}
                onChange={handleChecked}
              />
            }
            label="I acknowledge this action will delete all data associated with the selected computer and uninstall the ActivTrak agent from the computer."
          />
          <label htmlFor={'uninstall-acknowledgment'}>
            {(softDeletesEnabled || delayedDelete) && (
              <span>
                *Please allow up to 12 hours for the deletion to complete.
                <strong>
                  Computer data will be reflected in other reports until this
                  action is complete.
                </strong>
              </span>
            )}
          </label>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} autoFocus>
          Cancel
        </Button>
        <Button
          onClick={handleDelete}
          color="primary"
          variant="contained"
          disabled={!checked}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
