import React from 'react';
import { UseDownloadContext } from '../../../common/services/Download';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { getAccountSetting } from '../../../common/helpers/accountSettings/accountSettingsStore';
import { downloadDynamicAgent } from '../../../common/utils/downloads';

export const NoReportData = () => {
  const usedLicenses = getAccountSetting('usedLicenses');

  const { downloadService } = UseDownloadContext();
  const handleDownload = () => downloadDynamicAgent(downloadService);

  return (
    <Box
      sx={{
        height: '65vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
      }}
    >
      <Typography component="h5">No Results</Typography>
      <Typography sx={{ fontSize: '16px' }}>
        Application data was not available to generate this report
      </Typography>
      {usedLicenses === 0 && (
        <Box>
          <Button
            sx={{ padding: `0 3px 0 0 !important` }}
            onClick={() => handleDownload()}
          >
            Download the Agent
          </Button>{' '}
          and install on a target computer to begin collecting data
        </Box>
      )}
    </Box>
  );
};
