import { useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';
import { favoritesService } from '../../services/Navigation/FavoritesService';
import { withEvents } from './withEvents';
import { newEvent } from '../../analytics/eventHeap';
import { findItemById, findItemByName } from './navigationStore';

const MAX_FAVORITE_ITEMS = 10;
const SUCCESS = 'success';
const WARNING = 'warning';

//This is used to dedup some items names
//TODO: this needs to be part of the metadata object
const UNIQUE_PAGE_NAME_MAP = {
  'app.alarms.settings': 'Alarm Configuration',
  'app.screenshots.latest': 'Screenshots Latest',
  'app.screenshots.history': 'Screenshots History',
  'app.videos.history': 'Videos History',
  'app.account.webhook': 'Alarm Notifications',
  'app.settings.security': 'Security Configuration',
  'app.reports.insightsSettings': 'Insights Configuration',
  'app.activConnect': 'ActivConnect',
  'app.reports.riskLevel': 'Risk Level Alarms',
  'app.account.audit': 'Security Audit Log'
};

const favoritesStoreDefinition = (set, get) => ({
  favorites: [],

  isFavoriteNavigationEnabled: true,

  latest: 0,

  coaching: {} as any,

  setFavorites: (favorites) => {
    return set(() => ({ favorites }));
  },

  isInFavorites: (item: any) => {
    const current = get().favorites;

    //TODO: remove by name comparison
    const found = current.find((f) => f.id === item.id || f.name === item.name);

    return !!found;
  }
});

export const favoritesStore = createStore(favoritesStoreDefinition);
const { setState, getState } = favoritesStore;

const alert = (title, status) => {
  window.postMessage({ name: 'atk:notify', title, status });
};

export const { subscribe, unsubscribe, notify } = withEvents();

const resolveItem = (id, name) => {
  //This is a special case, need to build dto manually
  if (name === 'app.downloads') {
    return {
      label: 'Downloads',
      onClick: () => ({
        stateName: 'app.downloads'
      })
    };
  } 

  return id ? findItemById(id) : findItemByName(name);
};

// Command/Unit of Work Pattern
// this function updates the backend and the store in one transaction
export const addToFavorites = async ({ id, name }, area = '') => {
  const current = getState().favorites;
  notify('start:addToFavorites', current);

  const found = resolveItem(id, name);

  //mapping before adding to favorites:
  const item = found.onClick();
  const mapped = {
    id: found.id,
    name: item.stateName,
    label: found.label,
    params: {
      pageId: item.pageId,
      subPageId: item.subPageId,
      modelId: item.modelId
    }
  };

  //Max 5
  if (current.length === MAX_FAVORITE_ITEMS) {
    alert(
      `There is currently a maximum limit of ${MAX_FAVORITE_ITEMS} favorite items`,
      WARNING
    );
    return;
  }

  mapped.label = UNIQUE_PAGE_NAME_MAP[mapped.name] || mapped.label;

  const updated = [...current, mapped];
  setState({ favorites: updated });

  alert(`${mapped.label} has been added to Favorites`, SUCCESS);
  await favoritesService().saveFavorites(updated);

  //Heap analytics - custom event
  newEvent('Favorited', {
    'Favorite Page': mapped.label,
    'Favorite Action': 'added',
    'Favorite Area': area
  });

  notify('end:addToFavorites', updated);
};

// Command/Unit of Work Pattern
// this function updates the backend and the store in one transaction
export const removeFromFavorites = async (item: any, area = '') => {
  const current = getState().favorites;
  notify('start:removeFromFavorites', current);

  const found = current.find((f) => f.id === item.id || f.name === item.name);
  const updated = current.filter((f) => f.id !== found.id);

  setState({ favorites: updated });

  alert(`${found.label} has been removed from Favorites`, SUCCESS);
  await favoritesService().saveFavorites(updated);

  //Heap analytics - custom event
  newEvent('Favorited', {
    'Favorite Page': found.label,
    'Favorite Action': 'removed',
    'Favorite Area': area
  });

  notify('end:removeFromFavorites', updated);
};

export const toggleFavorite = async (item: any, area: string) => {
  const { isInFavorites } = getState();

  if (isInFavorites(item)) {
    await removeFromFavorites(item, area);
  } else {
    await addToFavorites(item, area);
  }
};

export const useFavoritesStore = (selector) =>
  useStore<any, any>(favoritesStore, selector);

