import _ from 'lodash';
import qs, { IStringifyOptions } from 'qs';
import { IProfile } from '../models';

/**
 * Parses JSON string until an object is created.
 *
 * @param {string | null} stringToParse - Stringified JSON
 * @returns {T} - Generic Object
 */
export const parseJson = <T>(stringToParse: string | null): T => {
  if (!stringToParse) {
    return {} as T;
  }

  try {
    const parsedString = JSON.parse(stringToParse);
    if (!parsedString || parsedString === 'undefined') {
      return {} as T;
    }

    return typeof parsedString === 'string'
      ? (JSON.parse(parsedString) as T)
      : (parsedString as T);
  } catch (error) {
    console.error(
      `ActivTrak Error: Cannot load object due to invalid JSON.\nObject String: ${stringToParse}\nError: `,
      error
    );
    return {} as T;
  }
};

export const htmlEscape = (text) => {
  if (!text || !_.isString(text)) {
    return text;
  }
  return text.replace(/["&'\\/<>]/g, function (a) {
    return {
      '"': '&quot;',
      '&': '&amp;',
      "'": '&#39;',
      '/': '&#47;',
      '<': '&lt;',
      '>': '&gt;'
    }[a];
  });
};

export const stringify = (data) => {
  if (!data || typeof data !== 'object') {
    return data;
  } else {
    return JSON.stringify(data);
  }
};

/*
-----sample inputs:
activinsights::executive_summary
activinsights::team_comparison__productivity_and_focus
activinsights::benchmarks_and_goals__team
activinsights::technology_usage__overview
activinsights::weekly_user_dashboard
------sample outputs:
Executive Summary
Team Comparison - Productivity and Focus
Benchmarks and Goals - Team
Technology Usage - Overview
Weekly Use Dashboard
 */
export function humanReadableLookmlDashboardId(str: any): string {
  const parsed = str
    .split('::')[1]
    .replaceAll('__', ' - ')
    .replaceAll('_', ' ');
  const wordArray = parsed
    .toLowerCase()
    .split(' ')
    .map((word) =>
      word === 'and' ? word : word[0].toUpperCase() + word.substring(1)
    );
  return wordArray.join(' ');
}

export const encodeProfileValues = (profile): IProfile => {
  profile.phone = _.isString(profile.phone)
    ? _.escape(profile.phone)
    : profile.phone;
  profile.firstName = _.isString(profile.firstName)
    ? _.escape(profile.firstName)
    : profile.firstName;
  profile.lastName = _.isString(profile.lastName)
    ? _.escape(profile.lastName)
    : profile.lastName;
  return profile;
};

export const decodeProfileValues = (profile: IProfile) => {
  profile.phone = _.isString(profile.phone)
    ? _.unescape(profile.phone)
    : profile.phone;
  profile.firstName = _.isString(profile.firstName)
    ? _.unescape(profile.firstName)
    : profile.firstName;
  profile.lastName = _.isString(profile.lastName)
    ? _.unescape(profile.lastName)
    : profile.lastName;
  return profile;
};

export const encodeFilters = (filters) => {
  const queryString = Object.keys(filters)
    .map(
      (key) => encodeURIComponent(key) + '=' + encodeURIComponent(filters[key])
    )
    .join('&');

  return queryString && encodeURIComponent(`&${queryString}`);
};

export const decodeFilters = (filters) => {
  const decoded = decodeURI(decodeURIComponent(filters))
    .replace(/^&/g, '')
    .replace(/"/g, '\\"')
    .replace(/&/g, '","')
    .replace(/=/g, '":"')
    .replace(/%5E/g, '^')
    .replace(/\+/g, ' ')
    .replace(/%2C/g, ',')
    .replace(/%23/g, '#')
    .replace(/%24/g, '$')
    .replace(/%2F/g, '/')
    .replace(/%40/g, '@')
    .replace(/%26/g, '&')
    .replace(/%2B/g, '+')
    .replace(/%3A/g, ':')
    .replace(/>%3D/g, '>=') //greater than or equal to
    .replace(/<%3D/g, '<='); //less than or equal to

  return JSON.parse(`{"${decoded}"}`);
};

export const convertObjectToQs = (
  obj: object,
  options: IStringifyOptions = {}
): string => qs.stringify(obj, options);
