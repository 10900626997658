import { react2angular } from '../../../_reactivtrak/src/common/third-party/react2angular/index.tsx';
import { getAccountSettings } from '../../../_reactivtrak/src/common/helpers/accountSettings/accountSettingsStore';
import { PrintActivityDashboardViewComponent } from '../../../_reactivtrak/src/dashboard/views/PrintActivityDashboard.view.tsx';
import { printableDashboardTemplate } from '../../../_reactivtrak/src/common/config/routing/routeDefinitions/templates';
import { generateParameters } from '../../../_reactivtrak/src/common/components/ReportFilters/utils/generateParameters.ts';
import { DateRanges } from '../../../_reactivtrak/src/common/components/ReportFilters/constants/dateRanges.ts';
import { getPresetLabel } from '../../../_reactivtrak/src/common/components/ReportFilters/utils/dateRange.utils.ts';

const PrintActivityDashboardComponent = {
    template: printableDashboardTemplate,
    controller: 'PrintableDashboardCtrl'
};
angular.module('app').component('printDashboardComponent', PrintActivityDashboardComponent);

angular.module('app').component('printDashboard', react2angular(PrintActivityDashboardViewComponent, []));

angular.module('app').controller('PrintableDashboardCtrl', PrintableDashboardCtrl);

PrintableDashboardCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$state',
    '$timeout',
    'atDashboardWidgetService',
    'localStorageService',
    'atHelperFunctions',
    'templateServiceFunctions',
    'browserServiceFunctions'
];

function PrintableDashboardCtrl(
    $rootScope,
    $scope,
    $state,
    $timeout,
    atDashboardWidgetService,
    localStorageService,
    atHelperFunctions,
    templateServiceFunctions,
    browserServiceFunctions
) {
    const accountSettings = getAccountSettings();
    $scope.printPage = true;
    $scope.overLimitOrOverStorage = accountSettings.overLimitOrOverStorage;
    $scope.isPrinted = false;
    $scope.isFirefoxOrMsBrowser =
        browserServiceFunctions.isFirefoxBrowser() || browserServiceFunctions.isIeOrEdgeBrowser();
    $rootScope.app.layout.isPrintPage = true;
    $scope.aggregatePeriod = 'Day';
    $scope.topUsersProductivity = localStorageService.get('topUsersDashboardWidgetFilter-' + accountSettings.username);
    $scope.topUsersMode = localStorageService.get('topUsersDashboardWidgetMode-' + accountSettings.username);
    $scope.topCategoriesOptions = localStorageService.get(
        'topCategoriesDashboardWidgetOptions-' + accountSettings.username
    );
    $scope.topAppsProductivity = localStorageService.get('topAppsDashboardWidgetFilter-' + accountSettings.username);
    $scope.topSitesProductivity = localStorageService.get('topSitesDashboardWidgetFilter-' + accountSettings.username);

    $scope.onDateRangeChanged = function () {
        $scope.aggregatePeriod = localStorageService.get(
            'aggregateProductivityWidgetPeriod-' + accountSettings.username
        );
    };

    $scope.shortLabelsFilter = function (maxSize, property, formatLabel) {
        return kendo.template(function (data) {
            let value = data[property];
            if (formatLabel) {
                value = formatLabel(value);
            }
            if (value.length > maxSize) {
                value = value.substring(0, maxSize - 1) + 'â€¦';
            }
            return value;
        });
    };

    $scope.bindReport = function (reportFilters) {
        const {
            users,
            dates: { range, getDates }
        } = reportFilters;
        const { toDate, fromDate } = getDates();
        const user = (users?.length && users[0]) || null;
        $scope.user = user;
        $scope.filterMode = $scope.user?.filterMode;
        $scope.reportDates = { toDate, fromDate };
        $scope.parameters = generateParameters(reportFilters, {});
        atDashboardWidgetService.callBindFunctions();

        $scope.printFilterData = {
            dateRange: function () {
                return range === DateRanges.Custom ? 'Custom' : getPresetLabel(range);
            },
            selectedDateFrom: function () {
                return atHelperFunctions.formatDate($scope.reportDates?.fromDate, {
                    span: false,
                    compact: false,
                    onlyDate: true
                });
            },
            selectedDateTo: function () {
                return atHelperFunctions.formatDate($scope.reportDates?.toDate, {
                    span: false,
                    compact: false,
                    onlyDate: true
                });
            },
            selectedUserHtmlPrinter: function () {
                const user = $scope.user;
                return templateServiceFunctions.userTypeTemplate(user);
            },
            userOrComputer: function () {
                const user = $scope.user;
                let userOrComputer = 'Filtered By';
                if (user.userType === 'User' || user.userType === 'Alias') {
                    userOrComputer += ' User';
                    return userOrComputer;
                }
                if (user.userType === 'Computer') {
                    userOrComputer += ' Computer';
                    return userOrComputer;
                }
                if (user.userType === 'Group' || user.userType === 'AdGroup') {
                    userOrComputer += ' Group';
                    return userOrComputer;
                }
                return userOrComputer;
            },
            currentDate: function () {
                return atHelperFunctions.formatDate(new Date(), {
                    span: false
                });
            }
        };

        $scope.onDateRangeChanged();
    };

    $scope.topCategoriesChartRedraw = function () {
        const chart = angular.element('#topCategoriesChart').data('kendoChart');
        if (chart !== undefined) {
            chart.redraw();
        }
    };

    let paceTimeout;
    let loadingTimeout;
    let paceDone = false;
    let nonPaceTimeout;

    function isLoading() {
        return angular.element('.status-loading').length > 0;
    }

    function startPaceTimeout() {
        paceDone = true;
        $timeout.cancel(nonPaceTimeout);

        if (paceTimeout) {
            $timeout.cancel(paceTimeout);
        }

        if (!$scope.isPrinted && $state.current.name === 'app.print-dashboard') {
            paceTimeout = $timeout(function () {
                if (loadingTimeout) {
                    $timeout.cancel(loadingTimeout);
                }
                loadingTimeout = $timeout(function checkLoadingTimeout() {
                    if (loadingTimeout) {
                        $timeout.cancel(loadingTimeout);
                    }

                    if (isLoading()) {
                        loadingTimeout = $timeout(checkLoadingTimeout, 1000);
                    } else {
                        if (!$scope.isPrinted && $state.current.name === 'app.print-dashboard') {
                            $scope.print();
                        }
                    }
                }, 1000);
            }, 3000);
        }
    }

    $scope.$on('cfpLoadingBar:loaded', startPaceTimeout);

    $scope.print = function () {
        print();
        $scope.isPrinted = true;
    };

    $scope.goBackToDashboard = function () {
        $rootScope.app.layout.isPrintPage = false;
        $state.go('app.dashboard');
    };

    $scope.dashboardWidth = {
        width: $scope.isFirefoxOrMsBrowser ? '700px' : '1000px'
    };

    window.onafterprint = function () {
        $scope.goBackToDashboard();
    };

    $scope.cancelPrint = function () {
        $scope.isPrinted = true;
        $timeout.cancel(nonPaceTimeout);
        $timeout.cancel(paceTimeout);
        $timeout.cancel(loadingTimeout);
        $scope.goBackToDashboard();
    };

    nonPaceTimeout = $timeout(function () {
        $scope.onDateRangeChanged();

        $timeout(function () {
            if (!paceDone) {
                startPaceTimeout();
            }
        }, 5000);
    });
}
