import React from 'react';
import authorization from '../../../common/helpers/authorization';
import { ExportSize } from '../../../common/enums/ExportSize';
import { EXPORT_DESTINATION_OPTIONS } from '../../../common/components/ReportFilters/constants/exportMenuOptions';
import { ReportType } from '../../../common/enums/ReportType';
import { useReportFilterStore } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import { IReportFilters } from '../../../common/components/ReportFilters/models/IReportFilters';
import { IExportReportParams } from '../../../common/models/IExport';
import { generateBody } from '../../../common/components/ReportFilters/utils/generateParameters';
import { BundleFlag } from '../../../common/enums/BundleFlag';
import { AsyncExportButton } from '../../../common/components/ReportFilters/components/AsyncExportButton';
import { ExportButtonContainer } from '../../../common/components/ReportFilters/components/ExportButton';

export const ScheduleAdherenceExport = () => {
  const reportFilters: IReportFilters = useReportFilterStore((s) => s);

  const hasAsyncExport: boolean = authorization.hasFeature(
    BundleFlag.WorkingHoursReportExport
  );

  const hasLargeExport: boolean = authorization.hasFeature(
    BundleFlag.LargeExport
  );

  const createExportPayload = (): IExportReportParams => {
    const { from, to, userType, userMode, userId } = generateBody(
      reportFilters,
      {}
    );

    return {
      type: ReportType.ScheduleAdherence,
      startDate: from,
      endDate: to,
      userType,
      userMode,
      userId
    };
  };

  if (!hasAsyncExport) return <ExportButtonContainer />;

  return (
    <AsyncExportButton
      reportType={ReportType.ScheduleAdherence}
      createExportPayload={createExportPayload}
      showExportsLimitedTooltip={true}
      exportableItemCount={
        hasLargeExport ? ExportSize.LargeBatch : ExportSize.SmallBatch
      }
      menuOptions={EXPORT_DESTINATION_OPTIONS}
    />
  );
};
