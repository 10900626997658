import { createStore } from 'zustand';
import { persist } from 'zustand/middleware';
import { IScheduledTimelineFilters } from '../models/ScheduledTimeline.types';
import {
  SCHEDULED_TIMELINE_DEFAULT_FILTERS,
  SCHEDULED_TIMELINE_STORE_KEY
} from '../constants/scheduleAdherence.const';

export const scheduledTimelineStore = createStore<IScheduledTimelineFilters>()(
  persist(() => ({ ...SCHEDULED_TIMELINE_DEFAULT_FILTERS }), {
    name: SCHEDULED_TIMELINE_STORE_KEY
  })
);

export const setScheduledTimeline = (
  timeline: Partial<IScheduledTimelineFilters>
) => {
  scheduledTimelineStore.setState(timeline);
};

export const getScheduledTimeline = () => {
  return scheduledTimelineStore.getState();
};
