import { regionUrlSuffixes } from '../../common/constants/regionCodes';
import { userPermissionStore } from '../../common/hooks/userPermissionStore';
import { getApiDomainStore } from '../../common/stores/apiDomainStore';
import {
  IComputerDto,
  IDeviceV1Dto,
  IComputer,
  IComputerAgentHealth,
  IHealthCheck,
  IUserAgentHealth,
  IComputerAgentHealthDto,
  IHealthCheckDto,
  IUserAgentHealthDto,
  IComputerDetail,
  IAgentLogSettings,
  IAgentSettings,
  IScheduledAction,
  IAgentCommandDto,
  IAgentCommand,
  IAgentStatus,
  IAgentStatusDto,
  IComputerDetailDto
} from '../models';
import { IComputerDomain } from '../models/IComputerDomain';

import { formatComputerComplexName } from './';

export const mapToComputers = (computers: IComputerDto[]): IComputer[] => {
  return computers?.map((row) => {
    const {
      compid,
      agentversion,
      windowsdomain,
      firstlog,
      lastlog,
      lcount,
      scount,
      scheduledActionId,
      alias,
      computer,
      lastss,
      storage
    } = row;

    return {
      id: compid,
      agentVersion: agentversion,
      windowsDomain: windowsdomain,
      firstLog: firstlog,
      lastLog: lastlog,
      logsCount: lcount,
      screenShotCount: scount,
      os: getComputerOS(row),
      alias,
      computer,
      lastss,
      storage,
      scheduledActionId,
      selected: false,
      domainComputerAlias: formatComputerComplexName(
        windowsdomain,
        computer,
        alias
      )
    };
  });
};

export const mapDeviceV1ToComputers = (
  computers: IDeviceV1Dto[]
): IComputer[] => {
  return computers?.map((row) => {
    const {
      id,
      domain,
      name,
      alias,
      version,
      firstActivity,
      lastActivity,
      deleteDate,
      lastScreenshot
    } = row;

    return {
      id: id,
      agentVersion: version,
      windowsDomain: domain,
      firstLog: firstActivity,
      lastLog: lastActivity,
      logsCount: null,
      screenShotCount: null,
      os: getComputerOS({
        windowsdomain: domain,
        computer: name
      } as IComputerDto),
      alias: alias,
      computer: name,
      lastss: lastScreenshot,
      storage: null,
      scheduledActionId: deleteDate ? 1 : 0,
      selected: false,
      domainComputerAlias: formatComputerComplexName(domain, name, alias)
    };
  });
};

export function mapDeviceV1ToScheduledActionDto(
  actions: IDeviceV1Dto[]
): IScheduledAction[] {
  return actions
    ?.filter((d) => d.deleteDate)
    .map((row) => {
      const { id, domain, name } = row;

      return {
        id: id,
        typeid: id,
        typename: 'computer',
        actionname: null,
        description: null,
        scheduledtime: null,
        completedtime: null,
        filter: null,
        primary: domain,
        secondary: name,
        dntid: null
      };
    });
}

export const mapToAgentHealth = (
  userIsSuperAdminOrSupportBasic: boolean,
  screenshotsAllowed: boolean,
  computers: IComputerAgentHealthDto[]
): IComputerAgentHealth[] => {
  const videoEnabled =
    userPermissionStore.getState().userBundleFlags?.isVideoEnabled ?? false;

  return computers?.map((row) => {
    const { name, osVersion, users } = row;

    return {
      name: name,
      osVersion: osVersion,
      users: mapToUserAgentHealth(
        userIsSuperAdminOrSupportBasic,
        screenshotsAllowed,
        videoEnabled,
        users
      )
    };
  });
};

export const mapToUserAgentHealth = (
  userIsSuperAdminOrSupportBasic: boolean,
  screenshotsAllowed: boolean,
  videosEnabled: boolean,
  users?: IUserAgentHealthDto[]
): IUserAgentHealth[] => {
  return users?.map((row) => {
    const { name, logonDomain, agentVersion, time, healthChecks } = row;

    return {
      name: name,
      logonDomain: logonDomain,
      agentVersion: agentVersion,
      time: time,
      healthChecks: mapToHealthChecks(
        userIsSuperAdminOrSupportBasic,
        screenshotsAllowed,
        videosEnabled,
        healthChecks
      )
    };
  });
};

export const mapToAgentLogSettings = (
  logSettings: any[]
): IAgentLogSettings[] => {
  return logSettings?.map((log) => {
    const { name, level } = log;
    return {
      name: name,
      level: level
    };
  });
};

export const mapToAgentSettings = (agentSettings: any): IAgentSettings => {
  const { logSettings, accountId, computerId } = agentSettings;
  const newLogSettings = mapToAgentLogSettings(logSettings);
  const newSettings = {
    accountId: accountId,
    computerId: computerId,
    logSettings: newLogSettings
  };
  return newSettings;
};

const doesRegionMatchDefaultDomain = (healthCheckMessage: string) => {
  const urlChecks = regionUrlSuffixes; //i.e. ['-au.', '-ca.', '-eu.', '-uk.', '-us.'];
  const domainStore = getApiDomainStore();

  let domainIncludesRegion = false;
  let heathCheckMatchesApi = false;
  urlChecks.forEach((urlCheck) => {
    if (healthCheckMessage?.includes(urlCheck)) {
      heathCheckMatchesApi = true;
      if (domainStore.defaultDomain.includes(urlCheck)) {
        domainIncludesRegion = true;
      }
    }
  });
  return [domainIncludesRegion, heathCheckMatchesApi];
};

const shouldIncludeHealthCheck = (
  healthCheck: IHealthCheck,
  screenshotsAllowed: boolean,
  videoEnabled: boolean
): boolean => {
  if (healthCheck.name == 'Endpoint') {
    const [domainIncludesRegion, heathCheckMatchesApi] =
      doesRegionMatchDefaultDomain(healthCheck.message);
    if (heathCheckMatchesApi) {
      if (domainIncludesRegion) {
        return true;
      }
      return false;
    }
  }
  switch (healthCheck.name) {
    case 'Average Memory':
    case 'Average Cpu':
      return false;
    case 'Screenshot Backlog Record Count':
    case 'Screenshot':
      return screenshotsAllowed;
    case 'Video Backlog Record Count':
    case 'Video':
      return videoEnabled;
    default:
      return true;
  }
};

export const mapToHealthChecks = (
  userIsSuperAdminOrSupportBasic: boolean,
  screenshotsAllowed: boolean,
  videosEnabled: boolean,
  healthchecks?: IHealthCheckDto[]
): IHealthCheck[] => {
  const mappedHealthChecks: IHealthCheck[] = [];

  healthchecks?.forEach((healthcheck) => {
    const { name, status, message } = healthcheck;

    const [formattedName, formattedMessage] = formatHealthCheckMessage(
      name,
      message
    );

    const tempCheck = {
      name: formattedName,
      status: status,
      message: formattedMessage
    };
    if (
      userIsSuperAdminOrSupportBasic ||
      shouldIncludeHealthCheck(tempCheck, screenshotsAllowed, videosEnabled)
    ) {
      mappedHealthChecks.push(tempCheck);
    }
  });
  return mappedHealthChecks;
};

export const mapToComputerDetail = (
  computerDetail: IComputerDetailDto
): IComputerDetail => {
  const { compid, lcount, sstorage, vstorage, scount, vcount } = computerDetail;

  return {
    id: compid,
    videoStorage: vstorage,
    screenShotStorage: sstorage,
    logCount: lcount,
    screenShotCount: scount,
    videoCount: vcount,

    firstLog: computerDetail.firstlog,
    lastLog: computerDetail.lastlog,
    os: getComputerOS(computerDetail),
    windowsDomain: computerDetail.windowsdomain,
    computerName: computerDetail.computer,
    alias: computerDetail.alias,
    agentVersion: computerDetail.agentversion
  };
};

export const getComputerOS = (computer: IComputerDomain): string => {
  if (!computer.windowsdomain) {
    return 'Apple';
  } else if (
    computer.computer.lastIndexOf('CA_', 0) === 0 &&
    computer.windowsdomain === 'Google'
  ) {
    return 'Chrome';
  } else {
    return 'Windows';
  }
};

const formatCode = (code: string) => {
  //add spaces to camelCase types
  let formattedCode = code?.replace(/([a-z])([A-Z])/g, '$1 $2');
  //don't separate ActivTrak
  if (formattedCode && formattedCode.indexOf('Activ Trak') > -1) {
    formattedCode = formattedCode.replace('Activ Trak', 'ActivTrak');
  }

  return formattedCode;
};

const parseJSONObject = (jsonString: string) => {
  try {
    const parsedObject = jsonString?.startsWith('{')
      ? JSON.parse(jsonString)
      : null;
    if (parsedObject && typeof parsedObject === 'object') {
      return parsedObject;
    }
  } catch (e) {
    return null;
  }
  return null;
};

export const mapToAgentCommand = (
  agentCommands: IAgentCommandDto[]
): IAgentCommand[] => {
  return agentCommands?.map((row, index) => {
    const { id, status, creationTime, accountId, code, computerId } = row;
    const newStatus = mapToStatus(status);

    return {
      status: newStatus,
      creationTime: creationTime,
      accountId: accountId,
      code: code,
      computerId: computerId,
      uniqueRowId: id.toString() + '-' + index.toString(),
      codeFormatted: formatCode(code)
    };
  });
};

export const mapToStatus = (status: IAgentStatusDto): IAgentStatus => {
  if (status) {
    return {
      outcome: status.outcome,
      message: status.message,
      executionTime: status.executionTime,
      receivedTime: status.receivedTime,
      parsedStatusMessage: parseJSONObject(status.message)
    };
  }
  return null;
};

function formatHealthCheckMessage(name: string, message: string): [any, any] {
  //add spaces to camelCase types
  let formattedName = name?.replace(/([a-z])([A-Z])/g, '$1 $2');
  if (formattedName) {
    //exceptions
    formattedName = formattedName.replace('Back Log', 'Backlog');
    formattedName = formattedName.replace('alarmhist ', 'Alarm History ');
    formattedName = formattedName.replace(
      'diagnosticlogs ',
      'Diagnostic Logs '
    );

    formattedName = formattedName.replace('log Backlog', 'Log Backlog');
    formattedName = formattedName.replace('ss Backlog', 'Screenshot Backlog');
    formattedName = formattedName.replace('video Backlog', 'Video Backlog');
  }
  let formattedMessage = message;
  if (formattedMessage) {
    switch (formattedName) {
      case 'Screenshot':
        formattedMessage = formattedMessage.replace(
          'Screenshot captured',
          'Screenshots available'
        );
        break;
      case 'Video':
        formattedMessage = formattedMessage.replace(
          'Video captured',
          'Video available'
        );
        break;
      case 'Average Cpu':
        formattedMessage =
          (parseFloat(formattedMessage) * 100).toFixed(3) + '%';
        break;
      case 'Average Memory':
        formattedMessage =
          (parseInt(formattedMessage) / 1024 / 1024).toFixed(1) + ' MBytes';
        break;
    }
  }
  return [formattedName, formattedMessage];
}
