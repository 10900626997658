import React, { useState } from 'react';
import { FormControlLabelSC } from '../styles';
import { useAuthorization } from '../../common/services/Authorization';

import { BundleFlag } from '../../common/enums/BundleFlag';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { getAccountSettings } from '../../common/helpers/accountSettings/accountSettingsStore';

type ComponentProps = {
  open: boolean;
  onClose: () => void;
  onDelete?: () => void;
};

export const DeleteComputersConfirmationModal = (props: ComponentProps) => {
  const { open, onClose, onDelete = () => {} } = props;
  const [checked, setChecked] = useState(false);

  const authorization = useAuthorization();
  const softDeletesEnabled = authorization.hasFeature(BundleFlag.SoftDeletes);
  const { delayedDelete } = getAccountSettings();

  const close = () => {
    onClose();
    setChecked(false);
  };

  const handleChecked = () => {
    setChecked(!checked);
  };

  const handleDelete = async (): Promise<void> => {
    onDelete();
    close();
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle id="delete-computer-dialog-title">
        Delete Computer Data
      </DialogTitle>
      <DialogContent id="delete-computer-dialog-content">
        <label htmlFor={'delete-computer-dialog-content'}>
          Do you want to delete all data associated with the selected computer?
        </label>
        <div>
          <FormControlLabelSC
            control={
              <Checkbox
                color="primary"
                checked={checked}
                onChange={handleChecked}
              />
            }
            label="I acknowledge all associated data including all logs,
          screenshots, and videos will be deleted. If the agent is still
          installed, the computer will reappear and new data will be
          collected."
          />
          <label htmlFor={'delete-computer-dialog-content'}>
            {(softDeletesEnabled || delayedDelete) && (
              <span>
                *Please allow up to 12 hours for the deletion to complete.&nbsp;
                <strong>
                  Computer data will be reflected in other reports until this
                  action is complete.{' '}
                </strong>
              </span>
            )}
          </label>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} autoFocus>
          Cancel
        </Button>
        <Button
          onClick={handleDelete}
          variant="contained"
          color="primary"
          disabled={!checked}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
