import { BundleFlag } from '../enums/BundleFlag';
import { Role } from '../enums/Roles';
import authorization from '../helpers/authorization';
import { IScreenshotSettingsStore } from '../models/IScreenshotSettingsStore';
import {
  getRealtimeSettingsDtoCache,
  IRealtimeSettingsDtoCache
} from '../caches/realtimeSettingsDtoCache';
import { SCREENSHOT_SETTINGS_STORE_DEFAULT } from '../stores/screenshotSettingsStore';

const mapRealtimeSettingsDtoStoreToScreenshotSettingsStore = (
  realtimeSettingsDtoStore: IRealtimeSettingsDtoCache
): IScreenshotSettingsStore => {
  const { thumbnailFeatureFlag, screenshotFeatureFlag } =
    realtimeSettingsDtoStore.realtimeSettings;
  const paidPlan = authorization.hasFeature(BundleFlag.PaidFunctionality);
  const roleAuthorized = !authorization.hasAnyRole([
    Role.Viewer,
    Role.SupportBasic
  ]);
  return {
    thumbnailsEnabled:
      thumbnailFeatureFlag &&
      screenshotFeatureFlag &&
      paidPlan &&
      roleAuthorized,
    screenshotsEnabled: screenshotFeatureFlag
  };
};

export const fetchSettings = async (): Promise<IScreenshotSettingsStore> => {
  try {
    const response = await getRealtimeSettingsDtoCache();
    return mapRealtimeSettingsDtoStoreToScreenshotSettingsStore(response);
  } catch (error) {
    console.error(
      'ActivTrak Error: Error fetching screenshot settings.',
      error
    );
    return SCREENSHOT_SETTINGS_STORE_DEFAULT;
  }
};
