import React, { useCallback } from 'react';
import { ColDef } from 'ag-grid-community';
import { GridColumnsFilter } from '../../../common/components/ReportFilters/components/GridColumnsFilter';
import { IScheduleAdherenceUsersDto } from '../models/ScheduleAdherence.types';
import { mapColumnsToColumnState } from '../utils/workingHoursReport.utils';
import {
  resetScheduleAdherenceColumns,
  setScheduleAdherenceColumns
} from '../stores/scheduleAdherenceColumnsStore';
import { useScheduleAdherenceReportStore } from '../hooks/useScheduleAdherenceReportStore';
import { useScheduleAdherenceViewStore } from '../hooks/useScheduleAdherenceViewStore';

export const ScheduleAdherenceColumnSelector = () => {
  const { view } = useScheduleAdherenceViewStore((s) => s);
  const { columnSelectorFields } = useScheduleAdherenceReportStore((s) => s);

  const handleColumnsSubmit = useCallback(
    (selectedColumns: ColDef<IScheduleAdherenceUsersDto>[]): void => {
      const mapped = mapColumnsToColumnState(selectedColumns);
      setScheduleAdherenceColumns({ [view]: mapped });
    },
    [view]
  );

  const handleRestoreColumns = useCallback(() => {
    resetScheduleAdherenceColumns();
  }, []);
  return (
    <GridColumnsFilter
      columns={columnSelectorFields}
      onSubmit={handleColumnsSubmit}
      onRestoreDefaults={handleRestoreColumns}
    />
  );
};
