import React from 'react';
import { IconButton, TextField } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

type TextFieldContainerProps = {
  showSearch?: boolean;
  setShowSearch?: React.Dispatch<React.SetStateAction<boolean>>;
};

const TextFieldContainer = ({
  showSearch,
  setShowSearch
}: TextFieldContainerProps) => (
  <TextField
    placeholder={'Teams'}
    variant="outlined"
    fullWidth
    onClick={setShowSearch ? () => setShowSearch(!showSearch) : undefined}
    InputProps={{
      readOnly: true,
      endAdornment: (
        <IconButton>
          {!showSearch ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        </IconButton>
      )
    }}
  ></TextField>
);

export default TextFieldContainer;
