import React, { MutableRefObject, useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  Box
} from '@mui/material';
import { ICoreCategory } from '../../models';
import PercentInput from './PercentInput';
import {
  DialogContentStyled,
  TotCss
} from '../../styles/TimeOnTask.view.styles';
import DialogActionsBox from './DialogActionsBox';
import BackButtonBox from './BackButtonBox';

type ComponentProps = {
  open: boolean;
  onClose: () => void;
  backToStep2: () => void;
  onSaveConfiguration: () => void;
  coreCategoryRef: MutableRefObject<ICoreCategory>;
};

export const Step3Dialog = (props: ComponentProps) => {
  const { open, onClose, backToStep2, onSaveConfiguration, coreCategoryRef } =
    props;

  const [percentValue, setPercentValue] = useState<number | null>(null);

  const handlePercentBlur = (value: number | null) => {
    setPercentValue(value);
  };

  const onSave = () => {
    coreCategoryRef.current.targetPercentage = percentValue;
    setPercentValue(null);
    onSaveConfiguration();
  };

  useEffect(() => {
    if (open) {
      setPercentValue(coreCategoryRef.current.targetPercentage);
    }
  }, [coreCategoryRef, open]);

  const onBackToStep2 = () => {
    if (percentValue) {
      coreCategoryRef.current.targetPercentage = percentValue;
    }
    backToStep2();
  };

  return (
    open && (
      <Dialog open={open} onClose={onClose}>
        <BackButtonBox onClick={onBackToStep2} />
        <Box width={692} maxHeight={353}>
          <DialogTitle>
            Step 3 of 3: Set your Team’s Core Activity Time Goal
          </DialogTitle>
          <DialogContentStyled>
            <Typography sx={TotCss.instructions}>
              Determine the percentage of productive time your team should
              dedicate to their core categories.
            </Typography>
            <PercentInput
              defaultValue={coreCategoryRef.current.targetPercentage}
              onBlur={handlePercentBlur}
            />
            <Typography sx={{ ...TotCss.instructions, marginTop: '12px' }}>
              Ex. 70% of 7 Productive Hrs/Day Goal = 4.9 Core Activity Hrs/Day
            </Typography>
          </DialogContentStyled>
          <DialogActions>
            <DialogActionsBox
              close={onClose}
              onClick={onSave}
              disabled={!percentValue}
              confirmText={'Save configuration'}
            />
          </DialogActions>
        </Box>
      </Dialog>
    )
  );
};
