import {
  deleteReportingData,
  fetchReportingData,
  postReportingData
} from '../helpers';
import { ApiRoutes } from '../constants/routesApi';
import { IExportRequest, IExport } from '../models/IExport';

export const fetchAllExports = async (): Promise<IExport[]> => {
  const exportStatuses = await fetchReportingData<IExport[]>({
    path: ApiRoutes.exports.fetchAllExports
  });

  return exportStatuses;
};

export const fetchExport = async (exportId: string): Promise<IExport> => {
  const exportStatus = await fetchReportingData<IExport>({
    path: ApiRoutes.exports.fetchExport.replace(':id', exportId)
  });

  return exportStatus;
};

export const createExport = async (
  reportParams: IExportRequest
): Promise<IExport> => {
  return await postReportingData<IExport>({
    path: ApiRoutes.exports.create,
    args: reportParams
  });
};

export const cancelExport = async (id: string): Promise<void> => {
  if (!id) {
    throw new Error('Export id is required');
  }

  await postReportingData<void>({
    path: ApiRoutes.exports.cancel.replace(':id', id)
  });
};

export const deleteExport = async (id: string): Promise<void> => {
  await deleteReportingData<void>({
    path: ApiRoutes.exports.delete.replace(':id', id)
  });
};
