import React from 'react';
import { IconButton, Chip, Box, Typography } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { IInsightsCategory } from '../../models';
import { gray0, gray6 } from '../../../common/constants';
import ClearIcon from '@mui/icons-material/Clear';
import { StyledCategoryBox } from '../../styles/TimeOnTask.view.styles';

type CategoryTextContainerProps = {
  showSearch?: boolean;
  setShowSearch?: React.Dispatch<React.SetStateAction<boolean>>;
  isHideable: boolean;
  checkedItems: IInsightsCategory[];
  setCheckedItems: React.Dispatch<React.SetStateAction<IInsightsCategory[]>>;
};

const CategoryTextContainer = ({
  showSearch,
  setShowSearch,
  isHideable,
  checkedItems,
  setCheckedItems
}: CategoryTextContainerProps) => {
  const handleDelete = (categoryId: string) => {
    setCheckedItems((prev) =>
      prev.filter((item) => item.categoryId !== categoryId)
    );
  };

  return (
    <StyledCategoryBox
      onClick={setShowSearch ? () => setShowSearch(!showSearch) : undefined}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          width: 'calc(100% - 40px)'
        }}
      >
        {checkedItems.length === 0 ? (
          <Typography sx={{ marginLeft: '4px', color: gray6 }}>
            Categories
          </Typography>
        ) : (
          checkedItems.map((item) => (
            <Chip
              key={item.categoryId}
              label={item.category}
              color="primary"
              size="small"
              onDelete={() => handleDelete(item.categoryId)}
              deleteIcon={<ClearIcon sx={{ fill: gray0 }} />}
              sx={{
                borderRadius: '4px',
                marginRight: '4px',
                marginBottom: '4px',
                color: gray0,
                fontWeight: 500
              }}
            />
          ))
        )}
      </Box>
      {isHideable && (
        <IconButton>
          {!showSearch ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        </IconButton>
      )}
    </StyledCategoryBox>
  );
};

export default CategoryTextContainer;
