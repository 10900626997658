import {
  detailTypeEmployeeId,
  detailTypeIdDisplay
} from '../constants/DetailTypeNames';
import {
  ENTER_UNIQUE_VALUE,
  ENTER_VALID_EMAIL,
  ENTER_VALID_EMPLOYEE_ID
} from '../constants/ErrorMessages';
import { IAgentMove } from '../models/IAgentMove';
import {
  IValueValidation,
  IUserIdentity,
  IValueValidationPrimary
} from '../models/IUserIdentity';
import { PageDirection } from '../models/PageDirection';

export const isEmail = (search: string): boolean => {
  const regexp = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
  return regexp.test(search);
};

export const validateUnique = (
  emailValue: string,
  selectedIdentity: IUserIdentity,
  dataItem: IValueValidation
) => {
  if (selectedIdentity && selectedIdentity.emails) {
    for (const emailId of selectedIdentity.emails) {
      if (
        emailId.value == emailValue &&
        emailId.originalValue &&
        dataItem.originalValue !== emailId.originalValue
      ) {
        dataItem.validationFailed = true;
        dataItem.failedExplanation = ENTER_UNIQUE_VALUE;
      }
    }
  }
};

export const typeDisplayName = (typeName: string) => {
  switch (typeName) {
    case detailTypeEmployeeId:
      return detailTypeIdDisplay;
    default:
      return typeName;
  }
};

const validateAllFieldsPopulated = (
  dataItem: IValueValidation,
  proposedValue: string
) => {
  if (!proposedValue || proposedValue.length == 0) {
    dataItem.validationFailed = true;
  }
};

export const validateEmailArray = (emails: IValueValidationPrimary[]) => {
  let validationFailed = false;
  emails?.forEach((email) => {
    email.validationFailed = !isEmail(email.value);
    validationFailed = validationFailed || email.validationFailed;
    //don't set error message if new row hasn't been fully populated
    if (email.value && email.value.length > 0 && email.validationFailed) {
      email.failedExplanation = ENTER_VALID_EMAIL;
    } else {
      email.failedExplanation = ' ';
    }
  });
  return validationFailed;
};

export const validateEmail = (
  dataItem: IValueValidation,
  proposedValue: string
) => {
  dataItem.validationFailed = !isEmail(proposedValue);
  //don't set error message if new row hasn't been fully populated
  if (
    dataItem.validationFailed &&
    (dataItem.originalValue || (proposedValue && proposedValue.length > 0))
  ) {
    dataItem.failedExplanation = ENTER_VALID_EMAIL;
  }
};

export const validateEmployeeId = (
  dataItem: IValueValidation,
  proposedType: string,
  proposedValue: string
) => {
  if (proposedType == detailTypeEmployeeId) {
    dataItem.validationFailed = !proposedValue || proposedValue.length == 0;
    if (dataItem.validationFailed) {
      if (
        dataItem.originalValue ||
        (proposedType &&
          proposedType.length > 0 &&
          proposedValue &&
          proposedValue.length > 0)
      ) {
        dataItem.failedExplanation = ENTER_VALID_EMPLOYEE_ID;
      }
    }
  }
};

export const validateValueChange = (
  dataItem: IValueValidation,
  proposedValue: string,
  selectedIdentity: IUserIdentity
) => {
  if (dataItem) {
    dataItem.validationFailed = false;
    dataItem.failedExplanation = ' ';
    validateAllFieldsPopulated(dataItem, proposedValue);
    validateEmail(dataItem, proposedValue);
    validateUnique(proposedValue, selectedIdentity, dataItem);
  }
};

export const GetNextUserIdFromArray = (
  nextPage: PageDirection,
  identities: number[],
  selectedIdentityId: number
): number => {
  let selectedIndex = identities?.findIndex((x) => x == selectedIdentityId);
  let nextIdentityId: number = -1;
  let iterationCount = 0;
  //skip over orphaned agents
  while (
    identities &&
    iterationCount < identities.length && //no infinite loops
    nextIdentityId == -1
  ) {
    ++iterationCount;
    if (nextPage == PageDirection.Previous) {
      selectedIndex =
        selectedIndex > 0 ? selectedIndex - 1 : identities.length - 1;
    } else {
      selectedIndex =
        selectedIndex >= identities.length - 1 ? 0 : selectedIndex + 1;
    }
    nextIdentityId = selectedIndex >= 0 ? identities[selectedIndex] : null;
  }

  return nextIdentityId;
};

export const GetNextUserId = (
  nextPage: PageDirection,
  identities: IUserIdentity[],
  selectedIdentityId: number
): number => {
  let selectedIndex = identities?.findIndex((x) => x.id == selectedIdentityId);
  let nextIdentity: IUserIdentity = null;
  let iterationCount = 0;
  //skip over orphaned agents
  while (
    identities &&
    iterationCount < identities.length && //no infinite loops
    (nextIdentity == null || !nextIdentity?.id)
  ) {
    ++iterationCount;
    if (nextPage == PageDirection.Previous) {
      selectedIndex =
        selectedIndex > 0 ? selectedIndex - 1 : identities.length - 1;
    } else {
      selectedIndex =
        selectedIndex >= identities.length - 1 ? 0 : selectedIndex + 1;
    }
    nextIdentity = selectedIndex >= 0 ? identities[selectedIndex] : null;
  }
  if (!nextIdentity?.id) {
    return -1;
  }
  return nextIdentity.id;
};

export const getMoveAgentArgs = (
  selectedAgentUserId: number,
  destination: IUserIdentity,
  newTracked: boolean
): IAgentMove => {
  if (destination) {
    const moveAgentArgs = {
      email: null,
      userid: { value: selectedAgentUserId.toString() },
      displayName: {
        value: destination.displayName?.value ?? destination.emailsDisplay
      },
      entity: { value: destination.id.toString() },
      revision: { value: destination.revision.toString() },
      tracking: newTracked
    };
    return moveAgentArgs;
  }
  return null;
};
