import { xfetch } from './utils';
import { childArrayMap } from '../../models/childArrayMap';
import { selfAccountMap } from '../../models/selfAccountMap';
import { handleRequestSort } from './sortChildAccounts';
import { PAID_DASHBOARD_TYPE } from '../constants';


import { postLoginStore } from '../../../../_reactivtrak/src/common/stores/postLoginStore';
import { getApiDomainStore } from "../../../../_reactivtrak/src/common/stores/apiDomainStore";

const baseUrl = getApiDomainStore().frontendApi;

export async function addNewCustomer(newCustomer) {
  try {
    const result = await xfetch(
      `${baseUrl}/api/commandcenter/accounts/childaccount`,
      { method: 'POST' },
      { ...newCustomer }
    );

    return result.json();
  } catch (e) {
    throw new Error(e);
  }
}

export async function getChildAccounts(dashboardType = PAID_DASHBOARD_TYPE) {
  try {
    const result = await xfetch(
      `${baseUrl}/api/commandcenter/accounts/children?subscriptionType=${dashboardType}`,
      {
        method: 'GET'
      }
    );

    if (!result) {
      return childArrayMap([]);
    }

    const { childStats } = await result.json();
    return handleRequestSort(childArrayMap(childStats));
  } catch (e) {
    throw new Error(
      'Could not get your Accounts from the server, please try again later'
    );
  }
}

export async function getEnterprise() {
  try {
    const result = await xfetch(
      `${baseUrl}/api/commandcenter/accounts/enterprise`,
      {
        method: 'GET'
      }
    );

    if (!result) {
      return {};
    }

    return await result.json();
  } catch (e) {
    throw new Error(
      'Could not get your Enterprise Accounts from the server, please try again later'
    );
  }
}

export async function getChildAccountsMinimal(dashboardType = 'all') {
  try {
    const result = await xfetch(
      `${baseUrl}/api/commandcenter/accounts/children/minimal?subscriptionType=${dashboardType}`,
      {
        method: 'GET'
      }
    );

    if (!result) {
      return childArrayMap([]);
    }

    const { childStats } = await result.json();
    return handleRequestSort(childArrayMap(childStats));
  } catch (e) {
    throw new Error(
      'Could not get your Accounts from the server, please try again later'
    );
  }
}

export async function editChildAccounts(accountId, companyName) {
  try {
    await xfetch(
      `${baseUrl}/api/commandcenter/accounts/${accountId}`,
      { method: 'PUT' },
      { companyName }
    );
  } catch (e) {
    throw new Error('Could not Edit the Company Name, please try again later');
  }
}

export async function getSelfAccounts() {
  try {
    const result = await xfetch(`${baseUrl}/api/commandcenter/accounts/self`, {
      method: 'GET'
    });

    if (!result) {
      return selfAccountMap({});
    }

    const response = await result.json();
    return selfAccountMap(response);
  } catch (e) {
    throw new Error(
      'Could not get you Self Account Data, please try again later'
    );
  }
}

export async function getChildToken(accountId) {
  try {
    const result = await xfetch(
      `${baseUrl}/account/child/token`,
      { method: 'POST' },
      { accountId }
    );
    return await result.json();
  } catch (e) {
    throw new Error(
      'Could not retrieve your child Data, please try again later'
    );
  }
}

export async function linkToParentAccount(childAccountId) {
  try {
    await xfetch(
      `${baseUrl}/account/LinkToParent`,
      { method: 'POST' },
      { childAccountId }
    );
  } catch (e) {
    if (e.message === 'CHARGEBEE_25_LIMIT') {
      throw new Error(
        'Child account was created but could not link to this parent account because the limit was exceeded.'
      );
    }
    throw new Error(
      'Child account was created but could not link due to a server error.'
    );
  }
}

export async function postLoginTemp() {  
  const result = await postLoginStore.getState().fetch();
  return result;
}
