import { useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';
import {
  IAccountSettings,
  IAccountSettingsBase,
  IAccountSettingsStore
} from '../../models';
import authorization from '../authorization';

const defaultState: IAccountSettingsBase = {
  accountSettings: {}
};

//TBD: child account info may require a separate state
export const accountSettingsStore = createStore<IAccountSettingsStore>(
  (set) => ({
    ...defaultState,
    reset: () => {
      set(defaultState);
    }
  })
);

export const getAccountSettings = () => {
  return accountSettingsStore.getState().accountSettings;
};

export const getAccountSetting = (setting: keyof IAccountSettings): any => {
  return accountSettingsStore.getState().accountSettings[setting];
};

export const setAccountSettings = (newSettings: Partial<IAccountSettings>) => {
  const currentSettings = accountSettingsStore.getState().accountSettings;
  const combinedSettings = { ...currentSettings, ...newSettings };
  accountSettingsStore.setState({ accountSettings: combinedSettings });
  authorization.setAuthorizations();
};

export const useAccountSettingsStore = <T>(
  selector: (state: IAccountSettingsStore) => T
) => useStore(accountSettingsStore, selector);
