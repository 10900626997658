import { useCallback, useState } from 'react';
import {
  IGroup,
  GroupsStateProps,
  IGroupsState,
  IAddGroupsDto,
  IForceDeleteMessage,
} from '../../models';
import {
  GroupSearchType,
  GroupType,
  NotificationType,
  SortDirection
} from '../../enums';
import { mapNewGroupResponse, sorter } from '../../helpers';
import { AxiosResponse } from 'axios';
import { IGroupSettings } from '../../../groups/models';
import { mapGroupAdminMix, mapGroupAdminFilter, mapGroupAdminType, formatForceDeleteMessage } from '../../../groups/utils';
import { IGroupsSummaryDto } from '../../models/IGroupsSummaryDto';

export const useGroupsState = ({ service }: GroupsStateProps): IGroupsState => {
  const {
    fetchItems,
    fetchSettings,
    createItem,
    updateItem,
    deleteItem,
    forceDeleteItem,
    uploadCsv,
    downloadCsv,
    updateSettings,
    forceUpdateSettings
  } = service;

  const [groups, setGroups] = useState<IGroup[]>();
  //original groups is like an auto-reset before new filters
  const [originalGroups, setOriginalGroups] = useState<IGroup[]>();
  const [groupsSettings, setGroupsSettings] = useState<IGroupSettings>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [groupsNotification, setGroupsNotification] =
    useState<NotificationType>();
  const [forceDelete, setForceDelete] = useState<boolean>(false);
  const [forceDeleteMessage, setForceDeleteMessage] =
    useState<IForceDeleteMessage>();
  const [forceUpdateGroupsSettings, setForceUpdateGroupsSettings] = useState<boolean>(false);

  const getGroups = useCallback(
    async (params?: object): Promise<void> => {
      setIsLoading(true);
      try {
        const groupsData: IGroupsSummaryDto = await fetchItems(params);
        const grps: IGroup[] = groupsData.groups
          .map((item) => {
            const container: IGroup = {
              id: item.id,
              name: item.name,
              userCount: item.clientCount,
              computerCount: item.deviceCount,
              type: mapGroupAdminType(item),
              userPreview: item.clientPreview,
              computerPreview: item.devicePreview,
              selected: false,
              mix: mapGroupAdminMix(item),
              filter: mapGroupAdminFilter(item)
            };
            return container;
          })
          .filter((g) => g.id >= 0);

        setGroups(grps);
        setOriginalGroups(grps);
        setIsLoading(false);
      } catch (error) {
        setGroupsNotification({ msg: 'Unable to load Groups', type: 'error' });
        console.error('ActivTrak Error: Unable to load Groups', error);
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const init = useCallback(async (): Promise<void> => {
    if (!groups) {
      await getGroups();
    }
  }, [getGroups, groups]);

  const getGroupsSettings = useCallback(
    async (): Promise<void> => {
      try {
        const data = await fetchSettings();
        setGroupsSettings(data);
      } catch (error) {
        setGroupsNotification({
          msg: 'Unable to load Groups Settings',
          type: 'error'
        });
        console.error('ActivTrak Error: Unable to load Groups Settings');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const searchGroups = useCallback(
    (type?: GroupSearchType, value?: number | string): void => {
      let params = {};
      if (type === GroupSearchType.Search) params = { Search: value };
      if (type === GroupSearchType.User) params = { ClientId: value };
      if (type === GroupSearchType.Computer) params = { DeviceId: value };

      getGroups(params);
    },
    [getGroups]
  );

  const filterGroups = useCallback(
    (types: GroupType[]): void => {
      if (types.includes(GroupType.NonExistent)) {
        setGroups([]);
      } else if (!types?.length || types.includes(GroupType.All)) {
        setGroups(originalGroups);
      } else {
        const filteredGrps = [];
        originalGroups.forEach((g) => {
          types.forEach((type) => {
            if (type === GroupType.All || g.filter === type) {
              filteredGrps.push(g);
            }
          });
        });
        setGroups(filteredGrps);
      }
    },
    [originalGroups]
  );

  const updateName = useCallback(async (group: IGroup): Promise<void> => {
    try {
      await updateItem(group);

      const updatedGroups = (groups: IGroup[]): IGroup[] => {
        if (!groups) {
          return;
        }

        const i = groups.findIndex((g) => g.id === group.id);
        if (i === -1) {
          return groups;
        }
        groups[i].name = group.name;
        return groups;
      };
      setGroups(updatedGroups);
      setOriginalGroups(updatedGroups);
      // TEMP: Commentting out until better soultion to tie Group and Groups Notifications Together
      // setGroupsNotification({ msg: 'Success: Name updated.', type: 'success' });
    } catch (error) {
      console.error(
        `ActivTrak Error: Unable to update Group: ${group?.name}`,
        error
      );
      //letting UX handle this since it needs to reset name
      throw new Error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSelectedGroups = useCallback((selectedItems: IGroup[]): void => {
    setGroups((prevState) => {
      const resetData = prevState.map((item) => {
        return { ...item, selected: false };
      });
      selectedItems.forEach((g) => {
        const i = resetData.findIndex((group) => g.id === group.id);
        if (i === -1) {
          return;
        }
        resetData[i] = g;
      });
      return resetData;
    });
  }, []);

  const setSortedGroups = useCallback(
    (sortDirection: SortDirection, sortOrderBy: string): void => {
      const newOrder = sorter(groups, sortDirection, sortOrderBy);
      setGroups(newOrder);
    },
    [groups]
  );

  const createGroup = useCallback(async (name: string): Promise<IGroup> => {
    if (!name) {
      setGroupsNotification({ msg: 'Please add a name', type: 'error' });
    }
    const names: string[] = [name];
    const payload : IAddGroupsDto =
    {
        Names: names
    };
    try {
      const data = await createItem(payload);
      if (data.successful.length==0)
      {
        throw {
          ex: 'Unable to add a Group. There is already a group with this name.',
          error: new Error()
        };
      }
      const group = mapNewGroupResponse(data.successful[0]);
      setGroups((prevState) => [...prevState, ...[group]]);
      setOriginalGroups((prevState) => [...prevState, ...[group]]);
      setGroupsNotification({ msg: 'Group Created', type: 'success' });
      //additional return of data to be used as needed
      return group;
    } catch (error) {
      setGroupsNotification({
        msg:  error.ex || (error  || 'Unable to add a Group'),
        type: 'error'
      });
      console.error(`ActivTrak Error: Unable to add a Group: ${name}`, error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //TODO: this is used when creating a new Group, until a new create group api is created to do everything
  const setMemberCount = (
    groupId: number,
    userIncrement?: number,
    computerIncrement?: number
  ): void => {
    const grps = groups;
    const i = grps.findIndex((g) => g.id === groupId);
    if (i === -1) {
      return;
    }
    const grp = grps[i];
    grp.userCount += userIncrement;
    grp.computerCount += computerIncrement;
    setGroups(grps);
  };

  const deleteGroups = useCallback(
    async (selectedGroups: IGroup[], forceDelete: boolean): Promise<boolean> => {
      try {
        if (forceDelete)
          await forceDeleteItem(selectedGroups);
        else
          await deleteItem(selectedGroups);
        const rmById = (group: IGroup) =>
          !selectedGroups.some((g) => g.id === group.id);
        setGroups((prevState) => prevState?.filter(rmById));
        setOriginalGroups((prevState) => prevState?.filter(rmById));
        resetForceDelete();
        setGroupsNotification({ msg: 'Group Deleted', type: 'success' });
        return true;
      } catch (error) {
        if (forceDelete)
        {
          resetForceDelete();
          setGroupsNotification({
            msg: `Unable to delete groups. ${error}`,
            type: 'error'
          });
        }
        else
        {
          setForceDelete(true);
          const formattedMessage = formatForceDeleteMessage(error);
          setForceDeleteMessage(formattedMessage);
        }
        console.error(
          `ActivTrak Error: Unable to delete groups: ${selectedGroups.reduce((acc, g) => acc += `${g.name},`, '' )}`,
          error
        );
      }
      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const resetForceDelete = () : void => {
    setForceDelete(false)
    setForceDeleteMessage(null);
  };

  const uploadCSV = async (file: File): Promise<number> => {
    try {
      const response = await uploadCsv(file);
      if (response != 200) {
        getGroups();
      }
      //additional return of data to be used as needed
      return response;
    } catch (error) {
      // for UX instances that have additional error handling
      throw new Error(error);
    }
  };

  const downloadCSV = async (): Promise<AxiosResponse> => {
    try {
      return await downloadCsv();
    } catch (error) {
      setGroupsNotification({
        msg: 'Unable to download Groups',
        type: 'error'
      });
      console.error('ActivTrak Error: Unable to download groups', error);
    }
  };

  const updateGroupsSettings = useCallback(
    async (groupSettings: IGroupSettings, forceUpdate: boolean): Promise<void> => {
      try {
        if (forceUpdate)
          await forceUpdateSettings(groupSettings);
        else
          await updateSettings(groupSettings);

        resetForceUpdateGroupsSettings();
        setGroupsSettings(groupSettings);
        setGroupsNotification({
          msg: 'Active Directory settings saved.',
          type: 'success'
        });
        getGroups();
      } catch (error) {
        if (forceUpdate)
        {
          resetForceUpdateGroupsSettings();
          setGroupsNotification({
            msg: 'Error updating group settings.',
            type: 'error'
          });
          console.error(`'Error updating group settings.: ${error}`);
        }
        else
        {
          setForceUpdateGroupsSettings(true);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const resetForceUpdateGroupsSettings = () : void => {
    setForceUpdateGroupsSettings(false);
  };

  return {
    groups,
    originalGroups,
    groupsSettings,
    isLoading,
    init, //initial load if groups array doesn't exist
    getGroups, //subsequent forced calls
    updateName,
    searchGroups,
    filterGroups,
    setMemberCount,
    setSelectedGroups,
    setSortedGroups,
    createGroup,
    deleteGroups,
    uploadCSV,
    downloadCSV,
    getGroupsSettings,
    updateGroupsSettings,
    groupsNotification,
    setGroupsNotification,
    forceDelete,
    forceDeleteMessage,
    resetForceDelete,
    forceUpdateGroupsSettings,
    resetForceUpdateGroupsSettings
  };
};
