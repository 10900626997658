import alarmDetailsGroupTemplate from 'views/alarmDetails/components/alarmDetailsGroups.html?raw';
import { getAccountSettings } from '../../../../../_reactivtrak/src/common/helpers/accountSettings/accountSettingsStore';
import { alarmObjectVerifierFunctions } from '_app/serviceFunctions/alarmObjectVerifierFunctions';
import { uniqWith, union, compact } from 'lodash';

angular.module('app').directive('alarmDetailsGroups', AlarmDetailsGroups);

function AlarmDetailsGroups() {
    return {
        restrict: 'A',
        scope: {
            parentAlarm: '=alarm',
            options: '='
        },
        template: alarmDetailsGroupTemplate,
        controller: AlarmDetailsGroupsCtrl
    };
}

AlarmDetailsGroupsCtrl.$inject = [
    '$scope',
    '$q',
    'templateServiceFunctions',
    'messagesService',
    'atHelperFunctions',
    'filterService',
    'authorizationService'
];

function AlarmDetailsGroupsCtrl(
    $scope,
    $q,
    templateServiceFunctions,
    msg,
    atHelperFunctions,
    filterService,
    authorizationService
) {
    const { filterSettings } = getAccountSettings();
    $scope.filterSettings = filterSettings;
    $scope.alarm = $scope.parentAlarm;
    $scope.paidPlanAlertMessage = msg.get('groupAlarmsPaidPlanAvailableOnly');
    $scope.isAlarmGroupsEnabled = authorizationService.hasFeature('isAlarmGroupsEnabled');
    const allGroupsId = '-1';

    function groupsUpdated() {
        let selectedGroups = this.value();
        const lastGroupAdded = selectedGroups && selectedGroups[selectedGroups.length - 1];

        if (!selectedGroups || selectedGroups.length === 0 || (lastGroupAdded && lastGroupAdded === allGroupsId)) {
            selectedGroups = [allGroupsId];
        } else {
            const allGroupsIndex = selectedGroups.indexOf(allGroupsId);

            if (allGroupsIndex > -1) {
                selectedGroups.splice(allGroupsIndex, 1);
            }
        }

        if ($scope.alarm && $scope.alarm.groups !== selectedGroups) {
            $scope.alarm.groups = selectedGroups;
        }

        atHelperFunctions.safeApply($scope.$root);
    }

    function groupTemplate(group) {
        return templateServiceFunctions._reactUserGroupTypeLabel(group);
    }

    function setGroupSelectionEnable() {
        if ($scope.groupSelectControl) {
            $scope.groupSelectControl.enable($scope.options.isPaidPlan);
        }
    }

    const groupsDataSource = new kendo.data.DataSource({
        serverFiltering: true,
        transport: {
            read: function (options) {
                const filter =
                    options.data.filter &&
                    options.data.filter.filters &&
                    options.data.filter.filters[0] &&
                    options.data.filter.filters[0].value
                        ? options.data.filter.filters[0].value
                        : null;

                const selectedGroups = $scope.alarm.groups
                    ? filterService.getEntitiesById('groups', $scope.alarm.groups)
                    : $q.when({
                          data: {
                              data: []
                          }
                      });

                selectedGroups.then(function (idsResult) {
                    filterService.getEntities('groups', filter).then(function (searchResult) {
                        const result = uniqWith(union(idsResult.data.data, searchResult.data.data), function (u1, u2) {
                            return u1.userId === u2.userId;
                        });
                        const data = compact(
                            result.map(function (item) {
                                if (parseInt(item.userId) > 0) {
                                    item.filterMode = 'both';
                                    return item;
                                }
                                return null;
                            })
                        );
                        data.unshift({
                            userName: 'All Users and Computers',
                            userId: allGroupsId,
                            userType: 'AllUsers',
                            groupType: 'Both',
                            skipMemberCount: true
                        });
                        options.success(data);
                    });
                });
            }
        }
    });

    $scope.selectOptions = {
        dataSource: groupsDataSource,
        placeholder: 'Select groups...',
        footerTemplate: $scope.filterSettings.optimize ? 'Results Limited' : false,
        dataTextField: 'userName',
        dataValueField: 'userId',
        valuePrimitive: true,
        autoBind: true,
        itemTemplate: function (data) {
            return templateServiceFunctions._reactUserGroupTypeLabel(data);
        },
        tagTemplate: groupTemplate,
        change: groupsUpdated,
        delay: 200,
        clearButton: false
    };

    function isComponentValid() {
        return {
            invalidReasons: alarmObjectVerifierFunctions.verifyAlarmModule($scope.alarm, $scope.component.type)
        };
    }

    function updatePayload(payload) {
        payload.groups = payload.groups.length === 1 && payload.groups[0] === allGroupsId ? null : payload.groups;

        return true;
    }
    // eslint-disable-next-line
    function alarmUpdated(oldAlarm) {
        if ($scope.alarm) {
            $scope.alarm.groups = $scope.alarm.groups || [allGroupsId];
        }
    }

    $scope.component = {
        type: alarmObjectVerifierFunctions.moduleTypes.groups,
        isValid: isComponentValid,
        updatePayload: updatePayload,
        alarmUpdated: alarmUpdated
    };

    $scope.options.register($scope.component);

    $scope.$watch('options.isPaidPlan', setGroupSelectionEnable);
    $scope.$watch('groupSelectControl', setGroupSelectionEnable);
}
