'use strict';

angular.module('app').controller('AppLoadingCtrl', AppLoadingCtrl);

AppLoadingCtrl.$inject = ['$state', '$window', 'authorizationService', 'routesService', 'activTrakAcademyService'];

function AppLoadingCtrl($state, $window, authorizationService, routesService, activTrakAcademyService) {
    // App Loading Page was added to ensure app settings are fully loaded before any other checks are made.
    // This was needed for routing to restricted pages and needing the role access info to be in place.
    var toState = $state.params.toState;
    var toParams = $state.params.toParams;
    var next = $state.params.next;
    var stateName = toState ? toState.name : null;
    var stateParams = toParams ? toParams : null;

    var queryStringToObject = function (queryString) {
        if (!queryString) return {};
        var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
        var result = {};
        pairs.forEach(function (pair) {
            var parts = pair.split('=');
            var key = decodeURIComponent(parts[0]);
            var value = decodeURIComponent(parts[1] || '');
            if (result[key] !== undefined) {
                if (!Array.isArray(result[key])) {
                    result[key] = [result[key]];
                }
                result[key].push(value);
            } else {
                result[key] = value;
            }
        });
        return result;
    };

    // If CC Role, redirect to CC
    // Deep linking is not allowed for CC due to multiple account access
    if (authorizationService.hasRole(['ccaccess', 'ccenterpriseaccess'])) {
        $window.location.href = '/#/cc/dashboard'; // TODO: Convert to app.cc once CC routes are added to the same router
        return;
    } else if (next && next !== '/') {
        var route = routesService.getRouteByUrl(next);
        if (route && (authorizationService.hasRouteByName(route.name) || route.name === 'app.help.academy')) {
            var nextParts = next.split('?');
            stateName = route.name;
            stateParams = queryStringToObject(nextParts[1]);
        }
    }

    if (stateName === 'app.help.academy') {
        activTrakAcademyService.redirectToAcademy(stateParams, true);
    } else if (stateName && stateName !== 'app.loading' && stateName !== 'app') {
        $state.go(stateName, stateParams);
    } else {
        authorizationService.redirectToAuthorizedHome($state);
    }
}
