import { styled } from '@mui/material/styles';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { primaryAqua } from '../../constants';

// NOTE: added 'margin-left: -16px' to counter-act the additional 'padding-left: 16px' that rows have
export const CheckBoxBulkSelector = styled('div')(({ children }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-16px',
    button: {
      position: 'absolute',
      top: '10px',
      left: '22px',
      minWidth: 0,
      padding: '5px 8px',
      backgroundColor: 'transparent',
      '&:hover, &:focus': { backgroundColor: 'transparent' }
    },
    cursor: children[0].props.disabled ? 'not-allowed' : 'pointer'
  };
});

export const IndeterminateCheckBoxIconSC = styled(IndeterminateCheckBoxIcon)`
  color: ${primaryAqua};
`;
