import React from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { hideModal, showModal } from '../../../common/stores/globalModalStore';
import { fontColor900 } from '../../../common/constants';
import { GlobalModalContent } from '../templates/GlobalModalContent';
import { OutlinedButton, PrimaryButton } from '../styles/ModalBtn.styles';
import { cancelExport } from '../../../common/utils/exportStore.utils';
import { ExportCanceledModalContent } from './ExportCanceledModalContent';
import {
  getCompletedExport,
  getInProgressExport
} from '../../../common/stores/exportStore';
import { ExportErrorModalContent } from './ExportErrorModalContent';

export const ExportInitiatedModalContent = () => {
  const handleCancelClick = async () => {
    const { id } = getInProgressExport() ?? getCompletedExport();

    try {
      await cancelExport(id);
      showModal(<ExportCanceledModalContent />);
    } catch (error) {
      console.error(
        `ActivTrak Error: Failed to cancel export for id ${id}:`,
        error
      );
      showModal(<ExportErrorModalContent />);
    }
  };

  return (
    <GlobalModalContent>
      <DialogTitle>Export Initiated</DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            color: fontColor900
          }}
        >
          Your data is being exported now! When the export is complete, the
          download will start automatically. You can still browse reports and
          dashboards while we export your data.
        </Typography>
        <Typography
          mt={2}
          sx={{
            color: fontColor900
          }}
        >
          If you'd like to initiate a different export, cancel the current one
          and start over.
        </Typography>
      </DialogContent>
      <DialogActions>
        <OutlinedButton variant="outlined" onClick={handleCancelClick}>
          Cancel Export
        </OutlinedButton>
        <PrimaryButton onClick={hideModal}>Close</PrimaryButton>
      </DialogActions>
    </GlobalModalContent>
  );
};
