import {
  DialogContent,
  Box,
  Dialog,
  Typography,
  DialogTitle
} from '@mui/material';
import React from 'react';
import {
  DialogActionsStyled,
  GetStartedTypogStyled
} from '../../styles/TimeOnTask.view.styles';
import DialogActionsBox from './DialogActionsBox';

type ComponentProps = {
  open: boolean;
  onClose: () => void;
  getStarted: () => void;
};

export const GetStartedDialog = (props: ComponentProps) => {
  const { open, onClose, getStarted } = props;

  const close = () => {
    onClose();
  };

  return (
    open && (
      <Dialog open={open} onClose={close}>
        <Box width={590} maxHeight={320}>
          <DialogTitle>Get started with Activity Alignment</DialogTitle>
          <DialogContent>
            <Box>
              <GetStartedTypogStyled>
                Activity Alignment helps managers improve team member
                performance and role alignment. Get tailored guidance based on
                team members’ productivity and time spent in the tools critical
                to their role.
              </GetStartedTypogStyled>
              <Typography>
                To get started, define the core categories for your team and set
                a goal for how much time should be spent on them.
              </Typography>
            </Box>
          </DialogContent>
          <DialogActionsStyled>
            <DialogActionsBox
              close={close}
              onClick={getStarted}
              disabled={false}
              confirmText={'Get started'}
            />
          </DialogActionsStyled>
        </Box>
      </Dialog>
    )
  );
};
