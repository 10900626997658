import { useCallback, useState } from 'react';
import {
  ITicketsState,
  TicketsStateProps,
  ITicket,
  ITicketComment,
  ITicketPayload,
  ITicketCommentPayload
} from '../models';
import { NotificationType, SortDirection } from '../../common/enums';
import { sorter } from '../../common/helpers';
import { mapToComment, mapToTicket, searchSupportTickets } from '../utils';
import { ITicketFilter } from '../models/ITicketFilter';

export const useTicketsState = ({
  service
}: TicketsStateProps): ITicketsState => {
  const { fetchTickets, postTicket, fetchTicketComments, postTicketComment } =
    service;

  const [tickets, setTickets] = useState<ITicket[]>();
  const [originalTickets, setOriginalTickets] = useState<ITicket[]>();
  const [currentTicket, setCurrentTicket] = useState<ITicket>();
  const [currentTicketComments, setCurrentTicketComments] =
    useState<ITicketComment[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCommentsLoading, setIsCommentsLoading] = useState<boolean>(false);
  const [percentUploaded, setPercentUploaded] = useState<number>(0);
  const [ticketsNotification, setTicketsNotification] =
    useState<NotificationType>();

  //get the progress data from the axios call by passing an upload config
  const progressConfig = {
    onUploadProgress: (progressEvent: ProgressEvent) => {
      const { loaded, total } = progressEvent;
      const percentCompleted = Math.round((loaded * 100) / total);
      setPercentUploaded(percentCompleted);
    }
  };

  const getTickets = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchTickets();
      const mapped: ITicket[] = response.map((item) => mapToTicket(item));
      setTickets(mapped);
      //keep a copy of all tickets for local search resetting
      setOriginalTickets(mapped);
    } catch (error) {
      setTicketsNotification({
        msg: 'Unable to load support tickets',
        type: 'error'
      });
      console.error('ActivTrak Error: Unable to load support tickets', error);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = useCallback(() => {
    if (!tickets) {
      getTickets();
    }
  }, [getTickets, tickets]);

  const addTicket = useCallback(async (payload: ITicketPayload) => {
    //passing simple object of payload, instead of FormData, here in case the data needs to be used elsewhere at this point
    if (payload?.body) {
      const formData = new FormData();
      if (payload.subject) formData.append('Subject', payload.subject);
      if (payload.body) formData.append('Body', payload.body);
      if (payload.attachments)
        formData.append('Attachment', payload.attachments);

      try {
        await postTicket(formData, progressConfig);
        setPercentUploaded(undefined);

        setTicketsNotification({
          msg: 'New ticket was successfully created. It will be shown in a few minutes.',
          type: 'success'
        });
      } catch (error) {
        setTicketsNotification({
          msg: 'An error occurred while creating the ticket.',
          type: 'error'
        });
        console.error(
          'ActivTrak Error: Unable to create support ticket',
          error
        );
      }
    } else {
      setTicketsNotification({
        msg: 'Please add some ticket information',
        type: 'error'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTicketComments = useCallback(async (id) => {
    setIsCommentsLoading(true);
    try {
      const response = await fetchTicketComments(id);
      const mapped: ITicketComment[] = response.map((item) =>
        mapToComment(item)
      );
      setCurrentTicketComments(mapped);
    } catch (error) {
      setTicketsNotification({
        msg: 'Unable to load support ticket comments',
        type: 'error'
      });
      console.error(
        'ActivTrak Error: Unable to load support ticket comments',
        error
      );
    } finally {
      setIsCommentsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addTicketComment = useCallback(
    async (id: number, payload: ITicketCommentPayload) => {
      //passing simple object of payload, instead of FormData, here in case the data needs to be used elsewhere at this point
      if (payload?.body) {
        const formData = new FormData();
        if (payload.body) formData.append('Body', payload.body);
        if (payload.attachments)
          formData.append('Attachment', payload.attachments);

        try {
          await postTicketComment(id, formData, progressConfig);
          setPercentUploaded(undefined);

          setTicketsNotification({
            msg: 'The comment was successfully added.',
            type: 'success'
          });
        } catch (error) {
          setTicketsNotification({
            msg: 'An error occurred while adding a comment to the ticket.',
            type: 'error'
          });
          console.error(
            `ActivTrak Error: Unable to add a comment to support ticket: ${id}`,
            error
          );
        }
      } else {
        setTicketsNotification({
          msg: 'Please add some comment information',
          type: 'error'
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const searchTickets = useCallback(
    (filter: string): void => {
      if (filter) {
        const filtered = searchSupportTickets(originalTickets, filter);
        setTickets(filtered);
      } else {
        setTickets(originalTickets);
      }
    },
    [originalTickets]
  );

  const filterTickets = useCallback(
    (filter: ITicketFilter) => {
      if (originalTickets?.length && filter) {
        if (filter?.filterValue !== '') {
          const filtered = originalTickets.filter(
            (item) => item.status === filter.filterValue
          );
          return setTickets(filtered);
        }
        setTickets(originalTickets);
      }
    },
    [originalTickets]
  );

  const setSortedTickets = useCallback(
    (sortDirection: SortDirection, sortOrderBy: string): void => {
      const newOrder = sorter(tickets, sortDirection, sortOrderBy);
      setTickets(newOrder);
    },
    [tickets]
  );

  return {
    tickets,
    currentTicket,
    currentTicketComments,
    isLoading,
    isCommentsLoading,
    percentUploaded,
    ticketsNotification,
    getTickets,
    filterTickets,
    setTicketsNotification,
    setCurrentTicket,
    init,
    setSortedTickets,
    searchTickets,
    addTicket,
    getTicketComments,
    addTicketComment
  };
};
