'use strict';
import { iconResolverServiceFunctions } from '_app/serviceFunctions/iconResolverServiceFunctions';
import { browserServiceFunctions } from '_app/serviceFunctions/browserServiceFunctions';
import { atHelperFunctions } from '_app/serviceFunctions/atHelperFunctions';
import ProductivityStatus from '../constants/productivityStatus';

let _alarmTypeClasses = {
    Activity: 'fa-bell',
    SafeSearch: 'icon-at-safe-search',
    Audit: 'fa-eye',
    Usb: 'fa-usb'
};

let _alarmScreenshotsClasses = {
    None: 'fa-ellipsis-h',
    Screenshot: 'icon-at-screenshot',
    Multi: 'icon-at-multiple-screenshots-camera',
    Video: 'pg-video'
};

function getTextWidth(text, font) {
    let canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'));
    let context = canvas && canvas.getContext && canvas.getContext('2d');
    context.font = font;
    let metrics = context.measureText && context.measureText(text);
    return metrics ? metrics.width : 0;
}

function showGroupMembers(userType, skipMemberCount) {
    return userType !== 'User' && userType !== 'Computer' && userType !== 'Alias' && !skipMemberCount;
}

function getMemberText(userType, data) {
    if (!showGroupMembers(userType, data.skipMemberCount)) {
        return '';
    }

    let count;
    switch (data.filterMode) {
        case 'users':
            count = data.userCount;
            break;
        case 'both':
            count = data.userCount + data.computerCount;
            break;
        default:
            count = data.computerCount;
    }

    return ' (' + (count ? count : 'No') + ' group member' + (count === 1 ? ')' : 's)');
}

function calculateDurations(duration) {
    if (typeof duration === 'string') {
        duration = convertTimeStringToSeconds(duration);
    }

    return {
        hours: Math.floor(duration / 3600),
        minutes: Math.floor((duration % 3600) / 60),
        seconds: duration % 60
    };
}

function countTooltipTemplate(members, mode) {
    let memberList = {
        users: [],
        computers: []
    };

    let usersListLength = members.users && members.users.length ? members.users.length : 0;
    let computersListLength = members.computers && members.computers.length ? members.computers.length : 0;
    if (usersListLength >= computersListLength) {
        memberList = addMembers(members, 'computers', 'users');
    } else {
        memberList = addMembers(members, 'users', 'computers');
    }

    let tooltipHTML = '';
    if (memberList.users.length > 0) {
        if (mode === 'both') {
            tooltipHTML += '<div><strong style="text-decoration: underline;">Users</strong></div>';
        }
        for (let i = 0; i < memberList.users.length; i++) {
            tooltipHTML +=
                '<div class="at-computers-tooltip-item">' +
                minimizeString(20, memberList.users[i]) +
                (i >= memberList.users.length - 1 ? '' : ',') +
                '</div>';
        }
        if (memberList.computers.length > 0) {
            tooltipHTML += '<br/>';
        }
    }

    if (memberList.computers.length > 0) {
        if (mode === 'both') {
            tooltipHTML += '<div><strong style="text-decoration: underline;">Computers</strong></div>';
        }
        for (let i = 0; i < memberList.computers.length; i++) {
            tooltipHTML +=
                '<div class="at-computers-tooltip-item">' +
                minimizeString(20, memberList.computers[i]) +
                (i >= memberList.computers.length - 1 ? '' : ',') +
                '</div>';
        }
    }

    return tooltipHTML === '' ? undefined : '<div class="at-computers-tooltip">' + tooltipHTML + '</div>';
}

function standardTooltipContent(e) {
    let element = e.target[0];
    let elemRect = atHelperFunctions.getElementOffset(e.target);
    let paddingLeft = parseFloat(e.target.css('padding-left'));
    let paddingRight = parseFloat(e.target.css('padding-right'));
    let elemWidth = elemRect.right - elemRect.left - (paddingLeft + paddingRight);
    let childrenWidth = 0;
    let children = e.target.children().toArray();

    children.forEach((i) => {
        let childRect = atHelperFunctions.getElementOffset($(i));
        childrenWidth +=
            childRect.right -
            childRect.left +
            parseFloat($(i).css('margin-left')) +
            parseFloat($(i).css('margin-right'));
    });

    if (elemWidth < childrenWidth || element.offsetWidth < element.scrollWidth) {
        return e.target.text();
    } else {
        // fixes empty tooltip on initial hover when the content does fit the cell
        $('[role="tooltip"]').css('visibility', 'hidden');
    }
}

function addMemberCount(list, members, truncateList, total) {
    total = truncateList ? total - 1 : total;
    total = Math.min(total, members.length);
    for (let i = 0; i < total; i++) {
        list.push(browserServiceFunctions.htmlEscape(members[i]));
    }

    if (truncateList) {
        // Parse out collapsed member count
        let unlistedCount = 0;
        if (members[10]) {
            let spaceIndex = members[10].indexOf(' ', 2);
            let parsedCount = members[10].substring(2, spaceIndex);
            unlistedCount = parseInt(parsedCount) - 1;
        }
        list.push('+ ' + (members.length + unlistedCount - list.length) + ' More');
    }
}

function addMembers(members, firstList, secondList) {
    let list = {
        users: [],
        computers: []
    };
    if (members[firstList] && members[firstList].length > 0) {
        let listSize = Math.min(members[firstList].length, 5);
        let truncateList = members[firstList].length > 5;
        addMemberCount(list[firstList], members[firstList], truncateList, listSize);
    }
    if (members[secondList] && members[secondList].length > 0) {
        let listSize = 10 - list[firstList].length;
        let truncateList = members[secondList].length > listSize || members[secondList].length >= 11;
        addMemberCount(list[secondList], members[secondList], truncateList, listSize);
    }
    return list;
}

const convertTimeStringToSeconds = function (timeString) {
    let timeParts = timeString.split(':');
    let hours = parseInt(timeParts[0]);
    let minutes = parseInt(timeParts[1]);
    let seconds = parseInt(timeParts[2]);

    let time = isNaN(hours) ? 0 : hours * 60 * 60;
    time += isNaN(minutes) ? 0 : minutes * 60;
    time += isNaN(seconds) ? 0 : seconds;

    return time;
};

const minimizeString = (maxSize, value) => {
    if (typeof value === 'string') {
        if (value.length > maxSize) {
            value = value.substring(0, maxSize - 1) + '…';
        }
    }
    return value;
};

const userTypeWithMembersTemplate = kendo.template((data) => {
    if (!data) {
        return '';
    }

    let userType = data.userType ? data.userType : data.type;
    let icon = iconResolverServiceFunctions.getUserTypeIcon(userType, data.groupType, data.filterMode);
    let userName = browserServiceFunctions.htmlEscape(data.userName ? data.userName : data.name);

    if (
        !data.groupTTOptions &&
        showGroupMembers(userType, data.skipMemberCount) &&
        !atHelperFunctions.isSmallWindow()
    ) {
        // Remove lists based on mode.
        let memberList = {
            users: data.filterMode === 'computers' ? [] : data.userMemberList,
            computers: data.filterMode === 'users' ? [] : data.computerMemberList
        };
        let content = countTooltipTemplate(memberList, data.filterMode);

        if (content) {
            data.groupTTOptions = {
                animation: {
                    open: {
                        effects: 'fade:in'
                    },
                    close: {
                        effects: 'fade:out'
                    }
                }
            };

            data.groupTTOptions.content = content;
            data.groupTTOptions.position = 'right';
            data.groupTTOptions.width = 170;
        }
    }

    return (
        '<span class="k-state-default text-nowrap">' +
        icon +
        '<span class="m-l-5" kendo-tooltip k-options="::dataItem.groupTTOptions">' +
        userName +
        getMemberText(userType, data) +
        '</span></span>'
    );
});

const userTypeTemplate = kendo.template((data) => {
    if (!data) {
        return '';
    }

    let userType = data.userType ? data.userType : data.type;
    let icon = iconResolverServiceFunctions.getUserTypeIcon(userType, data.groupType, data.filterMode);
    let userName = browserServiceFunctions.htmlEscape(
        data.userName ? data.userName : data.alias ? data.alias : data.name
    );
    return '<span class="k-state-default text-nowrap">' + icon + '<span class="m-l-5">' + userName + '</span></span>';
});

const userCountTemplate = kendo.template((data) => {
    if (!data) {
        return '';
    }

    let userType = data.userType ? data.userType : data.type;
    return data.userCount && _.includes(['AllUsers', 'Group', 'AdGroup'], userType) ? data.userCount : '';
});

const _reactUserTypeIconTemplate = (data) => {
    if (!data) {
        return '';
    }

    let userType = data.userType ? data.userType : data.type;

    let userName = data.userName || data.name || '';

    return iconResolverServiceFunctions._reactUserGroupTypeIcon(userType, data.groupType, data.filterMode, userName);
};

const _reactUserGroupTypeLabel = function (data) {
    return _reactUserTypeIconTemplate(data);
};

// For alarmDetailsGroups now using _reactUserGroupTypeLabel
const userTypeTemplateAsString = (data) => {
    if (!data) {
        return '';
    }

    let userType = data.userType ? data.userType : data.type;
    let icon = iconResolverServiceFunctions.getUserTypeIcon(userType, data.groupType, data.filterMode);
    let userName = browserServiceFunctions.htmlEscape(data.userName ? data.userName : data.name);
    return '<span class="k-state-default text-nowrap">' + icon + '<span class="m-l-5">' + userName + '</span></span>';
};

const groupTypeIconTemplate = kendo.template((data) => {
    if (!data) {
        return '';
    }

    return iconResolverServiceFunctions.getGroupTypeIcon(data.type, data.mix);
});

const groupTypeIconAndNameTemplateAsString = (data, react) => {
    if (!data) {
        return '';
    }
    let groupName = browserServiceFunctions.htmlEscape(data.name);
    let icon;
    const getPadding = () => {
        if (data.groupid > 0) {
            return 'padding';
        }
        return '';
    };

    if (react) {
        icon = iconResolverServiceFunctions._reactUserGroupTypeIconAppAccess(data.type, data.mix);
    } else {
        icon = iconResolverServiceFunctions.getGroupTypeIcon(data.type, data.mix);
    }

    return (
        `<span class="k-state-default text-nowrap viewable-group-name ${getPadding()}">` +
        icon +
        '<span class="m-l-5 modal-row">' +
        groupName +
        '</span></span>'
    );
};

const groupTypeIconTemplateAsString = (data, react = false) => {
    if (!data) {
        return '';
    }

    if (react) {
        return iconResolverServiceFunctions._reactUserGroupTypeIconAppAccess(data.type, data.mix);
    } else {
        return iconResolverServiceFunctions.getGroupTypeIcon(data.type, data.mix);
    }
};

const productivityTemplate = (item) => {
    if (item == null || item.prod == null) {
        return '';
    }

    return iconResolverServiceFunctions.getProductivityIcon(item.prod, true, 'fs-20');
};

const productivityTimeTemplate = (duration = 0, productivity) => {
    let productivityClass;
    switch (productivity) {
        case 1:
            productivityClass = 'text-productive';
            break;
        case -1:
            productivityClass = 'text-unproductive';
            break;
        case -3:
            productivityClass = 'text-passive';
            break;
        case 2:
            productivityClass = 'text-productive-passive';
            break;
        case -2:
            productivityClass = 'text-unproductive-passive';
            break;
        case -4:
        case 9:
            productivityClass = 'text-offline-meetings';
            break;
        default:
            productivityClass = 'text-undefined';
    }

    return `<div class="text-right at-productivity-time at-ellipsis">${friendlyViewTimeFormat(
        duration
    )}<i class="material-icons fs-12 ignore-selection ${productivityClass}">lens</i></div>`;
};

const productivityDropdownTemplate = (item) => {
    if (!item) {
        return '';
    }

    let legendItem = {
        Productive: 'fa-check-circle text-productive',
        Unproductive: 'fa-times-circle text-unproductive',
        Undefined: 'fa-question-circle text-undefined'
    };

    let css = legendItem[item.name];

    if (!css) {
        return '';
    }

    return (
        '<span style="text-align: left;">' +
        '<i class="fa ' +
        css +
        ' m-r-10" style="font-size: 1.1em"></i>' +
        item.name +
        '</span>'
    );
};

const productivityFilterTemplate = ({ name, productivity } = {}, showName) => {
    if (!name || typeof productivity === 'undefined') {
        return '';
    }

    const nameParts = name.split(' ');
    const nameStart = nameParts.shift();

    let html;

    if (productivity === null) {
        html = '<span class="column-productivity-all">All</span>';
    } else {
        const nameSpan = showName
            ? `<span class="m-l-5"><span class="text-medium">${nameStart}</span> ${nameParts}</span>`
            : '';
        html = `${iconResolverServiceFunctions.getProductivityIcon(productivity, false)}${nameSpan}`;
    }

    return `<span class="column-productivity-filter clickable${
        showName ? '' : ' column-productivity-no-name'
    }">${html}</span>`;
};

const alarmTypeFilterTemplate = (item) => {
    if (!item) {
        return '';
    }

    if (item.type === null) {
        return 'All';
    } else {
        let iconClass = _alarmTypeClasses[item.type];
        return '<i class="fa ' + iconClass + '"></i>';
    }
};

const alarmScreenshotsFilterTemplate = (item) => {
    if (!item) {
        return '';
    }

    if (item.type === null) {
        return 'All';
    } else {
        let iconClass = _alarmScreenshotsClasses[item.type];
        return '<i class="fa ' + iconClass + '"></i>';
    }
};

const osIconTemplate = (os) => {
    if (os === 'Apple') {
        return '<i class="fa fa-apple" title="macOS"></i>';
    }

    if (os === 'Chrome') {
        return '<i class="fa fa-chrome" title="Chrome OS"></i>';
    }

    if (os === 'Windows') {
        return '<i class="fa fa-windows" title="Windows">';
    }

    return 'All';
};

const buttonTemplate = (color, icon, click, text, isButton, customClass) => {
    let tag = isButton ? 'button' : 'a';
    return (
        '<' +
        tag +
        ' role="button" class="' +
        (customClass || '') +
        ' btn ' +
        color +
        ' btn-animated from-top fa ' +
        icon +
        '"' +
        (isButton ? '' : ' href="\\#"') +
        ' ng-click="' +
        click +
        '($event, dataItem)"><span>' +
        text +
        '</span></' +
        tag +
        '>'
    );
};

const groupDropdownIconTemplate = (groupType) => {
    if (groupType === 'ActivTrak') {
        return '<i class="fa icon-at-group-activtrak-both" title="ActivTrak Groups"></i>';
    }

    if (groupType === 'ActiveDirectory') {
        return '<i class="fa icon-at-group-active-directory-both" title="Active Directory Groups"></i>';
    }

    return '<span title="All Groups">All</span>';
};

// language=JSRegexp
const domainRegex = /^(?<protocol>http[s]?:\/{2})?(?<domain>[\w\-\.~]+?\.[\w\-\.~:]+)$/;

const getFaviconTemplate = (field) => {
    return (item) => {
        let value = item[field];
        let escapedValue = browserServiceFunctions.htmlEscape(value);

        if (!escapedValue) {
            return '';
        }

        let regexpResult = domainRegex.exec(value);

        if (regexpResult) {
            let domain = browserServiceFunctions.htmlEscape(regexpResult.groups.domain);
            let fullUrl = (regexpResult.groups.protocol || 'http://') + domain;

            return (
                '<img src="https://www.google.com/s2/favicons?domain=//' +
                domain +
                '/" class="favicon m-r-5">' +
                '<a target="_blank" href="' +
                fullUrl +
                '">' +
                escapedValue +
                '</a>'
            );
        }

        let fullUrl = value.indexOf('://') === -1 ? 'http://' + escapedValue : escapedValue;

        return (
            '<span class="spacer"></span>' +
            '<a target="_blank" href="' +
            (fullUrl.indexOf(':') === -1 ? '//' : '') +
            fullUrl +
            '">' +
            escapedValue +
            '</a>'
        );
    };
};

const getFaviconTemplateAsString = (url, isDomain) => {
    if (!url) {
        return '';
    }

    let escapedField = browserServiceFunctions.htmlEscape(url);
    let fullUrl = isDomain ? 'http://' + escapedField : escapedField;
    if (fullUrl.indexOf('://', fullUrl.length - 3) === -1 && fullUrl.indexOf('http') === 0) {
        let domain = fullUrl.split(':')[1];
        return (
            '<img src="https://www.google.com/s2/favicons?domain=' + domain + '/" class="favicon m-r-5">' + escapedField
        );
    }

    return '<span class="spacer"></span>' + escapedField;
};

const getTooltipOptions = (options) => {
    if (browserServiceFunctions.isMobileAgent()) {
        return undefined;
    }
    return _.merge(
        {
            content: (e) => {
                let text = browserServiceFunctions.htmlEscape(standardTooltipContent(e));
                if (text) {
                    let string = text.split('\n').sort((a, b) => {
                        return b.length - a.length;
                    })[0];
                    string = string
                        .replace(/&amp;/g, '&')
                        .replace(/&quot;/g, '"')
                        .replace(/'/g, "'")
                        .replace(/&lt;/g, '<')
                        .replace(/&gt;/g, '>')
                        .replace(/&#47;/g, '/');
                    let width = getTextWidth(string, '14px "Segoe UI",Arial,sans-serif') + 20;
                    return (
                        '<div style="width: ' +
                        width +
                        'px; max-width: 60em; overflow: hidden; word-wrap: break-word;">' +
                        text.trim() +
                        '</div>'
                    );
                } else {
                    return;
                }
            },
            onHover: () => {},
            onShow: () => {},
            onHide: () => {},
            show: (e) => {
                if (e.sender.options.onHover && typeof e.sender.options.onHover === 'function') {
                    e.sender.options.onHover(e);
                }

                if (e.sender.content.text()) {
                    e.sender.content.html(
                        e.sender.content.html().replace(/\n/g, '<br>').replace('{<br>', '{').replace('<br>}', '}')
                    );

                    if (e.sender.options.onShow && typeof e.sender.options.onShow === 'function') {
                        e.sender.options.onShow(e);
                    }

                    if (e.sender.popup && e.sender.popup.wrapper && e.sender.popup.wrapper.css('z-index') < 10100) {
                        e.sender.popup.wrapper.css('z-index', '10100');
                    }

                    $('[role="tooltip"]').css('visibility', 'visible');
                }
            },
            hide: (e) => {
                if (e.sender.options.onHide && typeof e.sender.options.onHide === 'function') {
                    e.sender.options.onHide(e);
                }

                $('[role="tooltip"]').css('visibility', 'hidden');
            }
        },
        options
    );
};

let _filterElements = [];

function setFilterElementsEnable(state) {
    _filterElements.forEach((el) => {
        let autoComplete = el.data('kendoAutoComplete');
        if (autoComplete) {
            autoComplete.enable(state);
        }
    });
    return state;
}

function dataSourceRequestEnd() {
    return setFilterElementsEnable(true);
}

function dataSourceRequestStart() {
    return setFilterElementsEnable(false);
}

const createFilter = (columnName, gridDataSource, options) => {
    options = options || {};

    if (!columnName) {
        return {
            cell: {
                operator: 'contains',
                showOperators: false,
                delay: 300
            }
        };
    }

    if (gridDataSource && !gridDataSource.isBound) {
        gridDataSource.bind('requestStart', dataSourceRequestStart);
        gridDataSource.bind('requestEnd', dataSourceRequestEnd);
        gridDataSource.isBound = true;
    }

    return {
        cell: {
            operator: 'contains',
            showOperators: false,
            template: (args) => {
                let enterPressed = false;
                let filtering = false;

                args.element.kendoAutoComplete({
                    valuePrimitive: true,
                    filter: 'contains',
                    dataSource: options.hideAutoComplete ? undefined : args.dataSource,
                    noDataTemplate: '',
                    dataTextField: columnName,
                    delay: 500,
                    filtering: (e) => {
                        if (enterPressed) {
                            e.preventDefault();
                            enterPressed = false;
                        }
                        filtering = true;
                    }
                });

                _filterElements.push(args.element);

                args.element.keydown((e) => {
                    if ((e.keyCode || e.which) === 13) {
                        enterPressed = true;

                        // Check for angular for unit tests
                        // eslint-disable-next-line no-unused-vars
                        let el = angular && angular.element(e.target).trigger('change');

                        if (filtering) {
                            // eslint-disable-next-line no-unused-vars
                            let el = angular && angular.element(e.target).blur();
                            filtering = false;
                        }
                    }
                });
            }
        }
    };
};

const durationTemplate = (item) => {
    if (!item || !item.duration) {
        return '';
    }

    return friendlyViewTimeFormat(item.duration);
};

const _mapSeriesName = (name, showActiveLabel) => {
    const activeLabel = showActiveLabel ? '' : ' Active';

    switch (name) {
        case 'productive':
            return `Productive${activeLabel}`;
        case 'productivePassive':
            return `Productive Passive`;
        case 'unproductive':
            return `Unproductive${activeLabel}`;
        case 'unproductivePassive':
            return `Unproductive Passive`;
        case 'undefined':
            return `Undefined${activeLabel}`;
        case 'passive':
            return showActiveLabel ? `Passive` : `Undefined Passive`;
        default:
            return atHelperFunctions.capitalizeFirstLetterEachWord(atHelperFunctions.camelCaseToString(name));
    }
};

const chartTooltipTimeTemplate = (item, showActiveLabel) => {
    if (!item || !item.series || !item.series.name || !item.value) {
        return;
    }

    const seriesName = _mapSeriesName(item.series.name.replace('idle', 'passive'), showActiveLabel);

    return `<span class="m-l-5 m-r-5">${seriesName} &mdash; ${kendo.toString(
        friendlyViewTimeFormat(item.value)
    )}</span>`;
};

const timelineTooltipTemplate = (user, time, duration, productivity, activity, showActiveLabel = false) => {
    if (duration === 0 || typeof productivity === 'undefined') {
        return '';
    }

    const productivityName = ProductivityStatus.getName(productivity, showActiveLabel, true);
    let activityLabel;

    if (productivity === ProductivityStatus.OfflineMeetings) {
        activityLabel = 'Meeting';
    } else if (ProductivityStatus.isPassive(productivity)) {
        activityLabel = 'Prior Activity';
    } else {
        activityLabel = 'Longest Activity';
    }
    const productivityClass = ProductivityStatus.getClass(productivity);

    const nameHtml = `<div class="at-timeline-tooltip-row text-medium">${user}</div>`;
    const productivityHtml = `<div class="at-timeline-tooltip-row">${productivityName}</div>`;
    const durationHtml = `<div class="at-timeline-tooltip-row">${duration}</div>`;
    const timeHtml = `<div class="at-timeline-tooltip-row">${time}</div>`;
    const activityHtml = activity ? `<div class="at-timeline-tooltip-row">${activityLabel}: ${activity}</div>` : '';

    return `<div class="at-timeline-tooltip left-border-${productivityClass}">${nameHtml}${productivityHtml}${durationHtml}${timeHtml}${activityHtml}</div>`;
};

const chartTooltipTemplate = ({ seriesName, activeTime = 0, passiveTime = 0, totalPercent = 0, color = '#333' }) => {
    const htmlStart = `<div class="chart-tooltip" style="--borderColor:${color}"><div class="tooltip-row">`;
    const filtertotalPercent = seriesName === 'Offline Meetings' ? '' : `${totalPercent}%`;
    const topRow = `<div class="row-text">${filtertotalPercent} ${seriesName} ${friendlyViewTimeFormat(
        activeTime,
        true
    )}</div>`;
    const bottomRow =
        passiveTime >= 0
            ? `<div class="row-text">Includes ${friendlyViewTimeFormat(
                  passiveTime,
                  true
              )} of  ${seriesName} (Passive)</div>`
            : '';
    const htmlEnd = '</div></div>';

    return `${htmlStart}${topRow}${bottomRow}${htmlEnd}`;
};

const chartTooltipTimeTemplateWithCategory = (item) => {
    if (!item || !item.category || !item.series || !item.series.name || !item.value) {
        return;
    }

    return `<span class="m-l-5 m-r-5">${chartTooltipTimeTemplate(item)}<br/>${item.category.replace(
        String.fromCharCode(10),
        ' - '
    )}</span>`;
};

const createOfflineMeetingTooltipTemplate = (calendarIntegrated) => {
    const templateValues = {
        enabled: {
            text: 'Offline Meeting data is available historically and not live. It is not included in Productive, Total Hours, or Active Hours.',
            ctaText: 'LEARN MORE',
            ctaLink:
                'href="https://support.activtrak.com/hc/en-us/articles/14157396964123-Offline-Meetings-EA-" target="_blank"'
        },
        disabled: {
            text: 'Offline Meeting data can take up to 24 hours to begin populating after enabling the integration.',
            ctaText: 'ENABLE INTEGRATION',
            ctaLink: 'ui-sref="app.integrations"'
        }
    };

    const templateValue = calendarIntegrated ? templateValues.enabled : templateValues.disabled;

    return `<div style="width: 380px"><div style="text-align:left"><p>${templateValue.text}</p></div><div style="width:100%; text-align:right"><span><a class="text-productive" ${templateValue.ctaLink}>${templateValue.ctaText}</a></span></div></div>`;
};

const offlineMeetingsHeaderTemplate = () => {
    const html = `<div style="display: flex; justify-content: center; align-items: center;">
  <div style="text-align: center;">
   Offline<br />Meetings
  </div>
  <span style="padding-left: 5px;">
    <i-tooltip placement="top" top-offset="4px" template="createOfflineMeetingTooltipTemplate"> </i-tooltip>
  </span>
</div>`;
    return html;
};

const labelTooltipTemplate = (item) => {
    if (!item) {
        console.error('ActivTrak Error: Cannot create duration due to missing item.');
        return '';
    }

    return kendo.toString(friendlyViewTimeFormat(item.value));
};

const friendlyViewTimeFormat = (time, removeSeconds) => {
    let duration;

    if (typeof time === 'number' || typeof time === 'string') {
        // Calculate durations based on seconds
        duration = calculateDurations(time);
    } else {
        // Return original value, does not match expectations
        return time;
    }

    if (removeSeconds && duration.seconds > 29) {
        if (duration.minutes === 59) {
            duration.hours++;
            duration.minutes = 0;
        } else {
            duration.minutes++;
        }
    }

    let hoursLabel = duration.hours > 0 ? duration.hours.toFixed(0) + 'h' : '';
    let minutesLabel =
        duration.minutes > 0 || duration.hours > 0 || removeSeconds
            ? (hoursLabel !== '' ? ' ' : '') + duration.minutes.toFixed(0) + 'm'
            : '';
    let secondsLabel = removeSeconds ? '' : (minutesLabel !== '' ? ' ' : '') + duration.seconds.toFixed(0) + 's';
    if (removeSeconds && duration.minutes === 0 && duration.hours === 0 && duration.seconds !== 0) {
        minutesLabel = '< 1m';
    }

    return hoursLabel + minutesLabel + secondsLabel;
};

const calculateTimeMajorUnit = (max) => {
    if (isNaN(max)) {
        console.error('ActivTrak Error: Cannot calculate time major unit due to max not being a number.', max);
        return;
    }

    let steps = [2, 5, 10, 30, 60, 300, 600, 900, 3600];
    let step = Math.floor(max / 5);

    if (step <= 3600) {
        return Math.min.apply(
            null,
            steps.map((item) => {
                return item > step ? item : max;
            })
        );
    }

    let hourStep = Math.floor(step / 3600) + 1;

    if (hourStep < 5) {
        return 5 * 3600;
    }

    let power = Math.ceil(Math.log10(hourStep + 0.5)) - 1;

    if (power === 0) {
        return 10 * 3600;
    }

    return Math.floor(hourStep / Math.pow(10, power) + 1) * Math.pow(10, power) * 3600;
};

const resetFilterElements = () => {
    _filterElements = [];
    return _filterElements;
};

const getCreationDuration = (creationDate) => {
    if (!creationDate || typeof creationDate.diff !== 'function') {
        return;
    }

    // Calculate duration
    let diff = moment().utc().diff(creationDate);
    return moment.duration(diff);
};

const getCreationLengthString = (creationDate) => {
    let duration = getCreationDuration(creationDate);

    if (!duration) {
        return;
    }

    // Break duration into parts
    let years = duration.years();
    let months = duration.months();
    let days = duration.days();
    let hours = duration.hours();
    let minutes = duration.minutes();

    // Create string
    let string = '';
    string += years > 0 ? years + (years > 1 ? 'years ' : 'year ') : '';
    string += months > 0 ? months + (months > 1 ? 'months ' : 'month ') : '';
    string += days > 0 ? days + (days > 1 ? 'days ' : 'day ') : '';
    string += days < 2 && hours > 0 ? hours + (hours > 1 ? 'hours ' : 'hour ') : ''; // Show if account is less than 2 days old
    string += days < 1 && minutes > 0 ? minutes + (minutes > 1 ? 'mins ' : 'min ') : ''; // Show if account is less than 1 day old

    return string.replace(new RegExp(' ' + '$'), '');
};

const sortAlphabetically = (items) => {
    return Object.keys(items)
        .sort()
        .reduce((accumulator, key) => {
            accumulator[key] = items[key];

            return accumulator;
        }, {});
};

export const templateServiceFunctions = {
    sortAlphabetically,
    convertTimeStringToSeconds,
    minimizeString,
    userTypeWithMembersTemplate,
    userTypeTemplate,
    userCountTemplate,
    userTypeTemplateAsString,
    groupTypeIconTemplate,
    groupTypeIconAndNameTemplateAsString,
    groupTypeIconTemplateAsString,
    productivityTemplate,
    productivityTimeTemplate,
    productivityDropdownTemplate,
    productivityFilterTemplate,
    alarmTypeFilterTemplate,
    alarmScreenshotsFilterTemplate,
    osIconTemplate,
    groupDropdownIconTemplate,
    getFaviconTemplate,
    getFaviconTemplateAsString,
    getTooltipOptions,
    createFilter,
    durationTemplate,
    chartTooltipTimeTemplate,
    timelineTooltipTemplate,
    chartTooltipTemplate,
    createOfflineMeetingTooltipTemplate,
    offlineMeetingsHeaderTemplate,
    chartTooltipTimeTemplateWithCategory,
    labelTooltipTemplate,
    friendlyViewTimeFormat,
    calculateTimeMajorUnit,
    resetFilterElements,
    getCreationDuration,
    getCreationLengthString,
    buttonTemplate,
    getTextWidth,
    _reactUserGroupTypeLabel,
    _reactUserTypeIconTemplate
};
