import _, { head } from 'lodash';
import {
  Role,
  ClaimType,
  ChannelType,
  PermissionLevel,
  AuthLevel
} from '../../enums';
import { IPermission, IToken, IAuthorizationService } from '../../models';
import { isRouteRestricted } from './RouteRestrictionsService';
import { getRouteByUrl } from '../../config/routing';
import { getRouteAccessLevel } from '../../hooks/userPermissionStore';
import authorizationService from '../../helpers/authorization';
import { getAccountSettings } from '../../helpers/accountSettings/accountSettingsStore';

export default class AuthorizationService implements IAuthorizationService {
  private userRoles: string[];
  private channelTypes: string[];
  public isImpersonating: boolean;

  constructor(token: IToken) {
    const imposter = this.getClaimsByType(token, ClaimType.Imposter);
    this.isImpersonating = imposter !== null && imposter.length > 0;
    this.userRoles = this.getClaimsByType(token, ClaimType.Role);
    this.channelTypes = this.getClaimsByType(token, ClaimType.ChannelType);
  }

  public hasRole(role: Role): boolean {
    return authorizationService.hasRole(role.toString().toLowerCase());
  }

  public hasFeature(featureFlag: string): boolean {
    return authorizationService.hasFeature(featureFlag);
  }

  public pageAuthorization(url: string): PermissionLevel {
    const userRoles: Role[] = _.map(
      this.userRoles,
      (role) => role.toLowerCase() as Role
    );

    // there's only one instance of channeltype
    // NOTE: CC child accounts have same settings as regular 'Admin', so can't distinguish them yet.
    const channelType: string =
      (this.channelTypes && head(this.channelTypes)) ?? '';
    const isCcParent: boolean =
      userRoles.includes(Role.CommandCenter) &&
      channelType &&
      channelType.toLowerCase().indexOf(ChannelType.cc_parent) > -1;

    const levels: IPermission[] = this.getPagePermissions(url, userRoles);
    const hasCcParentLevelSet: boolean =
      levels.length &&
      levels.some((level) => level && level.role === Role.MultiAccount);

    let pageRoles: Role[] = [];
    if (isCcParent && hasCcParentLevelSet) {
      pageRoles = [Role.MultiAccount];
    } else {
      pageRoles = _.filter(
        userRoles,
        (role) => this.getPagePermission(role, levels) != null
      );
    }

    if ((pageRoles?.length ?? 0) === 0) {
      return null;
    }

    const permissions: IPermission[] = _.map(pageRoles, (role) =>
      this.getPagePermission(role, levels)
    );
    const pagePermission = _.orderBy(
      permissions,
      (permission) => permission.level === PermissionLevel.Edit
    ).pop();

    if (pagePermission && pagePermission.level === PermissionLevel.None) {
      return null;
    }

    const { parentAccountId } = getAccountSettings();

    // Check for route restrictions
    if (isRouteRestricted(url, Boolean(parentAccountId))) {
      return null;
    }
    return pagePermission?.level;
  }

  private getPagePermission(role: Role, levels: IPermission[]): IPermission {
    if (levels?.length ?? 0) {
      return _.find(levels, (level) => level.role === role);
    }
    return null;
  }

  // TODO: Update PermissionLevel to AuthLevel to eliminate this mapping
  private mapAuthLevelToPermissionLevel(
    authLevel: AuthLevel
  ): PermissionLevel | null {
    switch (authLevel) {
      case AuthLevel.Edit:
        return PermissionLevel.Edit;
      case AuthLevel.View:
        return PermissionLevel.Read;
      default:
        return null;
    }
  }

  private getPagePermissions(url: string, userRoles: Role[]): IPermission[] {
    const route = getRouteByUrl(url);
    const levels = userRoles.map((role) => {
      const authLevel = getRouteAccessLevel(route.name, [
        role.toLocaleLowerCase() as Role
      ]);
      const permLevel = this.mapAuthLevelToPermissionLevel(authLevel);
      return { role, level: permLevel } as IPermission;
    });
    return levels ?? [];
  }

  public getClaimsByType(token: IToken, claimType: string): string[] {
    return (
      token?.Claims?.filter((c) => c.Type === claimType.toString()).map(
        (c) => c.Value
      ) ?? []
    );
  }

  public hasAnyRole(roles: Role[]) {
    return _.find(roles, (role) => this.hasRole(role)) !== undefined;
  }

  public hasAuthorizationLevel(
    levels: AuthLevel[],
    routeName: string,
    featureName?: string
  ): boolean {
    return authorizationService.hasAuthorizationLevel(
      levels,
      routeName,
      featureName
    );
  }

  public canDeleteGroup(): boolean {
    return this.hasAnyRole([
      Role.Admin,
      Role.SuperAdmin,
      Role.SupportAdvanced,
      Role.Configurator
    ]);
  }

  public isSupportUser(): boolean {
    return (
      this.isImpersonating ||
      this.hasAnyRole([
        Role.SupportBasic,
        Role.SupportIntermediate,
        Role.SupportAdvanced,
        Role.SuperAdmin
      ])
    );
  }
}
