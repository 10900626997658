import { useCallback, useState } from 'react';
import moment from 'moment';
import { getAccountForSupport } from '../../common/services/Application/SupportPortalLoad';
import { StorageService, useStorageState } from '../../common/hooks';
import { IAccountSettingsDto } from '../../common/models';
import { notificationMessage } from '../constants';
import {
  createAccountCard,
  decodeToken,
  getTokenExpiration,
  handleFetchError,
  impersonateUser
} from '../utils';
import { ISupportPortalServiceProps } from '../models/ISupportPortalService';
import { ISupportPortalState } from '../models/ISupportPortalState';
import {
  IAccountIdPayloadDto,
  IAddAccountPayloadDto,
  ISetDataCollectionPayloadDto,
  IUnlockUserPayloadDto
} from '../models/IPayloadDto';
import { IAccountSnapshotDto, ITokenDto } from '../models/IApiResponseDto';
import { IFullDetails, INewlyAddedAccount } from '../models/IAccountData';

export const useSupportPortalState = ({
  service
}: ISupportPortalServiceProps): ISupportPortalState => {
  const {
    getAccountSnapshot,
    getToken,
    setDataCollection,
    unlockUser,
    verifyAccount
  } = service;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const storageService = StorageService();
  const { getStorageInfo } = useStorageState({
    storageService
  });

  const fetchAccountToken = useCallback(
    async (payload: IAddAccountPayloadDto): Promise<ITokenDto> => {
      setIsError(false);
      try {
        const userToken = await getToken(payload);
        userToken.expiration = getTokenExpiration(decodeToken(userToken.token));

        return userToken;
      } catch (error) {
        setIsError(true);
        console.error(notificationMessage.fetchTokenError(payload), error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchAccountDetails = useCallback(
    async (token: string): Promise<IAccountSettingsDto> => {
      setIsError(false);
      try {
        const accountInfo = await getAccountForSupport(token);

        return accountInfo;
      } catch (error) {
        setIsError(true);
        console.error(notificationMessage.fetchDetailsForCardError, error);
      }
    },
    []
  );

  const fetchDetailsForAccountCard = useCallback(
    async (payload: IAddAccountPayloadDto): Promise<INewlyAddedAccount> => {
      setIsLoading(true);
      setIsError(false);
      try {
        const userToken = await fetchAccountToken(payload);
        const data = await fetchAccountDetails(userToken.token);
        const newAccount = createAccountCard(data, userToken, payload);

        setIsLoading(false);
        return newAccount;
      } catch (error) {
        setIsError(true);
        handleFetchError(error, payload);
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getSnapshot = useCallback(
    async (
      token: string,
      payload: IAccountIdPayloadDto
    ): Promise<IAccountSnapshotDto> => {
      setIsError(false);
      try {
        const snapshot = await getAccountSnapshot(token, payload);

        return snapshot;
      } catch (error) {
        setIsError(true);
        console.error(notificationMessage.fetchSnapshotError, error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const fetchAllDetailsForModal = useCallback(
    async (
      token: string,
      payload: IAccountIdPayloadDto
    ): Promise<IFullDetails> => {
      setIsLoading(true);
      setIsError(false);

      try {
        const fullDetails = await Promise.all([
          fetchAccountDetails(token),
          getSnapshot(token, payload),
          getStorageInfo(token)
        ]);

        setIsLoading(false);
        return fullDetails;
      } catch (error) {
        setIsLoading(false);
        setIsError(true);
        console.error(notificationMessage.fetchDetailsForModalError, error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const refreshAuthToken = useCallback(
    async (
      tokenInfo: ITokenDto,
      payload: IAddAccountPayloadDto
    ): Promise<void> => {
      setIsError(false);
      try {
        if (
          tokenInfo.expiration &&
          moment(tokenInfo.expiration).isAfter(moment())
        ) {
          const newUserToken = await fetchAccountToken(payload);
          tokenInfo.token = newUserToken.token;
          tokenInfo.impersonateToken = newUserToken.impersonateToken;
          tokenInfo.expiration = getTokenExpiration(
            decodeToken(newUserToken.token)
          );
        }
      } catch (error) {
        setIsError(true);
        console.error(notificationMessage.refreshTokenError, error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleImpersonation = useCallback(
    async (
      authToken: ITokenDto,
      payload: IAddAccountPayloadDto
    ): Promise<void> => {
      setIsError(false);
      try {
        if (authToken) {
          await refreshAuthToken(authToken, payload);
          impersonateUser(authToken);
        }
      } catch (error) {
        setIsError(true);
        console.error(
          notificationMessage.impersonationError(payload.accountId),
          error
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const setDataCollectionSetting = useCallback(
    async (
      payload: ISetDataCollectionPayloadDto,
      token: string
    ): Promise<void> => {
      setIsError(false);

      try {
        await setDataCollection(payload, token);
      } catch (error) {
        setIsError(true);
        console.error(notificationMessage.setDataCollectionError, error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const setUnlockUser = useCallback(
    async (payload: IUnlockUserPayloadDto): Promise<void> => {
      setIsError(false);

      try {
        await unlockUser(payload);
      } catch (error) {
        setIsError(true);
        console.error(notificationMessage.unlockUserError, error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const setVerifyAccount = useCallback(
    async (payload: IAccountIdPayloadDto): Promise<void> => {
      setIsError(false);
      try {
        await verifyAccount(payload);
      } catch (error) {
        setIsError(true);
        console.error(notificationMessage.verifyAccountError, error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    fetchAccountToken,
    fetchAccountDetails,
    fetchDetailsForAccountCard,
    setDataCollectionSetting,
    setUnlockUser,
    setVerifyAccount,
    isLoading,
    isError,
    fetchAllDetailsForModal,
    handleImpersonation
  };
};
