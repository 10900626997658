import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Link,
  Box
} from '@mui/material';
import CustomInput from '../CustomInput/CustomInput.jsx';
import CustomSelect from '../CustomSelect/CustomSelect.jsx';
import { addCustomerClasses } from './AddCustomerDialog.styles.js';
import {
  addNewCustomer,
  linkToParentAccount
} from '../../utils/api/commandCenterApi.js';

import { setMarketingData } from '../../utils/setMarketingData.js';
import countries from '../../../_app/constants/countryStates/countries.json';
import states from '../../../_app/constants/countryStates/states.json';
import { validatePassword } from './../../helpers';
import { impersonate } from '../../../../_reactivtrak/src/common/hooks/useImpersonate';
import { CC_TYPE } from '../../utils/constants';

// eslint-disable-next-line no-undef
const privacyPolicyUrl = `${import.meta.env.VITE_ACTIVTRAK_URL}/privacy-policy/`;
// eslint-disable-next-line no-undef
const termsOfServiceUrl = `${import.meta.env.VITE_ACTIVTRAK_URL}/terms-of-service/`;

const INITIAL_ERROR_STATE = {
  firstName: false,
  lastName: false,
  organization: false,
  phone: false,
  email: false,
  password: false,
  country: false,
  state: false
};
const INITIAL_VALUES_STATE = {
  firstName: '',
  lastName: '',
  organization: '',
  phone: '',
  email: '',
  password: '',
  country: '',
  state: '',
  countryIsoCode: ''
};
const INITIAL_SUBMITTING_STATE = false;
const INITIAL_POLICY_STATE = false;
const INITIAL_POLICY_ERROR = false;
const INITIAL_SIGN_UP_ERROR = '';
const DEFAULT_ACCOUNT_VALUES = {
  plan: 'activtrak_advanced_usd_trial',
  planCode: 'activtrak_advanced_usd_trial',
  timezone: 'America/Mexico_City'
};
const NEW_ACCOUNT_DELAY_MESSAGE =
  'There may be a slight delay before your new account shows up in the Command Center dashboard.';

const AddCustomerDialog = (props) => {
  const {
    open,
    onCloseDialog,
    onSuccess,
    onError,
    profile,
    ccType,
    cceDefaultFields
  } = props;
  const [submitting, setSubmitting] = useState(false);
  const [policyAgree, setPolicyAgree] = useState(INITIAL_POLICY_STATE);
  const [policyError, setPolicyError] = useState(INITIAL_POLICY_ERROR);
  const [values, setValues] = React.useState(INITIAL_VALUES_STATE);
  const [errors, setErrors] = React.useState(INITIAL_ERROR_STATE);
  const [signUpError, setSignUpError] = React.useState(INITIAL_SIGN_UP_ERROR);
  const [statesByCountry, setStates] = React.useState([]);
  const [passwordErrors, setPasswordErrors] = React.useState([]);
  const [defaultAccountValues, setDefaultAccountValues] = useState(
    DEFAULT_ACCOUNT_VALUES
  );

  useEffect(() => {
    if (ccType === CC_TYPE.Enterprise) {
      setDefaultAccountValues({
        timezone: DEFAULT_ACCOUNT_VALUES.timezone,
        plan: cceDefaultFields?.planCode,
        planCode: cceDefaultFields?.planCode,
        channel: cceDefaultFields?.channel,
        subChannel: cceDefaultFields?.subChannel,
        parentPoolAcctId: cceDefaultFields?.parentPoolAcctId
      });
    }
  }, [ccType, cceDefaultFields]);

  useEffect(() => {
    if (policyAgree) {
      setPolicyError(INITIAL_POLICY_ERROR);
    }
  }, [policyAgree]);

  const handleClose = (e, reason) => {
    if (reason !== 'backdropClick') {
      const url = location.href;
      values.country = '';
      values.countryIsoCode = '';
      e.preventDefault();
      setSubmitting(INITIAL_SUBMITTING_STATE);
      setPolicyAgree(INITIAL_POLICY_STATE);
      setErrors({ ...INITIAL_ERROR_STATE });
      setValues({ ...INITIAL_VALUES_STATE });
      setSignUpError(INITIAL_SIGN_UP_ERROR);
      location.href = url.split('?signup')[0];
      onCloseDialog();
    }
  };

  const handleErrors = (event, error) => {
    if (event.target.name) {
      setErrors({ ...errors, [event.target.name]: error });
    }
  };

  const handleChange = (event) => {
    if (event.target.name) {
      setValues({ ...values, [event.target.name]: event.target.value });
    }
  };

  const handlePasswordChange = (event) => {
    const password = event.target.value;
    setValues({ ...values, [event.target.name]: event.target.value });
    const passwordMessages = validatePassword(password);
    setPasswordErrors(passwordMessages);
    setErrors({ ...errors, password: passwordMessages.length > 0 });
  };

  const isValid = () => {
    let invalidFields = false;
    Object.keys(errors).forEach((key) => {
      if (key == 'state') {
        if (statesByCountry.length > 0 && values[key] === '') {
          invalidFields = true;
        }
      } else if (key !== 'countryIsoCode' && (!values[key] || errors[key])) {
        invalidFields = true;
      }
    });
    return !invalidFields && policyAgree;
  };

  const onPolicyChange = () => {
    setPolicyAgree(!policyAgree);
  };

  const showErrors = () => {
    let err = errors;
    Object.keys(values).forEach((value) => {
      if (!values[value]) {
        err = { ...err, [value]: true };
      }
    });
    setPolicyError(!policyAgree);
    setErrors(err);
  };

  const changeCountry = (event) => {
    values.state = '';

    const country = countries.find((n) => n.name === event.target.value);
    values.country = country ? country.name : '';
    values.countryIsoCode = country ? country.isoCode : '';

    if (values.countryIsoCode !== '') {
      let result = states[values.countryIsoCode];
      setStates(result || []);
      return;
    }
    setStates([]);
  };

  const submitForm = async (event) => {
    if (isValid()) {
      const marketingData = setMarketingData({
        campaign: profile.account,
        source: profile.companyName,
        country: values.country,
        state: values.state
      });
      const newCustomer = {
        ...values,
        ...defaultAccountValues,
        marketingData
      };
      setSubmitting(true);
      setSignUpError(INITIAL_SIGN_UP_ERROR);
      try {
        const { account } = await addNewCustomer(newCustomer);

        if (ccType === CC_TYPE.Standard) {
          await linkToParentAccount(account.id);
        }
        onSuccess(
          'New customer trial added successfully',
          <>
            <Link
              sx={addCustomerClasses.successLink}
              href="#"
              onClick={(e) => {
                e.preventDefault();

                setTimeout(async () => {
                  try {
                    await impersonate(account.id)();
                    window.location.href = '/#/app/home';
                  } catch (ex) {
                    console.error(ex);
                  }
                });
              }}
            >
              Go to new account dashboard
            </Link>{' '}
            - {NEW_ACCOUNT_DELAY_MESSAGE}
          </>
        );
        handleClose(event);
      } catch (err) {
        handleFormErrors(err, event);
      }
    } else {
      showErrors();
    }
  };

  const handleFormErrors = (error, event) => {
    const message = error.message;
    if (message.match('Account already exists.')) {
      setSignUpError('This email is already registered.');
    } else if (message.match('Enter a valid email.')) {
      setSignUpError('Enter a valid email.');
      setErrors({ ...errors, email: true });
    } else {
      onError(error);
      handleClose(event);
    }
    setSubmitting(false);
  };
  const renderFormContent = () => {
    return (
      <>
        <DialogTitle sx={addCustomerClasses.title}>Add New Account</DialogTitle>
        <DialogContent>
          {signUpError && (
            <Typography
              sx={addCustomerClasses.signUpError}
              color="error"
              variant="subtitle2"
            >
              {signUpError}
            </Typography>
          )}
          <form noValidate autoComplete="on">
            <Box sx={addCustomerClasses.multipleTextField}>
              <CustomInput
                name="firstName"
                onChange={handleChange}
                onBlur={handleErrors}
                label="First Name"
                value={values.firstName}
                error={errors.firstName}
                required
              />
              <CustomInput
                onChange={handleChange}
                onBlur={handleErrors}
                name="lastName"
                label="Last Name"
                value={values.lastName}
                error={errors.lastName}
                required
              />
            </Box>
            <CustomInput
              onChange={handleChange}
              onBlur={handleErrors}
              name="organization"
              value={values.organization}
              label="Customers Organization Name"
              required
              error={errors.organization}
            />
            <Box sx={addCustomerClasses.multipleTextField}>
              <CustomSelect
                name="country"
                items={countries}
                value={values.country}
                label="Country"
                onChange={changeCountry}
                onBlur={handleErrors}
                error={errors.country}
                required
              />
              <CustomSelect
                name="state"
                items={statesByCountry}
                value={values.state}
                label="State / Province"
                onChange={handleChange}
                onBlur={handleErrors}
                error={errors.state}
                required={statesByCountry.length > 0}
              />
            </Box>
            <CustomInput
              onChange={handleChange}
              onBlur={handleErrors}
              name="phone"
              value={values.phone}
              error={errors.phone}
              type="phone"
              label="Phone"
              required
            />
            <CustomInput
              onChange={handleChange}
              onBlur={handleErrors}
              name="email"
              type="email"
              value={values.email}
              error={errors.email}
              label="Work Email"
              required
            />
            <CustomInput
              onChange={handlePasswordChange}
              onBlur={handlePasswordChange}
              name="password"
              value={values.password}
              error={errors.password}
              type="password"
              label="Password"
              displayerrors={0}
              required
            />
            <Box sx={addCustomerClasses.passwordError}>
              {passwordErrors.length > 0 &&
                passwordErrors.map((e) => (
                  <span
                    sx={addCustomerClasses.passwordError}
                    key={Math.random()}
                  >
                    {e}
                  </span>
                ))}
            </Box>

            <Box sx={addCustomerClasses.privacy}>
              <Checkbox
                color="primary"
                checked={policyAgree}
                onChange={onPolicyChange}
                name="policyAgree"
              />
              <Box>
                <Typography
                  variant="body2"
                  color="disabled"
                  fontSize={14}
                  fontWeight="fontWeightMedium"
                >
                  By submitting this form, I agree to ActivTrak&apos;s{' '}
                  <Link
                    href={termsOfServiceUrl}
                    target="_blank"
                    sx={addCustomerClasses.consentLink}
                  >
                    Terms of Service
                  </Link>
                  {'   '}
                  and consent to the processing of the personal data provided
                  above in accordance with and as described in the{' '}
                  <Link
                    href={privacyPolicyUrl}
                    target="_blank"
                    sx={addCustomerClasses.consentLink}
                  >
                    Privacy Policy
                  </Link>
                  .
                </Typography>
                <Box sx={addCustomerClasses.error}>
                  {policyError && (
                    <Typography variant="caption" color="error">
                      Must accept Privacy Policy
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </form>
        </DialogContent>
        <DialogActions sx={addCustomerClasses.dialogActions}>
          <Button
            onClick={submitForm}
            fullWidth
            variant="contained"
            color="primary"
            disabled={!isValid()}
            disableElevation
          >
            Create New Account
          </Button>
          <Link href="#" onClick={handleClose} className="btn-cancel">
            Cancel
          </Link>
        </DialogActions>
      </>
    );
  };
  const renderLoadingState = () => {
    return (
      <Box sx={addCustomerClasses.loading}>
        <Typography variant="h6">Provisioning Account</Typography>
        <CircularProgress />
      </Box>
    );
  };

  return (
    <Dialog
      sx={addCustomerClasses.dialog}
      fullWidth={true}
      maxWidth="md"
      disableEscapeKeyDown
      onClose={handleClose}
      open={open}
    >
      <Box sx={addCustomerClasses.wrapper}>
        {!submitting && renderFormContent()}
        {submitting && renderLoadingState()}
      </Box>
    </Dialog>
  );
};

export default AddCustomerDialog;
