'use strict';
import _ from 'lodash';
import { IAuthorizations } from '../../models/IAuthorizations';
import { Role } from '../../enums';
import { IAccountSettings } from '../../models';

const isProductionEnvironment = import.meta.env.VITE_NAME === 'prod';

const _getBundleFlags = (bundleFlags, roles) => {
  
  const isSuperAdmin = roles?.some(
    (role: string) => role.toLowerCase() === Role.SuperAdmin
  );

  if (!isProductionEnvironment || isSuperAdmin) {
    try {
      const rawData = window?.localStorage?.getItem(
        'activTrak.bundleFlagOverrides'
      );
      const bundleFlagOverrides = rawData ? JSON.parse(rawData) : {};
      const bundleFlagKeys = Object.keys(bundleFlagOverrides);

      if (bundleFlagKeys.length <= 0) {
        return bundleFlags;
      }

      let warningMessage =
        '%cActivTrak Warning:%c Overriding bundle flags due to activTrak.bundleFlagOverrides setting in local storage:\n\n';
      bundleFlagKeys.forEach(
        (key) =>
          (warningMessage += `   ${key}: override = ${bundleFlagOverrides[key]}, original = ${bundleFlags[key]}\n`)
      );
      warningMessage +=
        '\n%cNote: Overriding the bundle flags will only be represented in the UI views. This will not affect any other systems and could have adverse effects for flags that control whole systems such as "isScreenDetailsEnabled".';
      console.warn(
        warningMessage,
        'background-color: #444444; font-weight: bolder;',
        'background-color: inherit; font-weight: inherit;',
        'font-style: italic;'
      );

      return { ...bundleFlags, ...bundleFlagOverrides };
    } catch {
      () => {
        console.error(`ActivTrak Error: Unable to parse JSON for overrides.`);
        return bundleFlags;
      };
    }
  }

  return bundleFlags;
};

// TODO: Migrate all uses to user token service
const _generateRoles = (claims) => {
  if (!claims || typeof claims.forEach !== 'function') {
    console.error(
      'ActivTrak Error: Cannot generate user roles due to missing claims.',
      claims
    );
    return;
  }

  const roles = [];
  claims.forEach((claim) => {
    if (_.includes(claim.Type, 'claims/role')) {
      roles.push(claim.Value);
    }
  });

  // console.log('Parsed Roles', roles);
  return roles;
};

//IAccountSettingsDto needs to map to IAccountSettings somewhere
export const createAuthorizations = (
  accountSettings: Partial<IAccountSettings>,
  userClaims: IAuthorizations
) => {
  if (!userClaims)
    return {
      roles: null
    };
  // TODO: Research why we added role access to the role object and migrate to user token service if needed
  let roles = _generateRoles(userClaims);
  if (accountSettings?.roleAccess)
    roles = [...roles, ...accountSettings.roleAccess];

  if (!roles?.length) {
    console.error(
      'ActivTrak Error: User does not have any defined roles.',
      roles
    );
    return {
      roles: null
    };
  }

  const authorizations: IAuthorizations = {
    // TODO: Research why we added role access to the role object and migrate to user token service if needed
    roles
  };

  // TODO: Research why we would ever generate an authorizations object if we do not have accountSettings (post_login return)
  if (accountSettings) {
    /*
     * Needs migration
     */
    // TODO: Move to JWT user token service
    authorizations.bundleFlags = _getBundleFlags(
      accountSettings.bundleFlags,
      roles
    );
  }

  return authorizations;
};

export const authorizationsFactory = {
  createAuthorizations
};
