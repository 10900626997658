'use strict';

// Module Definition
angular.module('login', ['ui.router', 'ui.router.state.events', 'config', 'utils']);
angular.module('login').controller('LoginController', LoginController);

LoginController.$inject = [
    '$scope',
    '$location',
    '$rootScope',
    '$state',
    '$q',
    '$timeout',
    'localStorageService',
    '$http',
    'loginServiceUrl',
    '$window',
    'messagesService',
    'loginService',
    'authorizationService',
    'customUibModal',
    'envService',
    'browserServiceFunctions',
    'googleAppClientId'
];

function LoginController(
    $scope,
    $location,
    $rootScope,
    $state,
    $q,
    $timeout,
    localStorageService,
    $http,
    loginServiceUrl,
    $window,
    msg,
    loginService,
    authorizationService,
    customUibModal,
    envService,
    browserServiceFunctions,
    googleAppClientId
) {
    var vm = this;

    vm.impersonate = false;
    vm.showPassword = false;
    vm.showPasswordTooltip = 'Show Password';
    vm.showPasswordIconClass = 'icon-at-eye';
    vm.emailFieldPlaceholder = msg.get('atUserLogin');
    $rootScope.isAccountCreationMode = false;

    $window.document.title = msg.get('login') + ' | ' + $rootScope.app.name;

    vm.isIeOrEdgeBrowser = function () {
        var ua = window.navigator.userAgent;
        var edge = ua.indexOf('Edge/');
        var ie = 0;

        if (window.navigator.appName === 'Microsoft Internet Explorer') {
            ie = 1;
        } else if (window.navigator.appName === 'Netscape') {
            ie = ua.indexOf('Trident/');
        }
        return edge > 0 || ie > 0;
    };

    vm.showHero = function () {
        return document.documentElement.clientWidth > 768;
    };

    vm.toggleShowPassword = function () {
        vm.showPassword = !vm.showPassword;
        vm.showPasswordTooltip = vm.showPassword ? 'Hide Password' : 'Show Password';
        vm.showPasswordIconClass = vm.showPassword ? 'icon-at-eye-slash' : 'icon-at-eye';
    };

    function addVPNVerify(message) {
        if (envService.isProduction()) {
            return message;
        }

        return msg.get('loginErrorMessage_verifyVPN') + message;
    }

    function logLoginErrorMessage(type, message) {
        var logTypes = { Error: 'Error', Warn: 'Warn', Info: 'Info' };
        var errorMessageTypeMap = {
            problemsResettingPassword: logTypes.Error,
            loginFailed: logTypes.Error,
            nullToken: logTypes.Error,
            verifyError: logTypes.Error,
            apiServerUnavailable: logTypes.Error,
            logoutReason: logTypes.Error,
            chromebookInstallError: logTypes.Error,
            accountIdUnverifiedError: logTypes.Warn,
            lockout: logTypes.Warn,
            isOffline: logTypes.Warn,
            accountKilled: logTypes.Warn,
            googleAccountNotExists: logTypes.Warn,
            invalidTemporaryCode: logTypes.Warn,
            verifySuccess: logTypes.Info,
            resetPasswordSsoEnabled: logTypes.Info,
            forgotPasswordConfirmation: logTypes.Info,
            maintenanceMode: logTypes.Info,
            invalid: logTypes.Info,
            verifyVPN: logTypes.Info,
            verifyMessage: logTypes.Info,
            clear: logTypes.Info,
            accountNotFound: logTypes.Info,
            unknown: logTypes.Error
        };

        var mappedLogType = errorMessageTypeMap[type] ?? errorMessageTypeMap.unknown;
        var logType = logTypes[mappedLogType] ?? logTypes.Error;

        console[logType.toLowerCase()](`ActivTrak ${logType}: Login message "${type}" - ${message}`);
    }

    function updateErrorMessage(type) {
        if (!type) {
            if ($state.params.verifySuccess) {
                type = 'verifySuccess';
                vm.verifyMessage = msg.get('loginErrorMessage_verifySuccess');
            } else if ($state.params.verifyError) {
                type = 'verifyError';
                vm.verifyMessage = msg.get('loginErrorMessage_verifyError');
            } else if ($state.params.chromebookInstallError) {
                type = 'chromebookInstallError';
                vm.errorMessage = msg.get('loginErrorMessage_chromebookInstallError');
            } else if ($state.params.apiFailure) {
                type = 'apiServerUnavailable';
                vm.errorMessage = addVPNVerify(msg.get('loginErrorMessage_apiServerUnavailable'));
            } else if ($state.params.maintenanceModeReason) {
                type = 'maintenanceMode';
                vm.errorMessage = msg.get('loginErrorMessage_maintenanceMode', $state.params.maintenanceModeReason);
            } else if ($state.params.logoutReason) {
                type = 'logoutReason';
                $state.params.logoutReason = browserServiceFunctions.htmlEscape($state.params.logoutReason);
                vm.errorMessage = msg.get('loginErrorMessage_logoutReason', $state.params.logoutReason);
            } else {
                type = 'clear';
                vm.errorMessage = undefined;
                vm.hideSignup = false;
            }
        } else if (type === 'chromebookInstallError') {
            vm.errorMessage = $state.params.chromebookInstallError;
        } else if (type === 'clear') {
            vm.errorMessage = undefined;
            vm.hideSignup = false;
        } else {
            vm.errorMessage =
                type === 'apiServerUnavailable'
                    ? addVPNVerify(msg.get('loginErrorMessage_' + type))
                    : msg.get('loginErrorMessage_' + type);
        }

        vm.hideSignup = type === 'apiServerUnavailable' || type === 'isOffline';

        var successErrorTypes = [
            'verifyMessage',
            'forgotPasswordConfirmation',
            'verifySuccess',
            'maintenanceMode',
            'clear'
        ];

        if (successErrorTypes.indexOf(type) > -1) {
            vm.errorClass = 'text-success';
        } else {
            vm.errorClass = 'text-danger';
        }

        logLoginErrorMessage(type, vm.errorMessage || vm.verifyMessage);
    }

    function setToken(token, username, doNotStore) {
        if (typeof doNotStore === 'function') {
            doNotStore = doNotStore();
        }

        loginService.setGlobals(
            {
                currentUser: {
                    token: token,
                    username: username
                }
            },
            doNotStore
        );
    }

    function storeUsername(username) {
        localStorageService.set('username', username);
    }

    function getStoredUsername() {
        return localStorageService.get('username');
    }

    function goToNextOrLandingPage() {
        var localStorageNext = localStorageService.get('login-next');
        if (localStorageNext) {
            localStorageService.remove('login-next');
        }
        var next = $state.params.next || localStorageNext;
        $state.go('app.loading', { next });
    }

    function goToSupportPortal() {
        // remove any accounts saved in memory
        window.localStorage.removeItem('activTrak.supportPortal.accounts');
        window.localStorage.removeItem('activTrak.supportPortal.supportRole');
        window.localStorage.removeItem('activTrak.support.backup');
        // redirect to support portal
        $state.go('supportportal');
    }

    function checkOnline() {
        return $q(function (resolve) {
            if (!$window.navigator.onLine) {
                resolve(false);
            } else {
                $http.get('favicon.ico').then(
                    function () {
                        resolve(true);
                    },
                    function () {
                        resolve(false);
                    }
                );
            }
        });
    }

    // Check for stored user (remember me)
    var storedUsername = getStoredUsername();
    if (storedUsername && storedUsername !== '') {
        vm.rememberMe = true;
        vm.email = storedUsername;
    }

    if ($state.params.tempcode) {
        // Ensure there is no active token
        loginService.clearScope();
        var state = $location.search()['state'];
        if (state && state === 'SSO') {
            vm.state = 'sso-login';
            vm.email = $state.params.email;
            setToken($state.params.tempcode, vm.email);
            goToNextOrLandingPage();
        } else {
            // Validate tempcode
            vm.email = $state.params.resetid || $state.params.setid;
            vm.invitedUser = !!$state.params.setid;
            setToken($state.params.tempcode, vm.email, true);

            $http
                .get(loginServiceUrl.apiUrl() + '/resetPassword?username=' + encodeURIComponent(vm.email))
                .then(function (result) {
                    vm.state = 'resetPassword';
                    setToken(result.data.token, vm.email, true);
                })
                .catch(function (error) {
                    console.error('ActivTrak Error: Error requesting password reset', error);
                    if (error.data && error.data.code && error.data.code === 'sso') {
                        vm.setSso();
                    } else if (error.status === 401) {
                        // if expired send them back to the reset screen
                        updateErrorMessage('invalidTemporaryCode');
                        vm.state = 'forgotPassword';
                    } else {
                        // all other errors send back to loin with api error
                        updateErrorMessage('apiServerUnavailable');
                        vm.state = 'login';
                    }
                });
        }
    } else {
        vm.state = 'login';
        if ($state.params.email) {
            vm.email = $state.params.email;
            vm.rememberMe = false;
        }
    }

    if ($state.params.email && $state.params.email !== '') {
        vm.rememberMe = false;
        vm.email = $state.params.email;
    }

    if ($state.params.sso === 'true') {
        vm.state = 'SSO';
    }

    vm.loginGoogle = function (token) {
        updateErrorMessage();

        vm.busy = true;

        $http
            .post(loginServiceUrl.apiUrl() + '/account/google', {
                token: token
            })
            .then(function (result) {
                vm.busy = false;
                setToken(result.data.token, result.data.email);
                goToNextOrLandingPage();
            })
            .catch(function (error) {
                vm.busy = false;
                if (error === 'error' || error === 'authCancelled') {
                    // popup closed
                    return;
                }
                if (error.status === 400) {
                    if (error.data && error.data.code && error.data.code === 'SSO') {
                        updateErrorMessage('loginFailed');
                        return;
                    }

                    updateErrorMessage('googleAccountNotExists');
                    return;
                } else if (error.status === 423) {
                    if (error.data && error.data.code && error.data.code === 'IN_MAINTENANCE_MODE') {
                        $state.params.maintenanceModeReason = error.data.message;
                        updateErrorMessage();
                        return;
                    }
                } else if (error.status === 401 && error.data && error.data.code) {
                    if (error.data.code.indexOf('verify') !== -1) {
                        vm.showUnverified = true;
                        vm.isCreator = error.data.code.indexOf('creator') !== -1;
                        return;
                    } else if (error.data.code === 'kill') {
                        updateErrorMessage('accountKilled');
                        return;
                    }
                }

                console.error('ActivTrak Error: Error logging in with Google', error);
                updateErrorMessage('apiServerUnavailable');
            });
    };

    vm.setSso = function () {
        vm.errorMessage = undefined;
        vm.state = 'SSO';

        // TODO: look for cookie with username
    };

    vm.initiateSsoLogin = function () {
        localStorageService.set('login-next', $state.params.next);
        var url = loginServiceUrl.ssoAuthorizationUrl() + '/sso/saml/login?username=' + encodeURIComponent(vm.email);
        location.replace(url);
    };

    vm.resetPasswordFieldError = function () {
        return Object.keys($scope.resetPasswordForm.password.$error).length > 2;
    };

    vm.validPassword = function () {
        return typeof $scope.resetPasswordForm.$invalid !== 'undefined' && !$scope.resetPasswordForm.$invalid;
    };

    vm.setPassword = function () {
        if (!vm.validPassword()) {
            return;
        }

        var payload = {
            password: vm.password
        };

        $http
            .post(loginServiceUrl.apiUrl() + '/setPassword', payload)
            .then(function () {
                vm.auth();
            })
            .catch(function (error) {
                if (error.status === 401) {
                    // if expired send them back to the reset screen
                    updateErrorMessage('invalidTemporaryCode');
                    vm.state = 'forgotPassword';
                } else {
                    // all other errors send back to loin with api error
                    console.error('ActivTrak Error: Error setting password.', error);
                    updateErrorMessage('apiServerUnavailable');
                    vm.state = 'login';
                }
            });
    };

    vm.showForgotPassword = function () {
        updateErrorMessage();
        vm.state = 'forgotPassword';
    };

    vm.showLogin = function () {
        vm.state = 'login';
        authorizationService.clearAuthorizations();
        updateErrorMessage();
    };

    vm.resetPassword = function () {
        updateErrorMessage();

        var payload = {
            username: vm.email
        };
        vm.busy = true;
        $http
            .post(loginServiceUrl.apiUrl() + '/forgotPassword', payload)
            .then(function () {
                vm.busy = false;
                vm.state = 'login';
                updateErrorMessage('forgotPasswordConfirmation');
            })
            .catch(function (error) {
                vm.busy = false;
                if (error.data && error.data.code === 'sso') {
                    updateErrorMessage('resetPasswordSsoEnabled');
                    return;
                }

                if (error.data && error.data.message === 'userNotVerified') {
                    updateErrorMessage('accountIdUnverifiedError');
                    return;
                }

                if (!(error.data && error.data.message === 'userNotFound')) {
                    updateErrorMessage('problemsResettingPassword');
                }
            });
    };

    vm.auth = function () {
        /* globals __VERSION__:false */
        var version = __VERSION__;

        var payload = {
            username: vm.email,
            password: vm.password,
            keepSignedIn: vm.rememberMe,
            uiVersion: version
        };

        if (vm.rememberMe) {
            storeUsername(vm.email);
        }

        updateErrorMessage('clear');
        vm.busy = true;

        $http
            .post(loginServiceUrl.authorizationServiceUrl() + '/login', payload)
            .then(function (result) {
                if (vm.state !== 'resetPassword' && vm.state !== 'forgotPassword') {
                    vm.password = '';
                }
                if (result.data) {
                    if (!result.data.token) {
                        vm.busy = false;
                        console.error('Null token received');
                        updateErrorMessage('nullToken');
                    }

                    var isOverrideRole = function () {
                        return authorizationService.hasRole([
                            authorizationService.roles.superAdmin,
                            authorizationService.roles.supportBasic
                        ]);
                    };

                    var hasSupportPortalRole = function () {
                        return authorizationService.hasRole([authorizationService.roles.supportPortal]);
                    };

                    // Set temporary token used for role checks
                    setToken(result.data.token, result.config.data.username, true);

                    if (isOverrideRole()) {
                        // temporary handling to redirect authorized support portal users only
                        if (hasSupportPortalRole()) {
                            setToken(result.data.token, result.config.data.username, false);
                            goToSupportPortal();
                        } else {
                            vm.isSuperAdmin = authorizationService.hasRole(authorizationService.roles.superAdmin);
                            vm.state = 'support';
                            vm.busy = false;
                        }
                    } else {
                        // Set local storage token
                        setToken(result.data.token, result.config.data.username, false);
                        goToNextOrLandingPage();
                    }
                } else {
                    // Error handling
                    vm.busy = false;
                    console.error('No token received');
                }
            })
            .catch(function (result) {
                vm.busy = false;
                if (vm.state !== 'resetPassword' && vm.state !== 'forgotPassword') {
                    vm.password = '';
                }

                if (result && result.status !== 401) {
                    console.error(result);
                }

                if (result.data && result.data.code) {
                    if (result.data.code.indexOf('verify') !== -1) {
                        vm.showUnverified = true;
                        vm.isCreator = result.data.code.indexOf('creator') !== -1;
                        return;
                    } else if (result.data.code === 'IN_MAINTENANCE_MODE') {
                        $state.params.maintenanceModeReason = result.data.message;
                        updateErrorMessage();
                        return;
                    } else if (result.data.code === 'kill') {
                        updateErrorMessage('accountKilled');
                        return;
                    }
                }
                if (result.status === -1) {
                    checkOnline().then(function (online) {
                        if (online) {
                            console.error('ActivTrak Error: Error logging in.', result);
                            updateErrorMessage('apiServerUnavailable');
                        } else {
                            updateErrorMessage('isOffline');
                        }
                    });
                } else if (result.data && result.data.code && result.data.code.indexOf('lockout') !== -1) {
                    updateErrorMessage('lockout');
                } else {
                    updateErrorMessage('invalid');
                }
            });
    };

    vm.authAccount = function () {
        var payload = {
            username: vm.account,
            reason: JSON.stringify({
                description: vm.reasonDescription,
                ticket: vm.ticket
            }),
            impersonate: vm.impersonate
        };
        vm.busy = true;
        updateErrorMessage('clear');

        $http
            .post(loginServiceUrl.apiUrl() + '/account/select', payload)
            .then(function (result) {
                if (result.data) {
                    authorizationService.clearAuthorizations();
                    var userToken = result.data.impersonateToken || result.data.token;
                    setToken(userToken, result.data.userName);
                    $state.go('app.dashboard');
                } else {
                    vm.busy = false;
                    console.error('Unable to log in using /account/select endpoint.');
                }
            })
            .catch(function (result) {
                if (result && result.status === 400) {
                    updateErrorMessage('accountNotFound');
                }
                vm.busy = false;
            });
    };

    vm.showEmailVerifyModal = function (e) {
        e.preventDefault();
        var modal = customUibModal.open({
            animation: false,
            template:
                '<div class="verification-modal"><verification-options is-modal="true" owner="' +
                vm.email +
                '"></verification-options></div>',
            windowClass: 'centered-modal'
        });

        $rootScope.$on('dismiss-verification-modal', function () {
            modal.close();
        });
    };

    vm.signUpAppUrl = envService.signUpAppUrl();

    $scope.$watch(
        function () {
            return vm.user;
        },
        function () {
            updateErrorMessage();
        }
    );

    $scope.$watch(
        function () {
            return vm.rememberMe;
        },
        function (newValue, oldValue) {
            // If rememberme is turned off, delete user from storage
            if (!newValue && oldValue) {
                storeUsername(null);
            }
        }
    );

    // Handle route resolve errors
    $rootScope.$on('$stateChangeError', function (evt, toState, toParams, fromState, fromParams, error) {
        vm.showLogin();
        vm.busy = false;

        if (error.status === 401 || (error.detail && error.detail.status === 401)) {
            loginService.clearLastLogin();
            return;
        }

        if (error.detail.status === 423) {
            $state.params.maintenanceModeReason = error.detail.data.message;
            updateErrorMessage();
            return;
        }

        if (
            error &&
            error.detail &&
            error.detail.status === -1 &&
            error.detail.data &&
            error.detail.data.code.indexOf('MSA_ACCEPTANCE_NEEDED') !== -1
        ) {
            $state.params.logoutReason = error.detail.data.message;
            // console.log('StateChange Error', error);
            updateErrorMessage();
            return;
        }

        updateErrorMessage('apiServerUnavailable');

        console.error('ActivTrak Error: State change error from login.', error?.detail, error);

        // TODO: use the error object to display different messages if needed.
    });

    $timeout(function () {
        if ($state.params.showLockoutError) {
            updateErrorMessage('lockout');
        }
    });

    var handleCredentialResponse = function (e) {
        vm.loginGoogle(e.credential);
    };

    vm.googleBtnClick = function () {
        var divId = 'temp-google-signin-button';
        var googleSignIn = document.getElementById(divId);
        if (!googleSignIn) {
            googleSignIn = document.createElement('div');
            googleSignIn.setAttribute('id', divId);
            googleSignIn.style.display = 'none';
            googleSignIn.classList.add('google-signin-button');
            document.body.appendChild(googleSignIn);
            window.google.accounts.id.renderButton(googleSignIn, {});
        }

        googleSignIn.querySelector('div[role=button]').click();
    };

    $window.onGoogleLibraryLoad = function () {
        window.google.accounts.id.initialize({
            client_id: googleAppClientId,
            callback: handleCredentialResponse
        });
    };
}
