import { profileStore } from '../../../_reactivtrak/src/common/services/Profile/useProfileState';
import addDomainModalTemplate from 'views/modals/addBlockedDomainModal.html?raw';
import addDomainToGroupModalTemplate from 'views/modals/blockedDomainModal.html?raw';
import addGroupToDomainModalTemplate from 'views/modals/includedGroupsModal.html?raw';
import messageModalTemplate from 'views/modals/messageModal.html?raw';
import { find, remove } from 'lodash';

angular.module('app').controller('BlockingCtrl', BlockingCtrl);

BlockingCtrl.$inject = [
    '$scope',
    '$state',
    '$timeout',
    '$window',
    'customUibModal',
    'messagesService',
    'blockingService',
    'localStorageService',
    'atBlockingDataTracker',
    'atHelperFunctions',
    'templateServiceFunctions',
    'browserServiceFunctions',
    'authorizationService',
    'loginService'
];

function BlockingCtrl(
    $scope,
    $state,
    $timeout,
    $window,
    customUibModal,
    msg,
    blockingService,
    localStorageService,
    atBlockingDataTracker,
    atHelperFunctions,
    templateServiceFunctions,
    browserServiceFunctions,
    authorizationService,
    loginService
) {
    $scope.hasViewLevel = function (levels) {
        return authorizationService.hasAuthorizationLevel(levels, 'app.blocking');
    };

    const { username } = profileStore.getState().profile;

    $scope.domainFilter = 'all';
    $scope.totalPlusCount = 0;
    $scope.totalMinusCount = 0;
    $scope.isDirty = false;
    $scope.pageMode = localStorageService.get('BlockingPageMode-' + username) || 'groups';
    let previousMode;
    let updatedGroup = [];
    let blockingDataCache;
    let blockingDataPromise;
    let childDomains = [];
    let childGroups = [];
    let justAddedGroup;
    let allComputers;
    $scope.parentHeader = $scope.pageMode === 'groups' ? msg.get('groups') : msg.get('blockedDomains');
    $scope.childHeader = $scope.pageMode === 'groups' ? msg.get('blockedDomains') : msg.get('domainChildHeader');
    const tooltipOptions = {
        position: 'left',
        animation: {
            open: {
                effects: 'fade:in'
            },
            close: {
                effects: 'fade:out'
            }
        }
    };

    $scope.totalPlusTTOptions = angular.copy(tooltipOptions);
    $scope.totalPlusTTOptions.content = 'Total Unsaved Added Items';
    $scope.totalMinusTTOptions = angular.copy(tooltipOptions);
    $scope.totalMinusTTOptions.content = 'Total Unsaved Removed Items';

    const computerCountTooltipTemplate = function (computers) {
        let i;
        const maxListSize = Math.min(computers.length, 10);
        const header = computers.length > 0 ? '' : 'No Computer Members';
        let computerList = '';
        for (i = 0; i < maxListSize; i++) {
            computerList +=
                '<div class="at-computers-tooltip-item">' +
                templateServiceFunctions.minimizeString(20, computers[i].name) +
                (i === maxListSize - 1 && computers.length <= maxListSize ? '' : ',') +
                '</div>';
        }
        const footer =
            '<div class="at-computers-tooltip-footer">' +
            (computers.length > maxListSize ? '+ ' + (computers.length - maxListSize) + ' More' : '') +
            '</div>';
        return '<div class="at-computers-tooltip">' + header + computerList + footer + '</div>';
    };

    const groupTemplate = function (item, gridType) {
        if (!item.computerTTOptions) {
            item.computerTTOptions = angular.copy(tooltipOptions);
            item.computerTTOptions.content = computerCountTooltipTemplate(item.members);
            item.computerTTOptions.position = 'right';
            item.computerTTOptions.width = 150;
        }
        return (
            '<div class="inline at-row-item' +
            (gridType === 'parent' || item.isBlocked ? '' : ' at-unblocked') +
            '" kendo-tooltip k-options="::dataItem.computerTTOptions">' +
            '<div class="m-l-5 inline" ng-style="{\'max-width\': (columnWidth - 80) + \'px\'}">' +
            browserServiceFunctions.htmlEscape(item.name) +
            '</div>' +
            '<div class="m-l-5 inline">' +
            '(' +
            item.members.length +
            ' computers in group' +
            (item.members.length === 1 ? ')' : 's)') +
            '</div>' +
            '</div>'
        );
    };

    const domainTemplate = function (item, gridType) {
        return (
            '<div class="inline at-row-item at-ellipsis' +
            (gridType === 'parent' || item.isBlocked ? '' : ' at-unblocked') +
            "\" ng-style=\"{'max-width': (columnWidth - 70) + 'px'}\">" +
            templateServiceFunctions.getFaviconTemplateAsString(item.domain, true) +
            '</div>'
        );
    };

    const badgeTemplate = function (item, badgeType, itemType) {
        if (badgeType === 'original') {
            item.originalCount = item.originalCount > 0 ? item.originalCount : 0;
            item.badgeTTOptions =
                item.badgeTTOptions === undefined ? angular.copy(tooltipOptions) : item.badgeTTOptions;
            item.badgeTTOptions.content =
                item.originalCount +
                (itemType === 'Domain' ? ' Blocked Domain' : ' Included Group') +
                (item.originalCount === 1 ? '' : 's');
            return (
                '<div class="inline pull-right' +
                (item.isDirty ? ' at-dirty' : '') +
                '" kendo-tooltip k-options="::dataItem.badgeTTOptions"> ' +
                (item.originalCount > 0 || item.isDirty
                    ? '<span class="badge at-badge at-badge-count">{{::dataItem.originalCount}}</span>'
                    : '') +
                '</div>'
            );
        } else if (badgeType === 'plus') {
            item.plusBadgeTTOptions =
                item.plusBadgeTTOptions === undefined ? angular.copy(tooltipOptions) : item.plusBadgeTTOptions;
            item.plusBadgeTTOptions.content = item.plusCount + ' Added ' + itemType + (item.plusCount === 1 ? '' : 's');
            return item.plusCount !== 0
                ? '<div class="inline pull-right" kendo-tooltip k-options="::dataItem.plusBadgeTTOptions">+<span class="badge at-badge at-badge-plus">{{::dataItem.plusCount}}</span></div>'
                : '';
        } else if (badgeType === 'minus') {
            item.minusBadgeTTOptions =
                item.minusBadgeTTOptions === undefined ? angular.copy(tooltipOptions) : item.minusBadgeTTOptions;
            item.minusBadgeTTOptions.content =
                item.minusCount + ' Removed Group ' + itemType + (item.minusCount === 1 ? '' : 's');
            return item.minusCount !== 0
                ? '<div class="inline pull-right" kendo-tooltip k-options="::dataItem.minusBadgeTTOptions">-<span class="badge at-badge at-badge-minus">{{::dataItem.minusCount}}</span></div>'
                : '';
        }
        return '';
    };

    const addRemoveButtonTemplate = function (item, itemType) {
        item.buttonTTOptions = item.buttonTTOptions === undefined ? angular.copy(tooltipOptions) : item.buttonTTOptions;
        item.buttonTTOptions.content = (!item.isBlocked ? 'Add ' : 'Remove ') + itemType;
        return (
            '<span ' +
            'class="btn btn-xs pull-right at-btn-in-row' +
            (!(item.isDirty && !item.isBlocked) ? ' btn-danger' : '') +
            (item.isDirty && !item.isBlocked ? ' btn-success' : '') +
            '" ' +
            "ng-show=\"hasViewLevel('edit') && " +
            (itemType === 'Group' ? 'domainsHaveSelection' : 'groupsHaveSelection') +
            '" ' +
            'kendo-tooltip k-options="::dataItem.buttonTTOptions" ' +
            'ng-click="' +
            (itemType === 'Group' ? 'removeChildGroup(dataItem)' : 'removeChildDomain(dataItem)') +
            '">' +
            '<i class="fa' +
            (!(item.isDirty && !item.isBlocked) ? ' fa-minus' : '') +
            (item.isDirty && !item.isBlocked ? ' fa-plus' : '') +
            '"></i>' +
            '</span>'
        );
    };

    const parentGroupTemplate = function (item) {
        return (
            '<div class="at-pointer">' +
            groupTemplate(item, 'parent') +
            badgeTemplate(item, 'minus', 'Domain') +
            badgeTemplate(item, 'plus', 'Domain') +
            badgeTemplate(item, 'original', 'Domain') +
            '</div>'
        );
    };

    const parentDomainTemplate = function (item) {
        item.originalCount = typeof item.originalCount === 'number' ? item.originalCount : 0;
        if (item.originalCount === 0 && !item.isDirty) {
            if (!!$scope.selectedDomain && item.domain === $scope.selectedDomain.domain) {
                $scope.selectedDomain.domain = undefined;
            }
            return '';
        }
        return (
            '<div class="at-pointer">' +
            domainTemplate(item, 'parent') +
            badgeTemplate(item, 'minus', 'Group') +
            badgeTemplate(item, 'plus', 'Group') +
            badgeTemplate(item, 'original', 'Group') +
            '</div>'
        );
    };

    const childDomainTemplate = function (item) {
        return '<div>' + domainTemplate(item, 'child') + addRemoveButtonTemplate(item, 'Domain') + '</div>';
    };

    const childGroupTemplate = function (item) {
        return '<div>' + groupTemplate(item, 'child') + addRemoveButtonTemplate(item, 'Group') + '</div>';
    };

    $scope.removeAllChildren = function (type) {
        if ($scope.pageMode === 'groups' && !$scope.groupsHaveSelection) {
            return;
        }

        if ($scope.pageMode === 'domains' && !$scope.domainsHaveSelection) {
            return;
        }

        const source = type === 'group' ? childGroupDataSource : childDomainDataSource;
        const data = source.data();
        data.forEach(function (item) {
            const group = type === 'group' ? item : $scope.selectedGroup;
            const domain = type === 'group' ? $scope.selectedDomain : item;
            removeDomainGroup(group, domain, false);
        });
        domainDataSource.read({
            useCache: true
        });
        groupDataSource.read({
            useCache: true
        });
    };

    $scope.removeChildDomain = function (row) {
        updatedGroup = [];
        updateData($scope.selectedGroup, row.domain, !row.isBlocked);
        groupDataSource.read({
            useCache: true
        });
        domainDataSource.read({
            useCache: true
        });
        childDomainDataSource.read({
            domains: atBlockingDataTracker.getGroupDomains($scope.selectedGroup)
        });
    };

    $scope.removeChildGroup = function (row) {
        updatedGroup = [];
        updateData(row, $scope.selectedDomain.domain, !row.isBlocked);
        groupDataSource.read({
            useCache: true
        });
        domainDataSource.read({
            useCache: true
        });
        childGroupDataSource.read({
            groups: atBlockingDataTracker.getDomainGroups($scope.selectedDomain)
        });
    };

    $scope.getFaviconTemplate = templateServiceFunctions.getFaviconTemplate;

    $scope.getChildHeader = function () {
        const selected =
            $scope.pageMode === 'group' ? $scope.selectedGroup.displayName : $scope.selectedDomain.displayName;
        return typeof selected === 'string' ? ' | ' + selected : '';
    };

    $scope.addDomain = function () {
        if (!$scope.groupDataLoaded || $scope.showOverLimit) {
            return;
        }

        const modal = customUibModal.open({
            animation: false,
            template: addDomainModalTemplate,
            controller: 'addBlockedDomainModalController',
            windowClass: 'centered-modal',
            resolve: {
                modalData: {
                    groupDataSource: groupDataSource,
                    domainDataSource: blockingService.getDomains,
                    blockedDomainDataSource: domainDataSource,
                    saveDomain: blockingService.saveDomain,
                    deleteDomain: blockingService.deleteDomain,
                    domainChanges: atBlockingDataTracker.getChangedDomains()
                }
            }
        });

        modal.result.then(function (domainAdd) {
            updatedGroup = [];
            if (domainAdd.isBulkSelect) {
                for (const domainName in domainAdd.selectedDomains) {
                    const domain = atBlockingDataTracker.findDomain(domainName);
                    if (domain) {
                        for (const groupName in domain.groups) {
                            removeDomainGroup(domain.groups[groupName], domain, false);
                        }
                    }
                }
            }
            domainAdd.removedGroups.forEach(function (group) {
                for (const domain in domainAdd.selectedDomains) {
                    updateData(group, domain, false);
                }
            });
            domainAdd.includedGroups.forEach(function (group) {
                for (const domain in domainAdd.selectedDomains) {
                    updateData(group, domain, true);
                }
            });
            $scope.selectedDomain = domainAdd.selectedDomain;
            domainDataSource.read({
                useCache: true
            });
            groupDataSource.read({
                useCache: true
            });
        });
    };

    $scope.manageGroup = function () {
        $state.go('app.settings.groups');
    };

    $scope.addDomainToGroup = function () {
        if (!$scope.groupsHaveSelection) {
            return;
        }

        const modal = customUibModal.open({
            animation: false,
            template: addDomainToGroupModalTemplate,
            controller: 'blockedDomainModalController',
            windowClass: 'centered-modal',
            resolve: {
                modalData: {
                    selectedGroup: angular.copy($scope.selectedGroup),
                    gridDataSource: blockingService.getDomains,
                    domainChanges: atBlockingDataTracker.getChangedDomains(),
                    allComputerDomains: $scope.selectedGroup.name === 'All Computers' ? null : allComputers.domains
                }
            }
        });

        modal.result.then(function (domainUpdate) {
            updatedGroup = [];
            domainUpdate.blockedDomains.forEach(function (domain) {
                updateData($scope.selectedGroup, domain, true);
            });
            domainUpdate.unblockedDomains.forEach(function (domain) {
                updateData($scope.selectedGroup, domain, false);
            });
            domainDataSource.read({
                useCache: true
            });
            groupDataSource.read({
                useCache: true
            });
        });
    };

    $scope.addGroupToDomain = function () {
        if (!$scope.domainsHaveSelection) {
            return;
        }

        const modal = customUibModal.open({
            animation: false,
            template: addGroupToDomainModalTemplate,
            controller: 'includedGroupsModalController',
            windowClass: 'centered-modal',
            resolve: {
                modalData: {
                    selectedDomain: angular.copy($scope.selectedDomain),
                    gridDataSource: groupDataSource,
                    domainChanges: atBlockingDataTracker.getChangedDomains()
                }
            }
        });

        modal.result.then(function (groupUpdate) {
            updatedGroup = [];
            groupUpdate.includedGroups.forEach(function (group) {
                updateData(group, $scope.selectedDomain.domain, true);
            });
            groupUpdate.removedGroups.forEach(function (group) {
                updateData(group, $scope.selectedDomain.domain, false);
            });
            domainDataSource.read({
                useCache: true
            });
            groupDataSource.read({
                useCache: true
            });
        });
    };

    const getBlockedCount = function (list) {
        let count = 0;
        list.forEach(function (item) {
            if (item.isBlocked) {
                count += 1;
            }
        });
        return count;
    };

    const updateData = function (group, domainName, blocked) {
        const domain = atBlockingDataTracker.changedDomains[domainName];
        let groups;
        if (domain) {
            groups = angular.copy(domain.groups);
        }

        if (!!groups && !!groups.forEach) {
            groups.forEach(function (grp) {
                if (
                    group.name === 'All Computers' &&
                    blocked &&
                    grp.name !== 'All Computers' &&
                    updatedGroup.indexOf(group.name) === -1
                ) {
                    updatedGroup.push(grp.name);
                    updateDomainData(grp, domainName, false);
                    updateGroupData(grp, domainName, false);
                } else if (
                    group.name !== 'All Computers' &&
                    blocked &&
                    grp.name === 'All Computers' &&
                    updatedGroup.indexOf(group.name) === -1
                ) {
                    updatedGroup.push(grp.name);
                    updateDomainData(grp, domainName, false);
                    updateGroupData(grp, domainName, false);
                }
            });
        }

        if (!!group.name && updatedGroup.indexOf(group.name) === -1) {
            updateDomainData(group, domainName, blocked);
            updateGroupData(group, domainName, blocked);
        } else {
            updateDomainData(group, domainName, blocked);
        }
        $scope.isDirty = checkDirty();
    };

    const updateGroupData = function (group, domainName, blocked) {
        const data = find(atBlockingDataTracker.getGroupData(), function (g) {
            return g.name === group.name;
        });
        let blockedDomain = find(data.blockedDomains, function (d) {
            return d.domain === domainName;
        });
        if (!blockedDomain) {
            blockedDomain = {
                domain: domainName,
                isBlocked: blocked,
                isDirty: true
            };
            data.blockedDomains.push(blockedDomain);
        } else {
            blockedDomain.isDirty = atBlockingDataTracker.isDomainDirtyForGroup(domainName, data.name);
            blockedDomain.isBlocked = blocked;
            if (!blocked && !blockedDomain.isDirty) {
                remove(data.blockedDomains, function (d) {
                    return d.domain === domainName;
                });
            }
        }
        data.setDirty();
        atBlockingDataTracker.setGroupCounts(data);
        data.isBlocked = blocked;
        data.blockedCount = getBlockedCount(data.blockedDomains);
        atBlockingDataTracker.addKeyedGroup(angular.copy(data));
    };

    const getItem = function (array, itemId) {
        return angular.copy(
            find(array, function (i) {
                return i.id === itemId;
            })
        );
    };

    const getBlockingData = function () {
        return blockingService.getBlockingData().success(function (result) {
            return result;
        });
    };

    const generateBlockingData = function () {
        return getBlockingData().success(function (result) {
            blockingDataCache = result;
            const groups = blockingDataCache.groups;
            const domains = blockingDataCache.domains;
            const computers = blockingDataCache.computers;
            allComputers = null;

            groups.forEach(function (group) {
                if (group.name === 'All Computers') {
                    allComputers = group;
                }
                group.isDirty = false;
                group.originalCount = group.domains.length;
                group.plusCount = 0;
                group.minusCount = 0;
                group.blockedDomains = group.domains;
                group.blockedDomains.forEach(function (dom) {
                    dom.isBlocked = true;
                });
                group.computers = angular.copy(group.members);
                group.members = [];
                group.computers.forEach(function (computerId) {
                    group.members.push(getItem(computers, computerId));
                });
                group.setDirty = function () {
                    let isDirty = false;
                    this.blockedDomains.forEach(function (domain) {
                        if (domain.isDirty) {
                            isDirty = true;
                        }
                    });
                    this.isDirty = isDirty;
                };
                group.blockedCount = getBlockedCount(group.blockedDomains);
                atBlockingDataTracker.addKeyedGroup(angular.copy(group));
            });

            atBlockingDataTracker.setGroupData(groups);
            $scope.groupDataLoaded = true;
            const removeDomains = [];

            domains.forEach(function (domain, idx) {
                domain.isDirty = false;
                domain.plusCount = 0;
                domain.minusCount = 0;
                domain.originalCount = domain.groups.length;
                domain.setDirty = setDomainDirty;
                domain.groupCount = getBlockedCount(domain.groups);
                if (domain.originalCount === 0) {
                    removeDomains.push(idx);
                }
            });
            if (removeDomains.length > 0) {
                removeDomains.reverse();
                removeDomains.forEach(function (idx) {
                    domains.splice(idx, 1);
                });
            }
            const data = [];
            let i = 0;
            for (i = 0; i < 10000; i++) {
                const g = [];
                const isDirty = Math.random() >= 0.999;
                let changeCount = isDirty ? Math.floor(Math.random() * 3) + 1 : 0;
                changeCount = Math.random() >= 0.5 ? changeCount : changeCount * -1;
                g.length = Math.floor(Math.random() * 20) + 1;
                data.push({
                    domain: 'Domain-' + i,
                    groups: g,
                    isDirty: isDirty,
                    minusCount: changeCount > 0 ? 0 : changeCount * -1,
                    plusCount: changeCount < 0 ? 0 : changeCount,
                    originalCount: g.length
                });
            }
            atBlockingDataTracker.setDomainData(domains);
            return;
        });
    };

    const groupDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                const ret = [];
                if (options.data.useCache) {
                    ret.data = atBlockingDataTracker.getGroupData();
                    ret.total = ret.data.length;
                    options.success(ret);
                } else {
                    if (!blockingDataCache) {
                        let promise;
                        if (blockingDataPromise) {
                            promise = blockingDataPromise;
                        } else {
                            promise = generateBlockingData();
                            blockingDataPromise = promise;
                        }
                        promise.then(function () {
                            ret.data = atBlockingDataTracker.getGroupData();
                            ret.total = ret.data.length;
                            options.success(ret);
                        });
                    } else {
                        ret.data = atBlockingDataTracker.getGroupData();
                        ret.total = ret.data.length;
                        options.success(ret);
                    }
                }
            }
        },
        sort: [
            {
                field: 'name',
                dir: 'desc',
                compare: function (a, b) {
                    // All Computers should be on top
                    if (a.name === 'All Computers') {
                        a.name = 'All Computers';
                        return 1;
                    } else if (b.name === 'All Computers') {
                        b.name = 'All Computers';
                        return -1;
                    }

                    // Then sort by if dirty
                    if (a.isDirty && !b.isDirty) {
                        return 1;
                    } else if (!a.isDirty && b.isDirty) {
                        return -1;
                    }

                    // Then sort by blocked domain count
                    if (a.blockedDomains.length > 0 && b.blockedDomains.length === 0) {
                        return 1;
                    } else if (b.blockedDomains.length > 0 && a.blockedDomains.length === 0) {
                        return -1;
                    }

                    // Then sort by just added
                    if (justAddedGroup && a.name === justAddedGroup.name) {
                        return 1;
                    } else if (justAddedGroup && b.name === justAddedGroup.name) {
                        return -1;
                    }

                    // Then sort by name
                    let i;
                    for (i = 0; i < Math.min(a.name.length, b.name.length); i++) {
                        if (a.name.toUpperCase().charAt(i) !== b.name.toUpperCase().charAt(i)) {
                            return a.name.toUpperCase().charAt(i) < b.name.toUpperCase().charAt(i) ? 1 : -1;
                        }
                    }
                    return 0;
                }
            }
        ],
        pageSize: 50,
        schema: {
            data: 'data',
            total: 'total'
        }
    });

    const updateDomainData = function (group, domainName, blocked) {
        let data = find(atBlockingDataTracker.getDomainData(), function (d) {
            return d.domain === domainName;
        });
        if (!data) {
            data = {
                domain: domainName,
                groups: [],
                setDirty: setDomainDirty,
                isDirty: true,
                isBlocked: true,
                plusCount: 0,
                minusCount: 0
            };
            atBlockingDataTracker.getDomainData().push(data);
            updateDomainData(group, domainName, blocked);
        } else {
            let grp = find(data.groups, function (g) {
                return g.name === group.name;
            });
            atBlockingDataTracker.updateChanges(data, blocked, [group]);
            if (!grp) {
                grp = group;
                data.groups.push(grp);
            }
            grp.isDirty = atBlockingDataTracker.isDomainDirtyForGroup(domainName, group.name);
            grp.isBlocked = blocked;
            if (!blocked && !grp.isDirty) {
                remove(data.groups, function (g) {
                    return g.name === grp.name;
                });
            }
            data.setDirty();
            atBlockingDataTracker.setDomainCounts(data);
            data.groupCount = getBlockedCount(data.groups);
        }
    };

    const checkDirty = function () {
        let isDirty = false;
        $scope.totalPlusCount = 0;
        $scope.totalMinusCount = 0;
        atBlockingDataTracker.getGroupData().forEach(function (group) {
            $scope.totalPlusCount += group.plusCount;
            $scope.totalMinusCount += group.minusCount;
            isDirty = isDirty || group.isDirty;
        });
        atBlockingDataTracker.getDomainData().forEach(function (domain) {
            isDirty = isDirty || domain.isDirty;
        });
        return isDirty;
    };

    const setDomainDirty = function () {
        let isDirty = false;
        this.groups.forEach(function (group) {
            if (group.isDirty) {
                isDirty = true;
            }
        });
        this.isDirty = isDirty;
    };

    const domainDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                const ret = [];
                if (options.data.useCache) {
                    ret.data = atBlockingDataTracker.getDomainData();
                    ret.total = ret.data.length;
                    options.success(ret);
                } else {
                    if (!blockingDataCache) {
                        let promise;
                        if (blockingDataPromise) {
                            promise = blockingDataPromise;
                        } else {
                            promise = generateBlockingData();
                            blockingDataPromise = promise;
                        }
                        promise.then(function () {
                            ret.data = atBlockingDataTracker.getDomainData();
                            ret.total = ret.data.length;
                            options.success(ret);
                        });
                    } else {
                        ret.data = atBlockingDataTracker.getDomainData();
                        ret.total = ret.data.length;
                        options.success(ret);
                    }
                }
            }
        },
        sort: [
            {
                field: 'name',
                dir: 'desc',
                compare: function (a, b) {
                    // Sort by if dirty
                    if (a.isDirty && !b.isDirty) {
                        return 1;
                    } else if (!a.isDirty && b.isDirty) {
                        return -1;
                    }

                    // Then sort by group count
                    if (a.groups.length > 0 && b.groups.length === 0) {
                        return 1;
                    } else if (b.groups.length > 0 && a.groups.length === 0) {
                        return -1;
                    }

                    // Then sort by domain
                    let i;
                    for (i = 0; i < Math.min(a.domain.length, b.domain.length); i++) {
                        if (a.domain.toUpperCase().charAt(i) !== b.domain.toUpperCase().charAt(i)) {
                            return a.domain.toUpperCase().charAt(i) < b.domain.toUpperCase().charAt(i) ? 1 : -1;
                        }
                    }
                    return 0;
                }
            }
        ],
        pageSize: 50,
        schema: {
            data: 'data',
            total: 'total'
        }
    });

    const childDomainDataSource = new $window.kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                const ret = [];
                ret.data = childDomains;
                ret.total = childDomains.length;
                options.success(ret);
            }
        },
        sort: [
            {
                field: 'name',
                dir: 'desc',
                compare: function (a, b) {
                    // Sort by if dirty
                    if (a.isDirty && !b.isDirty) {
                        return 1;
                    } else if (!a.isDirty && b.isDirty) {
                        return -1;
                    }

                    // Then sort by domain
                    let i;
                    for (i = 0; i < Math.min(a.domain.length, b.domain.length); i++) {
                        if (a.domain.toUpperCase().charAt(i) !== b.domain.toUpperCase().charAt(i)) {
                            return a.domain.toUpperCase().charAt(i) < b.domain.toUpperCase().charAt(i) ? 1 : -1;
                        }
                    }
                    return 0;
                }
            }
        ],
        pageSize: 150,
        schema: {
            data: 'data',
            total: 'total'
        }
    });

    const childGroupDataSource = new $window.kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                const ret = [];
                ret.data = childGroups;
                ret.total = childGroups.length;
                options.success(ret);
            }
        },
        sort: [
            {
                field: 'name',
                dir: 'desc',
                compare: function (a, b) {
                    // Sort by if dirty
                    if (a.isDirty && !b.isDirty) {
                        return 1;
                    } else if (!a.isDirty && b.isDirty) {
                        return -1;
                    }

                    // Then sort by name
                    let i;
                    for (i = 0; i < Math.min(a.name.length, b.name.length); i++) {
                        if (a.name.toUpperCase().charAt(i) !== b.name.toUpperCase().charAt(i)) {
                            return a.name.toUpperCase().charAt(i) < b.name.toUpperCase().charAt(i) ? 1 : -1;
                        }
                    }
                    return 0;
                }
            }
        ],
        pageSize: 150,
        schema: {
            data: 'data',
            total: 'total'
        }
    });

    // Parent Group Grid Options
    $scope.parentGroupGridOptions = {
        dataSource: groupDataSource,
        columns: [
            {
                field: 'name',
                title: ' ',
                template: parentGroupTemplate,
                filterable: {
                    cell: {
                        operator: 'contains',
                        showOperators: false,
                        template: function (args) {
                            $(args.element)
                                .addClass('k-textbox')
                                .attr('style', 'width:100%')
                                .attr('placeholder', 'Filter Groups…')
                                .attr('ng-change', "updateFilter(this, 'parentGroup')")
                                .attr('ng-model', 'parentGroupFilter');
                        }
                    }
                },
                attributes: {
                    class: 'at-parent-group',
                    'ng-class':
                        "{'at-dirty': dataItem.isDirty, 'at-minus': dataItem.minusCount != 0, 'at-plus': dataItem.plusCount != 0}"
                }
            }
        ],
        filterable: {
            mode: 'row'
        },
        selectable: 'row',
        sortable: true,
        scrollable: {
            virtual: true
        },
        height: atHelperFunctions.getGridHeight(),
        dataBound: function (e) {
            const grid = e.sender;
            const data = grid._data;
            data.forEach(function (row, key) {
                if (!(!!$scope.selectedGroup && !!$scope.selectedGroup.name) && key === 0) {
                    $scope.selectedGroup = row;
                    grid.select('tr[data-uid="' + row.uid + '"]');
                } else if ($scope.selectedGroup.name === row.name) {
                    grid.select('tr[data-uid="' + row.uid + '"]');
                }
            });
            resizeGrid($scope.pageMode === 'groups' ? $scope.parentGroupGrid : $scope.parentDomainGrid, true);
        }
    };

    // Parent Domain Grid Options
    $scope.parentDomainGridOptions = {
        dataSource: domainDataSource,
        columns: [
            {
                field: 'domain',
                title: ' ',
                template: parentDomainTemplate,
                filterable: {
                    cell: {
                        operator: 'contains',
                        showOperators: false,
                        template: function (args) {
                            $(args.element)
                                .addClass('k-textbox')
                                .attr('style', 'width:100%')
                                .attr('placeholder', 'Filter Domain…')
                                .attr('ng-change', "updateFilter(this, 'parentDomain')")
                                .attr('ng-model', 'parentDomainFilter');
                        }
                    }
                },
                attributes: {
                    class: 'at-parent-domain',
                    'ng-class':
                        "{'at-dirty': dataItem.isDirty, 'at-minus': dataItem.minusCount != 0, 'at-plus': dataItem.plusCount != 0}"
                }
            }
        ],
        filterable: {
            mode: 'row'
        },
        selectable: 'row',
        sortable: true,
        scrollable: {
            virtual: true
        },
        height: atHelperFunctions.getGridHeight(),
        dataBound: function (e) {
            const grid = e.sender;
            const data = grid._data;

            data.forEach(function (row, key) {
                if (!(!!$scope.selectedDomain && !!$scope.selectedDomain.domain) && key === 0) {
                    $scope.selectedDomain = row;
                    grid.select('tr[data-uid="' + row.uid + '"]');
                } else if ($scope.selectedDomain.domain === row.domain) {
                    grid.select('tr[data-uid="' + row.uid + '"]');
                }
            });
        }
    };

    // Child Domain Grid Options
    $scope.childDomainGridOptions = {
        dataSource: childDomainDataSource,
        columns: [
            {
                field: 'domain',
                title: msg.get('domain'),
                filterable: {
                    cell: {
                        operator: 'contains',
                        showOperators: false,
                        template: function (args) {
                            $(args.element)
                                .addClass('k-textbox')
                                .attr('style', 'width:100%')
                                .attr('placeholder', 'Filter Domains…')
                                .attr('ng-change', "updateFilter(this, 'childDomain')")
                                .attr('ng-model', 'childDomainFilter');
                        }
                    }
                },
                template: childDomainTemplate,
                attributes: {
                    class: 'at-child-domain',
                    'ng-class':
                        "{'at-dirty': dataItem.isDirty, 'at-minus': !dataItem.isBlocked, 'at-plus': dataItem.isBlocked}"
                }
            }
        ],
        filterable: {
            mode: 'row'
        },
        sortable: true,
        scrollable: {
            virtual: true
        },
        height: atHelperFunctions.getGridHeight()
    };

    // Child Group Grid Options
    $scope.childGroupGridOptions = {
        dataSource: childGroupDataSource,
        columns: [
            {
                field: 'name',
                title: 'Groups',
                filterable: {
                    cell: {
                        operator: 'contains',
                        showOperators: false,
                        template: function (args) {
                            $(args.element)
                                .addClass('k-textbox')
                                .attr('style', 'width:100%')
                                .attr('placeholder', 'Filter Groups…')
                                .attr('ng-change', "updateFilter(this, 'childGroup')")
                                .attr('ng-model', 'childGroupFilter');
                        }
                    }
                },
                template: childGroupTemplate,
                attributes: {
                    class: 'at-child-group',
                    'ng-class':
                        "{'at-dirty': dataItem.isDirty, 'at-minus': !dataItem.isBlocked, 'at-plus': dataItem.isBlocked}"
                }
            }
        ],
        filterable: {
            mode: 'row'
        },
        sortable: true,
        scrollable: {
            virtual: true
        },
        height: atHelperFunctions.getGridHeight()
    };

    // Check if page is dirty before state change
    $scope.$on('$stateChangeStart', function (event, target, data) {
        // If scope is dirty and user did not log out
        // then ask for navigation confirmation.
        if ($scope.isDirty && loginService.isLoggedIn()) {
            event.preventDefault();

            const modal = customUibModal.open({
                animation: false,
                template: messageModalTemplate,
                controller: 'messageModalController',
                windowClass: 'centered-modal',
                resolve: {
                    messageData: {
                        messageTitle: msg.get('unsavedChanges'),
                        messageBody: msg.get('leaveAction'),
                        confirmLabel: msg.get('discardChanges'),
                        secondActionLabel: msg.get('saveChanges')
                    }
                }
            });

            modal.result.then(function (saveChanges) {
                if (saveChanges) {
                    $scope.applyChanges(function (success) {
                        if (success) {
                            $timeout(function () {
                                $state.go(target.name, data);
                            }, 1000);
                        }
                    });
                } else {
                    $scope.isDirty = false;
                    $state.go(target.name, data);
                }
            });
        }
    });

    // Switch between modes
    $scope.changeMode = function () {
        if ($scope.pageMode !== previousMode) {
            previousMode = $scope.pageMode;
            if (username) {
                localStorageService.set('BlockingPageMode-' + username, $scope.pageMode);
            }
            if ($scope.pageMode === 'groups') {
                $scope.parentHeader = msg.get('groups');
                $scope.childGridInfo = msg.get('selectGroup');
                if (!!$scope.selectedGroup && !!$scope.selectedGroup.name) {
                    groupDataSource.read({
                        useCache: true
                    });
                }
            } else {
                $scope.parentHeader = msg.get('blockedDomains');
                $scope.childGridInfo = msg.get('selectDomain');
                if (!!$scope.selectedDomain && !!$scope.selectedDomain.domain) {
                    domainDataSource.read({
                        useCache: true
                    });
                }
            }
            updateChildHeader();
        }
    };

    $scope.updateFilter = function (e, type) {
        if (type === 'parentGroup') {
            groupDataSource.filter({
                field: 'name',
                operator: 'contains',
                value: e.parentGroupFilter
            });
        } else if (type === 'parentDomain') {
            domainDataSource.filter({
                field: 'domain',
                operator: 'contains',
                value: e.parentDomainFilter
            });
        } else if (type === 'childGroup') {
            childGroupDataSource.filter({
                field: 'name',
                operator: 'contains',
                value: e.childGroupFilter
            });
        } else if (type === 'childDomain') {
            childDomainDataSource.filter({
                field: 'domain',
                operator: 'contains',
                value: e.childDomainFilter
            });
        }
    };

    // Row selected in parent grid
    $scope.parentRowClicked = function (row) {
        if (row !== undefined) {
            if ($scope.pageMode === 'groups') {
                $scope.groupsHaveSelection = true;
            } else if ($scope.pageMode === 'domains') {
                $scope.domainsHaveSelection = row.originalCount > 0 || row.isDirty;
            }
            if (row.name) {
                $scope.selectedGroup = row;
                $scope.selectedGroup.hasAllComp = true;
                childDomains = row.blockedDomains;
                childDomainDataSource.read();
            } else if (row.domain) {
                $scope.selectedDomain = row;
                childGroups = atBlockingDataTracker.getDomainGroups($scope.selectedDomain);
                childGroupDataSource.read();
            }
            updateChildHeader();
        }
    };

    const updateChildHeader = function () {
        let substring = '';
        if ($scope.pageMode === 'groups') {
            if (!!$scope.selectedGroup && !!$scope.selectedGroup.name) {
                substring =
                    $window.innerWidth >= 1600
                        ? ' (' + templateServiceFunctions.minimizeString(25, $scope.selectedGroup.name) + ')'
                        : '';
            }
            $scope.childHeader = msg.get('blockedDomains') + substring;
        } else if ($scope.pageMode === 'domains') {
            if (!!$scope.selectedDomain && !!$scope.selectedDomain.domain) {
                substring =
                    $window.innerWidth >= 1600
                        ? ' (' + templateServiceFunctions.minimizeString(25, $scope.selectedDomain.domain) + ')'
                        : '';
            }
            $scope.childHeader = msg.get('domainChildHeader') + substring;
        }
    };

    $scope.changeMode();

    const removeDomainGroup = function (group, domain) {
        updateData(group, domain.domain, false);
    };

    const refreshDataSource = function () {
        $scope.isDirty = false;
        atBlockingDataTracker.changedDomains = [];
        blockingDataCache = null;
        blockingDataPromise = null;
        $scope.totalPlusCount = 0;
        $scope.totalMinusCount = 0;
        $scope.selectedDomain = [];
        $scope.selectedGroup = [];
        childGroups = [];
        childDomains = [];
        $scope.parentGroupGrid.dataSource.read();
        $scope.childDomainGrid.dataSource.read();
        $scope.parentDomainGrid.dataSource.read();
        $scope.childGroupGrid.dataSource.read();
    };

    $scope.cancelChanges = function () {
        const modal = customUibModal.open({
            animation: false,
            template: messageModalTemplate,
            controller: 'messageModalController',
            windowClass: 'centered-modal',
            resolve: {
                messageData: {
                    messageTitle: msg.get('cancelConfirm'),
                    messageBody: msg.get('changesWillLost'),
                    confirmLabel: msg.get('yes'),
                    cancelLabel: msg.get('no')
                }
            }
        });

        modal.result.then(function () {
            refreshDataSource();
        });
    };

    $scope.applyChanges = function (callback) {
        $scope.isDirty = false;
        const payload = atBlockingDataTracker.createPayload();

        blockingService
            .updateDomains(payload)
            .success(function () {
                refreshDataSource();
                $scope.$emit('showNotification', {
                    message: msg.get('changesSaved'),
                    color: 'success'
                });
                if (typeof callback === 'function') {
                    callback(true);
                }
            })
            .error(function () {
                $scope.$emit('showNotification', {
                    message: msg.get('changesApplyingError'),
                    color: 'danger'
                });
                $scope.isDirty = true;
                if (typeof callback === 'function') {
                    callback(false);
                }
            });
    };

    // Resize grids based on window height
    const resizeGrid = function (grid, resetColumnWidth) {
        if (resetColumnWidth) {
            $scope.columnWidth = atHelperFunctions.getColumnWidth(grid);
        }
        const gridElement = angular.element(grid.element);
        const dataArea = gridElement.find('.k-grid-content');
        const newHeight = atHelperFunctions.getGridHeight();
        const diff = gridElement.innerHeight() - dataArea.innerHeight();
        gridElement.height(newHeight);
        dataArea.height(newHeight - diff);
    };

    // Bind window resizing
    angular.element($window).bind('resize', function () {
        resizeGrid($scope.parentGroupGrid, $scope.pageMode === 'groups');
        resizeGrid($scope.childDomainGrid, false);
        resizeGrid($scope.parentDomainGrid, $scope.pageMode === 'domains');
        resizeGrid($scope.childGroupGrid, false);
        updateChildHeader();
    });
}

angular.module('app').service('blockingService', BlockingService);

BlockingService.$inject = ['$http', 'envConfig'];

function BlockingService($http, envConfig) {
    this.getDomains = function (data) {
        return $http.get(envConfig.apiUrl() + '/api/blocking/domains-light?', data);
    };

    this.getBlockingData = function () {
        return $http.get(envConfig.apiUrl() + '/api/blocking/all');
    };

    this.deleteDomain = function (domain) {
        return $http.post(envConfig.apiUrl() + '/api/settings/domain/delete', {
            name: domain
        });
    };

    this.updateDomains = function (payload) {
        return $http.put(envConfig.apiUrl() + '/api/blocking/domains', payload);
    };

    this.saveDomain = function (data) {
        return $http.post(envConfig.apiUrl() + '/api/settings/domain', data);
    };
}
