import React, { Fragment, useState } from 'react';
import { useAuthorization } from '../../common/services/Authorization';
import { BundleFlag } from '../../common/enums/BundleFlag';
import { FormControlLabelSC, dntStyles as classes } from '../styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { getAccountSettings } from '../../common/helpers/accountSettings/accountSettingsStore';

type ComponentProps = {
  open: boolean;
  onClose: () => void;
  onDoNotTrack?: () => void;
  selectedUsers: any[];
};

export const DoNotTrackUsersConfirmationModal = (props: ComponentProps) => {
  const { open, onClose, onDoNotTrack = () => {}, selectedUsers } = props;
  const [checked, setChecked] = useState(false);

  const authorization = useAuthorization();
  const softDeletesEnabled = authorization.hasFeature(BundleFlag.SoftDeletes);
  const { delayedDelete } = getAccountSettings();

  const close = () => {
    onClose();
    setChecked(false);
  };

  const handleChecked = () => {
    setChecked(!checked);
  };

  const handleDoNotTrack = async (): Promise<void> => {
    onDoNotTrack();
    close();
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle id="do-not-track-users-dialog-title">
        Add to &quot;Do Not Track&quot; List
      </DialogTitle>
      <DialogContent id="do-not-track-users-dialog-content">
        <label htmlFor={'do-not-track-users-dialog-content'}>
          Do you want to add the following user
          {selectedUsers.length > 1 ? 's' : ''} to the Do Not Track List?
        </label>
        <div style={classes.doNotTrackWarning}>
          {selectedUsers.map((user, index) => (
            <Fragment key={`untracked-users-${index}`}>
              {selectedUsers.length > 1 && index > 0 ? ', ' : ''}
              {selectedUsers.length > 1 && index == selectedUsers.length - 1
                ? ' and '
                : ''}
              <strong>{user.user}</strong>
            </Fragment>
          ))}
          &nbsp;will be added to the Do Not Track list. All data associated with{' '}
          {selectedUsers.length > 1 ? 'these users' : 'this user'} will be
          permanently deleted. This action cannot be reversed.
        </div>
        <div>
          <FormControlLabelSC
            control={
              <Checkbox
                color="primary"
                checked={checked}
                onChange={handleChecked}
              />
            }
            label="I acknowledge that this action will delete all data associated with the selected user"
          />
          <label htmlFor={'do-not-track-users-dialog-content'}>
            {(softDeletesEnabled || delayedDelete) && (
              <span>
                *Please allow up to 12 hours for the deletion to complete.&nbsp;
                <strong>
                  Computer data will be reflected in other reports until this
                  action is complete.{' '}
                </strong>
              </span>
            )}
          </label>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} autoFocus>
          Cancel
        </Button>
        <Button
          onClick={handleDoNotTrack}
          color="primary"
          variant="contained"
          disabled={!checked}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
