import { FeatureFlag } from '../../../../../_reactivtrak/src/common/enums/FeatureFlag';
import { alarmObjectVerifierFunctions } from '../../../../../_app/serviceFunctions/alarmObjectVerifierFunctions';
import viewTemplate from 'views/alarmDetails/components/alarmDetailsScreenCaptures.html?raw';
import { getAccountSettings } from '../../../../../_reactivtrak/src/common/helpers/accountSettings/accountSettingsStore';

angular.module('app').directive('alarmDetailsScreenCaptures', AlarmDetailsScreenCaptures);

function AlarmDetailsScreenCaptures() {
    return {
        restrict: 'A',
        scope: {
            alarm: '=',
            functions: '=',
            options: '='
        },
        template: viewTemplate,
        controller: AlarmDetailsScreenCapturesCtrl
    };
}

AlarmDetailsScreenCapturesCtrl.$inject = ['$scope', 'messagesService', 'videosService', 'authorizationService'];

function AlarmDetailsScreenCapturesCtrl($scope, msg, videosService, authorizationService) {
    $scope.videoAlarmsEnabled = authorizationService.hasFeature(FeatureFlag.VideoPlayback);
    $scope.paidPlanAlertMessage = msg.get('multipleSSPaidPlanAvailableOnly');

    videosService.getVideoSettings().then(function (response) {
        $scope.prerecordVideo = response.data.preRecord;
    });

    function getActiveVideoRestrictions() {
        var conditions = $scope.alarm.conditions;
        var limitExceeded = false; // Force false for now.  Restriction handled by backend.
        var validMultipleConditions = false;

        if (conditions) {
            validMultipleConditions =
                $scope.options.getRestrictedOrFlagFields(conditions).length < conditions.length &&
                conditions.length > 1;
        }

        return {
            screenshot: !!$scope.alarm.screenshot,
            conditions: !validMultipleConditions && !$scope.alarm.isUsb,
            limitExceeded: limitExceeded,
            orFlag: $scope.alarm.orflag && !$scope.alarm.isUsb
        };
    }

    $scope.disableVideos = function () {
        var restrictions = getActiveVideoRestrictions();
        var isDisabled =
            restrictions.screenshot || restrictions.conditions || restrictions.limitExceeded || restrictions.orFlag;

        if (isDisabled && $scope.alarm.video) {
            $scope.alarm.video = false;
            $scope.$emit('showNotification', {
                message: msg.get('videoAlarmRestrictionsNotMet'),
                color: 'warning',
                timeout: 0
            });
        }

        return isDisabled || $scope.alarm.editor.isEditingCondition;
    };

    function setDurationGreaterThanPrerecord() {
        var conditions = $scope.alarm.conditions;
        $scope.durationGreaterThanPrerecord = null;
        if (conditions && $scope.alarm.video) {
            conditions.forEach(function (condition) {
                if (condition.field === 'DurationSeconds') {
                    var duration = parseInt(condition.value);
                    var prerecordTime = $scope.prerecordVideo ? 15 : 0;
                    $scope.durationGreaterThanPrerecord = !isNaN(duration) && duration >= prerecordTime;
                }
            });
        }
    }

    function updateMessages() {
        var input = angular.element('input[name="screenshotsec"]');
        var value = Number(input.val());

        if (isNaN(value)) {
            $scope.screenshotsecError = true;
            $scope.screenshotsecErrorMessage = msg.get('onlyDigits');
        } else if (value < 10) {
            $scope.screenshotsecError = true;
            $scope.screenshotsecErrorMessage = msg.get('mustBeAtLeast', 10);
        } else if (value > 2000000000) {
            $scope.screenshotsecError = true;
            $scope.screenshotsecErrorMessage = msg.get('valueIsTooLarge');
        } else {
            $scope.screenshotsecErrorMessage = '';
            $scope.screenshotsecError = false;
        }

        var { videoAlarmsLimit, videoAlarmsActive } = getAccountSettings();

        $scope.videoTooltipTemplate = msg.get(
            'alarmVideoSettingsTooltip',
            getActiveVideoRestrictions(),
            videoAlarmsLimit,
            videoAlarmsActive
        );
        $scope.screenshotTooltipTemplate =
            !$scope.alarm.screenshot && $scope.alarm.video ? msg.get('alarmScreenshotSettingsTooltip') : null;
    }

    $scope.hotsecChanged = function (e) {
        if (!e.originalEvent.key.match(/Control|Shift|Alt|Enter|Backspace|Delete|Home|End|Arrow|\d/)) {
            e.preventDefault();
        }
    };

    function isComponentValid() {
        // console.log('Screen Captures Validation');
        return {
            invalidReasons: alarmObjectVerifierFunctions.verifyAlarmModule($scope.alarm, $scope.component.type)
        };
    }

    function updatePayload(payload) {
        // console.log('Screen Captures Update Payload', payload);
        if (!payload.screenshotsec) {
            payload.screenshotsec = 10;
        }
        return true;
    }

    function alarmUpdated() {
        // console.log('Screen Captures Alarm Updated');
        if ($scope.alarm.screenshot && $scope.alarm.screenshotmultiple === undefined) {
            $scope.alarm.screenshotmultiple = false;
        }

        updateMessages();
        setDurationGreaterThanPrerecord();
    }

    $scope.component = {
        type: alarmObjectVerifierFunctions.moduleTypes.screenCaptures,
        isValid: isComponentValid,
        updatePayload: updatePayload,
        alarmUpdated: alarmUpdated
    };

    $scope.options.register($scope.component);

    if (!$scope.alarm.screenshotsec) {
        $scope.alarm.screenshotsec = 10;
    }
    updateMessages();
    setDurationGreaterThanPrerecord();
}
