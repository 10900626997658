import { useCallback, useState } from 'react';
import { fetchData } from '../../common/helpers';
import { useNotifications } from '../../common/services/Notifications';
import { IInsightsCategory } from '../models';
import { ICategoriesState } from '../models/ICategoriesState';
import { CATS_APPS_SITES } from '../utils/TimeOnTask.view.utils';

export const useCategoriesState = (): ICategoriesState => {
  const notificationService = useNotifications();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<IInsightsCategory[]>([]);

  const fetchCategories = useCallback(async (groupId: string) => {
    setIsLoading(true);
    try {
      const cachedData = sessionStorage.getItem(
        `${CATS_APPS_SITES}_${groupId}`
      );
      if (cachedData) {
        setCategories(JSON.parse(cachedData));
      } else {
        const data = await fetchData<IInsightsCategory[]>({
          path: `/api/insights/categories/${groupId}?includeApps=true&includeSites=true`
        });
        setCategories(data);
        sessionStorage.setItem(
          `${CATS_APPS_SITES}_${groupId}`,
          JSON.stringify(data)
        );
      }
    } catch (error) {
      notificationService.error('Error fetching aa categories');
      console.error(`Error fetching aa categories:`, error);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = useCallback(
    (groupId: string) => {
      fetchCategories(groupId);
    },
    [fetchCategories]
  );

  return <ICategoriesState>{
    init,
    isLoading,
    categories,
    setCategories
  };
};
