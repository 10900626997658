import { createStore } from 'zustand';
import { IScreenshotRedactionSettingsStore } from '../models/IScreenshotRedactionSettingsStore';
import { fetchSettings } from '../utils/screenshotSafeSearchSettingsStore.utils';

export const SCREENSHOT_REDACTION_SETTINGS_STORE_DEFAULT: IScreenshotRedactionSettingsStore =
  {
    isNearingLimit: false,
    isOverLimit: false,
    redactionQuotaLimit: 0,
    redactionQuotaRemaining: 0,
    redactionQuotaUsage: 0,
    redactionRenewDate: null,
    redactionQuotaUsageLabel: '',
    redactionQuotaLimitLabel: '',
    redactionRenewDateLabel: '',
    redactionQuotaRemainingLabel: ''
  };

export const screenshotRedactionSettingsStore =
  createStore<IScreenshotRedactionSettingsStore>(
    () => SCREENSHOT_REDACTION_SETTINGS_STORE_DEFAULT
  );

/**
 * Fetches the Screenshot Settings and updates the store.
 */
export const fetchScreenshotRedactionSettings =
  async (): Promise<IScreenshotRedactionSettingsStore> => {
    const response = await fetchSettings();
    const currentSettings = screenshotRedactionSettingsStore.getState();
    screenshotRedactionSettingsStore.setState({
      ...currentSettings,
      ...response
    });
    return screenshotRedactionSettingsStore.getState();
  };

/**
 * Returns the current export store state.
 */
export const getScreenshotRedactionSettingsStore =
  (): IScreenshotRedactionSettingsStore =>
    screenshotRedactionSettingsStore.getState();

/**
 * Resets the export store to its default state.
 */
export const resetScreenshotRedactionSettingsStore = (): void =>
  screenshotRedactionSettingsStore.setState(
    SCREENSHOT_REDACTION_SETTINGS_STORE_DEFAULT
  );
