import { IActivTrakRequestConfig } from '../models';
import { getApiDomainStore } from "../stores/apiDomainStore";

// TODO: Remove this file - replace with getApiDomainStore
export const getDefaultConfig = () => {
  const defaultConfig: IActivTrakRequestConfig = {
    authorizationRequired: true,
    baseURL: getApiDomainStore().frontendApi
  };
  return defaultConfig;
};

export const getWebApiConfig = () => {
  const webApiConfig: IActivTrakRequestConfig = {
    authorizationRequired: true,
    baseURL: getApiDomainStore().adminApi
  };
  return webApiConfig;
};

export const getAccountApiConfig = () => {
  const webApiConfig: IActivTrakRequestConfig = {
    authorizationRequired: true,
    baseURL: getApiDomainStore().accountsApi
  };
  return webApiConfig;
};

export const getReportingServiceUrl = () => {
  const reportingApiConfig: IActivTrakRequestConfig = {
    authorizationRequired: true,
    baseURL: getApiDomainStore().reportingService
  };
  return reportingApiConfig;
};

export const getWebsocketHttpsUrl = () => {
  const websocketConfig: IActivTrakRequestConfig = {
    authorizationRequired: true,
    baseURL: getApiDomainStore().websocketHttps
  };
  return websocketConfig;
};

export const getIdentityUrl = () => {
  const identityConfig: IActivTrakRequestConfig = {
    authorizationRequired: true,
    baseURL: getApiDomainStore().identityService
  };
  return identityConfig;
};

type ApiConfigsProps = {
  default: () => IActivTrakRequestConfig;
  reporting: () => IActivTrakRequestConfig;
  admin: () => IActivTrakRequestConfig;
  account: () => IActivTrakRequestConfig;
  identity: () => IActivTrakRequestConfig;
  websocket: () => IActivTrakRequestConfig;
};

export const apiConfigs: ApiConfigsProps = {
  default: getDefaultConfig,
  reporting: getReportingServiceUrl,
  admin: getWebApiConfig,
  account: getAccountApiConfig,
  identity: getIdentityUrl,
  websocket: getWebsocketHttpsUrl
};
