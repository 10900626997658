import { IRoute } from '../../../../models/IRoute';
import { LoadApplication } from '../../../../services/Application/AppLoad';
import { appTemplate as angularJsTemplate } from '../templates';
import { loginServiceFunctions } from '../../../../../../../_app/serviceFunctions/loginServiceFunctions';
export const app: IRoute = {
  name: 'app',
  stateDefinition: {
    abstract: true,
    url: '/app',
    template: angularJsTemplate,
    controller: 'MainCtrl',
    resolve: {
      loadApplication: [
        async () => {
          try {
            await LoadApplication();
          } catch (error) {
            const { logout } = loginServiceFunctions;
            if (error?.status === 401) {
              logout();

              throw error;
            }

            console.error('ActivTrak Error: Error loading application', error);
            const errorCode = error?.data?.code ?? error.message;
            if (errorCode?.includes('IN_MAINTENANCE_MODE')) {
              logout({
                maintenanceModeReason:
                  error?.data?.message ??
                  'Your ActivTrak account is undergoing maintenance. Please try again later.'
              });
            } else {
              logout({
                apiFailure: true
              });
            }

            throw error;
          }
        }
      ]
    }
  }
};

export default app;
