import moment from 'moment';
import { localStorageItems } from '../constants';
import {
  DashboardListNg,
  DashboardType,
  Role,
  dashboardOptions
} from '../enums';
import { getParsedLocalStorageItem, setLocalStorageItem } from './localStorage';
import authorizationService from './authorization';
import { IDashboardSettings } from '../models';
import { getDashboardAccessHeapType } from '../../dashboard/utils';
import { BundleFlag } from '../enums/BundleFlag';
import { profileStore } from '../services/Profile/useProfileState';
import { getAccountSettings } from './accountSettings/accountSettingsStore';

export const getDashboardSettings = (): IDashboardSettings => {
  const { username } = profileStore.getState().profile;
  let settings = {};
  if (username) {
    settings = getParsedLocalStorageItem(
      localStorageItems.dashboardSettingsStorage + username
    );
  }
  return settings;
};

export const setDashboardSettings = (value: IDashboardSettings): void => {
  const { username } = profileStore.getState().profile;

  if (username && value) {
    const localStorage_dashboard: IDashboardSettings =
      getParsedLocalStorageItem(
        localStorageItems.dashboardSettingsStorage + username
      ) || {};
    //merge current and new object values
    const settings = {
      ...localStorage_dashboard,
      ...value
    };
    // Convert object into JSON string
    const stringifiedSettings = JSON.stringify(settings);
    // Stringify again to match previous behavior
    // TODO: Remove double stringify once all UI components use the same library
    const doubleStringifiedSettings = JSON.stringify(stringifiedSettings);
    setLocalStorageItem(
      localStorageItems.dashboardSettingsStorage + username,
      doubleStringifiedSettings
    );
  }
};

//if user does not have route access to a dashboard, that is already taken care of by route logic
export const getDashboardRedirect = () => {
  const unrestrictedLanding = 'app.account.profile';
  const dashboardSettings = getDashboardSettings();

  /*get the current dashboard setting*/
  let dashboard =
    dashboardSettings?.dashboardSelection || DashboardType.default;

  const ngDashboardRoute =
    dashboard === DashboardType.default
      ? 'app.dashboard'
      : DashboardListNg.find((item) => item.includes(dashboard));

  const isRouteAuthorized =
    authorizationService.hasRouteByName(ngDashboardRoute);

  //check if current value of 'dashboardSelection' exists as a menu option
  const doesMenuItemExist = dashboardOptions.find(
    (item) => item.id === dashboard
  );
  if (!doesMenuItemExist || !isRouteAuthorized) {
    // if dashboard is default but not authorized need to find next available route
    if (dashboard === DashboardType.default) {
      const availableDashboard = DashboardListNg.find((dashboardRoute) =>
        authorizationService.hasRouteByName(dashboardRoute)
      );

      dashboard = availableDashboard ?? unrestrictedLanding;
    } else {
      // Do not change to default if user does not have access
      dashboard = authorizationService.hasRouteByName('app.dashboard')
        ? DashboardType.default
        : dashboard ?? unrestrictedLanding;
    }
  }

  /*FIRST-TIME ONLY: check if 'isTeamManagementEnabled' has not been stored yet*/
  if (
    authorizationService.hasFeature(BundleFlag.TeamManagementHomepage) &&
    authorizationService.hasAnyRole([Role.Admin, Role.Configurator]) &&
    !dashboardSettings?.isTeamManagementEnabled
  ) {
    dashboardSettings.isTeamManagementEnabled = true;

    //have to set this now because next lines of Heap depends on the new value
    dashboardSettings.dashboardSelection = dashboard;
    setDashboardSettings(dashboardSettings);

    window.heap?.addUserProperties({
      adminDashboardTestVariant: getDashboardAccessHeapType(
        dashboard as DashboardType
      )
    });
  }

  /*FIRST-TIME ONLY: check if 'isOrganizationOverviewEnabled' has not been stored yet*/
  if (
    authorizationService.hasFeature(BundleFlag.OrganizationOverviewHomepage) &&
    authorizationService.hasAnyRole([Role.Admin, Role.Configurator]) &&
    !dashboardSettings?.isOrganizationOverviewEnabled
  ) {
    dashboardSettings.isOrganizationOverviewEnabled = true;

    //have to set this now because next lines of Heap depends on the new value
    dashboardSettings.dashboardSelection = dashboard;
    setDashboardSettings(dashboardSettings);

    window.heap?.addUserProperties({
      adminDashboardTestVariant: getDashboardAccessHeapType(
        dashboard as DashboardType
      )
    });
  }

  /*FIRST-TIME ONLY: check if 'isAdminPanelEnabled' has not been stored yet*/
  if (
    authorizationService.hasFeature(BundleFlag.AdminPanelHomepage) &&
    authorizationService.hasAnyRole([Role.Admin]) &&
    !dashboardSettings?.isAdminPanelEnabled
  ) {
    dashboardSettings.isAdminPanelEnabled = true;
    const { unixCreationTime } = getAccountSettings();
    const accountCreationDate = moment.unix(unixCreationTime);
    const adminDashboardDefaultEffectiveAfter = moment.utc('2023-03-23');

    /*if the account was created after 'adminDashboardDefaultEffectiveAfter', force them to the Admin Panel*/
    if (
      accountCreationDate?.diff(adminDashboardDefaultEffectiveAfter, 'days') >=
      0
    ) {
      dashboard = DashboardType.admin;
    }

    //have to set this now because next lines of Heap depends on the new value
    dashboardSettings.dashboardSelection = dashboard;
    setDashboardSettings(dashboardSettings);

    window.heap?.addUserProperties({
      adminDashboardTestVariant: getDashboardAccessHeapType(
        dashboard as DashboardType
      )
    });
  }

  setDashboardSettings({
    dashboardSelection: dashboard
  });

  return dashboard;
};

//this is to make es5 Angular project happy
export default {
  getDashboardRedirect,
  getDashboardSettings,
  setDashboardSettings
};
