import { IInsightsSettingsStore } from '../models/IInsightsSettingsStore';

export const INSIGHTS_SETTINGS_STORE_CACHE_LIFETIME = 300000; // 5 minutes

export const INSIGHTS_SETTINGS_STORE_DEFAULT: IInsightsSettingsStore = {
  insightsEnabled: false,
  insightsAvailable: false,
  onboardingInProgress: false,
  schedulingEnabled: false,
  onboardRequestTime: null,
  calendarIntegrated: false,
  cacheTimestamp: undefined
};
