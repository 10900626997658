import React from 'react';
import { UserComputerFilter } from '../../../common/components/ReportFilters/components/UserComputerFilter';
import { DateRangeFilter } from '../../../common/components/ReportFilters/components/DateRangeFilter';
import { RefreshButton } from '../../../common/components/ReportFilters/components/RefreshButton';
import { AcademyButton } from '../../../common/components/ReportFilters/components/AcademyButton';
import { ProductivityFilter } from '../../../common/components/ReportFilters/components/ProductivityFilter';
import { ExportButtonContainer } from '../../../common/components/ReportFilters/components/ExportButton';
import { TopWebsitesHeaderProps } from '../models/TopWebsitesTypes';
import { setReportFilters } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import { generateBody } from '../../../common/components/ReportFilters/utils/generateParameters';
import { AsyncExportButton } from '../../../common/components/ReportFilters/components/AsyncExportButton';
import { WEBSITE_DETAILS_EXPORT_OPTIONS } from '../../../common/components/ReportFilters/constants/exportMenuOptions';
import Box from '@mui/material/Box';
import {
  ReportHeaderInline,
  ReportHeaderLeftColumn,
  ReportHeaderRightColumn
} from '../../../common/styles/app.component.styles';
import authorization from '../../../common/helpers/authorization';
import { BundleFlag } from '../../../common/enums/BundleFlag';
import { ReportType } from '../../../common/enums/ReportType';
import { ExportSize } from '../../../common/enums/ExportSize';
import { ExportDetail } from '../../../common/enums/ExportType';
import { IExportReportParams } from '../../../common/models/IExport';
import { getPrivacySettings } from '../../../common/hooks/privacySettingsStore';
import { getExportParamsStore } from '../../../common/stores/exportParamsStore';
import { useExportParamsStore } from '../../../common/hooks/useExportParamsStore';

export const TopWebsitesReportHeader = (props: TopWebsitesHeaderProps) => {
  const { reportFilters, isRefreshing, onRefresh } = props;
  const { dates, users, productivity } = reportFilters;
  const { hasDataForTable } = useExportParamsStore((s) => s);

  const ignoreDateRestrictions: boolean = authorization.isSupportOrSuperAdmin();

  const hasAsyncExport: boolean = authorization.hasFeature(
    BundleFlag.TopWebsitesReportExport
  );

  const hasLargeExport: boolean = authorization.hasFeature(
    BundleFlag.LargeExport
  );

  const { activityAllowed } = getPrivacySettings();

  const filteredMenuOptions = WEBSITE_DETAILS_EXPORT_OPTIONS.filter(
    (option) => {
      return activityAllowed || !option.visible;
    }
  );

  const createExportPayload = (
    detailExportOption?: ExportDetail
  ): IExportReportParams => {
    const exportParams = getExportParamsStore();
    const { from, to, userType, userMode, userId, productivity } = generateBody(
      reportFilters,
      { showProductivity: true }
    );

    const reportPayload: IExportReportParams = {
      type: ReportType.TopWebsites,
      startDate: from,
      endDate: to,
      userType,
      userMode,
      userId,
      productivity: productivity.toLowerCase()
    };

    return {
      ...reportPayload,
      ...exportParams,
      detail: detailExportOption
    };
  };

  return (
    <Box>
      <ReportHeaderLeftColumn>
        <ReportHeaderInline>
          <DateRangeFilter
            dates={dates}
            onSubmitFilter={setReportFilters}
            ignoreRestrictions={ignoreDateRestrictions}
          />
        </ReportHeaderInline>
        <ReportHeaderInline>
          <UserComputerFilter onSubmitFilter={setReportFilters} users={users} />
        </ReportHeaderInline>
        <ReportHeaderInline>
          <ProductivityFilter
            productivity={productivity}
            onSubmitFilter={setReportFilters}
          />
        </ReportHeaderInline>
        <ReportHeaderInline>
          <RefreshButton onRefresh={onRefresh} isRefreshing={isRefreshing} />
        </ReportHeaderInline>
      </ReportHeaderLeftColumn>
      <ReportHeaderRightColumn>
        <ReportHeaderInline sx={{ mr: { xs: 0, md: 1 } }}>
          <AcademyButton />
        </ReportHeaderInline>
        <ReportHeaderInline
          sx={{ display: { xs: 'none', md: 'block' }, mr: 0 }}
        >
          {hasAsyncExport ? (
            <AsyncExportButton
              reportType={ReportType.TopWebsites}
              createExportPayload={createExportPayload}
              showExportsLimitedTooltip
              exportableItemCount={
                hasLargeExport ? ExportSize.LargeBatch : ExportSize.SmallBatch
              }
              menuOptions={filteredMenuOptions}
              isDisabled={!hasDataForTable || isRefreshing}
            />
          ) : (
            <ExportButtonContainer />
          )}
        </ReportHeaderInline>
      </ReportHeaderRightColumn>
    </Box>
  );
};
