import viewTemplate from 'views/widgets/atSupportOptions.html?raw';
import {
    getAccountSettings,
    setAccountSettings
} from '../../../../_reactivtrak/src/common/helpers/accountSettings/accountSettingsStore';
import { profileStore } from '../../../../_reactivtrak/src/common/services/Profile/useProfileState';
import { Role } from '../../../../_reactivtrak/src/common/enums';

angular.module('app').directive('atSupportOptions', atSupportOptions);

function atSupportOptions() {
    return {
        restrict: 'E',
        scope: {
            refresh: '=',
            sections: '='
        },
        template: viewTemplate,
        controller: atSupportOptionsCtrl
    };
}

atSupportOptionsCtrl.$inject = ['$scope', 'loginService', 'supportConsoleService', 'authorizationService'];

function atSupportOptionsCtrl($scope, loginService, supportConsoleService, authorizationService) {
    const { account, dataCollection } = getAccountSettings();
    const { username } = profileStore.getState().profile;
    $scope.accountId = account;
    $scope.username = username;
    $scope.deleteDataCheckbox = true;
    $scope.supportLevel = authorizationService.hasRole([Role.SupportAdvanced])
        ? 'Advanced'
        : authorizationService.hasRole([Role.SupportIntermediate])
        ? 'Intermediate'
        : 'Basic';

    $scope.hasRole = function (role) {
        return authorizationService.hasRole(role);
    };

    function updateSupportActions() {
        $scope.supportActions = [
            {
                buttonLabel: 'Verify Account',
                onClick: verifyAccount,
                clickParams: $scope.accountId,
                confirmText: function () {
                    return 'Do you want to verify this account, Acct' + $scope.accountId + '?';
                },
                supportRole: Role.SupportBasic
            },
            {
                buttonLabel: 'Unlock User',
                onClick: unlockUser,
                clickParams: $scope.username,
                confirmText: function () {
                    return 'Do you want to unlock this user, ' + $scope.username + '?';
                },
                supportRole: Role.SupportBasic
            },
            {
                buttonLabel: dataCollection ? 'Turn Off Data Collection' : 'Turn On Data Collection',
                onClick: setDataCollection,
                clickParams: $scope.accountId,
                confirmText: function () {
                    return (
                        'Do you want to ' +
                        (dataCollection ? 'turn off Data Collection' : 'turn on Data Collection') +
                        ' for this account, Acct, Acct' +
                        $scope.accountId +
                        '?'
                    );
                },
                supportRole: Role.SupportAdvanced
            }
        ];
    }
    updateSupportActions();

    $scope.disableDelete = function () {
        return !$scope.deleteText || $scope.deleteText.toLowerCase() !== $scope.accountId.toString().toLowerCase();
    };

    $scope.actionClicked = function (action) {
        $scope.confirmAction = action;
    };

    $scope.cancelAction = function () {
        updateSupportActions();
        $scope.confirmAction = null;
        $scope.actionResult = null;
    };

    function verifyAccount(accountId) {
        $scope.supportFunctionBusy = true;
        supportConsoleService
            .verifyAccount(accountId)
            .then(function () {
                $scope.supportFunctionBusy = false;
                setAccountSettings({ isVerified: true });
                $scope.actionResult = 'This account has been verified.';
                $scope.sections = $scope.refresh();
                updateSupportActions();

                // Log as warning for accountability
                console.warn('Account ' + accountId + ' has been verified.');
            })
            .catch(function (error) {
                $scope.supportFunctionBusy = false;
                $scope.actionResult = 'An error has occurred.  Check the console for more details.';
                if (error.status === 400) {
                    $scope.actionResult = 'An error has occurred. ' + error.data.error;
                }
            });
    }

    function unlockUser(username) {
        $scope.supportFunctionBusy = true;
        supportConsoleService
            .unlockUser(username)
            .then(function () {
                $scope.supportFunctionBusy = false;
                $scope.actionResult = 'The user, ' + username + ', has been unlocked.';
                updateSupportActions();

                // Log as warning for accountability
                console.warn($scope.actionResult);
                $scope.supportFunctionBusy = false;
            })
            .catch(function (error) {
                $scope.supportFunctionBusy = false;
                $scope.actionResult = 'An error has occurred.  Check the console for more details.';
                if (error.status === 400) {
                    $scope.actionResult = 'An error has occurred. ' + error.data.error;
                }
            });
    }

    function setDataCollection(accountId) {
        $scope.supportFunctionBusy = true;
        supportConsoleService
            .setDataCollection(accountId, !dataCollection)
            .then(function () {
                $scope.supportFunctionBusy = false;
                $scope.actionResult = dataCollection
                    ? 'The data collection has been turned off for this account.'
                    : 'The data collection has been turned on for this account.';
                setAccountSettings({ dataCollection: !dataCollection });
                $scope.sections = $scope.refresh();
                updateSupportActions();

                // Log as warning for accountability
                console.warn($scope.dataCollectionResult);
                $scope.supportFunctionBusy = false;
            })
            .catch(function (error) {
                $scope.supportFunctionBusy = false;
                $scope.actionResult = 'An error has occurred.  Check the console for more details.';
                if (error.status === 400) {
                    $scope.actionResult = 'An error has occurred. ' + error.data.error;
                }
            });
    }

    function callDeleteAccount(accountId) {
        $scope.deleteDoubleConfirm = false;
        supportConsoleService
            .deleteAccount(accountId)
            .then(function () {
                // Log as warning for accountability
                console.warn('Account Deleted');
                loginService.logout();
            })
            .catch(function (error) {
                $scope.deleteDoubleConfirm = false;
                $scope.deleteResult = 'An error has occurred.  Check the console for more details.';
                if (error.status === 400) {
                    $scope.deleteResult = 'An error has occurred. ' + error.data.error;
                }
            });
    }

    $scope.deleteAccount = function (accountId) {
        if ($scope.deleteText !== accountId) {
            return;
        }

        callDeleteAccount(accountId);
    };

    $scope.cancelDeleteAccount = function () {
        $scope.deleteForm = false;
        $scope.deleteDoubleConfirm = false;
        $scope.deleteResult = null;
        $scope.deleteDataCheckbox = true;
        $scope.deleteAccountCheckbox = false;
        $scope.deleteText = null;
    };
}
