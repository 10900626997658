import { accountSettingsStore } from '../../../_reactivtrak/src/common/helpers/accountSettings/accountSettingsStore';

// TODO: Rename to SidebarNavigationSetup
export const favoriteNavigationSetup = ($scope, $state, $injector, $rootScope) => {
    var authorizationService = $injector.get('authorizationService');
    var notificationService = $injector.get('notificationService');
    var navigationService = $injector.get('navigationService');

    var navigationStore = $injector.get('navigationStore');

    window.addEventListener('message', function (message) {
        if (message.data && message.data.name === 'atk:notify') {
            notificationService.showNotification(message.data.title, message.data.status);
        }
    });

    function updateSidebar() {
        $scope.sidebar.setIsMobile();
        $scope.sidebar.setIsPinned();

        $scope.sidebar = navigationStore.createSidebar($scope.menuType);
    }

    $scope.$on('atWindowResized', updateSidebar);
    $scope.$on('AccountSettingsChanged', updateSidebar);

    function getMenuType() {
        var menuType =
            $state.current.name === 'supportportal'
                ? navigationService.menuTypes.SupportPortal
                : navigationService.menuTypes.CoreApp;

        return menuType;
    }
    $scope.menuType = getMenuType();

    // TODO: [begin:dedup] - Is there need to initialize multiple times?
    // This is required if there is a change to access settings such as RBAC
    // Need to review on when to recreate the sidebar instead of for any change
    var accountSettingsStoreUnsubscribe = accountSettingsStore.subscribe(() => {
        $scope.sidebar = navigationStore.createSidebar($scope.menuType);
    });

    // Create sidebar
    var sidebar = navigationStore.createSidebar($scope.menuType);
    $rootScope.sidebar = sidebar;
    // TODO: [end:dedup]

    function authorizeRouteByPageTitle() {
        if ($state.current.name !== 'supportportal' && $state.current.name !== 'app.loading') {
            var menuItems = $scope.sidebar && $scope.sidebar.restrictedMenu && $scope.sidebar.restrictedMenu.value;
            menuItems?.forEach((item) => {
                if (item && item.getRouteName() === $state.current.name) {
                    //TODO: this checks only the first rule as a quick fix. But need a better solution to allow pages to bypass side menu route blockage
                    var rules = item.rules && item.rules[0];
                    if (rules && !rules.disableRedirect) {
                        authorizationService.redirectToAuthorizedHome($state);
                    }
                }
            });
        }
    }

    // Validates current page is accessible according to menu rules
    var restrictedMenuSubscription = $scope.sidebar.restrictedMenu.subscribe(authorizeRouteByPageTitle);
    $scope.$on('$destroy', function () {
        restrictedMenuSubscription.unsubscribe();
        accountSettingsStoreUnsubscribe();
    });

    $scope.$on('$stateChangeSuccess', function () {
        authorizeRouteByPageTitle();
        navigationStore.setNavigationState($state.current);
    });
    navigationStore.setNavigationState($state.current);

    function getTag(toState) {
        if (toState && toState.data) {
            var tag = $scope.sidebar.getCurrentTag() || {};
            toState.data.tag = tag.label;
            toState.data.color = tag.color;
        }
    }

    // Update state name observable on state change
    $scope.$on('$stateChangeStart', function (e, toState, toParams) {
        $scope.sidebar.currentStateSubject.next({
            stateName: toState.name,
            modelId: toParams.modelId,
            pageId: toParams.pageId,
            subPageId: $state.params.subPageId
        });
        getTag(toState);
    });
};
