import React from 'react';
import authorization from '../../../common/helpers/authorization';
import {
  setReportFilters,
  useReportFilterStore
} from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import { useRefreshStore } from '../../../common/components/ReportFilters/hooks/useRefreshStore';
import { setIsRefreshing } from '../../../common/components/ReportFilters/stores/refreshStore';
import { IReportFilters } from '../../../common/components/ReportFilters/models/IReportFilters';
import { UserComputerFilter } from '../../../common/components/ReportFilters/components/UserComputerFilter';
import { DateRangeFilter } from '../../../common/components/ReportFilters/components/DateRangeFilter';
import { RefreshButton } from '../../../common/components/ReportFilters/components/RefreshButton';
import { AcademyButton } from '../../../common/components/ReportFilters/components/AcademyButton';
import Box from '@mui/material/Box';
import {
  ReportHeaderInline,
  ReportHeaderLeftColumn,
  ReportHeaderRightColumn
} from '../../../common/styles/app.component.styles';

export const WorkingHoursReportNewGridHeader = () => {
  const reportFilters: IReportFilters = useReportFilterStore((s) => s);
  const { dates, users } = reportFilters;

  const { isRefreshing } = useRefreshStore((s) => s);

    const ignoreDateRestrictions: boolean =
      authorization.isSupportOrSuperAdmin();

  return (
    <Box>
      <ReportHeaderLeftColumn>
        <ReportHeaderInline>
          <DateRangeFilter
            dates={dates}
            onSubmitFilter={setReportFilters}
            ignoreRestrictions={ignoreDateRestrictions}
          />
        </ReportHeaderInline>
        <ReportHeaderInline>
          <UserComputerFilter onSubmitFilter={setReportFilters} users={users} />
        </ReportHeaderInline>
        <ReportHeaderInline>
          <RefreshButton
            onRefresh={setIsRefreshing}
            isRefreshing={isRefreshing}
          />
        </ReportHeaderInline>
      </ReportHeaderLeftColumn>
      <ReportHeaderRightColumn>
        <ReportHeaderInline sx={{ mr: { xs: 0, md: 1 } }}>
          <AcademyButton />
        </ReportHeaderInline>
      </ReportHeaderRightColumn>
    </Box>
  );
};
