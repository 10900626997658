import { useCallback, useEffect, useState } from 'react';
import { INotificationService } from '../../common/services/Notifications';
import { copyToClipboard } from '../../common/utils';
import { IAgentDownloadService } from '../../common/models';
import { MINUTES_IN_72_HOURS } from '../../common/constants';
import { OperationalSystemsTypeShort } from '../../common/enums';
import { IAgentInstallSettingsState } from '../models';
import {
  getAccountSettings,
  setAccountSettings
} from '../../common/helpers/accountSettings/accountSettingsStore';

type AgentInstallSettingsStateProps = {
  service: IAgentDownloadService;
  notificationService: INotificationService;
};

export const useAgentInstallSettingsState = ({
  service,
  notificationService
}: AgentInstallSettingsStateProps): IAgentInstallSettingsState => {
  const { fetchAgentLink, updateNonAdminDirectDownload } = service;

  const [winLink, setWinLink] = useState<string>('');
  const [macLink, setMacLink] = useState<string>('');
  const [linkLoading, setLinkLoading] = useState<string>('');
  const [nonAdminDirectDownloadSetting, setNonAdminDirectDownloadSetting] =
    useState<boolean>(false);

  useEffect(() => {
    const { agentDownloadSettings } = getAccountSettings();
    const localSetting =
      agentDownloadSettings?.nonAdminMayDownloadAgent ?? false;
    setNonAdminDirectDownloadSetting(localSetting);
  }, []);

  const getAgentLink = useCallback(
    async (os: OperationalSystemsTypeShort): Promise<void> => {
      setLinkLoading(os);

      try {
        const res = await fetchAgentLink(
          os?.toLowerCase(),
          MINUTES_IN_72_HOURS
        );
        if (res?.data && res?.data?.url) {
          const url = res.data.url;
          os === OperationalSystemsTypeShort.win
            ? setWinLink(url)
            : setMacLink(url);
          notificationService.success(
            `Success! Your Agent Link for ${
              os == OperationalSystemsTypeShort.win ? 'Windows' : 'Mac'
            } is ready to download`
          );
        } else {
          throw new Error('Error creating secure agent link.');
        }
      } catch (error) {
        notificationService.error('Unable to load Links');
        console.error('ActivTrak Error: Unable to load Links', error);
      } finally {
        setLinkLoading('');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getWinAgentLink = useCallback((): void => {
    if (winLink !== '') {
      copyToClipboard(winLink, notificationService);
    } else {
      getAgentLink(OperationalSystemsTypeShort.win);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAgentLink, winLink]);

  const getMacAgentLink = useCallback((): void => {
    if (macLink !== '') {
      copyToClipboard(macLink, notificationService);
    } else {
      getAgentLink(OperationalSystemsTypeShort.mac);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAgentLink, macLink]);

  const updateNonAdminDirectDownloadSetting = async (
    newCheckedValue: boolean
  ): Promise<void> => {
    setLinkLoading('true');

    try {
      await updateNonAdminDirectDownload({
        nonAdminMayDownloadAgent: newCheckedValue
      });
      setNonAdminDirectDownloadSetting(newCheckedValue);
      const { agentDownloadSettings } = getAccountSettings();
      setAccountSettings({
        agentDownloadSettings: {
          ...agentDownloadSettings,
          nonAdminMayDownloadAgent: newCheckedValue
        }
      });
      notificationService.success(
        'Success! Your Non-Admin Download Agent Setting is Updated!'
      );
    } catch (error) {
      notificationService.error(
        'Unable to update Non-Admin Download Agent Setting'
      );
      console.error(
        'ActivTrak Error: Unable to update Non-Admin Download Agent Setting',
        error
      );
    } finally {
      setLinkLoading('');
    }
  };

  return {
    winLink,
    macLink,
    linkLoading,
    nonAdminDirectDownloadSetting,
    getWinAgentLink,
    getMacAgentLink,
    updateNonAdminDirectDownloadSetting
  };
};
