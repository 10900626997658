import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  ColumnMovedEvent,
  ColumnResizedEvent,
  GridReadyEvent,
  IDatasource
} from 'ag-grid-community';
import {
  fetchReportingData,
  mergeArraysOfDuplicateObjects
} from '../../../common/helpers';
import { useReportFilterStore } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import { IReportFilters } from '../../../common/components/ReportFilters/models/IReportFilters';
import { useNotifications } from '../../../common/services/Notifications';
import { useScheduleAdherenceViewStore } from '../hooks/useScheduleAdherenceViewStore';
import { useScheduleAdherenceReportStore } from '../hooks/useScheduleAdherenceReportStore';
import { setScheduleAdherenceReportStore } from '../stores/scheduleAdherenceReportStore';
import { getScheduleAdherenceView } from '../stores/scheduleAdherenceViewStore';
import { useScheduleAdherenceColumnsStore } from '../hooks/useScheduleAdherenceColumnsStore';
import { IScheduledTimelineFilters } from '../models/ScheduledTimeline.types';
import { generateParameters } from '../../../common/components/ReportFilters/utils/generateParameters';
import { useRefreshStore } from '../../../common/components/ReportFilters/hooks/useRefreshStore';
import { IScheduleAdherenceUsersDto } from '../models/ScheduleAdherence.types';
import { getScheduledColumns } from '../utils/scheduleAdherenceColumns';
import { useScheduledTimelineStore } from '../hooks/useScheduledTimelineStore';
import { mapColumnsToColumnState } from '../utils/workingHoursReport.utils';
import {
  getScheduleAdherenceColumns,
  setScheduleAdherenceColumns
} from '../stores/scheduleAdherenceColumnsStore';
import AtGrid from '../../../common/components/AtGrid/components/AtGrid';
import { NoReportData } from '../../common/components/NoReportData';

export const ScheduleAdherenceGrid = () => {
  const { view } = useScheduleAdherenceViewStore((s) => s);
  const { isLoading } = useScheduleAdherenceReportStore((s) => s);

  const gridRef = useRef<AgGridReact>(null);
  const currentPage = useRef<number>(1);

  const reportFilters: IReportFilters = useReportFilterStore((s) => s);
  const shiftTimes = useScheduledTimelineStore((s) => s);
  const { refreshTimestamp } = useRefreshStore((s) => s);

  const scheduledColumns = useScheduleAdherenceColumnsStore((s) => s);

  const notificationService = useNotifications();

  const columns = useRef<ColDef<IScheduleAdherenceUsersDto>[]>(
    getScheduledColumns()
  );

  //WHEN THE VIEW CHANGES...
  useEffect(() => {
    const currentColumnState = getScheduleAdherenceColumns();

    // set the columns for the view if grid is already loaded
    gridRef.current.api?.applyColumnState({
      state: currentColumnState[view],
      //needed when columns are re-ordered
      applyOrder: true
    });

    //update the column selector
    const mergedColumns = mergeArraysOfDuplicateObjects(
      currentColumnState[view], //these are in the latest order so make them the target
      columns.current, //merge the rest of the column info before updating the grid columns with this
      'colId'
    );
    setScheduleAdherenceReportStore({ columnSelectorFields: mergedColumns });
  }, [view]);

  //WHEN THE SELECTED COLUMNS CHANGE...
  useEffect(() => {
    const { view: currentView } = getScheduleAdherenceView();

    //set the columns for the view if grid is already loaded
    gridRef.current.api?.applyColumnState({
      state: scheduledColumns[currentView],
      //needed when columns are re-ordered
      applyOrder: true
    });
  }, [scheduledColumns]);

  //set the columns for the grid when it is loaded
  const handleGridReady = useCallback((event: GridReadyEvent) => {
    const { view: currentView } = getScheduleAdherenceView();
    const currentColumnState = getScheduleAdherenceColumns();

    event.api?.applyColumnState({
      state: currentColumnState[currentView],
      //needed when columns are re-ordered
      applyOrder: true
    });
  }, []);

  const getDataSource = useCallback(
    (
      reportFilters: IReportFilters,
      shiftTimes: IScheduledTimelineFilters
    ): IDatasource => {
      //reset page to 1 when dataSource is re-generated
      currentPage.current = 1;

      const dataSource: IDatasource = {
        rowCount: undefined,
        getRows: async (params) => {
          const pageSize = 150;
          const page = currentPage.current;

          setTimeout(async () => {
            const reportFiltersStr = generateParameters(reportFilters, {
              ...shiftTimes,
              page: page,
              pageSize: pageSize
            });
            setScheduleAdherenceReportStore({ isLoading: true });
            try {
              const response =
                await fetchReportingData<IScheduleAdherenceUsersDto>({
                  path: '/reports/v1/scheduleadherence?' + reportFiltersStr
                });
              const data = response?.data;
              const total = response?.total;
              //update local currentPage
              currentPage.current = Math.ceil(total / params.endRow);

              //update global currentPage for export api
              setScheduleAdherenceReportStore({
                currentPage: currentPage.current
              });

              params.successCallback(data, total - 1);
            } catch (error) {
              params.failCallback();
              notificationService.error(
                'Unable to load Schedule Adherence data'
              );
              console.error(
                'ActivTrak Error: Error fetching Schedule Adherence data: ',
                error
              );
            } finally {
              setScheduleAdherenceReportStore({ isLoading: false });
            }
          }, 0);
        }
      };
      return dataSource;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const datasource = useMemo<IDatasource>(() => {
    const source = getDataSource(reportFilters, shiftTimes);
    //this forces the grid to refresh the data with the new external filters
    gridRef.current?.api?.setGridOption('datasource', source);

    return source;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataSource, reportFilters, shiftTimes, refreshTimestamp]);

  const handleColumnResize = useCallback((event: ColumnResizedEvent) => {
    const columnState = event.api.getColumnState();
    const mapped = mapColumnsToColumnState(columnState);
    const { view: currentView } = getScheduleAdherenceView();
    setScheduleAdherenceColumns({ [currentView]: mapped });
  }, []);

  const handleColumnReorder = useCallback(
    (event: ColumnMovedEvent<IScheduleAdherenceUsersDto>) => {
      const columnState = event.api.getColumnState();
      const { view: currentView } = getScheduleAdherenceView();
      setScheduleAdherenceColumns({ [currentView]: columnState });
    },
    []
  );

  return (
    <AtGrid
      gridRef={gridRef}
      selectedColumnFields={columns.current}
      isLoading={isLoading}
      gridOptionOverrides={{
        noRowsOverlayComponent: NoReportData,
        rowModelType: 'infinite',
        cacheBlockSize: 150,
        cacheOverflowSize: 0,
        infiniteInitialRowCount: 0,
        datasource: datasource,
        onGridReady: handleGridReady,
        onColumnResized: handleColumnResize,
        onColumnMoved: handleColumnReorder
      }}
    />
  );
};
