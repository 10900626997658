import viewTemplate from 'views/widgets/atDateRangePicker.html?raw';
import { getAccountSettings } from '../../../../_reactivtrak/src/common/helpers/accountSettings/accountSettingsStore';

angular.module('app').directive('atDateRangePickerRetention', [AtDateRangePickerRetention]);

function AtDateRangePickerRetention() {
    return {
        restrict: 'AE',
        template: viewTemplate,
        replace: true,
        scope: {
            dateRange: '=?',
            changeCallback: '=atChange',
            autoFocus: '='
        },
        link: function ($scope, element) {
            var { dateFormat, unixCreationTime, currentIanaTimeZone, settings } = getAccountSettings();
            var tz = currentIanaTimeZone || moment.tz.guess();
            var picker = element.find('input[name="datepicker"]');
            var creationDateObject = moment.unix(unixCreationTime);
            var maxDate_ianaTZ = moment().tz(tz).format(dateFormat.toUpperCase());
            var maxDate = moment().format(dateFormat.toUpperCase());
            if (maxDate_ianaTZ > maxDate) maxDate = maxDate_ianaTZ;

            angular.element('div.daterangepicker.show-calendar').remove();

            var customMinDate;

            var dataPlan = settings?.dataRetentionSettings?.['term-months'] ?? null;
            if (dataPlan) {
                customMinDate = moment().subtract(dataPlan, 'M').format(dateFormat.toUpperCase());
                customMinDate = moment(customMinDate).isBefore(creationDateObject)
                    ? creationDateObject.format(dateFormat.toUpperCase())
                    : customMinDate;
            } else {
                customMinDate = creationDateObject ? creationDateObject.format(dateFormat.toUpperCase()) : undefined;
            }

            var customEndDate = moment($scope.dateRange.to).isBefore(customMinDate)
                ? customMinDate
                : moment($scope.dateRange.to);

            picker.daterangepicker(
                {
                    showDropdowns: true,
                    startDate: moment($scope.dateRange.from),
                    endDate: customEndDate,
                    opens: 'right',
                    minDate: customMinDate,
                    maxDate: maxDate,
                    locale: {
                        separator: ' to ',
                        format: dateFormat.toUpperCase()
                    },
                    applyButtonClasses: 'btn-success at-date-range-picker-btn',
                    cancelButtonClasses: 'btn-danger at-date-range-picker-btn'
                },
                function (start, end) {
                    //TODO: THIS IS A TEMPORARY PATCH TO COMPENSATE FOR LOCAL TZ OFFSET - THIS WILL BE REFACTORED
                    // !! DO NOT REPLICATE !!
                    var tz = currentIanaTimeZone || moment.tz.guess();
                    var start_patch = moment.tz(`${start.format('YYYY-MM-DD')} 00:00:00`, tz);
                    var end_patch = moment.tz(`${end.format('YYYY-MM-DD')} 00:00:00`, tz);

                    $scope.changeCallback({ start: start_patch, end: end_patch });
                }
            );

            if ($scope.autoFocus) {
                picker.focus();
                picker.blur();

                $scope.autoFocus = false;
            }
        }
    };
}
