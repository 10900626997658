import { IRoute } from '../../../../models/IRoute';
import { LoadApplication } from '../../../../services/Application/AppLoad';

export const verify: IRoute = {
  name: 'verify',
  stateDefinition: {
    url: '/verify?vtoken&email',
    controller: 'VerifyCtrl',
    resolve: {
      loadApplication: [
        'loginService',
        async (loginService) => {
          if (!loginService.isLoggedIn()) {
            return {};
          }
          await LoadApplication();
        }
      ]
    }
  },
  access: {
    allowAnonymous: true,
    edit: [],
    read: []
  }
};

export default verify;
