import React, { useEffect } from 'react';
import { getAccountSetting } from '../../../common/helpers/accountSettings/accountSettingsStore';
import { useHasDataEmit } from '../../common/hooks/useHasDataEmit';
import {
  getEndOfDayTimestamp,
  getStartOfDayTimestamp,
  shouldEnableTimeSlider
} from '../../../common/components/ReportFilters/utils/dateRange.utils';
import { UserComputerFilter } from '../../../common/components/ReportFilters/components/UserComputerFilter';
import { DateRangeFilter } from '../../../common/components/ReportFilters/components/DateRangeFilter';
import { RefreshButton } from '../../../common/components/ReportFilters/components/RefreshButton';
import { AcademyButton } from '../../../common/components/ReportFilters/components/AcademyButton';
import { ProductivityFilter } from '../../../common/components/ReportFilters/components/ProductivityFilter';
import { ExportButtonContainer } from '../../../common/components/ReportFilters/components/ExportButton';
import { DateTimeSlider } from '../../../common/components/ReportFilters/components/DateTimeSlider';
import { ScreenshotsHeaderProps } from '../models/ScreenshotsTypes';
import { setReportFilters } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import Box from '@mui/material/Box';
import {
  ReportHeaderInline,
  ReportHeaderLeftColumn,
  ReportHeaderRightColumn
} from '../../../common/styles/app.component.styles';
import authorization from '../../../common/helpers/authorization';

export const ScreenshotsReportHeader = (props: ScreenshotsHeaderProps) => {
  const { reportFilters, isRefreshing, onRefresh } = props;
  const { dates, users, productivity, fromTimestamp } = reportFilters;
  const { fromDate, toDate } = dates.getDates();

  const hasData: boolean = useHasDataEmit();

  const enableTimeSlider = shouldEnableTimeSlider(fromDate, toDate) && hasData;

  useEffect(() => {
    if (!enableTimeSlider) {
      setReportFilters({ fromTimestamp: null });
    }
  }, [enableTimeSlider]);

  const ignoreDateRestrictions: boolean = authorization.isSupportOrSuperAdmin();
  const currentIanaTimeZone = getAccountSetting('currentIanaTimeZone');

  return (
    <>
      <Box>
        <ReportHeaderLeftColumn>
          <ReportHeaderInline>
            <DateRangeFilter
              dates={dates}
              onSubmitFilter={setReportFilters}
              ignoreRestrictions={ignoreDateRestrictions}
            />
          </ReportHeaderInline>
          <ReportHeaderInline>
            <UserComputerFilter
              onSubmitFilter={setReportFilters}
              users={users}
            />
          </ReportHeaderInline>
          <ReportHeaderInline>
            <ProductivityFilter
              productivity={productivity}
              onSubmitFilter={setReportFilters}
            />
          </ReportHeaderInline>
          <ReportHeaderInline>
            <RefreshButton onRefresh={onRefresh} isRefreshing={isRefreshing} />
          </ReportHeaderInline>
        </ReportHeaderLeftColumn>
        <ReportHeaderRightColumn>
          <ReportHeaderInline sx={{ mr: { xs: 0, md: 1 } }}>
            <AcademyButton />
          </ReportHeaderInline>
          <ReportHeaderInline
            sx={{ display: { xs: 'none', md: 'block' }, mr: 0 }}
          >
            <ExportButtonContainer />
          </ReportHeaderInline>
        </ReportHeaderRightColumn>
      </Box>
      <Box sx={{ m: 3, display: { xs: 'none', md: 'block' } }}>
        <DateTimeSlider
          min={getStartOfDayTimestamp(fromDate, currentIanaTimeZone)}
          max={getEndOfDayTimestamp(toDate, currentIanaTimeZone)}
          fromTimestamp={fromTimestamp}
          isDisabled={!enableTimeSlider}
          setFilterValues={setReportFilters}
        />
      </Box>
    </>
  );
};
