import { IInsightsSetting } from '../../insights/models/IInsightsSetting';
import { ApiRoutes } from '../constants/routesApi';
import { fetchData } from '../helpers/apis/apiRequest';
import { IInsightsSettingsStore } from '../models/IInsightsSettingsStore';
import { IInsightsSubNavigationSettingsDto } from '../models/IInsightsSubnavigationSettingsDto';
import { postLoginStore } from '../stores/postLoginStore';

const mapInsightsSettings = (
  subNavigationResponse: IInsightsSubNavigationSettingsDto,
  insightsSetting: IInsightsSetting,
  insightsEnabled: boolean
): IInsightsSettingsStore => {
  return {
    insightsEnabled: insightsEnabled ?? false,
    insightsAvailable:
      (insightsEnabled &&
        !insightsSetting?.inProgress &&
        Boolean(insightsSetting?.onBoardRequestTime)) ??
      false,
    onboardingInProgress: insightsSetting?.inProgress ?? false,
    schedulingEnabled: !(insightsSetting?.schedulingDisabled ?? true),
    onboardRequestTime: insightsSetting?.onBoardRequestTime ?? null,
    calendarIntegrated:
      subNavigationResponse?.isIntegratedCalendarEnabled ?? false,
    cacheTimestamp: Date.now()
  };
};

export const fetchSettings = async (): Promise<IInsightsSettingsStore> => {
  let subNavigationResponse;

  try {
    subNavigationResponse = await fetchData<IInsightsSubNavigationSettingsDto>({
      path: ApiRoutes.insights.subNavigationSettings
    });
  } catch (error) {
    console.error(
      'ActivTrak Error: Error fetching Insights sub-navigation settings',
      error
    );
  }

  const { insightsSetting, insightsEnabled } = await postLoginStore
    .getState()
    .fetch();

  return mapInsightsSettings(
    subNavigationResponse,
    insightsSetting,
    insightsEnabled
  );
};
