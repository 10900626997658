import React from 'react';
import { FeatureState } from '../../../enums/FeatureState';
export const NavigationFeatureBadge = (item) => {
  return item?.isBeta || item?.isEarlyAccess ? (
    <div
      className="at-menu-item-beta"
      style={{
        ['marginTop']: '2px',
        ['marginLeft' as any]: `${item.level === 0 ? '-2px' : '2px'}`
      }}
    >
      <div className="label-content">
        {item?.isBeta
          ? FeatureState.Beta.toUpperCase()
          : FeatureState.EA.toUpperCase()}
      </div>
    </div>
  ) : (
    <></>
  );
};
