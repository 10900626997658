import { generateParameters } from '../../../../../_reactivtrak/src/common/components/ReportFilters/utils/generateParameters';
import template from 'views/widgets/dashboard/topUsers.html?raw';
import { getReportFilters } from '../../../../../_reactivtrak/src/common/components/ReportFilters/hooks/reportFiltersStore';
import lodash from 'lodash';
import { profileStore } from '../../../../../_reactivtrak/src/common/services/Profile/useProfileState';

angular.module('app').directive('topUsers', topUsers);

function topUsers() {
    return {
        restrict: 'E',
        scope: {
            user: '=',
            isMobileAgent: '=',
            bindArray: '=',
            isAccountCreationMode: '=',
            forceTitle: '@',
            productivity: '@',
            mode: '@',
            printPage: '='
        },
        template,
        controller: topUsersCtrl
    };
}

topUsersCtrl.$inject = [
    '$scope',
    '$state',
    'dashboardApiService',
    'atDashboardWidgetService',
    'trackEventService',
    'topUsersService',
    'browserServiceFunctions',
    'localStorageService'
];

function topUsersCtrl(
    $scope,
    $state,
    dashboardApiService,
    atDashboardWidgetService,
    trackEventService,
    topUsersService,
    browserServiceFunctions,
    localStorageService
) {
    $scope.showDetails = false;

    $scope.productivityOptions = $scope.productivity || 'Total';
    $scope.widgetMode = $scope.mode || 'Users';
    let user = {};
    const { username } = profileStore.getState().profile;

    if (!$scope.printPage) {
        localStorageService.set('topUsersDashboardWidgetMode-' + username, $scope.widgetMode);
        localStorageService.set('topUsersDashboardWidgetFilter-' + username, $scope.productivityOptions);
    }

    const bindWidget = function () {
        const reportFilters = getReportFilters();
        if ($scope.widgetMode === 'Users') {
            $scope.dataCall = dashboardApiService.getProductivity;
            $scope.dataCallParams = {
                params: generateParameters(reportFilters, {}),
                options: $scope.productivityOptions
            };
        } else {
            $scope.dataCall = $scope.isAccountCreationMode ? dashboardApiService.getUsers : topUsersService.getUsers;
            $scope.dataCallParams = {
                params: generateParameters(reportFilters, {}),
                options: 'groups'
            };
        }

        const { users } = reportFilters;
        user = users.length > 0 ? users[0] : {};

        $scope.filterMode = user.filterMode;
        $scope.widgetTitle = $scope.forceTitle || 'Top ' + $scope.filterMode + ' and Groups';
        $scope.bindWidgetCallNumber = ($scope.bindWidgetCallNumber + 1) % 10 || 1;
    };
    $scope.bindWidget = bindWidget;

    function handleModeChange(mode) {
        if (!$scope.printPage) {
            localStorageService.set('topUsersDashboardWidgetMode-' + username, mode);
        }

        $scope.widgetMode = mode;
        bindWidget();
    }

    function handleSortChange(selection) {
        if (!$scope.printPage) {
            localStorageService.set('topUsersDashboardWidgetFilter-' + username, selection.text);
        }

        $scope.productivityOptions = selection.text;
        bindWidget();
    }

    function setToggleOptions() {
        $scope.toggleOptions = {
            labels: [
                {
                    heapId: 'id_57efb2fa-a93e-46f6-a8e3-ed7d7ff7ebf8',
                    text: 'Users',
                    value: user.filterMode === 'users' ? 'Users' : 'Computers'
                },
                {
                    heapId: 'id_39e9658d-29b0-4579-8ecc-c0bc87bf75cd',
                    text: 'Groups',
                    value: 'Groups'
                }
            ],
            onChange: handleModeChange
        };
    }

    const sortSelectionList = [
        {
            heapId: 'id_79309ac9-0074-4e21-bcb9-a7117b6bbce6',
            text: 'Total',
            value: 'Total'
        },
        {
            heapId: 'id_dd25a240-f416-401b-b133-8d6594bf3d00',
            text: 'Productive',
            value: 'Productive'
        },
        {
            heapId: 'id_9ec1f02e-83e6-47cd-ae38-1915f175fe1c',
            text: 'Unproductive',
            value: 'Unproductive'
        }
    ];

    sortSelectionList.push({
        heapId: 'id_21ba3fc4-e572-43b3-a59b-a30e1f6641c1',
        text: 'Undefined',
        value: 'Undefined'
    });

    $scope.sortButtonOptions = {
        type: 'sort',
        dynamicLabel: true,
        heapId: 'id_649b570c-85d9-4f6a-bc6e-0737eb8e7a39',
        selectionList: sortSelectionList,
        onChange: handleSortChange
    };

    atDashboardWidgetService.registerWidget('topUsers', 3, bindWidget);

    $scope.rowClicked = function (dataItem) {
        if (browserServiceFunctions.isMobileAgent()) {
            return;
        }

        if ($scope.widgetMode === 'Users') {
            const groupBy = user.filterMode === 'computers' ? 'computers' : 'users';
            const data = { name: dataItem.user, groupBy };
            trackEventService.track('tu-graph');
            $state.go('app.reports.topusers', data);
        } else {
            $state.go('app.reports.topusers', {
                groupId: dataItem.userId,
                groupBy: 'groups'
            });
        }
    };

    $scope.dataManipulation = function (data) {
        if (!data.rows && data.length <= 0) {
            return data;
        }

        let rows;
        if (data.rows) {
            rows = data.rows;
        } else {
            rows = data;
        }

        if ($scope.productivityOptions === 'Productive') {
            rows = lodash.sortBy(rows, [
                function (row) {
                    return row.productive + row.productivePassive;
                }
            ]);
        } else if ($scope.productivityOptions === 'Unproductive') {
            rows = lodash.sortBy(rows, [
                function (row) {
                    return row.unproductive + row.unproductivePassive;
                }
            ]);
        } else if ($scope.productivityOptions === 'Idle') {
            rows = lodash.sortBy(rows, [
                function (row) {
                    return row.undefinedPassive + row.unproductivePassive + row.productivePassive;
                }
            ]);
        } else if ($scope.productivityOptions === 'Undefined') {
            rows = lodash.sortBy(rows, [
                function (row) {
                    return row.undefined + row.undefinedPassive;
                }
            ]);
        } else {
            rows = lodash.sortBy(rows, [
                function (row) {
                    return row.total;
                }
            ]);
        }
        rows = lodash.reverse(rows);

        if ($scope.widgetMode === 'Groups') {
            rows = lodash.slice(rows, 0, 5);
        }

        return rows;
    };

    $scope.gotoReport = function () {
        const groupBy = $scope.widgetMode === 'Groups' ? 'groups' : user.filterMode;
        $state.go('app.reports.topusers', {
            groupBy: groupBy
        });
    };

    $scope.$watch('filterMode', function (newVal, oldVal) {
        if (newVal !== oldVal) {
            setToggleOptions();
        }
    });

    $scope.$watch('user.filterMode', function (newVal) {
        $scope.titleIcon = newVal === 'users' ? 'icon-at-user' : 'fa-desktop';
        $scope.filterMode = newVal;
    });
}
