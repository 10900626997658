import React, { useEffect, useState } from 'react';
import { getInsightsSettingsStore } from '../../../common/stores/insightsSettingsStore';
import { InfoButton } from '../../../common/components/Button';
import { showModal } from '../../../common/stores/globalModalStore';
import { getHasFeature } from '../utils/workingHoursReport.utils';
import { BundleFlag } from '../../../common/enums/BundleFlag';
import { OfflineMeetingsPromoModal } from '../../../_global/modals/components/OfflineMeetingsPromoModal';
import { OfflineMeetingsTooltipText } from '../components/TooltipText';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const showOfflineMeetingsPromoModal = () => {
  const modalContent = React.createElement(OfflineMeetingsPromoModal);

  showModal(modalContent);
};

export const OnlineMeetingsHeader = () => {
  const [isIntegratedCalendarEnabled, setIsIntegratedCalendarEnabled] =
    useState<boolean>(false);
  const hasOfflineMeetings = getHasFeature(BundleFlag.OfflineMeetingsData);

  useEffect(() => {
    const getIsCalendarEnabled = async () => {
      const insightsSettingsStore = await getInsightsSettingsStore();
      setIsIntegratedCalendarEnabled(
        insightsSettingsStore?.calendarIntegrated ?? false
      );
    };
    getIsCalendarEnabled();
  }, []);

  const infoButton = hasOfflineMeetings && (
    <InfoButton
      tooltipText={
        <OfflineMeetingsTooltipText
          isIntegratedCalendarEnabled={isIntegratedCalendarEnabled}
        />
      }
    />
  );
  const modalButton = !hasOfflineMeetings && (
    <Button
      id="29c35d21-8954-4698-8ef6-3821f690b912"
      variant="contained"
      onClick={showOfflineMeetingsPromoModal}
      size="small"
    >
      Enable Now
    </Button>
  );
  return (
    <>
      <Typography sx={{ display: 'flex' }}>
        <Typography
          component="span"
          sx={{ textAlign: 'center', width: hasOfflineMeetings ? 60 : 'auto' }}
        >
          Offline Meetings
        </Typography>
        {infoButton}
      </Typography>
      {modalButton}
    </>
  );
};
