import { ExportDestination } from '../enums/ExportDestination';

export interface ISaveExportModalContent {
  key: ExportDestination;
  description: string;
  primaryBtnText: string;
}

export const SAVE_EXPORT_MODAL_CONTENT: ISaveExportModalContent[] = [
  {
    key: ExportDestination.GCS,
    description:
      'Your data has been successfully exported! Complete your export by downloading the file to your device.',
    primaryBtnText: 'Download file to device'
  },
  {
    key: ExportDestination.GoogleDrive,
    description: 'Your data has been successfully exported to Google Drive!',
    primaryBtnText: 'View in Google Drive'
  }
];
