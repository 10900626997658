import { useEffect, useState } from 'react';

import {
  useRouteStore,
  isExcludedPage,
  findItemById
} from '../navigationStore';

import { setCoachingNotificationLastSeenWeek } from '../../../services/Navigation/Coaching';
import { toggleFavorite, useFavoritesStore } from '../favoritesStore';
import { IconResolver } from './IconResolver';
import { NavigationFeatureBadge } from './NavigationFeatureBadge';
import { MenuItemLabel } from './SideBar.styles';
import { FavoritesStar } from './FavoritesStar';
import { ExpandIcon } from './ExpandIcon';
import { useSideBarNavigation } from '../../../hooks/useSideBarNavigation';

const handleStarToggle = (
  e: React.MouseEvent<HTMLElement, MouseEvent>,
  item: any
) => {
  e.preventDefault();
  e.stopPropagation();

  const found = findItemById(item.id);
  toggleFavorite(found, 'navigation');
};

export const NavigationItem = (props) => {
  const { item, index } = props;

  const [subMenuOpen, setSubMenuOpen] = useState(item?.submenuOpen ?? false);
  const [isActive, setIsActive] = useState(false);
  const [showStar, setShowStar] = useState(false);

  const route = useRouteStore((s) => s.route);
  const { navigate } = useSideBarNavigation();

  const isInFavorites = useFavoritesStore((s) => s.isInFavorites);
  const favorites = useFavoritesStore((s) => s.favorites);

  const isFavoriteNavigationEnabled = useFavoritesStore(
    (s) => s.isFavoriteNavigationEnabled
  );

  useEffect(() => {
    const shouldShowStar = isInFavorites({ id: item.id });
    setShowStar(() => shouldShowStar);
  }, [isInFavorites, item.id, favorites]);

  useEffect(() => {
    setIsActive(() => {
      const active = route.name === item.action?.options?.ref;
      //If coach item click, set coach notification to read if exists
      if (
        active &&
        route.name === 'app.coaching' &&
        item.hasCoachingNotifications
      ) {
        item.hasCoachingNotifications = false;
        setCoachingNotificationLastSeenWeek();
      }
      return active;
    });
  }, [route.name, item]);

  const handleNavigate = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    item: any
  ): Promise<void> => {
    e.preventDefault();
    await navigate(item);
  };

  const handleToggle = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    item: any
  ): void => {
    e.preventDefault();

    setSubMenuOpen(() => {
      return !subMenuOpen;
    });

    setIsActive(() => {
      const active = route.name === item.action?.options?.ref;
      return active;
    });
  };

  return (
    <li id={`id_${item.id}`} key={index}>
      <div className="at-menu-item-container">
        <div
          className={`at-menu-item-row ${
            subMenuOpen ? 'expanded' : 'collapsed'

            // TODO: check activeSubmenu - does not seem to be working
          } ${item.activeSubmenu ? 'active-submenu' : ''}`}
        >
          <a
            className={`at-flex-container at-menu-item at-pointer ${
              isActive ? 'focus' : ''
            }`}
            onClick={(e) => {
              const navigate = ['internal', 'external', 'event'];
              navigate.includes(item.action.type)
                ? handleNavigate(e, item)
                : handleToggle(e, item);
            }}
          >
            <div className="at-flex-child at-menu-item-icon">
              {<IconResolver icon={item.icon} />}

              {item.hasCoachingNotifications && (
                <div className="at-menu-item-coaching-notification"></div>
              )}

              <NavigationFeatureBadge {...item} />
            </div>
            <MenuItemLabel level={item.level} className="at-flex-child">
              {item.label}
              {!item.submenus &&
                !isExcludedPage(item.label) &&
                isFavoriteNavigationEnabled && (
                  <FavoritesStar
                    id={`nav_star_${item.id}`}
                    favorited={showStar}
                    onClick={(e) => handleStarToggle(e, item)}
                  />
                )}
            </MenuItemLabel>

            {item.submenus && (
              <div className="at-flex-child at-menu-item-dropdown-control at-menu-item-icon">
                <ExpandIcon subMenuOpen={subMenuOpen} />
              </div>
            )}
          </a>
        </div>

        {/* submenu */}
        {item.submenus && subMenuOpen && (
          <ul className="at-submenu-items">
            {item.submenus.map((subitem, subindex) => {
              return (
                <NavigationItem
                  key={subindex}
                  item={subitem}
                  index={subindex}
                />
              );
            })}
          </ul>
        )}
      </div>
    </li>
  );
};
