import React, { useState } from 'react';
import {
  Box,
  TableCell,
  TableRow,
  IconButton,
  Link,
  Tooltip,
  Typography
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import LaunchIcon from '@mui/icons-material/Launch';
import { enterpriseClasses } from './Enterprise.styles.js';
import { impersonate } from '../../../../_reactivtrak/src/common/hooks/useImpersonate';
import { PlanType } from '../../../../_reactivtrak/src/common/enums/PlanType';
import { PlanPeriod } from '../../../../_reactivtrak/src/common/enums/PlanPeriod';
import CircleIcon from '@mui/icons-material/Circle';
import { regularFontWeight, mediumFontWeight } from '../../theme/styleGuide';
import { capitalizeFirstLetter } from '../../../../_reactivtrak/src/common/utils/string.utils';

export const EnterpriseRow = ({ account, isChild = false, onError }) => {
  const [open, setOpen] = useState(false);

const freeOrTrialChildPlan =
  isChild && account?.planPeriod === PlanPeriod.Trial
    ? capitalizeFirstLetter(PlanPeriod.Trial)
    : (isChild && account?.planPeriod === PlanPeriod.None) && 'Free';

  const isLicenseOverage = () => account.usedLicenses > account.licenseQuantity;

  const displayCollapsedRows = () => {
    if (open) {
      return (
        <>
          {account.children?.length > 0 &&
            account.children?.map((acct) => (
              <React.Fragment key={acct.accountNumber}>
                <EnterpriseRow account={acct} isChild={true} />
              </React.Fragment>
            ))}
        </>
      );
    }
  };

  const renderViewChildrenCol = () => {
    if (isChild) {
      return (
        <TableCell>
          <Box flexDirection="row-reverse" display="flex" alignItems="center">
            <Link
              href={'#'}
              onClick={(e) => {
                e.preventDefault();

                setTimeout(async () => {
                  try {
                    await impersonate(account.accountNumber)();
                    window.location.href = '/#/app/home';
                  } catch (ex) {
                    console.error(ex);
                    onError(ex);
                  }
                });
              }}
              target="_blank"
            >
              <IconButton size="small" color="primary">
                <LaunchIcon sx={enterpriseClasses.launchIcon} />
              </IconButton>
            </Link>
          </Box>
        </TableCell>
      );
    } else if (account.children?.length > 0) {
      return (
        <TableCell>
          <Box flexDirection="row-reverse" display="flex" alignItems="center">
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
        </TableCell>
      );
    }
    return <TableCell></TableCell>;
  };

  const renderLicenseCell = (cellType = 'used' | 'quantity') => {
    if (isChild && cellType === 'used') {
      if (freeOrTrialChildPlan && account?.planPeriod === PlanPeriod.None) {
        // Free plans do not count against trial pool allotment.
        return '-';
      } else {
        return account?.usedLicenses;
      }
    }

    if (!isChild) {
      let licenseCellValue = '';
      switch (cellType) {
        case 'used':
          licenseCellValue = account?.usedLicenses;
          break;
        case 'quantity':
          licenseCellValue = account?.licenseQuantity;
          break;
      }
      return (
        <>
          {isLicenseOverage() ? (
            <Tooltip
              title="Your used licenses total is more than your purchased licenses for this plan."
              arrow
            >
              <Typography component="span" style={{ color: 'red' }}>
                {licenseCellValue}
              </Typography>
            </Tooltip>
          ) : (
            licenseCellValue
          )}
        </>
      );
    }
  };

  const renderPlanName = (planType, accountPeriod) => {
    const isTrial = accountPeriod === PlanPeriod.Trial;

    for (const planValue of Object.values(PlanType)) {
      if (planType?.toLowerCase() === planValue.toLowerCase()) {
        return isTrial ? (
          <Tooltip
            title="Expired trials convert to free plans and do not count against trial pool allotment."
            arrow
          >
            <Typography component="span" sx={{ fontWeight: mediumFontWeight }}>
              {`${planValue} - ${capitalizeFirstLetter(PlanPeriod.Trial)}`}
            </Typography>
          </Tooltip>
        ) : (
          `${planValue} - ${capitalizeFirstLetter(accountPeriod)}`
        );
      }
    }
  };

  return (
    <>
      <TableRow sx={enterpriseClasses.tableRow}>
        {/* # Account # */}
        <TableCell align="center" sx={{ paddingLeft: isChild ? '10px' : '' }}>
          <Box>
            {isChild && <CircleIcon sx={enterpriseClasses.circleIcon} />}
            <Typography
              component="span"
              sx={
                isChild
                  ? { ...enterpriseClasses.circleIconTextChild }
                  : { ...enterpriseClasses.circleIconText }
              }
            >
              {account?.accountNumber}
            </Typography>
          </Box>
        </TableCell>
        <TableCell colSpan={2}>
          <Typography
            sx={{ fontWeight: isChild ? regularFontWeight : mediumFontWeight }}
          >
            {account.name}
          </Typography>
        </TableCell>
        {/* Account Plan */}
        <TableCell align="center">
          <Box>
            {!isChild && (
              <Typography sx={{ fontWeight: mediumFontWeight }}>
                {renderPlanName(account?.planType, account?.planPeriod)}
              </Typography>
            )}
            {freeOrTrialChildPlan && (
              <>
                <CircleIcon
                  sx={{ ...enterpriseClasses.circleIcon, marginLeft: '0px' }}
                />
                <Typography
                  component="span"
                  style={{
                    ...enterpriseClasses.circleIconTextChild,
                    marginLeft: '8px'
                  }}
                >
                  {freeOrTrialChildPlan}
                </Typography>
              </>
            )}
          </Box>
        </TableCell>
        <TableCell align="center">
          {!isChild && <Box>{account.children?.length}</Box>}
        </TableCell>
        {/* Email */}
        <TableCell align="center">
          <Box>{account?.creatorEmail}</Box>
        </TableCell>

        <TableCell align="center">
          <Box>{renderLicenseCell('used')}</Box>
        </TableCell>
        <TableCell align="center">
          <Box>{renderLicenseCell('quantity')}</Box>
        </TableCell>
        {renderViewChildrenCol()}
      </TableRow>
      {displayCollapsedRows()}
    </>
  );
};
