import React, { useEffect, useState } from 'react';
import { useReportFilterStore } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import { TopApplicationsReportWrapper } from '../utils/topApplicationsReportWrapper';
import { ReactivTrakComponentWrapper } from '../../../ReactivTrakComponentWrapper';
import { useRefreshState } from '../../../common/components/ReportFilters/hooks';
import { IReportFilters } from '../../../common/components/ReportFilters/models/IReportFilters';
import { TopApplicationsReportHeader } from '../components/TopApplicationsReportHeader';
import { resetExportParamsStore } from '../../../common/stores/exportParamsStore';

const TopApplicationsReportView = () => {
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const reportFilters: IReportFilters = useReportFilterStore((s) => s);

  const { refreshTimestamp, handleRefresh } = useRefreshState();

  useEffect(() => {
    return resetExportParamsStore;
  }, []);

  useEffect(() => {
    if (refreshTimestamp) {
      setIsRefreshing(true);
      setTimeout(() => {
        setIsRefreshing(false);
      }, 5000);
    }
  }, [refreshTimestamp]);

  return (
    <>
      <TopApplicationsReportHeader
        reportFilters={reportFilters}
        onRefresh={handleRefresh}
        isRefreshing={isRefreshing}
        refreshTimestamp={refreshTimestamp}
      />
      <TopApplicationsReportWrapper
        reportFilters={reportFilters}
        refreshTimestamp={refreshTimestamp}
      />
    </>
  );
};

export default TopApplicationsReportView;

export const TopApplicationsReportViewComponent = () => (
  <ReactivTrakComponentWrapper>
    <TopApplicationsReportView />
  </ReactivTrakComponentWrapper>
);
