import React from 'react';
import { ColDef, ColumnState } from 'ag-grid-community';
import { BundleFlag } from '../../../common/enums/BundleFlag';
import { formatDuration, htmlEscape } from '../../../common/helpers';
import authorization from '../../../common/helpers/authorization';
import {
  stopLightProductivityColors,
  secondaryFontSize
} from '../../../common/constants';
import Link from '../../../common/components/Link';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';

export const getAccessForFeature = (
  dataBundleFlag,
  promoBundleFlag,
  featureFlag
) => {
  const hasDataBundleFlag = authorization.hasFeature(dataBundleFlag);
  const hasPromoBundleFlag = authorization.hasFeature(promoBundleFlag);
  const hasFeatureFlag = authorization.hasFeature(featureFlag);
  const canAccessUpgrade = authorization.hasRouteByName('app.account.upgrade');

  const hasColumnBundleFlag =
    hasDataBundleFlag || (hasPromoBundleFlag && canAccessUpgrade);

  return hasColumnBundleFlag && hasFeatureFlag;
};

export const getHasFeature = (feature: BundleFlag): boolean =>
  authorization.hasFeature(feature);

export const generateListOfItemsLinked = (
  array: string[],
  type: string
): React.ReactNode => {
  if (!array || typeof array.forEach !== 'function') {
    return <></>;
  }

  const item = array[0];

  const escapedName = htmlEscape(item);
  const query = {};
  query[type] = escapedName;

  return (
    <Link to="app.reports.activitylog" params={query}>
      {item}
    </Link>
  );
};

export const generateLinkedItem = (
  item: string,
  type: string
): React.ReactNode => {
  if (!item) {
    return <></>;
  }

  const escapedName = htmlEscape(item);
  const query = {};
  query[type] = escapedName;

  return (
    <Link to="app.reports.activitylog" params={query}>
      {item}
    </Link>
  );
};

export const getProductivityTimeTemplate = (duration = 0, productivity) => {
  let color: string;
  switch (productivity) {
    case 1:
      color = stopLightProductivityColors.productive;
      break;
    case -1:
      color = stopLightProductivityColors.unproductive;
      break;
    case -3:
      color = stopLightProductivityColors.undefinedPassive;
      break;
    case 2:
      color = stopLightProductivityColors.productivePassive;
      break;
    case -2:
      color = stopLightProductivityColors.unproductivePassive;
      break;
    case -4:
    case 9:
      color = stopLightProductivityColors.offlineMeetings;
      break;
    default:
      color = stopLightProductivityColors.undefined;
  }
  const productivityBubble = (
    <CircleIcon sx={{ color, fontSize: secondaryFontSize }} />
  );

  return (
    <Typography>
      {formatDuration(duration)} {productivityBubble}
    </Typography>
  );
};

export const mapColumnsToColumnState = (columns: ColDef[]): ColumnState[] =>
  columns.map((column) => ({
    colId: column.colId,
    hide: column.hide,
    width: column.width,
    flex: column.flex,
    sort: column.sort,
    sortIndex: column.sortIndex
  }));
