import React, { useCallback, useEffect, useState } from 'react';
import { IReportModalListItem, WidgetProps } from '../../models';
import {
  errorMsg,
  lastUpdateText,
  modalButtonLabel,
  noUsersMeetingCriteria,
  statuses,
  teamWidgetSubheader,
  widgetTitle
} from '../../constants';
import { useLastUpdate, useTodaysUserActivityState } from '../../hooks';
import authorizationService from '../../../common/helpers/authorization';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  OverallStatus,
  TemplateShadow,
  BaseLoadingView,
  ErrorMsgDisplay,
  ModalButton,
  WidgetDialog,
  WidgetModalList
} from '../templates';
import {
  WidgetLinkTypography,
  WidgetLinkWrapper
} from '../../../common/components/WidgetLink/WidgetLink.styles';
import { LastUpdatedSC, ModalHeader } from '../../styles/templates.styles';
import { UserIconButton } from '../../styles/todaysActivity.styles';
import TodaysUserActivityStatus from './TodaysUserActivityStatus';
import { checkAllValuesAreZero } from '../../utils';
import { LinkWithIcon } from '../../../common/components/Link/LinkWithIcon';
import { getDateTimeFormatFromHour } from '../../../common/utils/datetime/dateHourConversion';
import { gray5 } from '../../../common/constants';
import {
  setReportFilters,
  useReportFilterStore
} from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import { IReportFilters } from '../../../common/components/ReportFilters/models/IReportFilters';
import { DEFAULT_REPORT_FILTERS } from '../../../common/components/ReportFilters/constants/defaultReportFilters';

export default function TodaysUserActivity(props: Readonly<WidgetProps>) {
  const { refreshTimestamp } = props;

  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [noResults, setNoResults] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [usersList, setUsersList] = useState<IReportModalListItem[]>([]);
  const [usersModalTitle, setUsersModalTitle] = useState<string>('');

  const {
    getTodaysActivityData,
    isLoading,
    isTileError,
    todaysUserActivity,
    isChartLoading,
    originalDayData,
    chartDate,
    getChartData,
    isChartError,
    accountTimezone
  } = useTodaysUserActivityState();

  const reportFilters: IReportFilters = useReportFilterStore((s) => s);

  const lastUpdate = useLastUpdate(todaysUserActivity?.lastUpdated);

  useEffect(
    () => {
      getTodaysActivityData(reportFilters);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportFilters.groupId, getTodaysActivityData, refreshTimestamp]
  );

  useEffect(() => {
    if (!todaysUserActivity) {
      setIsDisabled(true);
      return;
    }

    const { activeToday, activeTotal } = todaysUserActivity;
    if (activeTotal === 0 || activeToday / activeTotal === 0) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [todaysUserActivity]);

  useEffect(() => {
    if (todaysUserActivity) {
      const hasNoResults = checkAllValuesAreZero([
        todaysUserActivity.active,
        todaysUserActivity.passive,
        todaysUserActivity.inactive
      ]);
      setNoResults(hasNoResults);
    }
  }, [todaysUserActivity]);

  const handleFetchUserActivityData = useCallback(() => {
    getChartData({
      groupId: reportFilters.groupId[0],
      from: getDateTimeFormatFromHour(reportFilters.timelineFromHour),
      to: getDateTimeFormatFromHour(reportFilters.timelineToHour),
      strict: reportFilters.timelineStrict,
      interval: DEFAULT_REPORT_FILTERS.timelineInterval, // Force defaults when loading the filter until we add these controls to the UI
      sort: DEFAULT_REPORT_FILTERS.timelineSort // Force defaults when loading the filter until we add these controls to the UI
    });
  }, [getChartData, reportFilters]);

  const handleOpen = (status: number, title: string) => {
    const usersListByStatus = todaysUserActivity?.usersStatus?.filter(
      (user) => user.activityStatus === status
    );

    setUsersList(usersListByStatus);
    setUsersModalTitle(title);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const hasProductivityAccess = authorizationService.hasRouteByName(
    'app.reports.productivity'
  );

  const onSubmitFilters = (filters) => {
    const { from, to, strict, interval, sort } = filters;
    setReportFilters({
      timelineFromHour: from,
      timelineToHour: to,
      timelineStrict: strict,
      timelineInterval: interval,
      timelineSort: sort
    });
  };

  return (
    <TemplateShadow
      sx={{
        height: '450px'
      }}
    >
      <Stack
        direction="row"
        sx={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <WidgetLinkWrapper direction="row" display="inline-block">
          <WidgetLinkTypography variant="h4">
            {widgetTitle.activity}
          </WidgetLinkTypography>
        </WidgetLinkWrapper>
        <Box>
          <LinkWithIcon
            text="Team Pulse"
            redirectTo={'app.teampulse'}
            disabled={noResults}
            id="5addf687-1fcd-47e1-aca0-86d6e7bf8411"
          />
        </Box>
      </Stack>
      {!isLoading && !isTileError && todaysUserActivity && (
        <>
          <Box
            sx={{
              paddingTop: '34px',
              minHeight: { xs: '328px', md: '350px', lg: '328px' },
              textAlign: 'center'
            }}
          >
            <OverallStatus
              header={`${todaysUserActivity?.activeToday}/${todaysUserActivity?.activeTotal}`}
              subheader={teamWidgetSubheader.todaysActivity}
              reverseResponsive
            />
            <Stack
              direction="row"
              justifyContent="center"
              spacing={3}
              sx={{ marginTop: '50px' }}
            >
              {statuses.map((item) => (
                <UserIconButton
                  id={`team-management-${item.id}`}
                  key={`activity-status-${item.statusType}`}
                  onClick={() =>
                    handleOpen(item.activityStatus, item.statusType)
                  }
                  disabled={noResults}
                >
                  <TodaysUserActivityStatus
                    statusType={item.statusType}
                    iconColor={noResults ? gray5 : item.iconColor}
                    values={todaysUserActivity}
                  />
                </UserIconButton>
              ))}
            </Stack>
            {hasProductivityAccess && (
              <ModalButton
                placement="bottom-start"
                minWidth="1050px"
                maxHeight="380px"
                loaderHeight="90%"
                buttonLabel={modalButtonLabel.todaysActivity}
                id="85d50918-588e-43ed-8114-45554c0e7279"
                onInit={handleFetchUserActivityData}
                isListLoading={isChartLoading}
                isDisabled={isDisabled}
                isModalError={isChartError}
                timelineData={originalDayData}
                timezone={accountTimezone.current}
                date={chartDate}
                onSubmitFilters={onSubmitFilters}
              />
            )}
          </Box>
          {lastUpdate && (
            <LastUpdatedSC>
              {lastUpdateText} {lastUpdate}
            </LastUpdatedSC>
          )}
          <WidgetDialog isOpen={open} onClose={handleClose} width="400px">
            <ModalHeader variant="h6">{usersModalTitle} now</ModalHeader>
            {!usersList || usersList?.length === 0 ? (
              <Typography mt={2}>{noUsersMeetingCriteria}</Typography>
            ) : (
              <WidgetModalList data={usersList} />
            )}
          </WidgetDialog>
        </>
      )}
      <BaseLoadingView
        isLoading={isLoading}
        isTileError={isTileError}
        errorMsg={<ErrorMsgDisplay msg={errorMsg} />}
      />
    </TemplateShadow>
  );
}
