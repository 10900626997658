export enum ExportDetail {
  Only = 'topLevelOnly',
  Title = 'byTitle',
  SubPage = 'bySubpage',
  User = 'byUser'
}

export enum ExportPage {
  Current = 'currentPage',
  All = 'allPages'
}
