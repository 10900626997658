import { createStore } from 'zustand';
import { IWebsocketSettingsStore } from '../models/IWebsocketSettingsStore';
import { fetchSettings } from '../utils/websocketSettingsStore.utils';

export const WEBSOCKET_SETTINGS_STORE_DEFAULT_STATE: IWebsocketSettingsStore = {
  pingInterval: 120000,
  reconnectDelayMax: 300000,
  reconnectDelayStart: 1000
};

export const websocketSettingsStore = createStore<IWebsocketSettingsStore>(
  () => WEBSOCKET_SETTINGS_STORE_DEFAULT_STATE
);

/**
 * Fetches the Websocket Settings from the API and sets it in the store.
 */
export const fetchWebsocketSettings =
  async (): Promise<IWebsocketSettingsStore> => {
    const response = await fetchSettings();
    const currentSettings = websocketSettingsStore.getState;
    websocketSettingsStore.setState({ ...currentSettings, ...response });
    return websocketSettingsStore.getState();
  };

/**
 * Returns the current Websocket Settings Store state.
 */
export const getWebsocketSettingsStore = (): IWebsocketSettingsStore =>
  websocketSettingsStore.getState();

/**
 * Resets the Websocket Settings Store to its default state.
 */
export const resetWebsocketSettingsStore = (): void =>
  websocketSettingsStore.setState(WEBSOCKET_SETTINGS_STORE_DEFAULT_STATE);
