import { cloneDeep } from 'lodash';

export const mergeArraysOfDuplicateObjects = (
  original,
  newdata,
  selector = 'id'
) => {
  const cloned = cloneDeep(original);

  newdata.forEach((dat) => {
    const foundIndex = original.findIndex(
      (ori) => ori[selector] == dat[selector]
    );
    cloned[foundIndex] = { ...cloned[foundIndex], ...dat };
  });

  return cloned;
};
