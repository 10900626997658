import { ApiRoutes } from '../constants/routesApi';
import { fetchData } from '../helpers/apis/apiRequest';
import { IAccountSettingsDto } from '../models';
import { createCachedStore } from './createCachedStore';

/**
 * Cached store for post_login
 */
export const postLoginStore = createCachedStore<IAccountSettingsDto>(
  async () =>
    await fetchData<IAccountSettingsDto>({
      path: ApiRoutes.settings.postLogin
    })
);
