import React, { useEffect } from 'react';
import { shouldEnableTimeSlider } from '../../../common/components/ReportFilters/utils/dateRange.utils';
import { UserComputerFilter } from '../../../common/components/ReportFilters/components/UserComputerFilter';
import { DateRangeFilter } from '../../../common/components/ReportFilters/components/DateRangeFilter';
import { RefreshButton } from '../../../common/components/ReportFilters/components/RefreshButton';
import { AcademyButton } from '../../../common/components/ReportFilters/components/AcademyButton';
import { ExportButtonContainer } from '../../../common/components/ReportFilters/components/ExportButton';
import { AlarmLogHeaderProps } from '../models/AlarmLogTypes';
import { setReportFilters } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import { ColumnFilter } from '../../../common/components/ReportFilters/components/ColumnFilter';
import { generateBody } from '../../../common/components/ReportFilters/utils/generateParameters';
import { AsyncExportButton } from '../../../common/components/ReportFilters/components/AsyncExportButton';
import { PAGE_EXPORT_OPTIONS } from '../../../common/components/ReportFilters/constants/exportMenuOptions';
import Box from '@mui/material/Box';
import {
  ReportHeaderInline,
  ReportHeaderLeftColumn,
  ReportHeaderRightColumn
} from '../../../common/styles/app.component.styles';
import authorization from '../../../common/helpers/authorization';
import { BundleFlag } from '../../../common/enums/BundleFlag';
import { ReportType } from '../../../common/enums/ReportType';
import { ExportPage } from '../../../common/enums/ExportType';
import { IExportReportParams } from '../../../common/models/IExport';
import { ExportSize } from '../../../common/enums/ExportSize';
import { getExportParamsStore } from '../../../common/stores/exportParamsStore';
import { useExportParamsStore } from '../../../common/hooks/useExportParamsStore';

export const AlarmLogHeader = (props: AlarmLogHeaderProps) => {
  const { reportFilters, isRefreshing, onRefresh } = props;
  const { dates, users } = reportFilters;
  const { fromDate, toDate } = dates.getDates();
  const { hasDataForTable } = useExportParamsStore((s) => s);

  const enableTimeSlider =
    shouldEnableTimeSlider(fromDate, toDate) && hasDataForTable;

  useEffect(() => {
    if (!enableTimeSlider) {
      setReportFilters({ fromTimestamp: null });
    }
  }, [enableTimeSlider]);

  const ignoreDateRestrictions: boolean = authorization.isSupportOrSuperAdmin();

  const hasAsyncExport: boolean = authorization.hasFeature(
    BundleFlag.AlarmLogReportExport
  );

  const hasLargeExport: boolean = authorization.hasFeature(
    BundleFlag.LargeExport
  );

  const createExportPayload = (
    pageExportOption?: string
  ): IExportReportParams => {
    const exportParams = getExportParamsStore();
    const { from, to, userType, userMode, userId } = generateBody(
      reportFilters,
      {}
    );

    let currentReportConfig = exportParams;

    if (pageExportOption === ExportPage.All) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { limitToPage, ...allPagesReportConfig } = exportParams;
      currentReportConfig = allPagesReportConfig;
    }

    const reportPayload: IExportReportParams = {
      type: ReportType.AlarmLog,
      startDate: from,
      endDate: to,
      userType,
      userMode,
      userId
    };

    return { ...reportPayload, ...currentReportConfig };
  };

  return (
    <Box>
      <ReportHeaderLeftColumn>
        <ReportHeaderInline>
          <DateRangeFilter
            dates={dates}
            onSubmitFilter={setReportFilters}
            ignoreRestrictions={ignoreDateRestrictions}
          />
        </ReportHeaderInline>
        <ReportHeaderInline>
          <UserComputerFilter onSubmitFilter={setReportFilters} users={users} />
        </ReportHeaderInline>
        <ReportHeaderInline>
          <RefreshButton onRefresh={onRefresh} isRefreshing={isRefreshing} />
        </ReportHeaderInline>
      </ReportHeaderLeftColumn>
      <ReportHeaderRightColumn>
        <ReportHeaderInline sx={{ mr: { xs: 0, md: 1 } }}>
          <AcademyButton />
        </ReportHeaderInline>
        <ReportHeaderInline
          sx={{ mr: 1, display: { xs: 'none', md: 'block' } }}
        >
          <ColumnFilter />
        </ReportHeaderInline>
        <ReportHeaderInline
          sx={{ display: { xs: 'none', md: 'block' }, mr: 0 }}
        >
          {hasAsyncExport ? (
            <AsyncExportButton
              reportType={ReportType.AlarmLog}
              createExportPayload={createExportPayload}
              showExportsLimitedTooltip
              exportableItemCount={
                hasLargeExport ? ExportSize.LargeBatch : ExportSize.SmallBatch
              }
              menuOptions={PAGE_EXPORT_OPTIONS}
              isDisabled={!hasDataForTable || isRefreshing}
            />
          ) : (
            <ExportButtonContainer />
          )}
        </ReportHeaderInline>
      </ReportHeaderRightColumn>
    </Box>
  );
};
