import { Role, RoleAccessKeys } from '../../../../../../enums';
import { IRoute } from '../../../../../../models/IRoute';
import { BundleFlag } from '../../../../../../enums/BundleFlag';
import { refreshInsightsSettings } from '../../../../../../stores/insightsSettingsStore';

export const appReportsScheduleAdherence: IRoute = {
  name: 'app.reports.scheduleadherence',
  stateDefinition: {
    url: '/scheduleadherence',
    template: '<schedule-adherence></schedule-adherence>',
    resolve: {
      loadInsightsSettingsStore: [async () => await refreshInsightsSettings()]
    },
    data: {
      pageTitle: 'Schedule Adherence'
    }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.WorkingHours,
    // roleAccessKey: RoleAccessKeys.ScheduleAdherence,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.User, Role.Viewer],
    bundleFlags: [BundleFlag.ScheduleAdherenceReport]
  }
};

export default appReportsScheduleAdherence;
