import { styled } from '@mui/material/styles';
import {
  Box,
  DialogActions,
  DialogContent,
  FormControlLabel,
  TextField,
  Typography
} from '@mui/material';
import { gray14, gray3, gray4, gray5 } from '../../common/constants';

export const TotCss = {
  search: {
    marginTop: '-40px'
  },
  addDelete: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  instructions: {
    fontSize: '16px',
    margin: '-2px 0 15px 0' // Default, can be overridden
  },
  scrollableBox: {
    overflow: 'scroll',
    height: '210px' // Default, can be overridden
  },
  editHeader: {
    fontSize: '18px',
    fontWeight: '500',
    margin: '20px 0 10px 0'
  },
  success: {
    marginTop: '-20px'
  }
};

export const BorderBoxDiv = styled('div')`
  border: 1px solid ${gray4};
  padding: 8px;
  border-radius: 4px;
  width: 100%;
  height: 40px;
`;

export const PillBoxText = styled('div')`
  background-color: ${gray14};
  color: ${gray5};
  line-height: 8px;
  font-weight: 500;
  padding: 8px;
  border-radius: 4px;
  display: inline-block;
`;

export const ButtonContainer = styled('div')`
  button {
    border-radius: 50%;
    min-width: 32px;
  }
`;

export const PercentInputContainer = styled(Box)`
  font-size: 16px;
  margin-top: 25px;
`;

export const StyledTextField = styled(TextField)`
  width: 70px;
  margin-top: -8px;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 0; //Need 0's in place to override upstream styles
  &:hover {
    background-color: ${gray3};
  }
`;

export const StyledBoxContainer = styled(Box)`
  border: solid 1px ${gray4};
  padding: 0 10px;
  margin-top: 5px;
`;

export const StyledSearchBarContainer = styled(Box)`
  margin: -10px -4px -5px -4px;
`;

export const DialogContentStyled = styled(DialogContent)`
  margin-top: -10px;
  padding-bottom: 0;
`;

export const BoxMarginStyled = styled(Box)`
  margin-top: 24px;
`;

export const StyledBackBox = styled(Box)`
  margin: 10px 0 -20px 0;
`;

export const GetStartedTypogStyled = styled(Typography)`
  margin-bottom: 12px;
`;

export const DialogActionsStyled = styled(DialogActions)`
  margin-top: -45px;
`;

export const StyledCategoryBox = styled(Box)`
  display: flex;
  align-items: center;
  border: 1px solid ${gray4};
  border-radius: 4px;
  padding: 8px;
  width: 100%;
  max-height: 90px;
  flex-wrap: wrap;
  overflow-y: scroll;
  justify-content: space-between;
`;
