import { gray7, green2, red2, yellow2 } from '../../common/constants';
import { SortDirection } from '../../common/enums';
import { convertToPercent, sorter } from '../../common/helpers';
import {
  workloadTopLineCopy,
  workloadBottomCopy,
  WorkloadLevels
} from '../constants/workload';
import {
  IBarChartCopyLayout,
  IReportModalListItem,
  IWorkloadChartData,
  IWorkloadLevelDto,
  IWorkloadLevels,
  IWorkloadLevelsDto,
  IWorkloadLevelsUserDto,
  IWorkloadUsersStatus,
  WorkloadLabel,
  WorkloadLevel,
  WorkloadLevelsLabel
} from '../models';
import { formatDateByTimezone } from '../../common/utils/datetime/datetimeFormatters';
import { getEndOfWeekByTimezone } from '../../common/utils/datetime/datetimeCalculations';

export const getWorkloadByWeekData = (
  trends: IWorkloadLevelDto[]
): IWorkloadChartData => {
  const healthy = [];
  const overutilized = [];
  const underutilized = [];
  const labels = [];
  const weeklyUsers = [];

  trends?.forEach((item) => {
    const weekStart = formatDateByTimezone(item.date, { excludeYear: true });
    const weekEnd = getEndOfWeekByTimezone(item.date, { excludeYear: true });
    labels.push(weekStart);

    if (item.users?.length) {
      weeklyUsers.push({
        weekOf: `Week of ${weekStart} - ${weekEnd}`,
        users: mapWorkloadUsers(item.users)
      });
    }

    //get the initial percentages
    let convertedHealthy =
      item.total > 0 ? convertToPercent(item.healthy, item.total) : 0;

    let convertedOverutilized =
      item.total > 0 ? convertToPercent(item.overutilized, item.total) : 0;

    let convertedUnderutilized =
      item.total > 0 ? convertToPercent(item.underutilized, item.total) : 0;

    //total all the values in the stacked bar
    const total =
      convertedHealthy + convertedOverutilized + convertedUnderutilized;
    if (total) {
      const diff = 100 - total;
      //if total !== 100 (diff !== 0), update largest value in the set with the diff
      if (diff !== 0) {
        //put entire bar's values into one array and sort to find the largest value
        const tmpBarTotal = [
          { type: WorkloadLevel.Healthy, value: convertedHealthy },
          {
            type: WorkloadLevel.OverUtilized,
            value: convertedOverutilized
          },
          {
            type: WorkloadLevel.UnderUtilized,
            value: convertedUnderutilized
          }
        ];
        const sorted = sorter(tmpBarTotal, SortDirection.Descending, 'value');
        const largestValue = sorted[0];

        if (largestValue.type === WorkloadLevel.OverUtilized)
          convertedOverutilized += diff;
        else if (largestValue.type === WorkloadLevel.UnderUtilized)
          convertedUnderutilized += diff;
        else convertedHealthy += diff;
      }
    }
    //push the final values
    healthy.push(convertedHealthy);
    underutilized.push(convertedUnderutilized);
    overutilized.push(convertedOverutilized);
  });

  return {
    labels: labels,
    datasets: [
      {
        label: WorkloadLevelsLabel.UnderUtilized,
        data: underutilized,
        backgroundColor: yellow2,
        hoverBackgroundColor: yellow2,
        checkmarkColor: gray7
      },
      {
        label: WorkloadLevelsLabel.Healthy,
        data: healthy,
        backgroundColor: green2,
        hoverBackgroundColor: green2,
        checkmarkColor: gray7
      },
      {
        label: WorkloadLevelsLabel.OverUtilized,
        data: overutilized,
        backgroundColor: red2,
        hoverBackgroundColor: red2
      }
    ],
    weeklyUsers
  };
};

const getWorkloadStatus = (
  data: Omit<IWorkloadLevelDto, 'users'>
): WorkloadLabel => {
  if (convertToPercent(data.healthy, data.total) >= 70) {
    return WorkloadLabel.Optimal;
  }

  if (convertToPercent(data.overutilized, data.total) >= 50) {
    return WorkloadLabel.High;
  }

  if (convertToPercent(data.underutilized, data.total) > 50) {
    return WorkloadLabel.Low;
  }

  return WorkloadLabel.Varied;
};

const getLevelStatus = (data: IWorkloadLevelsDto): IWorkloadUsersStatus => {
  const lastHealthy = data?.trends?.reduce(
    (final, percent) => final + percent.healthy,
    0
  );
  const lastUnderutilized = data?.trends?.reduce(
    (final, percent) => final + percent.underutilized,
    0
  );
  const lastOverutilized = data?.trends?.reduce(
    (final, percent) => final + percent.overutilized,
    0
  );
  const lastTotal = data?.trends?.reduce(
    (final, percent) => final + percent.total,
    0
  );

  const allLastPercents = {
    healthy: lastHealthy,
    underutilized: lastUnderutilized,
    overutilized: lastOverutilized,
    total: lastTotal
  };

  return {
    last30Days: getWorkloadStatus(allLastPercents),
    previous30Days: getWorkloadStatus(data.previous)
  };
};

export const mapToWorkloadByWeek = (
  data: IWorkloadLevelsDto
): IWorkloadLevels => {
  if (data) {
    return {
      chartData: getWorkloadByWeekData(data?.trends),
      previous: convertToPercent(data.previous?.healthy, data.previous?.total),
      lastUpdated: data.lastUpdated,
      status: getLevelStatus(data)
    };
  }
};

export const mapWorkloadUsers = (
  users: IWorkloadLevelsUserDto[]
): IReportModalListItem[] => {
  const mapped = users.map((user) => {
    return {
      name: user.name,
      value: WorkloadLevels[user.status]
    };
  });

  return sorter(mapped, SortDirection.Descending, 'value');
};

export const getWorkloadBalanceCopy = (
  last: WorkloadLabel,
  previous: WorkloadLabel
): IBarChartCopyLayout => {
  if (previous === WorkloadLabel.Optimal) {
    switch (last) {
      case WorkloadLabel.Optimal:
        return {
          top: workloadTopLineCopy.great,
          bottom: workloadBottomCopy.great
        };
      case WorkloadLabel.High:
        return {
          top: workloadTopLineCopy.isUnhealthy,
          bottom: workloadBottomCopy.overUtilized
        };
      case WorkloadLabel.Low:
        return {
          top: workloadTopLineCopy.isUnhealthy,
          bottom: workloadBottomCopy.underUtilized
        };
      case WorkloadLabel.Varied:
        return {
          top: workloadTopLineCopy.isVaried,
          bottom: `${workloadBottomCopy.previousOptimal} ${workloadBottomCopy.overAndUnderUtilized}`
        };
    }
  } else {
    switch (last) {
      case WorkloadLabel.Optimal:
        return {
          top: workloadTopLineCopy.great,
          bottom: workloadBottomCopy.healthy
        };
      case WorkloadLabel.High:
        return {
          top: workloadTopLineCopy.remainUnhealthy,
          bottom: workloadBottomCopy.continueOverUtilized
        };
      case WorkloadLabel.Low:
        return {
          top: workloadTopLineCopy.remainUnhealthy,
          bottom: workloadBottomCopy.continueUnderUtilized
        };
      case WorkloadLabel.Varied:
        return {
          top: workloadTopLineCopy.remainVaried,
          bottom: workloadBottomCopy.overAndUnderUtilized
        };
    }
  }
};

const workloadMsg = {
  [WorkloadLabel.Optimal]: 'All users have a healthy utilization level.',
  [WorkloadLabel.High]:
    'More than half of your team is overutilized. Look for opportunities to reduce their workload as burnout is detrimental to both the individual and the business.',
  [WorkloadLabel.Low]:
    'More than half of your team is underutilized. Look for opportunities to increase their engagement.',
  [WorkloadLabel.Varied]:
    'Team workload is varied. Look for opportunities to reallocate workload between team members.'
};

export const getWorkloadSubtitleCopy = (last: WorkloadLabel) => {
  return workloadMsg[last] || '';
};
