import { IRealtimeSettingsStore } from '../models/IRealtimeSettingsStore';
import {
  getRealtimeSettingsDtoCache,
  IRealtimeSettingsDtoCache
} from '../caches/realtimeSettingsDtoCache';
import { REALTIME_SETTINGS_STORE_DEFAULT_STATE } from '../stores/realtimeSettingsStore';

const mapRealtimeSettingsDtoStoreToRealtimeSettingsStore = (
  realtimeSettingsDtoStore: IRealtimeSettingsDtoCache
): IRealtimeSettingsStore => {
  const {
    realtimePollDelay,
    realtimePollAfterError,
    thumbnailPollDelay,
    messageDelayThreshold,
    messageTimeoutThreshold,
    lastSeenThreshold
  } = realtimeSettingsDtoStore.realtimeSettings;

  return {
    realtimePollDelay: realtimePollDelay,
    realtimePollAfterError: realtimePollAfterError,
    thumbnailPollDelay: thumbnailPollDelay,
    messageDelayThreshold: messageDelayThreshold,
    messageTimeoutThreshold: messageTimeoutThreshold,
    lastSeenThreshold: lastSeenThreshold
  };
};

export const fetchSettings = async (): Promise<IRealtimeSettingsStore> => {
  try {
    const response = await getRealtimeSettingsDtoCache();
    return mapRealtimeSettingsDtoStoreToRealtimeSettingsStore(response);
  } catch (error) {
    console.error(
      'ActivTrak Error: Error fetching screenshot settings.',
      error
    );
    return REALTIME_SETTINGS_STORE_DEFAULT_STATE;
  }
};
