import React, { useState, useEffect } from 'react';
import {
  localStorageItems,
  IGNORE_CATEGORY_ID,
  primaryColor
} from '../../common/constants';
import { getLocalStorageItem } from '../../common/helpers';
import {
  ICategory,
  IClassificationActivity,
  IClassificationGroup,
  IGroup
} from '../../common/models';
import { ClassificationType } from '../../common/enums';
import { createFilterOptions, FilterOptionsState } from '@mui/material';
import { IClassificationFilter } from '../models';
import { profileStore } from '../../common/services/Profile/useProfileState';

export const furtherSearchSuggestion = (
  inputValue: string,
  className?: object
) => {
  return (
    <div style={className}>
      Search &ldquo;{inputValue}&rdquo; across all activities{' '}
      <span style={{ color: primaryColor, opacity: 0.6, float: 'right' }}>
        Press Enter
      </span>
    </div>
  );
};

export const bulkClassificationFilters: {
  allActivities: IClassificationFilter;
  pendingProductivity: IClassificationFilter;
  pendingCategorization: IClassificationFilter;
  autoClassification: IClassificationFilter;
  groupClassified: IClassificationFilter;
  ignored: IClassificationFilter;
} = {
  allActivities: {
    label: ClassificationType.All,
    template: () => ClassificationType.All,
    filter: 'all',
    filterValue: ''
  },
  pendingProductivity: {
    label: ClassificationType.PendingProductivity,
    template: () => ClassificationType.PendingProductivity,
    filter: 'productivity',
    filterValue: 0
  },
  pendingCategorization: {
    label: ClassificationType.PendingCategorization,
    template: () => ClassificationType.PendingCategorization,
    filter: 'categoryId',
    filterValue: null
  },
  autoClassification: {
    label: ClassificationType.AutoClassified,
    template: () => ClassificationType.AutoClassified,
    filter: 'status',
    filterValue: 'AutoClassified'
  },
  groupClassified: {
    label: ClassificationType.GroupClassified,
    template: () => ClassificationType.GroupClassified,
    filter: 'groupOverride',
    filterValue: true
  },
  ignored: {
    label: ClassificationType.Ignored,
    template: () => ClassificationType.Ignored,
    filter: 'categoryId',
    filterValue: IGNORE_CATEGORY_ID
  }
};

export const filterOptions = (
  options: IClassificationActivity[],
  params: FilterOptionsState<string>,
  className?: object
) => {
  const optionNames = options.map((x) => (x.name ? x.name : ''));
  const filter = createFilterOptions<any>();
  const filtered = filter(optionNames, params);
  const { inputValue } = params;

  // Suggest searching all activities with this term
  const isExisting = options.some((option) => inputValue === option?.name);
  if (inputValue !== '' && !isExisting) {
    filtered.push({
      inputValue: inputValue,
      name: furtherSearchSuggestion(inputValue, className)
    });
  }
  return filtered;
};

export const ignoreCategoryItem = {
  appCount: 0,
  websiteCount: 0,
  name: 'Ignore',
  id: IGNORE_CATEGORY_ID,
  selected: false,
  disabled: true
};

export const withIgnoreCategory = (categories: ICategory[]) => {
  if (!categories.some((c) => c.id === IGNORE_CATEGORY_ID)) {
    categories.push(ignoreCategoryItem);
  }
  return categories;
};

//This method will filter the IGroupDto[] by selecting only the groupIDs
//NOT IN the IClassificationGroup[] AND the one in the IClassificationGroup
//It will order alphabetically
export const filterGroupsByGroupClassifications = (
  classificationGroups: IClassificationGroup[],
  groups: IGroup[],
  classificationGroup: IClassificationGroup
): IGroup[] => {
  const selectedGroup = groups?.filter(
    (x) => x.id === classificationGroup.groupId
  );

  const usedGroupIds = classificationGroups.map((x) => x.groupId);

  //Pull out groups with groupIds not present in usedGroupIds
  const unusedGroups = groups?.filter((x) => usedGroupIds.indexOf(x.id) === -1);

  //Group dropdowns should contain their own selected group
  //and any other groups that are not being used. Order alphabetically
  const availableGroups = Array.from(unusedGroups.concat(selectedGroup)).sort(
    (x, y) => x.name.localeCompare(y.name)
  );

  return availableGroups;
};

export const useAllActivitiesDialog = () => {
  const [hideAllActivitiesDialog, setHideAllActivitiesDialog] =
    useState<boolean>(false);
  const { username } = profileStore.getState().profile;

  const localStorage_hideAllActivities = getLocalStorageItem(
    localStorageItems.allActivitiesModal + username
  );

  const currentValue: boolean = localStorage_hideAllActivities === 'true';

  useEffect(() => {
    setHideAllActivitiesDialog(currentValue);
  }, [currentValue]);

  return hideAllActivitiesDialog;
};
