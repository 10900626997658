import { generateParameters } from '../../../../../_reactivtrak/src/common/components/ReportFilters/utils/generateParameters';
import template from 'views/widgets/dashboard/teamPulseWidget.html?raw';
import lodash from 'lodash';
import { inMemoryStore } from '../../../../../_ui-utils/src';
import { getReportFilters } from '../../../../../_reactivtrak/src/common/components/ReportFilters/hooks/reportFiltersStore';
import {
    getAccountSettings,
    accountSettingsStore
} from '../../../../../_reactivtrak/src/common/helpers/accountSettings/accountSettingsStore';
import { profileStore } from '../../../../../_reactivtrak/src/common/services/Profile/useProfileState';

angular.module('app').directive('teamPulseWidget', function () {
    return {
        restrict: 'E',
        template,
        scope: {},
        controller: TeamPulseWidgetCtrl
    };
});

TeamPulseWidgetCtrl.$inject = [
    '$scope',
    '$rootScope',
    'atDashboardWidgetService',
    'envConfig',
    'authorizationService',
    'localStorageService'
];

function TeamPulseWidgetCtrl(
    $scope,
    $rootScope,
    atDashboardWidgetService,
    envConfig,
    authorizationService,
    localStorageService
) {
    const teamPulseDataControl = inMemoryStore.get('teamPulseDataControl');
    teamPulseDataControl.initialize(localStorageService);

    // TODO: remove timeFormat from hear and add to dateSettingsStore
    const { viewableGroupsCount, timeFormat } = getAccountSettings();
    const { username } = profileStore.getState().profile;

    let destroying = false;

    const widgetId = 'teamPulseWidget';
    $scope.hasTeamPulseGroups = authorizationService.hasFeature('isTeamPulseEnabled');
    let user = {};

    // Set report mode
    if (!$scope.hasTeamPulseGroups) {
        teamPulseDataControl.reportMode = teamPulseDataControl.reportModes.users;
    } else if (!teamPulseDataControl.reportMode.value) {
        if (viewableGroupsCount) {
            const groupCount =
                user.filterMode === 'users'
                    ? viewableGroupsCount.viewableUserGroupsCount
                    : viewableGroupsCount.viewableComputerGroupsCount;
            teamPulseDataControl.reportMode =
                groupCount > 2 ? teamPulseDataControl.reportModes.groups : teamPulseDataControl.reportModes.users;
        } else {
            teamPulseDataControl.reportMode = teamPulseDataControl.reportModes.users;
        }

        teamPulseDataControl.reportMode = teamPulseDataControl.reportMode.value;
    }

    // Set initial value based on local storage or default
    $scope.reportMode =
        localStorageService.get('teamPulseReportMode' + username) || teamPulseDataControl.reportMode.value;
    teamPulseDataControl.reportMode.next($scope.reportMode);

    // Set initial value based on local storage or default
    const timeMode = localStorageService.get('teamPulseTimeMode' + username) || teamPulseDataControl.timeMode.value;
    teamPulseDataControl.timeMode.next(timeMode);

    // Set initial value based on local storage or default
    $scope.selectedFilter =
        localStorageService.get('teamPulseFilter-' + $scope.reportMode + '-' + username) ||
        teamPulseDataControl.filter.value;
    teamPulseDataControl.filter.next($scope.selectedFilter);
    $scope.selectedFilter = teamPulseDataControl.filter.value;

    // Set initial value based on local storage or default
    $scope.selectedSort =
        localStorageService.get('teamPulseSort-' + $scope.reportMode + '-' + username) ||
        teamPulseDataControl.sort.value;
    teamPulseDataControl.sort.next($scope.selectedSort);

    $scope.teamPulseOptions = {
        isReport: false,
        containerHeight: 320
    };

    teamPulseDataControl.isReport = $scope.teamPulseOptions.isReport;
    teamPulseDataControl.timeFormat = timeFormat;
    teamPulseDataControl.containerHeight = $scope.teamPulseOptions.containerHeight;
    teamPulseDataControl.isViewer = authorizationService.hasRole([authorizationService.roles.viewer]);

    function handleSortSelection(selection) {
        teamPulseDataControl.sort = selection.text;
    }

    function setSortButtonOptions() {
        const availableSelections = [
            {
                heapId: 'id_130f4d92-d05c-40a8-bad0-e35d80cb7196',
                text: 'highestProductivity',
                value: 'Productivity %',
                type: 'all'
            },
            {
                heapId: 'id_0bc8c933-036e-4ec0-823b-46fd4f3cc145',
                text: 'username',
                value: 'Username',
                type: 'Users',
                alternative: 'groupName'
            },
            {
                heapId: 'id_eb4473ac-d3ff-4ab3-a2cc-0d3eca3b01d9',
                text: 'firstSeen',
                value: 'First Seen',
                type: 'Users',
                alternative: 'highestProductivity'
            },
            {
                heapId: 'id_c7a09c40-75f4-4b02-ad0d-dea6685cb452',
                text: 'lastSeen',
                value: 'Last Seen',
                type: 'Users',
                alternative: 'highestProductivity'
            },
            {
                heapId: 'id_5f8442a1-18ab-4023-a95c-fdb56eabda5b',
                text: 'averageProductiveHours',
                value: 'Average Time',
                type: 'Groups',
                alternative: 'productiveHours'
            },
            {
                heapId: 'id_64ec991b-be77-4de1-9e65-fda46cbe4d44',
                text: 'groupName',
                value: 'Group Name',
                type: 'Groups',
                alternative: 'username'
            }
        ];

        const selectionList = availableSelections.filter(function (selection) {
            return selection.type === 'all' || selection.type === teamPulseDataControl.reportMode.value;
        });

        // Change sort if no longer valid selection
        const selectedSort = lodash.find(availableSelections, function (item) {
            return item.text === teamPulseDataControl.sort.value;
        });
        if (
            selectedSort &&
            selectedSort.type !== 'all' &&
            selectedSort.type !== teamPulseDataControl.reportMode.value
        ) {
            teamPulseDataControl.sort = selectedSort.alternative;
        }

        teamPulseDataControl.sort = teamPulseDataControl.sort.value;

        $scope.selectedSort = teamPulseDataControl.sort.value;
        $scope.sortButtonOptions = {
            type: 'sort',
            dynamicLabel: true,
            heapId: 'id_188a324c-da80-4863-a91b-feb0c2278c1e',
            selectionList: selectionList,
            onChange: handleSortSelection
        };
    }
    setSortButtonOptions();

    function handleFilterSelection(selection) {
        teamPulseDataControl.filter = selection.text;
    }

    function setFilterButtonOptions() {
        const availableSelections = [
            {
                heapId: 'id_02943f2c-e867-4f9d-aac6-ee77c3b97653',
                text: 'all',
                value: 'All',
                type: 'all'
            },
            {
                heapId: 'id_71fbfbf3-dbd6-49ab-b68a-db5ed429d6f5',
                text: 'activeNow',
                value: 'Active Now',
                type: 'Users',
                alternative: 'activeToday'
            },
            {
                heapId: 'id_79b77219-aaef-49ee-bd2b-d8b072d45dad',
                text: 'activeToday',
                value: 'Active Today',
                type: 'all'
            },
            {
                heapId: 'id_386b30bd-b0d5-4625-ae2e-fa5f70eeca93',
                text: 'passiveNow',
                value: 'Passive Now',
                type: 'Users',
                alternative: 'activeToday'
            },
            {
                heapId: 'id_c460909d-a71b-46b9-9ae3-fea7b6348932',
                text: 'inactiveNow',
                value: 'Inactive Now',
                type: 'Users',
                alternative: 'inactiveToday'
            },
            {
                heapId: 'id_58478017-ec63-41e5-a43c-c99585fb04b5',
                text: 'inactiveToday',
                value: 'Inactive Today',
                type: 'all'
            }
        ];

        const selectionList = availableSelections.filter(function (selection) {
            return selection.type === 'all' || selection.type === teamPulseDataControl.reportMode.value;
        });

        // Change filter if no longer valid selection
        const selectedFilter = lodash.find(availableSelections, function (item) {
            return item.text === teamPulseDataControl.filter.value;
        });
        if (
            selectedFilter &&
            selectedFilter.type !== 'all' &&
            selectedFilter.type !== teamPulseDataControl.reportMode.value
        ) {
            teamPulseDataControl.filter = selectedFilter.alternative;
        }

        teamPulseDataControl.filter = teamPulseDataControl.filter.value;

        $scope.selectedFilter = teamPulseDataControl.filter.value;

        $scope.filterButtonOptions = {
            type: 'filter',
            dynamicLabel: true,
            heapId: 'id_9efb0368-8d5b-41cf-98b8-ba17c23baa59',
            selectionList: selectionList,
            onChange: handleFilterSelection
        };
    }
    setFilterButtonOptions();

    function setToggleOptions() {
        $scope.toggleOptions = {
            labels: [
                {
                    heapId: 'id_aa8b23aa-5e7f-4cda-85e9-d1952cae6319',
                    text: teamPulseDataControl.reportModes.users,
                    value: user.filterMode === 'users' ? 'Users' : 'Computers'
                },
                {
                    heapId: 'id_464fc119-a087-4c9b-a1a7-c103c7b8b54a',
                    text: teamPulseDataControl.reportModes.groups,
                    value: 'Groups'
                }
            ],
            onChange: function (val) {
                teamPulseDataControl.reportMode = val;
                setSortButtonOptions();
                setFilterButtonOptions();
                setSettingsOptions();
            }
        };
    }

    function handleSettingsSelection(selection) {
        switch (selection.text) {
            case 'showActiveTime':
                teamPulseDataControl.timeMode = teamPulseDataControl.timeModes.active;
                setSettingsOptions();
                setSortButtonOptions();
                break;
            case 'showTotalTime':
                teamPulseDataControl.timeMode = teamPulseDataControl.timeModes.total;
                setSettingsOptions();
                setSortButtonOptions();
                break;
            case 'hideEmptyGroups':
                teamPulseDataControl.hideEmptyGroups = !teamPulseDataControl.hideEmptyGroups.value;
                setSettingsOptions();
                break;
        }
    }

    function setSettingsOptions() {
        $scope.settingsOptions = {
            type: 'settings',
            heapId: 'id_65808f35-ce04-4cc6-ab34-cfacaf162e67',
            selectionList: [],
            onChange: handleSettingsSelection
        };

        $scope.settingsOptions.selectionList.push(
            teamPulseDataControl.timeMode.value === teamPulseDataControl.timeModes.total
                ? {
                      heapId: 'id_9c03d093-558c-40b7-888a-516f8172f177',
                      text: 'showActiveTime',
                      value: 'Exclude Passive Time'
                  }
                : {
                      heapId: 'id_9c03d093-558c-40b7-888a-516f8172f177',
                      text: 'showTotalTime',
                      value: 'Include Passive Time'
                  }
        );

        if (teamPulseDataControl.reportMode.value === 'Groups') {
            $scope.settingsOptions.selectionList.push(
                teamPulseDataControl.hideEmptyGroups.value
                    ? {
                          heapId: 'id_0f5e95d9-25b0-4275-a0a1-4e34a9cb98da',
                          text: 'hideEmptyGroups',
                          value: 'Show Empty Groups'
                      }
                    : {
                          heapId: 'id_0f5e95d9-25b0-4275-a0a1-4e34a9cb98da',
                          text: 'hideEmptyGroups',
                          value: 'Hide Empty Groups'
                      }
            );
        }
    }
    setSettingsOptions();

    const reportingUrl = envConfig.reportingServiceUrl();

    const bindWidget = function () {
        if (!destroying) {
            const reportFilters = getReportFilters();
            const { users } = reportFilters;
            user = users.length ? users[0] : null;
            teamPulseDataControl.filterMode = user.filterMode;
            teamPulseDataControl.apiConfig = {
                url: envConfig.apiUrl(),
                reportingUrl: reportingUrl,
                websocketHttpsUrl: envConfig.websocketHttpsUrl(),
                isWidget: true,
                parameters: generateParameters(reportFilters, {
                    rangeOverride: 'Today'
                })
            };
            teamPulseDataControl.refreshData = true;
            setToggleOptions();
        }
    };

    const accountSettingsUnsubscribe = accountSettingsStore.subscribe(() => {
        setSettingsOptions();
        setSettingsOptions();
    });

    $scope.$on('$destroy', function () {
        accountSettingsUnsubscribe();
        destroying = true;
    });

    atDashboardWidgetService.registerWidget(widgetId, 1, bindWidget);
}
