import React from 'react';
import { DownloadProvider } from '../../../common/services';
import { WorkingHoursReportNewGridHeader } from '../components/WorkingHoursReportNewGridHeader';
import ScheduledTimelineFilter from '../components/ScheduledTimelineFilter';
import { ReactivTrakComponentWrapper } from '../../../ReactivTrakComponentWrapper';
import { ScheduleAdherenceGrid } from '../components/ScheduleAdherenceGrid';
import { ScheduleAdherenceColumnSelector } from '../components/ScheduleAdherenceColumnSelector';
import { ScheduleAdherenceExport } from '../components/ScheduleAdherenceExport';
import { ScheduleAdherenceViewToggle } from '../components/ScheduleAdherenceViewToggle';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

export const ScheduleAdherenceReportView = () => {
  return (
    <>
      <WorkingHoursReportNewGridHeader />
      <Stack
        direction="row"
        alignItems="center"
        sx={{ mt: 4, mb: 2 }}
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <ScheduleAdherenceViewToggle />
          <ScheduledTimelineFilter />
        </Box>
        <Box display="flex" alignItems="center">
          <Box mr={1}>
            <ScheduleAdherenceColumnSelector />
          </Box>
          <ScheduleAdherenceExport />
        </Box>
      </Stack>
      <ScheduleAdherenceGrid />
    </>
  );
};

export const ScheduleAdherenceReportViewComponent = () => (
  <ReactivTrakComponentWrapper>
    <DownloadProvider>
      <ScheduleAdherenceReportView />
    </DownloadProvider>
  </ReactivTrakComponentWrapper>
);
