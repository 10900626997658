import { DurationInputArg2 } from 'moment';
import { getDateSettingsStore } from '../../stores/dateSettingsStore';
import moment from 'moment';
import {
  formatDateByTimezone,
  formatDate,
  formatDateTime
} from './datetimeFormatters';
import { IFormatDateOptions } from './models/IFormatDateTimeOptions';
import { FUTURE_BUFFER_MS } from './constants/futureDateBuffer';

/**
 * Returns the current date in the account's timezone, adjusted by the specified amount and units,
 * formatted according to the account's date/time format.
 */
export const getFutureDateTime = (
  amount: number,
  unit: DurationInputArg2
): string => {
  const { timezoneKey } = getDateSettingsStore();
  return formatDateTime(moment.tz(timezoneKey).add(amount, unit));
};

/**
 * Returns the current date in the account's timezone, adjusted by the specified amount and units,
 * formatted according to the account's date/time format, excluding seconds.
 */
export const getFutureDateTimeRemoveSeconds = (
  amount: number,
  unit: DurationInputArg2
): string => {
  const { timezoneKey } = getDateSettingsStore();
  return formatDateTime(moment.tz(timezoneKey).add(amount, unit), {
    excludeSeconds: true
  });
};

/**
 * Returns the end of the week string based on the provided date string.
 */
export const getEndOfWeek = (date: string) => {
  const endOfWeek = moment(date).endOf('week');
  return formatDate(endOfWeek);
};

/**
 * Returns the end of the week string in the account's timezone.
 */
export const getEndOfWeekByTimezone = (
  utcDate: string,
  options: IFormatDateOptions
) => {
  const endOfWeek = moment.utc(utcDate).endOf('week');
  return formatDateByTimezone(endOfWeek.format(), options);
};

/**
 * Returns the amount of time that has lapsed as a formatted string between a from and to date range.
 */
export const getTimeLapsedString = (
  fromDate: moment.Moment,
  toDate: moment.Moment
): string | null => {
  if (!fromDate?.isValid() || !toDate?.isValid()) {
    return null;
  }

  const nowDiff = toDate.diff(fromDate);

  if (nowDiff < FUTURE_BUFFER_MS) {
    return 'Future';
  }

  if (nowDiff >= FUTURE_BUFFER_MS && nowDiff < 0) {
    return 'Now';
  }

  const timeLapseString = fromDate.from(toDate);
  if (timeLapseString.startsWith('a few seconds')) {
    return `1 second ago`;
  } else if (timeLapseString.startsWith('an')) {
    return `1${timeLapseString.substring(2)}`;
  } else if (timeLapseString.startsWith('a')) {
    return `1${timeLapseString.substring(1)}`;
  }

  return timeLapseString;
};

/**
 * Returns the amount of time that has lapsed as a formatted string between a from UTC date and now in UTC time.
 */
export const getTimeLapsedSinceNowString = (utcDate: string): string => {
  if (utcDate) {
    const momentDate = moment.utc(utcDate);
    const nowDate = moment.utc();
    return getTimeLapsedString(momentDate, nowDate);
  }
  return null;
};

/**
 * Returns the amount of time that has lapsed as a formatted string between a from date and now in the account's timezone.
 */
export const getTimeLapsedSinceNowStringByTimezone = (date: string): string => {
  if (date) {
    const { timezoneKey } = getDateSettingsStore();
    const momentDate = moment.tz(date, timezoneKey);
    const nowDate = moment.tz(timezoneKey);
    return getTimeLapsedString(momentDate, nowDate);
  }
  return null;
};
