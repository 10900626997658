import { getAccountSettings } from '../../../../_reactivtrak/src/common/helpers/accountSettings/accountSettingsStore';
import { setReportFilters } from '../../../../_reactivtrak/src/common/components/ReportFilters/hooks/reportFiltersStore.ts';
import { getFormattedFromTimestamp } from '../../../../_reactivtrak/src/common/components/ReportFilters/utils/dateRange.utils.ts';
import * as privacySettingsStore from '../../../../_reactivtrak/src/common/hooks/privacySettingsStore';
import { generateParameters } from '../../../../_reactivtrak/src/common/components/ReportFilters/utils/generateParameters';
import gridColumnSelectionModalTemplate from '../../../views/widgets/gridColumnSelectorModal.html?raw';

import { unescape } from 'lodash';
import { gridServiceFunctions } from '../../../../_app/serviceFunctions/gridServiceFunctions';
import * as productivityService from '../../../../_app/productivity/productivityService';
import productivityStatus from '../../../../_app/constants/productivityStatus';

// Register report component
import './activitylog.component.js';
import { setExportParamsStore } from '../../../../_reactivtrak/src/common/stores/exportParamsStore.ts';

angular.module('app').controller('ActivityLogCtrl', ActivityLogCtrl);

ActivityLogCtrl.$inject = [
    '$scope',
    '$state',
    '$timeout',
    '$compile',
    'messagesService',
    'activityLogService',
    '$rootScope',
    'customUibModal',
    'pageSizeService',
    'localStorageService',
    'exportInfoService',
    'gridPropertiesService',
    'atHelperFunctions',
    'templateServiceFunctions',
    'authorizationService'
];

function ActivityLogCtrl(
    $scope,
    $state,
    $timeout,
    $compile,
    msg,
    activityLogService,
    $rootScope,
    customUibModal,
    pageSizeService,
    localStorageService,
    exportInfoService,
    gridPropertiesService,
    atHelperFunctions,
    templateServiceFunctions,
    authorizationService
) {
    const accountSettings = getAccountSettings();

    //TODO: More elegant, maybe?
    const { activityTimeSettings } = $scope.$parent;

    // Hight of the header, used to calculate loader height
    const headerHeight = 248;
    $scope.loaderHeight = atHelperFunctions.getGridHeight(headerHeight);

    // Shared Scope
    $scope.helpers = atHelperFunctions;
    $scope.isLoading = true;
    $scope.usedLicenses = accountSettings.usedLicenses;

    const gridName = 'activity-log-grid';

    const { getPrivacySettings } = privacySettingsStore;

    const { screenshotsAllowed = false, activityAllowed = false, alarmActionsAllowed = false } = getPrivacySettings();
    const isSupportAdvanced = authorizationService.hasRole('supportadvanced');

    const noRecordsTemplate = kendo.template(
        '<div ng-show="!isLoading && !hasData" class="col-md-12 center-block m-t-20">' +
            '<strong>No Results</strong><br />' +
            '<span>Data was not available to generate this report.</span><br />' +
            '<div class="hidden-xs hidden-sm" ng-hide="usedLicenses">' +
            '<a ng-click="download()">Download the Agent</a> and install on a target computer to begin collecting data.' +
            '</div>' +
            '</div>'
    );

    $scope.download = $rootScope.download;
    $scope.filter = {};
    $scope.hasData = false;
    $scope.isSmallWindow = window.innerWidth < 992;
    $scope.activityLogService = activityLogService;
    $scope.isIgnoredSystem = localStorageService.get('isSystemUserIgnored-' + accountSettings.username) || false;
    let cacheKey;

    $scope.canEdit = authorizationService.hasAuthorizationLevel('edit', 'app.reports.activitylog');

    $scope.convertLogToScreenshot = function (logEntry) {
        if (!logEntry.ssUrl) {
            return null;
        }

        const time = logEntry.time_utc ? atHelperFunctions.convertTimezone(logEntry.time_utc) : logEntry.time;

        return {
            fullsize: logEntry.ssUrl,
            time: atHelperFunctions.formatDate(time, {
                span: false
            }),
            user: logEntry.user,
            computer: logEntry.computer,
            titlebar: logEntry.titleBar,
            url: logEntry.url,
            description: logEntry.description,
            executable: logEntry.executable,
            prod: logEntry.prod,
            appId: logEntry.appId,
            webId: logEntry.webId,
            isSafe: logEntry.isSafe,
            safeSearchObject: logEntry.safeSearchObject
        };
    };

    $(window).on('resize.doResize', function () {
        $scope.$evalAsync(function () {
            $scope.isSmallWindow = window.innerWidth < 992;
            $scope.loaderHeight = atHelperFunctions.getGridHeight(headerHeight);
        });
    });

    $scope.$on('$destroy', function () {
        $(window).off('resize.doResize');
    });

    // Set filter based on state params
    const filter = [];
    if ($state.params.user) {
        filter.push({
            field: 'user',
            operator: 'contains',
            value: unescape(decodeURIComponent($state.params.user))
        });
    }
    if ($state.params.computer) {
        filter.push({
            field: 'computer',
            operator: 'contains',
            value: unescape(decodeURIComponent($state.params.computer))
        });
    }

    if ($state.params.description) {
        filter.push({
            field: 'description',
            operator: 'contains',
            value: unescape(decodeURIComponent($state.params.description))
        });
    }
    if ($state.params.site) {
        filter.push({
            field: 'url',
            operator: 'contains',
            value: unescape(decodeURIComponent($state.params.site))
        });
    }
    if ($state.params.title) {
        filter.push({
            field: 'titleBar',
            operator: 'contains',
            value: unescape(decodeURIComponent($state.params.title))
        });
    }
    if ($state.params.exec) {
        filter.push({
            field: 'executable',
            operator: 'contains',
            value: unescape(decodeURIComponent($state.params.exec))
        });
    }

    function isPassive(item) {
        return productivityStatus.isPassive(item.prod);
    }

    function isSystemEvent(item) {
        return item && item.executable && item.executable.toLowerCase() === 'system event';
    }

    function isSystemUser(item) {
        return item && item.user && item.user.toLowerCase() === 'system';
    }

    const activityLogSource = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                const args = $scope.reportFilters ? generateParameters($scope.reportFilters, {}) : {};

                const data = options.data;

                data.titleBar = data.titleBar || $scope.filter.titleBar;
                data.executable = data.executable || $scope.filter.executable;
                data.description = data.description || $scope.filter.description;
                data.url = data.url || $scope.filter.url;

                const formattedTimeStamp = $scope.reportFilters?.fromTimestamp
                    ? getFormattedFromTimestamp($scope.reportFilters.fromTimestamp, accountSettings.currentIanaTimeZone)
                    : null;
                const resetSlider = data.refresh || !data.sort || !data.sort.length || data.sort[0].field !== 'time';
                if (resetSlider) {
                    setReportFilters({ fromTimeStamp: null });
                } else if (formattedTimeStamp) {
                    data.slider = formattedTimeStamp;
                }

                data.excludeSystem = $scope.isIgnoredSystem && $scope.isSmallWindow;

                $scope.isLoading = true;
                activityLogService
                    .getLog(args, {
                        params: data
                    })
                    .success(function (result) {
                        for (let index = 0; index < result.data.length; index++) {
                            result.data[index].index = index;
                        }

                        $rootScope.$broadcast('setGoogleExportParameters', {
                            columns: result.columns,
                            total: result.total,
                            calculateHere: true
                        });

                        cacheKey = result.cacheKey;
                        $scope.screenshotViewerConfig = {
                            dataSource: activityLogSource,
                            images: result.data.map($scope.convertLogToScreenshot),
                            ignoreMovementRestrictions: true
                        };

                        const resultLimit = 10000;
                        if (result.total >= resultLimit) {
                            $scope.topPager.options.messages.display = msg.get('itemsDisplay', 'entries', resultLimit);
                            $scope.topPager.refresh();

                            // Wait a cycle to ensure the tooltip-container is rendered
                            $timeout(function () {
                                const tooltip = angular.element('#tooltip-container');
                                tooltip.attr('i-tooltip', '');
                                tooltip.attr('placement', 'right');
                                tooltip.attr('top-offset', '4px');
                                tooltip.attr(
                                    'template',
                                    `'${msg.get('limitedTooltip', 'Results in table', resultLimit)}'`
                                );
                                const compiledElement = $compile(tooltip)($scope);
                                angular.element('#tooltip-container').replaceWith(compiledElement);
                            });
                        } else {
                            $scope.topPager.options.messages.display = msg.get('itemsDisplay', 'entries', 0);
                            $scope.topPager.refresh();
                        }
                        options.success(result);
                        $scope.isLoading = false;
                        $scope.hasData = result?.data?.length > 0;

                        //pass data state to react wrapper to control slider
                        $rootScope.$broadcast('hasDataCheck', { hasData: result.data?.length > 0 });
                    })
                    .error(function (error) {
                        options.error(error);
                        $scope.isLoading = false;
                        $scope.hasData = false;
                        $scope.$emit('showNotification', {
                            message: 'Error loading Activity Log report data.',
                            color: 'danger'
                        });
                        console.error(`ActivTrak Error: Error loading Activity Log report data.`, error);
                    });
            }
        },
        schema: {
            data: 'data',
            total: 'total',
            pagerTotal: 'sliderCount'
        },
        pageSize: pageSizeService.loadPageSize('activityLog-master', 50),
        serverPaging: true,
        serverSorting: true,
        serverFiltering: true,
        filter: filter,
        sort: {
            field: 'time',
            dir: 'asc'
        },
        entryLimit: 10000
    });
    $scope.activityLogSource = activityLogSource;

    $scope.pagerOptions = {
        autoBind: false,
        dataSource: activityLogSource,
        refresh: true,
        pageSizes: [10, 20, 30, 40, 50, 100, 500, 1000],
        buttonCount: 5,
        messages: {
            itemsPerPage: msg.get('itemsPerPage', 'entries'),
            display: msg.get('itemsDisplay', 'entries', 0),
            empty: msg.get('noItemsToDisplay', 'entries')
        }
    };

    $scope.urlExplanationTemplate = function () {
        const subject = activityAllowed ? 'URL' : 'website';

        return (
            '<div><div style="text-align:left"><p>A ' +
            subject +
            ' is returned when activity is logged from a web </br>browser and a ' +
            subject +
            ' is accessible to the agent.</p></div><div style="width:100%; text-align:right"><span><a class="text-productive" href="https://support.activtrak.com/hc/en-us/articles/23374178820123" target="_blank">LEARN MORE</a></span></div></div>'
        );
    };

    /*
    Custom property 'removeColumn' is for filtering out any columns that should not be rendered.
    This property will be ignored by Kendo.
    */
    const defaultGridColumns = [
        {
            field: 'prod',
            title: msg.get('prod'),
            filterable: {
                cell: {
                    operator: 'eq',
                    template: function (args) {
                        return productivityService.createProductivityColumnDropdown(args);
                    },
                    showOperators: false
                }
            },
            template: kendo.template(function (item) {
                return templateServiceFunctions.productivityTemplate(item);
            }),
            attributes: {
                class: 'text-center productivity'
            },
            width: '50px'
        },
        {
            field: 'screenshot',
            title: msg.get('screenshot'),
            sortable: {
                initialDirection: 'desc'
            },
            filterable: false,
            headerTemplate: '<i class="fa icon-at-screenshot text-master" title="' + msg.get('screenshot') + '"></i>',
            template: kendo.template(function (data) {
                if (data.screenshot > 0) {
                    return isSupportAdvanced
                        ? '<i class="fa ' +
                              (data.screenshotMultiple ? 'icon-at-multiple-screenshots-camera' : 'icon-at-screenshot') +
                              ' text-master not-allowed"></i>'
                        : '<a ng-click="showScreenshot(dataItem)"><i class="fa ' +
                              (data.screenshotMultiple ? 'icon-at-multiple-screenshots-camera' : 'icon-at-screenshot') +
                              ' text-master"></i></a>';
                }
                return '';
            }),
            attributes: {
                class: 'text-center'
            },
            width: '40px',
            removeColumn:
                !screenshotsAllowed ||
                !(
                    authorizationService.hasRoleAccess('Alarms-ScreenshotsReports') ||
                    authorizationService.hasRole([
                        authorizationService.roles.supportAdvanced,
                        authorizationService.roles.superAdmin
                    ])
                )
        },
        {
            field: 'popup',
            title: msg.get('popupAlarm'),
            sortable: false,
            hidden: true,
            filterable: false,
            headerTemplate: '<i class="fa fa-comment-o text-master" title="' + msg.get('popupAlarm') + '"></i>',
            template:
                '#if (popup > 0) {# <i class="fa fa-comment-o text-master" title="' +
                msg.get('popupAlarm') +
                '"></i></a> #}#',
            attributes: {
                class: 'text-center'
            },
            width: '40px',
            removeColumn: !alarmActionsAllowed
        },
        {
            field: 'email',
            title: msg.get('emailAlarm'),
            sortable: false,
            hidden: true,
            filterable: false,
            headerTemplate: '<i class="fa fa-envelope text-master" title="' + msg.get('emailAlarm') + '"></i>',
            template:
                '#if (email > 0) {# <i class="fa fa-envelope text-master" title="' +
                msg.get('emailAlarm') +
                '"></i></a> #}#',
            attributes: {
                class: 'text-center'
            },
            width: '40px'
        },
        {
            field: 'webhook',
            title: msg.get('webhookAlarm'),
            sortable: false,
            hidden: true,
            filterable: false,
            headerTemplate: '<i class="fa fa-link text-master" title="' + msg.get('webhookAlarm') + '"></i>',
            template: '# if (webhook > 0) {# <i class="fa fa-link text-master"></i></a> #}#',
            attributes: {
                class: 'text-center'
            },
            width: '40px'
        },
        {
            field: 'terminate',
            title: msg.get('terminate'),
            sortable: false,
            hidden: true,
            filterable: false,
            headerTemplate: '<i class="fa fa-ban text-master" title="' + msg.get('terminate') + '"></i>',
            template:
                '#if (terminate > 0) {# <i class="fa fa-ban text-master" title="' +
                msg.get('terminate') +
                '"></i></a> #}#',
            attributes: {
                class: 'text-center'
            },
            width: '40px',
            removeColumn: !alarmActionsAllowed
        },
        {
            field: 'time',
            title: 'Account Date/Time',
            headerTemplate: kendo.template(function () {
                const currentTimezoneUTC = moment().tz(accountSettings.currentIanaTimeZone).format('Z');
                return `Account Date/Time (UTC${atHelperFunctions.formatUtcOffset(currentTimezoneUTC)})`;
            }),
            filterable: false,
            template: function (dataItem) {
                if (dataItem.time_utc) {
                    return atHelperFunctions.compactDateTemplate({
                        time: atHelperFunctions.convertTimezone(dataItem.time_utc)
                    });
                }
                return atHelperFunctions.compactDateTemplate(dataItem);
            },
            attributes: {
                class: 'text-nowrap text-center'
            },
            width: '235px'
        },
        {
            field: 'time_from_utc',
            title: 'Activity Date/Time',
            sortable: false,
            filterable: false,
            template: function (dataItem) {
                if (!dataItem.time_utc || !dataItem.time_zone) {
                    return '';
                }

                try {
                    let momentDate = {};
                    let momentUtcOffset = '';
                    let parsedDate = '';
                    const UtcIndex = dataItem.time_zone.indexOf('UTC');
                    if (UtcIndex >= 0) {
                        //should be converting based on UTC+/-
                        const timezone =
                            parseInt(dataItem.time_zone.slice(UtcIndex + 3, dataItem.time_zone.length)) * 60;
                        momentDate = moment.utc(dataItem.time_utc).utcOffset(timezone);
                        parsedDate = momentDate.format('YYYY-MM-DDTHH:mm:ss');

                        momentUtcOffset = momentDate.format('ZZ');
                    } else {
                        //should be converting based on IANA string
                        momentDate = moment(dataItem.time_utc).tz(dataItem.time_zone);
                        parsedDate = momentDate.format('YYYY-MM-DDTHH:mm:ss');
                        momentUtcOffset = momentDate.format('ZZ');
                    }

                    if (parsedDate) {
                        const formattedUtc = atHelperFunctions.compactDateTemplate({
                            time: parsedDate
                        });
                        const formattedOffset = atHelperFunctions.formatUtcOffset(momentUtcOffset);
                        const utcString = 'UTC' + formattedOffset;

                        return (
                            '<div at-tooltip="' +
                            dataItem.time_zone +
                            '">' +
                            formattedUtc +
                            ' (' +
                            utcString +
                            ')</div>'
                        );
                    }
                } catch {
                    console.error('ActivTrak Error: Error parsing activity log time zone');
                }
                return '';
            },
            attributes: {
                class: 'text-nowrap text-center'
            },
            width: '235px',
            removeColumn: !activityTimeSettings.showLocalTimezone
        },
        {
            field: 'computer',
            title: msg.get('computer'),
            filterable: templateServiceFunctions.createFilter('computer', activityLogSource),
            attributes: {
                class: 'text-nowrap text-center'
            },
            width: '150px'
        },
        {
            field: 'primary_Domain',
            title: msg.get('primaryDomain'),
            filterable: false,
            hidden: true,
            attributes: {
                class: 'text-nowrap'
            },
            width: '150px'
        },
        {
            field: 'ip',
            title: msg.get('privateIp'),
            filterable: false,
            hidden: true,
            attributes: {
                class: 'text-nowrap'
            },
            width: '150px'
        },
        {
            field: 'publicIp',
            title: msg.get('publicIp'),
            filterable: templateServiceFunctions.createFilter('publicIp', activityLogSource),
            hidden: true,
            attributes: {
                class: 'text-nowrap'
            },
            width: '150px'
        },
        {
            field: 'login_Domain',
            title: msg.get('logonDomain'),
            filterable: false,
            hidden: true,
            attributes: {
                class: 'text-nowrap'
            },
            width: '150px'
        },
        {
            field: 'user',
            title: msg.get('user'),
            template: kendo.template(function (item) {
                if (isSystemUser(item)) {
                    return '<span class="at-deemphasize">' + kendo.htmlEncode(item.user.toLowerCase()) + '</span>';
                } else {
                    return kendo.htmlEncode(item.user);
                }
            }),
            filterable: templateServiceFunctions.createFilter('user', activityLogSource),
            attributes: {
                class: 'text-nowrap text-center'
            },
            width: '150px'
        },
        {
            field: 'session',
            title: msg.get('session'),
            filterable: false,
            hidden: true,
            attributes: {
                class: 'text-nowrap'
            },
            width: '150px'
        },
        {
            field: 'duration',
            title: msg.get('duration'),
            filterable: false,
            template: kendo.template(templateServiceFunctions.durationTemplate),
            attributes: {
                class: 'text-nowrap text-center'
            },
            width: '100px'
        },
        {
            field: 'titleBar',
            title: msg.get('title'),
            filterable: templateServiceFunctions.createFilter('titleBar', activityLogSource),
            template: kendo.template(function (item) {
                const title = '<a ng-click="filter(\'titleBar\', dataItem.titleBar)">{{dataItem.titleBar}}</a>';
                if (isSystemEvent(item)) {
                    return '<span class="at-noemphasis">' + title + '</span>';
                } else if (isPassive(item) && !item.titleBar.match(/Passive/i)) {
                    return '<span class="at-noemphasis"> Passive (' + title + ')</span>';
                } else if (isPassive(item)) {
                    return '<span class="at-noemphasis"> ' + title + '</span>';
                } else {
                    return title;
                }
            }),
            width: '300px',
            removeColumn: !activityAllowed
        },
        {
            field: 'executable',
            title: msg.get('executable'),
            filterable: templateServiceFunctions.createFilter('executable', activityLogSource),
            template: kendo.template(function (item) {
                const executable =
                    '<a ng-click="filter(\'executable\', dataItem.executable)">{{dataItem.executable}}</a>';
                if (isPassive(item) || isSystemEvent(item)) {
                    return '<span class="at-noemphasis">' + executable + '</span>';
                } else {
                    return executable;
                }
            }),
            attributes: {
                class: 'text-nowrap'
            },
            width: '150px'
        },
        {
            field: 'description',
            title: msg.get('description'),
            filterable: templateServiceFunctions.createFilter('description', activityLogSource),
            template: kendo.template(function (item) {
                const description =
                    '<a ng-click="filter(\'description\', dataItem.description)">{{dataItem.description}}</a>';
                if (isPassive(item) || isSystemEvent(item)) {
                    return '<span class="at-noemphasis">' + description + '</span>';
                } else {
                    return description;
                }
            }),
            attributes: {
                class: 'text-nowrap'
            },
            width: '150px'
        },
        {
            field: 'url',
            title: msg.get(activityAllowed ? 'url' : 'website'),
            headerTemplate:
                msg.get(activityAllowed ? 'url' : 'website') +
                `<i-tooltip
                    placement="top"
                    top-offset="4px"
                    template="urlExplanationTemplate">
                </i-tooltip>`,
            filterable: templateServiceFunctions.createFilter('url', activityLogSource),
            template: kendo.template(function (item) {
                const url = templateServiceFunctions.getFaviconTemplateAsString(item.url, true);
                if (isPassive(item) || isSystemEvent(item)) {
                    return '<span class="at-noemphasis">' + url + '</span>';
                } else {
                    return url;
                }
            }),
            attributes: {
                class: 'text-nowrap'
            },
            width: '500px'
        }
    ];

    function getGridOptions() {
        const cols = atHelperFunctions.filterDefaultColumns(defaultGridColumns);

        return {
            autoBind: false,
            dataSource: activityLogSource,
            filterable: {
                mode: 'row'
            },
            sortable: true,
            noRecords: {
                template: noRecordsTemplate
            },
            reorderable: true,
            resizable: true,
            columns: gridServiceFunctions.loadSavedGridOptions(angular.copy(cols), gridName),
            columnMenu: false,
            dataBound: function (e) {
                gridPropertiesService.bindFilterAutoComplete(e, true);
                const { dir, field } = activityLogSource.sort()[0];

                const paginationState = {
                    index: activityLogSource.page(),
                    size: activityLogSource.pageSize()
                };

                const defaultColumnFilter = {
                    filters: [],
                    logic: 'and'
                };

                const exportParams = {
                    limitToPage: paginationState,
                    sortDirection: dir,
                    sortField: field,
                    filter: activityLogSource.filter() ?? defaultColumnFilter,
                    hasDataForTable: !$scope.noData
                };

                setExportParamsStore(exportParams);
            },
            columnReorder: function () {
                $timeout(function () {
                    gridPropertiesService.hideLeftBorder();
                });
            }
        };
    }

    $scope.mainGridOptions = getGridOptions();

    $scope.tooltipOptions = templateServiceFunctions.getTooltipOptions({
        filter: 'td.text-nowrap'
    });

    $scope.filter = function (field, value) {
        activityLogSource.filter({
            field: field,
            value: value,
            operator: 'contains'
        });
    };

    $scope.removeFilter = function () {
        $timeout(function () {
            activityLogSource.filter([]);
        });
    };

    $scope.$on('restoreColumns', function () {
        if (!$scope.isSmallWindow) {
            $scope.activityLogGrid.isBound = false;
            gridServiceFunctions.resetSavedGridOptions($scope.activityLogGrid, gridName, getGridOptions);
        }
        pageSizeService.dataSourceReload(activityLogSource);
        gridPropertiesService.hideLeftBorder();
    });

    $scope.bindReport = function (filters) {
        $scope.reportFilters = filters ?? $scope.reportFilters;
        $timeout(function () {
            if ($scope.activityLogGrid) {
                gridServiceFunctions.bindGridSave($scope.activityLogGrid, gridName);

                if ($scope.activityLogGrid.isBound) {
                    $scope.activityLogGrid.isBound = false;
                }
            }
            activityLogSource.read();
        });
    };

    $scope.getGrid = function () {
        return $scope.isSmallWindow ? null : $scope.activityLogGrid;
    };

    $scope.$on('selectColumns', function () {
        const grid = $scope.getGrid();

        customUibModal.open({
            animation: false,
            template: gridColumnSelectionModalTemplate,
            controller: 'gridColumnSelectorModalCtrl',
            size: 'md',
            resolve: {
                grid: grid
            }
        });
    });

    // Export...
    exportInfoService.setExportInfo({
        mainDataSource: activityLogSource,
        getCacheKey: function () {
            return cacheKey;
        },
        getSecondaryParameters: function () {
            return {
                filter: activityLogSource.filter()
            };
        },
        getSortParameters: function () {
            return {
                sort: activityLogSource.sort()
            };
        }
    });

    $scope.showScreenshot = function (dataItem) {
        $scope.$broadcast('showScreenshotEvent', {
            index: dataItem.index
        });
    };

    $scope.$watch('isIgnoredSystem', function (newValue, oldValue) {
        if (newValue !== oldValue && newValue !== undefined && oldValue !== undefined) {
            localStorageService.set('isSystemUserIgnored-' + accountSettings.username, newValue);
            $scope.bindReport();
        }
    });

    $timeout(function () {
        if (pageSizeService.setDropdownHandler) {
            pageSizeService.setDropdownHandler('activityLog-master');
        }
    });
}

angular.module('app').service('activityLogService', ActivityLogService);

ActivityLogService.$inject = ['$http', 'envConfig'];

function ActivityLogService($http, envConfig) {
    this.getLog = function (parameters, data) {
        return $http.get(envConfig.reportingServiceUrl() + '/reports/v1/activitylog?' + parameters, data);
    };
}
