import { useEffect, useState } from 'react';
import { useInjector } from '../third-party/angular2react/useInjector';

export const useUIRouterHistory = () => {
  const $injector = useInjector();

  return {
    push: async (routeName: string, parameters?: unknown | undefined) => {
      const $state = $injector.get('$state');
      await $state.go(routeName, parameters);
    }
  };
};

interface IUIRouterState<TParams> {
  name: string,
  params?: TParams
}

export const useUIRouterState = <TParams>() => {

  const $injector = useInjector();
  const $state = $injector.get('$state');
  const $stateParams = $injector.get('$stateParams');

  const [state, setState] = useState<IUIRouterState<TParams>>({ name: $state.current.name, params: $stateParams });

  useEffect(() => {

    const $rootScope = $injector.get('$rootScope');
    const unsubscribe = $rootScope.$on('$stateChangeStart',
      (_: never, toState: Partial<IUIRouterState<TParams>>, toParams: TParams) => {
        setState(() => ({ name: toState.name, params: toParams }));
      });

    return () => {
      unsubscribe();
    }
  }, [$injector]);

  return state;
}
