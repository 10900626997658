import React from 'react';
import { SelectChangeEvent, FormControl } from '@mui/material';
import { ClockIcon } from '@mui/x-date-pickers';
import { SelectInlineLabelSC } from '../../../common/components/Select/SelectInlineLabel.styles';
import { getListOfScheduledTimes } from '../utils/scheduledTimeline.utils';

type ScheduledHourSelectorProps = {
  id: string;
  value?: string;
  onChange: (event: SelectChangeEvent<{ value: unknown }>) => void;
  defaultValue?: string;
  containerStyles?: object;
};

export const ScheduledHourSelector = (
  props: ScheduledHourSelectorProps
): JSX.Element => {
  const { id, value, onChange, defaultValue, containerStyles = {} } = props;
  const handleChange = (event) => onChange(event.target.value);

  //TODO: validation

  return (
    <FormControl
      sx={{ verticalAlign: 'middle', ...containerStyles }}
      variant="outlined"
      size="small"
    >
      <SelectInlineLabelSC
        id={`${id}-select`}
        value={value ?? defaultValue}
        inputProps={{ sx: { minWidth: '140px' } }}
        onChange={handleChange}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        IconComponent={() => (
          <ClockIcon sx={{ fontSize: '16px', m: '0px 16px 2px -24px' }} />
        )}
        MenuProps={{
          onClick: (e) => {
            e.preventDefault();
            e.stopPropagation();
          },
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
          sx: { zIndex: 1500 }
        }}
      >
        {getListOfScheduledTimes(30)}
      </SelectInlineLabelSC>
    </FormControl>
  );
};
