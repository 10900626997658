import {
  IAccountSettingsDto,
  IBaseAccountSettingsDto,
  IStorageDto
} from '../../common/models';
import {
  convertSort,
  formatTimeStamp,
  formatTotalStorage,
  stringify
} from '../../common/helpers';
import { convertUnixTimeStamp } from '../../../../appReact/common/utilities/utilities.js';
import { IBundleFlags } from '../../common/models/IBundleFlags';
import { IPrivacySettings } from '../../common/models/IPrivacySettings';
import { IAccountSnapshotDto } from '../models/IApiResponseDto';
import {
  IFullyMappedAccountDetails,
  IMappedAccountCreator,
  IMappedAccountDetails,
  IMappedAccountPlan,
  IMappedAccountSettings,
  IMappedAccountSnapshot,
  IMappedAccountSubscription,
  IMappedAccountUser,
  IMappedDateTimeSettings
} from '../models/IMappedDetails';
import {
  IAccountPlan,
  IAccountSubscription,
  IAccountUser
} from '../models/IFetchedDetails';

export const structureAccountDetails = (
  data: IAccountSettingsDto,
  accountSnapshot: IAccountSnapshotDto,
  storageDetails: IStorageDto,
  privacySettings: IPrivacySettings
): IFullyMappedAccountDetails => {
  return {
    ['Snapshot']: mapAccountSnapshot(accountSnapshot, storageDetails),
    ['Account Details']: mapAccountDetails(data),
    ['User']: mapUser(data),
    ['Subscriptions']: mapSubscription(data.subscriptions),
    ['Account Settings']: mapAccountSettings(data.settings, privacySettings),
    ['Bundle Flags']: convertSort<IBundleFlags>(data.bundleFlags)
  };
};

const mapAccountSnapshot = (
  accountSnapshot: IAccountSnapshotDto,
  storageDetails: IStorageDto
): IMappedAccountSnapshot => {
  return {
    ['Plan Type']: accountSnapshot.planType,
    ['Plan Code']: accountSnapshot.planCode,
    ['Plan Period']: accountSnapshot.planPeriod,
    ['License Count']: accountSnapshot.licenseCount,
    ['Account Creation Date']:
      formatTimeStamp(accountSnapshot.creationDate) || 'N/A',
    ['Total Storage as of Yesterday']: storageDetails
      ? formatTotalStorage(storageDetails.storage)
      : 'N/A',
    ['Last Activity Log as of Yesterday']:
      formatTimeStamp(accountSnapshot.lastActivityDate) || 'N/A',
    ['Last Login Date']:
      formatTimeStamp(accountSnapshot.lastLoginDate) || 'N/A',
    ['DB']: accountSnapshot.db,
    ['Collect']: accountSnapshot.dataCollection ?? 'N/A',
    ['Maintenance']: accountSnapshot.maintenance ?? 'N/A'
  };
};

const mapAccountDetails = (
  data: IAccountSettingsDto
): IMappedAccountDetails => {
  return {
    ['Account Id']: data.account,
    ['Parent Account Id']: data.parentAccountId ?? 'N/A',
    ['Verified']: data.isVerified,
    ['Phone Number']: data.phone,
    ['Database Name']: data.databaseName,
    ['Max Storage']: data.maxStorage,
    ['Over Limit or Over Storage']: data.overLimitOrOverStorage,
    ['MSA Acceptance Required']: data.msaAcceptanceRequired,
    ['Account Created']: convertUnixTimeStamp(data.unixCreationTime),
    ['Date/Time Settings']: mapDateTimeSettings(data),
    ['Creator Info']: mapCreatorInfo(data)
  };
};

const mapDateTimeSettings = (
  data: IAccountSettingsDto
): IMappedDateTimeSettings => {
  return {
    ['Date Format']: data.dateFormat,
    ['Time Format']: data.timeFormat,
    ['Timezone']: data.currentTimeZone,
    ['Iana Timezone']: data.currentIanaTimeZone
  };
};

const mapCreatorInfo = (data: IAccountSettingsDto): IMappedAccountCreator => {
  return {
    ['First Name']: data.firstName,
    ['Last Name']: data.lastName,
    ['Username/Email']: data.username
  };
};

const mapUser = (data: Partial<IAccountUser>): IMappedAccountUser => {
  return {
    ['Username']: data.user.username,
    ['Roles']: data.user.roles,
    ['Role Access']: data.roleAccess
  };
};

const mapSubscription = (
  data: IAccountSubscription
): IMappedAccountSubscription => {
  return {
    ['Current Subscription']: mapPlanInfo(data.current),
    ['Previous Subscription']: mapPlanInfo(data.previous),
    ['Failed Payment']: data.hasFailedPayment,
    ['Subscription Cancelled']: data.isSubscriptionCancelled,
    ['Subscription State']: data.state,
    ['Subscription Type']: data.subscriptionType
  };
};

const mapPlanInfo = (data: IAccountPlan): IMappedAccountPlan => {
  return {
    ['Plan Code']: data.code,
    ['Plan Type']: data.type,
    ['Plan Period']: data.period,
    ['In Trial']: data.isTrial
  };
};

const mapPrivacySettings = (privacySettings) => {
  return {
    ['Screenshots Allowed']: privacySettings.screenshotsAllowed,
    ['Activity Allowed']: privacySettings.activityAllowed,
    ['Alarm Actions Allowed']: privacySettings.alarmActionsAllowed
  };
};

const mapAccountSettings = (
  data: IBaseAccountSettingsDto,
  privacySettings: IPrivacySettings
): IMappedAccountSettings => {
  return {
    ['Auto Update']: data.autoUpdate,
    ['Data Collection']: data.dataCollection,
    ['Redaction Feature Flag']: data.redactionFeatureFlag,
    ['Safe Search Feature Flag']: data.safeSearchFeatureFlag,
    ['Is Data Console Enabled']: data.isDataConsoleEnabled,
    ['Video Alarms Feature Flag']: data.videoAlarmsFeatureFlag,
    ['Video Alarms Limit']: data.videoAlarmsLimit,
    ['Video Alarms Active']: data.videoAlarmsActive,
    ['Filter Settings']: stringify(data.filterSettings),
    ['Default Group']: stringify(data.defaultGroup),
    ['Viewable Groups Count']: stringify(data.viewableGroupsCount),
    ['Insights Available']: data.insightsAvailable,
    ['Agent Download Settings']: stringify(data.agentDownloadSettings),
    ['Insights Setting']: stringify(data.insightsSetting),
    ['Delayed Delete']: data.delayedDelete,
    ['Landing Page']: data.landingPage,
    ['User Merge Enabled']: data.userMergeEnabled,
    ['Flex Usage Enabled']: data.flexUsageEnabled,
    ['Flex Usage Expiration Date']: data.flexUsageExpirationDate,
    ['Show Team Pulse Screen Views']: data.showTeamPulseScreenViews,
    ['Role Date Filters']: data.roleDateFilters,
    ['Data Hash']: data.dataHash,
    ['Maintenance Mode']: data.maintenanceMode,
    ['Mac Auto Update']: data.macAutoupdate,
    ['Privacy Settings']: mapPrivacySettings(privacySettings)
  };
};
