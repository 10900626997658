import React from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { hideModal } from '../../../common/stores/globalModalStore';
import { fontColor900 } from '../../../common/constants';
import { GlobalModalContent } from '../templates/GlobalModalContent';
import { PrimaryButton } from '../styles/ModalBtn.styles';

export const ExportCanceledModalContent = () => {
  const handleClick = () => {
    hideModal();
  };

  return (
    <GlobalModalContent>
      <DialogTitle>Export Canceled</DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            color: fontColor900
          }}
        >
          Your current export has been canceled.
        </Typography>
      </DialogContent>
      <DialogActions>
        <PrimaryButton onClick={handleClick}>Close</PrimaryButton>
      </DialogActions>
    </GlobalModalContent>
  );
};
