import { createStore } from 'zustand';
import { persist } from 'zustand/middleware';
import { ColumnState } from 'ag-grid-community';
import { ReportNames, ReportViewTypes } from '../../common/enums/reports.enums';
import { REPORT_COLUMNS_STORAGE_PREFIX } from '../../common/constants/reports.const';
import { IScheduleAdherenceColumnsStore } from '../models/ScheduleAdherence.types';
import {
  SCHEDULE_DETAILED_COLUMNS_DEFAULT,
  SCHEDULE_SUMMARY_COLUMNS_DEFAULT
} from '../constants/scheduleAdherence.const';

//add column defaults for visibility status per view
export const scheduleAdherenceColumnsStore =
  createStore<IScheduleAdherenceColumnsStore>()(
    persist(
      () => ({
        [ReportViewTypes.SUMMARY]: SCHEDULE_SUMMARY_COLUMNS_DEFAULT,
        [ReportViewTypes.DETAILED]: SCHEDULE_DETAILED_COLUMNS_DEFAULT
      }),
      {
        name: REPORT_COLUMNS_STORAGE_PREFIX + ReportNames.SCHEDULE_ADHERENCE
      }
    )
  );

export const setScheduleAdherenceColumns = (
  newColumns: Partial<Record<ReportViewTypes, ColumnState[]>>
) => {
  scheduleAdherenceColumnsStore.setState(newColumns);
};

export const getScheduleAdherenceColumns = () =>
  scheduleAdherenceColumnsStore.getState();

export const resetScheduleAdherenceColumns = () => {
  scheduleAdherenceColumnsStore.setState(
    {
      [ReportViewTypes.SUMMARY]: SCHEDULE_SUMMARY_COLUMNS_DEFAULT,
      [ReportViewTypes.DETAILED]: SCHEDULE_DETAILED_COLUMNS_DEFAULT
    },
    true
  );
};
