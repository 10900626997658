import { getDateSettingsStore } from '../../../common/stores/dateSettingsStore';
import MenuItem from '@mui/material/MenuItem';
import { getHoursValue } from '../../../common/components/ReportFilters/utils/dateRange.utils';
import { TimeFormat } from '../../../common/utils/datetime/enums/dateTimeFormats';

export const getListOfScheduledTimes = (interval: number) => {
  const dateSettings = getDateSettingsStore();
  const isTimeFormat24Hour = dateSettings.isTimeFormat24Hour;
  const labelFormat = isTimeFormat24Hour
    ? TimeFormat.TwentyFourHourNoSeconds
    : TimeFormat.TwelveHourNoSeconds;

  const items = [];

  new Array(24).fill(null).forEach((_, index) => {
    items.push(
      <MenuItem
        key={`hour-${index}`}
        value={getHoursValue({ hour: index, minutes: 0, format: 'HH:mm' })}
      >
        {getHoursValue({ hour: index, minutes: 0, format: labelFormat })}
      </MenuItem>
    );

    //TODO: if feature changes to 5-min intervals, we need to update this to instead use a loop to push the below for each interval within an hour
    items.push(
      <MenuItem
        key={`time-interval-${index}`}
        value={getHoursValue({
          hour: index,
          minutes: interval,
          format: TimeFormat.TwentyFourHourNoSeconds
        })}
      >
        {getHoursValue({ hour: index, minutes: interval, format: labelFormat })}
      </MenuItem>
    );
  });

  return items;
};
