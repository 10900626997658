import { getAccountSettings } from '../../../../_reactivtrak/src/common/helpers/accountSettings/accountSettingsStore';
import { profileStore } from '../../../../_reactivtrak/src/common/services/Profile/useProfileState';

angular.module('app').controller('TeamPulsePromoCtrl', TeamPulsePromoCtrl);

TeamPulsePromoCtrl.$inject = [
    '$scope',
    '$state',
    '$window',
    'envService',
    'notificationService',
    'authorizationService',
    'utmService'
];

function TeamPulsePromoCtrl(
    $scope,
    $state,
    $window,
    envService,
    notificationService,
    authorizationService,
    utmService
) {
    if (authorizationService.hasFeature('isTeamPulseEnabled')) {
        $state.go('app.teampulse');
    }

    var { phone, companyName } = getAccountSettings();
    var { firstName, lastName, username } = profileStore.getState().profile;
    var marketoConfig = envService.getMarketoConfig();
    $scope.requestQuote = function () {
        $window.MktoForms2.loadForm(
            marketoConfig.formUrl,
            marketoConfig.munchkinId,
            marketoConfig.teamPulse,
            function (form) {
                var vals = {
                    Phone: phone,
                    FirstName: firstName,
                    LastName: lastName,
                    Email: username,
                    Company: companyName
                };
                utmService.configureForm(form, vals, utmService.getHardcodedMktoHiddenFields(), function () {
                    notificationService.showNotification(
                        'Quote request received. A member of our sales team will reach out to you shortly.',
                        'success'
                    );

                    return false;
                });
                $window.MktoForms2.lightbox(form).show();
            }
        );
    };
}
