import { getAccountSettings } from '../../../../_reactivtrak/src/common/helpers/accountSettings/accountSettingsStore';

angular.module('app').controller('SecurityController', SecurityController);

SecurityController.$inject = [
    '$scope',
    'authorizationService',
    'accessApiService',
    'envService',
    'notificationService'
];

function SecurityController($scope, authorizationService, accessApiService, envService, notificationService) {
    var { account: accountId } = getAccountSettings();
    $scope.ssoAuthorizationUrl = envService.ssoAuthorizationUrl();
    $scope.ssoSettings = {
        enabled: false,
        name: '',
        description: '',
        singleSignOnServiceUrl: '',
        certificate: ''
    };
    $scope.saving = false;
    $scope.loading = true;
    $scope.unlocked = authorizationService.hasFeature('isPaidFunctionalityEnabled');
    $scope.eligible = true;

    $scope.canUpgrade = function () {
        return authorizationService.hasRouteByName('app.account.upgrade');
    };

    function init() {
        $scope.loading = true;

        accessApiService.getSsoEligible(accountId).success(function (response) {
            $scope.eligible = response;
        });

        accessApiService.getSsoSettings(accountId).success(function (response) {
            $scope.ssoSettings.enabled = response.enabled;

            if (
                response.samlConfiguration.partnerIdentityProviderConfigurations &&
                response.samlConfiguration.partnerIdentityProviderConfigurations.length > 0
            ) {
                var ip = response.samlConfiguration.partnerIdentityProviderConfigurations[0];
                $scope.ssoSettings.name = ip.name;
                $scope.ssoSettings.description = ip.description;
                $scope.ssoSettings.singleSignOnServiceUrl = ip.singleSignOnServiceUrl;

                if (ip.partnerCertificates && ip.partnerCertificates.length > 0) {
                    $scope.ssoSettings.certificate = ip.partnerCertificates[0].string;
                }
            }

            $scope.loading = false;
        });
    }
    init();

    $scope.hasViewLevel = function (levels) {
        return authorizationService.hasAuthorizationLevel(levels, 'app.settings.security');
    };

    $scope.hasRoute = function (route) {
        return authorizationService.hasRouteByName(route);
    };

    $scope.save = function () {
        $scope.saving = true;

        accessApiService
            .saveSsoSettings(accountId, $scope.ssoSettings)
            .success(function () {
                $scope.saving = false;

                notificationService.showNotification('Security settings have been saved!', 'success');
            })
            .error(function (err) {
                notificationService.showNotification('Failed to save SSO configuration.', 'danger');

                console.error(err);
                $scope.saving = false;
            });
    };

    $scope.invalid = function () {
        var ssoSettings = $scope.ssoSettings;
        if (!ssoSettings.enabled) {
            return false;
        }

        if (
            ssoSettings.name === '' ||
            ssoSettings.description === '' ||
            ssoSettings.singleSignOnServiceUrl === '' ||
            ssoSettings.certificate === ''
        ) {
            return true;
        }

        return false;
    };

    $scope.cancel = function () {
        init();
    };
}
