import { useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';
import { IEntityStore } from '../models/IEntity';
import {
  createGroupComboList,
  getAllEntitiesList,
  validateEntity
} from '../utils/entityStore.utils';
import { IUsersFilter } from '../components/ReportFilters/models/IReportFilters';

const ENTITY_STORE_CACHE_LIFETIME = 300000; // 5 minutes
const defaultState = {
  users: [],
  computers: [],
  groups: [],
  isLoading: false,
  previousSearch: null,
  cacheTimestamp: undefined
};
const entitiesStore = createStore<IEntityStore>(() => defaultState);

export const getAllEntities = async (search?: string) => {
  // Define empty search and undefined search as null
  if (search === undefined || search === '') {
    search = null;
  }

  // Fetch data if search is different or cache is older than the timeout
  const { previousSearch, cacheTimestamp } = entitiesStore.getState();
  if (
    previousSearch !== search ||
    cacheTimestamp === undefined ||
    cacheTimestamp <= Date.now() - ENTITY_STORE_CACHE_LIFETIME
  ) {
    const currentState = entitiesStore.getState();
    entitiesStore.setState({ ...currentState, isLoading: true });
    try {
      const { users, computers, groups } = await getAllEntitiesList(search);
      entitiesStore.setState({
        users,
        computers,
        groups,
        previousSearch: search,
        cacheTimestamp: Date.now()
      });
    } catch (error) {
      console.error('ActivTrak Error: cannot load entities', error);
    } finally {
      const currentState = entitiesStore.getState();
      entitiesStore.setState({ ...currentState, isLoading: false });
    }
  }

  return entitiesStore.getState();
};

export const getUserGroupCombo = async (search: string) => {
  const { users, groups } = await getAllEntities(search);
  return createGroupComboList(users, groups, 'user');
};

export const getComputerGroupCombo = async (search: string) => {
  const { computers, groups } = await getAllEntities(search);
  return createGroupComboList(computers, groups, 'computer');
};

// export const initializeEntitiesStore = async () => {
//   resetEntitiesStore();
//   await getAllEntities();
// };

export const resetEntitiesStore = () => entitiesStore.setState(defaultState);

export const invalidateEntitiesCache = () => {
  const currentState = entitiesStore.getState();
  entitiesStore.setState({ ...currentState, cacheTimestamp: undefined });
};

// Temporary filter validation until we can get an API endpoint that returns all entities
export const validateUserFilter = async (users: IUsersFilter[]) => {
  const userChecks = users.map(async (user) => {
    const entities = (await getAllEntitiesList(user.name)) as IEntityStore;
    return validateEntity(entities, user);
  });
  const results = await Promise.all(userChecks);

  return results.every((isValid) => isValid);
};

const useEntityStore = (selector) =>
  useStore<any, any>(entitiesStore, selector);

export { entitiesStore, useEntityStore };
