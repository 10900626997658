import React from 'react';
import Modal from '@mui/material/Modal';
import { hideModal } from '../common/stores/globalModalStore';
import { useGlobalModalStore } from '../common/hooks/useGlobalModalStore';

export const App = () => {
  const { isModalOpen, content, disableBackdropClick } = useGlobalModalStore();

  return (
    <div>
      <Modal
        open={isModalOpen}
        onClose={(event, reason) => {
          if (
            (reason === 'backdropClick' || reason === 'escapeKeyDown') &&
            disableBackdropClick
          )
            return;
          hideModal();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {content ?? <></>}
      </Modal>
    </div>
  );
};
