import { createStore } from 'zustand';
import { IExportReportParams } from '../models/IExport';

const EXPORT_PARAM_STORE_DEFAULT: Partial<IExportReportParams> = {
  hasDataForTable: false
};

export const exportParamsStore = createStore<Partial<IExportReportParams>>(
  () => EXPORT_PARAM_STORE_DEFAULT
);

/**
 * Returns the export parameters that are set in the store.
 */
export const getExportParamsStore = (): Partial<IExportReportParams> =>
  exportParamsStore.getState();

/**
 * Sets the export parameters into the store merging the passed in values with the existing values.
 */
export const setExportParamsStore = (newState: Partial<IExportReportParams>) =>
  exportParamsStore.setState(newState);

/**
 * Resets the export parameters back to default in the store.
 */
export const resetExportParamsStore = (): void =>
  exportParamsStore.setState(EXPORT_PARAM_STORE_DEFAULT, true);
