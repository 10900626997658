import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CustomGrid from '../../common/components/Grid/CustomGrid';
import { AuthLevel, SortDirection } from '../../common/enums';
import { useNotifications } from '../../common/services/Notifications';
import AliasSearch from '../components/UserAliasSearch';
import InfoIcon from '@mui/icons-material/Info';
import {
  HeaderBarActionButtons,
  HeaderBarLeftActionButtons,
  HeaderBarRightActionButtons,
  InfoContainer,
  ProgressContainer
} from '../styles/Users.view.styles';
import { useUserColumns } from '../utils/useUserColumns';
import { useUserAliasState } from '../services/useUserAliasState';
import { AliasService } from '../services/AliasService';
import { useAuthorization } from '../../common/services/Authorization';
import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';
import Link from '../../common/components/Link';
import { BundleFlag } from '../../common/enums/BundleFlag';

export function UserAlias() {
  const aliasService = AliasService();
  const AliasState = useUserAliasState({ aliasService });

  const {
    getAlias,
    handleAliasChange,
    onApplyClick,
    filteredAliases,
    isLoading,
    onSortOrder,
    aliasNotification,
    setAliasNotification,
    onCancelClick,
    isSaving
  } = AliasState;

  const notificationService = useNotifications();
  const authorizationService = useAuthorization();
  const isUserIdentityEnabled = authorizationService.hasFeature(
    BundleFlag.UserIdentityConfiguration
  );

  const [isReadOnly] = useState<boolean>(
    isUserIdentityEnabled ||
      !authorizationService.hasAuthorizationLevel(
        [AuthLevel.Edit],
        'app.settings.aliases'
      )
  );

  useEffect(() => {
    getAlias();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (aliasNotification?.msg && aliasNotification?.type) {
      notificationService[aliasNotification.type](aliasNotification.msg);
      setAliasNotification(undefined);
    }
  }, [
    notificationService,
    aliasNotification?.msg,
    aliasNotification?.type,
    setAliasNotification
  ]);

  const onAliasChanged = (event) => {
    event.stopPropagation();
    const idChanged = parseInt(event.target.name);
    handleAliasChange(idChanged, event.target.value);
  };

  const aliasGridColumns = useUserColumns({
    onAliasChanged,
    isReadOnly
  });

  return (
    <Box>
      {(isLoading || isSaving) && (
        <ProgressContainer>
          <LinearProgress />
        </ProgressContainer>
      )}

      {isUserIdentityEnabled && (
        <Alert
          severity="info"
          icon={false}
          sx={{
            justifyContent: 'center',
            '> div': { textAlign: 'center' },
            mb: 3
          }}
        >
          This page has been changed to read-only. Any updates to a User Alias
          can be made by changing the display name for an individual user in the
          new <Link to="app.settings.identity">Users page</Link>.
        </Alert>
      )}
      {!isUserIdentityEnabled && (
        <InfoContainer sx={{ pb: 2 }}>
          <InfoIcon fontSize="inherit" /> Use aliases to change the name of a
          user as it appears in your reports. Assign the same alias to multiple
          user ids if you want to combine them into one entity for reporting.
        </InfoContainer>
      )}
      <HeaderBarActionButtons>
        <HeaderBarLeftActionButtons>
          {isReadOnly ? null : (
            <>
              <Button
                onClick={onApplyClick}
                variant="contained"
                color="primary"
              >
                Apply
              </Button>
              <Button onClick={onCancelClick}>Cancel</Button>
            </>
          )}
        </HeaderBarLeftActionButtons>
        <HeaderBarRightActionButtons>
          <AliasSearch
            gridColumns={aliasGridColumns}
            aliasState={AliasState}
          ></AliasSearch>
        </HeaderBarRightActionButtons>
      </HeaderBarActionButtons>
      <Box sx={{ mt: 1 }}>
        <CustomGrid
          isLoading={isLoading}
          isVirtualized={true}
          data={filteredAliases}
          columns={aliasGridColumns}
          uniqueRowProp="id"
          initialSortField="user"
          initialSortDirection={SortDirection.Ascending}
          onSortOrder={onSortOrder}
        />
      </Box>
    </Box>
  );
}

export const UserAliasComponent = () => (
  <ReactivTrakComponentWrapper>
    <UserAlias />
  </ReactivTrakComponentWrapper>
);
