import { createStore } from 'zustand';
import { IScreenshotSettingsStore } from '../models/IScreenshotSettingsStore';
import { fetchSettings } from '../utils/screenshotSettingsStore.utils';
import { resetRealtimeSettingsDtoCache } from '../caches/realtimeSettingsDtoCache';

export const SCREENSHOT_SETTINGS_STORE_DEFAULT: IScreenshotSettingsStore = {
  screenshotsEnabled: false,
  thumbnailsEnabled: false
};

export const screenshotSettingsStore = createStore<IScreenshotSettingsStore>(
  () => SCREENSHOT_SETTINGS_STORE_DEFAULT
);

/**
 * Fetches the Screenshot Settings and updates the store.
 */
export const fetchScreenshotSettings =
  async (): Promise<IScreenshotSettingsStore> => {
    const response = await fetchSettings();
    screenshotSettingsStore.setState(response);
    return screenshotSettingsStore.getState();
  };

/**
 * Returns the current export store state.
 */
export const getScreenshotSettingsStore = (): IScreenshotSettingsStore =>
  screenshotSettingsStore.getState();

/**
 *
 */
export const refreshScreenshotSettingsStore = async (): Promise<void> => {
  resetRealtimeSettingsDtoCache();
  await fetchScreenshotSettings();
};

/**
 * Resets the export store to its default state.
 */
export const resetScreenshotSettingsStore = (): void =>
  screenshotSettingsStore.setState(SCREENSHOT_SETTINGS_STORE_DEFAULT);
