import React, { createRef, MutableRefObject, useEffect, useState } from 'react';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  Box
} from '@mui/material';
import { IInsightsCategory, ICoreCategory } from '../../models';
import { SearchBar } from '../../../common/components/SearchBar';
import { useCategoriesState } from '../../hooks/useCategoriesState';
import CategoryCheckboxList from './CategoryCheckboxList';
import {
  DialogContentStyled,
  StyledSearchBarContainer,
  StyledBoxContainer,
  TotCss
} from '../../styles/TimeOnTask.view.styles';
import DialogActionsBox from './DialogActionsBox';
import BackButtonBox from './BackButtonBox';
import CategoryTextContainer from './CategoryTextContainer';
import { handleDropDownSearchChange } from '../../utils/TimeOnTask.view.utils';

type ComponentProps = {
  open: boolean;
  onClose: () => void;
  onBackToStep1: () => void;
  onGoToStep3: () => void;
  coreCategoryRef: MutableRefObject<ICoreCategory>;
};

export const Step2Dialog = (props: ComponentProps) => {
  const { open, onClose, onBackToStep1, onGoToStep3, coreCategoryRef } = props;

  const { init, categories, isLoading, setCategories } = useCategoriesState();

  const [checkedItems, setCheckedItems] = useState<IInsightsCategory[]>([]);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [filtered, setFiltered] = useState<IInsightsCategory[]>([]);
  const textInputRef = createRef<HTMLInputElement>();
  const [searchString, setSearchString] = useState<string>('');

  useEffect(() => {
    if (open) {
      init(coreCategoryRef.current.groupId);
      if (coreCategoryRef.current.categories.length > 0) {
        setCheckedItems(coreCategoryRef.current.categories);
        setShowSearch(true);
      }
    }
  }, [coreCategoryRef, init, open]);

  useEffect(() => {
    if (open && categories.length > 0) {
      setFiltered(categories);
    }
  }, [categories, open]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleDropDownSearchChange(event, categories, setFiltered, setSearchString);
  };

  const goToStep3 = () => {
    coreCategoryRef.current.categories = checkedItems;
    setCheckedItems([]);
    onGoToStep3();
  };

  const close = () => {
    coreCategoryRef.current.categories = [];
    setCheckedItems([]);
    setShowSearch(false);
    setFiltered([]);
    setCategories([]);
    onClose();
  };

  const backToStep1 = () => {
    coreCategoryRef.current.categories = checkedItems;
    setCheckedItems([]);
    setShowSearch(false);
    setFiltered([]);
    setCategories([]);
    onBackToStep1();
  };

  return (
    open && (
      <Dialog open={open} onClose={close}>
        <BackButtonBox onClick={backToStep1} />
        <Box width={570} maxHeight={630}>
          <DialogTitle>
            Step 2 of 3: Define Your Team's Core Categories
          </DialogTitle>
          <DialogContentStyled>
            {isLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden'
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Typography sx={TotCss.instructions}>
                  Select the categories that are directly associated with your
                  team’s role and responsibilities. This is where you expect
                  team members to spend the majority of their time.
                </Typography>
                <CategoryTextContainer
                  showSearch={showSearch}
                  setShowSearch={setShowSearch}
                  isHideable={true}
                  checkedItems={checkedItems}
                  setCheckedItems={setCheckedItems}
                />
                <StyledBoxContainer hidden={!showSearch}>
                  <StyledSearchBarContainer>
                    <SearchBar
                      placeholder={
                        'Search Categories, Websites, or Applications'
                      }
                      fieldRef={textInputRef}
                      onClick={(e) => e.stopPropagation()}
                      handleDropDownSearchChange={(e) => handleSearchChange(e)}
                    />
                  </StyledSearchBarContainer>
                  <CategoryCheckboxList
                    filtered={filtered}
                    checkedItems={checkedItems}
                    setCheckedItems={setCheckedItems}
                    searchString={searchString}
                  />
                </StyledBoxContainer>
              </>
            )}
          </DialogContentStyled>
          <DialogActions>
            <DialogActionsBox
              close={close}
              onClick={goToStep3}
              disabled={checkedItems.length === 0}
              confirmText={'Go to step 3 of 3'}
            />
          </DialogActions>
        </Box>
      </Dialog>
    )
  );
};
