import angular from 'angular';
import { react2angular } from '../common/third-party/react2angular';
import { ScheduleAdherenceReportViewComponent } from '../reports/working-hours/views/ScheduleAdherenceReport.view';

angular
  .module('app')
  .component(
    'scheduleAdherence',
    react2angular(ScheduleAdherenceReportViewComponent, [])
  );
