import { getAccountSettings } from '../../../_reactivtrak/src/common/helpers/accountSettings/accountSettingsStore';
import {
    getRealtimeSettingsStore,
    realtimeSettingsStore
} from '../../../_reactivtrak/src/common/stores/realtimeSettingsStore';
import {
    getScreenshotSettingsStore,
    screenshotSettingsStore
} from '../../../_reactivtrak/src/common/stores/screenshotSettingsStore';
import lodash from 'lodash';

/*
Real-time Screenshot Service
Purpose: Handle the processing of the real-time screenshot websocket requests
*/

angular.module('app').service('realtimeScreenshotService', RealtimeScreenshotService);

RealtimeScreenshotService.$inject = ['$timeout', '$rootScope', 'websocketService'];

function RealtimeScreenshotService($timeout, $rootScope, websocketService) {
    const service = this;

    // TODO: Move all states to the Realtime Settings Store
    let _pollDelay;
    let _messageTimeout;
    let _messageDelay;
    let _lastSeenThreshold;
    let _realTimeScreenshotEnabled;
    let _maxReconnectDelay;

    console.log('=> Real-time Screenshot Service Initialized');

    let _imageContainers = []; // Object for thumbnail containers

    function createContainer(item, containerSize, imageCallback, type) {
        const { account } = getAccountSettings();
        const container = {
            type: type,
            error: null,
            item: {
                id: item.userId,
                accountId: account,
                computerName: item.device,
                computerAlias: item.devicealias,
                primaryDomain: item.pdom,
                loginDomain: item.ldom,
                username: item.username || item.user,
                alternativeLoginDomain: item.altldom,
                alternativeUsername: item.altusername,
                userAlias: item.user,
                extendedAltNames: item.extendedaltnames,
                imageUrl: null,
                imageCallback: imageCallback,
                messageIds: [],
                width: containerSize && containerSize.width,
                height: containerSize && containerSize.height
            }
        };

        function messageHandler(message) {
            if (!message || !lodash.includes(container.item.messageIds, message.Id)) {
                return;
            }

            lodash.remove(container.item.messageIds, function (i) {
                return i === message.Id;
            });
            container.error = message.Error;
            message.Item = container.item;

            if (typeof container.item.imageCallback === 'function') {
                container.item.messageDelayTracker = container.item.messageIds.length > 0 ? moment() : null;
                container.item.lastMessageReceived = moment();

                if (container.error) {
                    container.item.imageCallback(null, container.error);
                    updateErrorDelay();
                    return;
                }

                let imageUrl = message.Result && message.Result.Screenshot && message.Result.Screenshot.Data;

                if (!imageUrl) {
                    if (message.Result.Screenshot.Title === 'Not in Chrome window') {
                        container.error = message.Result.Screenshot.Title;
                    } else {
                        container.error = 'No Image URL Returned.';
                    }

                    container.item.imageCallback(null, container.error);
                    updateErrorDelay();
                    return;
                }

                container.error = null;
                container.errorDelay = null;

                if (imageUrl && imageUrl.indexOf('base64') < 0) {
                    imageUrl = 'data:image/jpg;base64,' + imageUrl;
                }
                container.item.imageUrl = imageUrl || container.item.imageUrl;
                if (container.type === 'viewer') {
                    container.item.imageCallback(message);
                } else {
                    container.item.imageCallback(imageUrl);
                    container.continuePolling();
                }
            }
        }

        function updateErrorDelay() {
            if (container.errorDelay) {
                container.errorDelay = Math.min(container.errorDelay * 2, _maxReconnectDelay);
            } else {
                container.errorDelay = _pollDelay * 2;
            }
            container.continuePolling();
        }

        function checkAwaitingMessage() {
            if (
                container.item.messageDelayTracker &&
                moment().diff(container.item.messageDelayTracker, 'milliseconds') > _messageTimeout
            ) {
                container.item.messageDelayTracker = null;
            } else if (
                container.item.messageDelayTracker &&
                moment().diff(container.item.messageDelayTracker, 'milliseconds') > _messageDelay
            ) {
                container.error = 'delayed';
                container.item.imageCallback(null, container.error);
            }
            return !!container.item.messageDelayTracker;
        }

        container.requestScreenshot = function () {
            if (checkAwaitingMessage()) {
                return;
            }

            const params = {
                AgentId: {
                    AccountId: container.item.accountId,
                    ComputerName: container.item.computerName,
                    PrimaryDomain: container.item.primaryDomain,
                    LoginDomain: container.item.loginDomain,
                    UserName: container.item.username,
                    AlternativeLoginDomain: container.item.alternativeLoginDomain,
                    AlternativeUsername: container.item.alternativeUsername,
                    ExtendedAltNames: container.item.extendedAltNames
                }
            };

            if (container.item.width || container.item.height) {
                params.Screenshot = {};
                if (container.item.width) {
                    params.Screenshot.Width = container.item.width;
                }
                if (container.item.height) {
                    params.Screenshot.Height = container.item.height;
                }
            }

            websocketService
                .sendCommand('GetScreenshotCommand', params, messageHandler)
                .then(function (messageId) {
                    container.item.messageIds.push(messageId);
                    container.item.messageDelayTracker = moment();
                    container.item.firstMessageRequest = container.item.firstMessageRequest || moment();
                })
                .catch(function () {
                    container.error = 'Cannot Retrieve Screenshot';
                    if (typeof container.item.imageCallback === 'function') {
                        container.item.imageCallback(null, container.error);
                    }
                });
        };

        container.startPolling = function () {
            container.stopPollingCalled = false;
            container.lastPoll = moment();
            container.lastSeen = moment();
            container.requestScreenshot();
        };

        container.validatePolling = function () {
            container.lastSeen = moment();
            container.continuePolling();
        };

        container.continuePolling = function () {
            const pollDelayDiff = moment().diff(container.lastPoll, 'milliseconds');
            const pollDelay = container.errorDelay || _pollDelay;

            $timeout.cancel(container.continueDelay);

            if (pollDelayDiff < pollDelay) {
                container.continueDelay = $timeout(function () {
                    container.continuePolling();
                }, pollDelayDiff);
                return;
            }

            if (moment().diff(container.lastSeen, 'milliseconds') > _lastSeenThreshold) {
                container.stopPollingCalled = true;
            } else {
                container.stopPollingCalled = false;
            }

            if (!container.stopPollingCalled) {
                container.lastPoll = moment();
                container.requestScreenshot();
            }
        };

        container.stopPolling = function () {
            container.stopPollingCalled = true;
        };

        return container;
    }

    function findContainer(id) {
        const container = _imageContainers.find(function (c) {
            return c.item.id === id;
        });
        return container;
    }

    function pauseThumbnailPolling() {
        _imageContainers.forEach(function (container) {
            container.stopPolling();
        });
    }

    function continueThumbnailPolling() {
        _imageContainers.forEach(function (container) {
            container.startPolling();
        });
    }

    function setDefaults() {
        _imageContainers = [];
        const { thumbnailPollDelay, messageTimeoutThreshold, messageDelayThreshold, lastSeenThreshold } =
            getRealtimeSettingsStore();
        const { screenshotsEnabled } = getScreenshotSettingsStore();
        _pollDelay = thumbnailPollDelay;
        _messageTimeout = messageTimeoutThreshold;
        _messageDelay = messageDelayThreshold;
        _lastSeenThreshold = lastSeenThreshold;
        _realTimeScreenshotEnabled = screenshotsEnabled;
        _maxReconnectDelay = _pollDelay * 8;
    }

    // Initialize the websocket service
    service.initialize = function () {
        setDefaults();

        if (!_realTimeScreenshotEnabled) {
            return;
        }

        return websocketService.connect();
    };

    service.reset = function () {
        setDefaults();
    };

    service.destroy = function () {
        websocketService.close();
    };

    // Request fullscreen viewer image
    service.requestViewerImage = function (item, screenSize, imageCallback) {
        if (!_realTimeScreenshotEnabled) {
            imageCallback(null, 'Real-time Screenshots Disabled');
        }

        const container = createContainer(item, screenSize, imageCallback, 'viewer');
        container.requestScreenshot();
        pauseThumbnailPolling();
    };

    // Cancel fullscreen viewer image
    service.cancelViewerImage = function () {
        continueThumbnailPolling();
    };

    // Register thumbnail container
    // Create new container if it doesn't exist
    // Connect thumbnail to container if it does exist
    service.registerImageContainer = function (row, containerSize, imageCallback) {
        if (!_realTimeScreenshotEnabled) {
            return {
                imageUrl: null,
                error: 'Real-time Screenshots Disabled'
            };
        }

        if (!row || !row.userId) {
            return {
                imageUrl: null,
                error: 'No row data given'
            };
        }

        let container = findContainer(row.userId);
        if (!container) {
            container = createContainer(row, containerSize, imageCallback, 'thumbnail');
            _imageContainers.push(container);
            container.startPolling();
        } else {
            container.item.imageCallback = imageCallback;
            container.item.width = containerSize && containerSize.width;
            container.item.height = containerSize && containerSize.height;
            container.validatePolling();
        }

        return {
            imageUrl: container.item.imageUrl,
            error: container.error
        };
    };

    function settingsUpdated() {
        const { thumbnailPollDelay, messageTimeoutThreshold, messageDelayThreshold, lastSeenThreshold } =
            getRealtimeSettingsStore();
        const { screenshotsEnabled } = getScreenshotSettingsStore();
        console.log('=> Real-time Screenshot Settings Updated');
        _pollDelay = thumbnailPollDelay;
        _messageTimeout = messageTimeoutThreshold;
        _messageDelay = messageDelayThreshold;
        _lastSeenThreshold = lastSeenThreshold;
        _realTimeScreenshotEnabled = screenshotsEnabled;
        _maxReconnectDelay = _pollDelay * 8;
    }

    const screenshotSettingsStoreUnsubscribe = screenshotSettingsStore.subscribe(() => settingsUpdated());
    const realtimeSettingsStoreUnsubscribe = realtimeSettingsStore.subscribe(settingsUpdated);

    $rootScope.$on('$destroy', function () {
        screenshotSettingsStoreUnsubscribe();
        realtimeSettingsStoreUnsubscribe();
    });
}
