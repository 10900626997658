import { useStore } from 'zustand';
import { createStore } from 'zustand/vanilla';
import { IReportFilters } from '../models/IReportFilters';
import {
  createDefaultDateRangeFilters,
  createDefaultGroupFilters,
  getReportFiltersLocalStorage,
  setReportFiltersLocalStorage
} from '../utils/reportFilter.utils';
import { ReportFilterName } from '../constants/reportFilterName';
import { DEFAULT_REPORT_FILTERS } from '../constants/defaultReportFilters';
import { IAccountSettingsStore } from '../../../models';
import { groupsStore } from '../../../hooks';
import { accountSettingsStore } from '../../../helpers/accountSettings/accountSettingsStore';
import { isFilterValueValid } from '../utils/reportFilterValidation.utils';
import authorizationService from '../../../helpers/authorization';

// Create a store for the API filters
export const reportFiltersStore = createStore<IReportFilters>(() => ({
  ...DEFAULT_REPORT_FILTERS
}));

// Create a store for the default API filters
export const defaultReportFilterStore = createStore<IReportFilters>(() => ({
  ...DEFAULT_REPORT_FILTERS
}));

const resetCallbackStore = createStore<Record<string, () => void>>(() => ({}));

export const getReportFilters = (): IReportFilters =>
  reportFiltersStore.getState();

export const setReportFilters = (newFilters: Partial<IReportFilters>): void => {
  const currentFilters = reportFiltersStore.getState();

  // Ensure that any undefined values are removed from the filters
  const filteredFilters: Partial<IReportFilters> = Object.entries(
    newFilters
  ).reduce((acc, [key, value]) => {
    if (!Object.values(ReportFilterName).includes(key as ReportFilterName)) {
      console.warn(
        `ActivTrak Warn: Invalid key found in new filters when setting the report filters for key ${key}`
      );
    } else if (value !== undefined) {
      acc[key] = value;
    } else {
      console.warn(
        `ActivTrak Warn: Undefined value found in new filters when setting the report filters for key ${key}`
      );
    }

    return acc;
  }, {});

  const newState = {
    ...defaultReportFilterStore.getState(),
    ...currentFilters,
    ...filteredFilters
  } as IReportFilters;

  reportFiltersStore.setState(newState);
  if (!authorizationService.isSupportOrSuperAdmin()) {
    setReportFiltersLocalStorage(reportFiltersStore.getState());
  }
};

export const initializeReportFilters = () => {
  resetReportFilters();
  if (!authorizationService.isSupportOrSuperAdmin()) {
    const localReportFilters = getReportFiltersLocalStorage();
    if (localReportFilters) {
      setReportFilters(localReportFilters);
    }
  }

  const { groupFilters } = groupsStore.getState();
  const { accountSettings }: IAccountSettingsStore =
    accountSettingsStore.getState();

  const newDefaultGroupFilters = createDefaultGroupFilters(
    accountSettings.defaultGroup,
    groupFilters
  );
  const newDefaultDateRangeFilters = createDefaultDateRangeFilters();
  setDefaultReportFilters({
    ...newDefaultGroupFilters,
    ...newDefaultDateRangeFilters
  });
  const currentFilters = reportFiltersStore.getState();
  Object.values(ReportFilterName).forEach((value: ReportFilterName) => {
    if (!isFilterValueValid(value, currentFilters)) {
      resetReportFilterToDefault(value);
    }
  });

  if (currentFilters.users.length === 0) {
    // If no users are set, set the default group and users to the current default group and users
    const currentDefaultFilters = defaultReportFilterStore.getState();
    setReportFilters({
      groupId: currentDefaultFilters.groupId,
      users: currentDefaultFilters.users
    });
  }
};

export const setDefaultReportFilters = (
  newDefaultFilters: Partial<IReportFilters>
) => {
  const currentFilters = defaultReportFilterStore.getState();
  defaultReportFilterStore.setState({
    ...currentFilters,
    ...newDefaultFilters
  });
};

export const resetReportFilterToDefault = (filterName: ReportFilterName) => {
  const defaultFilters = defaultReportFilterStore.getState();
  setReportFilters({ [filterName]: defaultFilters[filterName] });
};

export const resetReportFilters = (): void => {
  const callbackList = resetCallbackStore.getState();
  reportFiltersStore.setState({
    ...DEFAULT_REPORT_FILTERS
  });
  defaultReportFilterStore.setState(DEFAULT_REPORT_FILTERS);
  resetCallbackStore.setState({});
  Object.values(callbackList).forEach((callback) => {
    if (typeof callback === 'function') callback();
  });
};

export const useReportFilterStore = <T>(selector: any): T =>
  useStore<any, any>(reportFiltersStore, selector);
