import { IDownloadService } from '../services';

export const isEdgeBrowser = () => {
  const edge = window.navigator.userAgent.indexOf('Edge/');
  if (edge > 0) {
    return true;
  }

  // other browser
  return false;
};

export const downloadFromUrl = (url) => {
  if (isEdgeBrowser()) {
    window.location.href = url;
  } else {
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const downloadDynamicAgent = (downloadService: IDownloadService) => {
  const platform = navigator.platform.toUpperCase();
  const userAgent = navigator.userAgent.toUpperCase();

  let os = '';
  if (platform.indexOf('WIN') >= 0) {
    os = 'win';
  } else if (userAgent.indexOf('CROS') >= 0) {
    os = 'chrome';
  } else {
    os = 'mac';
  }

  downloadService
    .downloadAgent(os)
    .then((response) => {
      const { url } = response;
      downloadFromUrl(url);
    })
    .catch(() => {
      //what should happen here?
    });
};
