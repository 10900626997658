import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import { useNotifications } from '../../common/services/Notifications';
import { SupportPortalService, useSupportPortalState } from '../hooks';
import { DetailsPanel, LoadingModal } from './';
import { notificationMessage } from '../constants';
import {
  createModalHeader,
  createSupportControlValues,
  structureAccountDetails
} from '../utils';
import { mapPrivacySettingsDtoToPrivacySettings } from '../../common/utils/privacySettingsStore.utils';
import { filterInitialAccountSettings } from '../../common/helpers/accountSettings/accountSettings.utils';
import { IDetailsModalProps } from '../models/IComponentProps';
import { IFullyMappedAccountDetails } from '../models/IMappedDetails';
import { IModalHeader, ISupportControlValues } from '../models/IRandomTypes';
import { IAccountIdPayloadDto } from '../models/IPayloadDto';

export const DetailsModal = (props: IDetailsModalProps) => {
  const { open, onClose, accountCredentials } = props;
  const { email, id, tokenData } = accountCredentials;

  const [accountDetails, setAccountDetails] =
    useState<IFullyMappedAccountDetails>(null);
  const [modalHeader, setModalHeader] = useState<IModalHeader>(null);
  const [supportControlValues, setSupportControlValues] =
    useState<ISupportControlValues>(null);

  const notificationService = useNotifications();
  const service = SupportPortalService();
  const { fetchAllDetailsForModal, isLoading, isError } = useSupportPortalState(
    {
      service
    }
  );

  useEffect(() => {
    if (open) {
      (async () => {
        try {
          const userToken = tokenData.token;

          const payload: IAccountIdPayloadDto = { accountId: id };

          const [accountSettings, accountSnapshotData, storageData] =
            await fetchAllDetailsForModal(userToken, payload);

          const filteredSettings =
            filterInitialAccountSettings(accountSettings);

          const privacySettings = mapPrivacySettingsDtoToPrivacySettings(
            filteredSettings?.privacySettings
          );

          const structuredAccountDetails: IFullyMappedAccountDetails =
            structureAccountDetails(
              accountSettings,
              accountSnapshotData,
              storageData,
              privacySettings
            );

          setModalHeader(createModalHeader(accountSettings));
          setSupportControlValues(createSupportControlValues(accountSettings));
          setAccountDetails(structuredAccountDetails);
        } catch (error) {
          notificationService.error(notificationMessage.detailsErrorAlert);
          console.error(notificationMessage.detailsErrorAlert, error);
        }
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const canRenderAccountDetails = !isLoading && !isError && accountDetails;

  return (
    <Dialog maxWidth="xl" open={open} onClose={onClose}>
      {canRenderAccountDetails && (
        <>
          <DialogTitle>
            {' '}
            <Box>
              <Typography variant="h5" align="left">
                {modalHeader.accountId}: {modalHeader.companyName}
              </Typography>
              <Box display="flex" justifyContent="space-between">
                <Typography fontWeight={500}>{email}</Typography>
                <Typography fontWeight={500}>
                  {modalHeader.userCount}/{modalHeader.totalCount}
                </Typography>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent dividers sx={{ paddingBottom: 0 }}>
            <DetailsPanel
              accountDetails={accountDetails}
              supportControlValues={supportControlValues}
              accountCredentials={accountCredentials}
              setDetailsModalClose={onClose}
            />
          </DialogContent>
          <DialogActions sx={{ paddingTop: '15px' }}>
            <Button onClick={onClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </>
      )}
      {isLoading && !isError && <LoadingModal />}
    </Dialog>
  );
};
