import React from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { hideModal, showModal } from '../../../common/stores/globalModalStore';
import { deleteExport } from '../../../common/utils/exportStore.utils';
import { fontColor900 } from '../../../common/constants';
import { SAVE_EXPORT_MODAL_CONTENT } from '../../../common/constants/saveExportModalContent';
import { GlobalModalContent } from '../templates/GlobalModalContent';
import { ExportErrorModalContent } from './ExportErrorModalContent';
import { PrimaryButton } from '../styles/ModalBtn.styles';
import { IExport } from '../../../common/models/IExport';

type SaveExportModalContentProps = {
  exportStatus: IExport;
};

export const SaveExportModalContent = (props: SaveExportModalContentProps) => {
  const { exportStatus } = props;
  const { downloadUrl, id, destination } = exportStatus;
  const modalContent = SAVE_EXPORT_MODAL_CONTENT.find(
    (item) => item.key === destination
  );

  const handleClick = async () => {
    try {
      await deleteExport(id);
      window.open(downloadUrl, '_blank', 'noreferrer=true');
      hideModal();
    } catch (error) {
      console.error('ActivTrak Error: Error removing export', error);
      showModal(<ExportErrorModalContent />);
    }
  };

  return (
    <GlobalModalContent>
      <DialogTitle>Export Ready</DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            color: fontColor900
          }}
        >
          {modalContent?.description}
        </Typography>
      </DialogContent>
      <DialogActions>
        <PrimaryButton onClick={handleClick}>
          {modalContent?.primaryBtnText}
        </PrimaryButton>
      </DialogActions>
    </GlobalModalContent>
  );
};
