import {
  DialogActions,
  DialogContent,
  Box,
  Dialog,
  Typography,
  DialogTitle
} from '@mui/material';
import React from 'react';
import { TotCss } from '../../styles/TimeOnTask.view.styles';
import DialogActionsBox from './DialogActionsBox';

type ComponentProps = {
  open: boolean;
  itemCount: number;
  onClose: () => void;
  onDeleteConfirm: () => void;
};

export const DeleteConfirmDialog = (props: ComponentProps) => {
  const { open, itemCount, onClose, onDeleteConfirm } = props;

  return (
    open && (
      <Dialog open={open} onClose={onClose}>
        <Box width={590} maxHeight={235}>
          <DialogTitle>Confirm delete configuration(s)</DialogTitle>
          <DialogContent>
            <Box>
              <Typography sx={{ ...TotCss.instructions, margin: '0' }}>
                Delete {itemCount} configuration(s)?
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <DialogActionsBox
              close={onClose}
              onClick={onDeleteConfirm}
              disabled={false}
              confirmText={'Confirm Delete'}
              buttonColor={'warning'}
            />
          </DialogActions>
        </Box>
      </Dialog>
    )
  );
};
