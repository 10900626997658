import { getAccountSettings } from '../../../../_reactivtrak/src/common/helpers/accountSettings/accountSettingsStore';
import { getPrivacySettings } from '../../../../_reactivtrak/src/common/hooks/privacySettingsStore';
import Report from '../../../../_app/reports/Report';
import ProductivityStatus from '../../../../_app/constants/productivityStatus';
import { generateParameters } from '../../../../_reactivtrak/src/common/components/ReportFilters/utils/generateParameters.ts';

// Register report component
import './topapplications.component.js';
import { setExportParamsStore } from '../../../../_reactivtrak/src/common/stores/exportParamsStore.ts';

angular.module('app').controller('TopApplicationsCtrl', TopApplicationsCtrl);

TopApplicationsCtrl.$inject = [
    '$scope',
    '$state',
    'googleChartApiPromise',
    '$rootScope',
    '$window',
    '$document',
    'messagesService',
    'TIMELINE_COLORS',
    'timelineService',
    'topApplicationsService',
    'localStorageService',
    'pageSizeService',
    'exportInfoService',
    'chartService',
    'atHelperFunctions',
    'templateServiceFunctions',
    'browserServiceFunctions',
    'iconResolverServiceFunctions',
    'authorizationService'
];

function TopApplicationsCtrl(
    $scope,
    $state,
    googleChartApiPromise,
    $rootScope,
    $window,
    $document,
    msg,
    TIMELINE_COLORS,
    timelineService,
    topApplicationsService,
    localStorageService,
    pageSizeService,
    exportInfoService,
    chartService,
    atHelperFunctions,
    templateServiceFunctions,
    browserServiceFunctions,
    iconResolverServiceFunctions,
    authorizationService
) {
    const accountSettings = getAccountSettings();
    $scope.usedLicenses = accountSettings.usedLicenses;
    $scope.download = $rootScope.download;

    const { activityAllowed = false } = getPrivacySettings();
    $scope.reportFilters = {};

    const report = new Report('Applications', {
        localStorageService,
        username: accountSettings.username,
        timeFormat: accountSettings.timeFormat,
        headerHeight: 360,
        detailsHeaderHeightOffset: 81,
        detailsControlHeightOffset: 43,
        detailsMode: activityAllowed ? 'titles' : 'users',
        offsetHorizontal: -10,
        isTeamViewerRole: authorizationService.hasRole(authorizationService.roles.viewer)
    });

    $scope.isDetailsLoading = true;

    if (activityAllowed) {
        report.addDetail('titles', {
            pageSize: 150,
            dataFunction: function (options) {
                if (options.data.fromCache && report.titlesGrid.cachedResults) {
                    return options.success(report.titlesGrid.cachedResults);
                } else {
                    $scope.isDetailsLoading = true;
                    const itemId = report.selectedItemId();
                    if (itemId >= 0) {
                        topApplicationsService
                            .getTitles(itemId, generateParameters($scope.reportFilters, { showProductivity: true }))
                            .then(function (result) {
                                report.titlesGrid.cachedResults = result.data;
                                options.success(result.data);
                                $scope.isDetailsLoading = false;
                            })
                            .catch(function (error) {
                                options.error(error);
                                if (error && error.message) {
                                    $scope.$emit('showNotification', {
                                        message: error.message,
                                        color: 'danger'
                                    });
                                }
                                console.error(error);
                                $scope.isDetailsLoading = false;
                            });
                    } else {
                        options.success([]);
                        $scope.isDetailsLoading = false;
                    }
                }
            }
        });
    }

    function addUserDetails(isRangeLessThan30Days) {
        if (isRangeLessThan30Days) {
            report.addDetail('users', {
                pageSize: pageSizeService.loadPageSize('timeline', 10),
                serverPaging: true,
                schema: {
                    data: 'data',
                    total: 'total'
                },
                pageSizeService,
                dataFunction: function (options) {
                    $scope.isDetailsLoading = true;
                    $scope.productivityChartState = $scope.PRODUCTIVITY_CHART_STATE_INITIAL;
                    $scope.intervalUnit = timelineService.getIntervalUnit($scope.fromDate, $scope.toDate);
                    const timelineParams =
                        generateParameters($scope.reportFilters, { showProductivity: true }) +
                        '&interval=1' +
                        '&intervalUnit=' +
                        $scope.intervalUnit;
                    topApplicationsService
                        .getActivityTimeline(report.selectedItemId(), timelineParams, {
                            params: options.data
                        })
                        .then(function (result) {
                            if (
                                timelineParams !== oldParams ||
                                $scope.timelineDataSource.page() !== oldPage ||
                                oldItemId !== report.selectedItemId()
                            ) {
                                maxDuration = timelineService.getMaxDuration(result.data.data);
                                oldParams = timelineParams;
                                oldItemId = $scope.itemId;
                                oldPage = $scope.timelineDataSource.page();
                            }
                            timelineService.assignLevels(result.data.data, maxDuration);
                            timelineService.calculateUserTotals(result.data.data);
                            options.success(result.data);
                            $scope.timelineData = result.data.data;
                            googleChartApiPromise.then(function () {
                                $scope.isDetailsLoading = false;
                                $scope.timelineReady = true;
                                $scope.reloadTrigger++;
                            });
                        })
                        .catch(function (error) {
                            options.error(error);
                            $scope.isDetailsLoading = false;
                            if (error && error.message) {
                                $scope.$emit('showNotification', {
                                    message: error.message,
                                    color: 'danger'
                                });
                            }
                            console.error(error);
                        });
                }
            });
            $scope.pagerOptions = report.createPagerOptions(report.users.dataSource, {
                itemsPerPage: msg.get('itemsPerPage', 'users'),
                display: msg.get('itemsDisplay', 'users'),
                empty: msg.get('noItemsToDisplay', 'users')
            });
        } else {
            report.removeDetail('users');
            $scope.pagerOptions = null;
        }
        $scope.timelineDataSource = report.users && report.users.dataSource;
    }

    report.addDetail('stats', {
        dataCallback: updateUsageChartConfig,
        dataFunction: function (options) {
            if (options.data.fromCache && report.stats.cachedResults) {
                const data = [
                    {
                        data: report.createChartData(report.stats.cachedResults),
                        dates: report.stats.cachedResults.dates
                    }
                ];
                options.success(data);
            } else {
                const itemId = report.selectedItemId();
                if (itemId) {
                    $scope.isDetailsLoading = true;
                    topApplicationsService
                        .getUsage(itemId, generateParameters($scope.reportFilters, { showProductivity: true }))
                        .then(function (result) {
                            result = result.dates ? result : result.data;
                            report.stats.cachedResults = result;
                            const data = [
                                {
                                    data: report.createChartData(result),
                                    dates: result.dates
                                }
                            ];
                            options.success(data);
                            $scope.isDetailsLoading = false;
                        })
                        .catch(function (error) {
                            options.success([
                                {
                                    data: [{}],
                                    dates: []
                                }
                            ]);
                            if (error && error.message) {
                                $scope.$emit('showNotification', {
                                    message: error.message,
                                    color: 'danger'
                                });
                            }
                            console.error(error);
                            $scope.isDetailsLoading = false;
                        });
                } else {
                    options.success([
                        {
                            data: [{}],
                            dates: []
                        }
                    ]);
                }
            }
        }
    });

    $scope.tooltipTimeFormat = report.tooltipTimeFormat;

    $scope.isMobile = browserServiceFunctions.isMobileAgent();
    $scope.reloadTrigger = 0;
    $scope.colors = TIMELINE_COLORS;
    $scope.state = $state;
    let maxDuration;
    let oldParams;
    let oldItemId;
    let oldPage;

    $scope.hasViewLevel = function (levels) {
        return authorizationService.hasAuthorizationLevel(levels, 'app.reports.topapplications');
    };

    $scope.canEditClassification = authorizationService.hasRouteByName('app.settings.classification.tab');

    $scope.mainGridHeight = function () {
        return report.headerHeight;
    };

    const setSelectedApplicationHeaderWidth = function () {
        $scope.selectedApplicationHeaderWidth = atHelperFunctions.getElementWidth(
            angular.element($window.document).find('#selected-applications-header')[0]
        );
    };

    const setStackControls = function () {
        $scope.stackControls = atHelperFunctions.getContentWidth() < 1320;
    };

    const generateTotals = function (data) {
        return {
            totalAppTime: data.totalAppTime,
            totalProductiveTime: data.totalProductiveTime,
            totalProductiveActiveTime: data.totalProductiveActiveTime,
            totalProductivePassiveTime: data.totalProductivePassiveTime,
            totalUnproductiveTime: data.totalUnproductiveTime,
            totalUnproductiveActiveTime: data.totalUnproductiveActiveTime,
            totalUnproductivePassiveTime: data.totalUnproductivePassiveTime,
            totalUndefinedTime: data.totalUndefinedTime,
            totalUndefinedActiveTime: data.totalUndefinedActiveTime,
            totalUndefinedPassiveTime: data.totalUndefinedPassiveTime
        };
    };

    const generateDataFromCache = function (report) {
        return {
            topApplications: report.mainGrid.cachedResults ?? [],
            totalAppTime: report.totalsCache.totalAppTime,
            totalProductiveTime: report.totalsCache.totalProductiveTime,
            totalProductiveActiveTime: report.totalsCache.totalProductiveActiveTime,
            totalProductivePassiveTime: report.totalsCache.totalProductivePassiveTime,
            totalUnproductiveTime: report.totalsCache.totalUnproductiveTime,
            totalUnproductiveActiveTime: report.totalsCache.totalUnproductiveActiveTime,
            totalUnproductivePassiveTime: report.totalsCache.totalUnproductivePassiveTime,
            totalUndefinedTime: report.totalsCache.totalUndefinedTime,
            totalUndefinedActiveTime: report.totalsCache.totalUndefinedActiveTime,
            totalUndefinedPassiveTime: report.totalsCache.totalUndefinedPassiveTime
        };
    };

    setSelectedApplicationHeaderWidth();
    setStackControls();

    $scope.detailsGridHeight = function () {
        return (
            report.headerHeight +
            report.detailsHeaderHeightOffset +
            ($scope.stackControls ? report.detailsControlHeightOffset : 0)
        );
    };

    $scope.durationTemplate = templateServiceFunctions.durationTemplate;

    report.setDataSource(function (options) {
        report.isLoading.next(true);
        $scope.isUsageChartReady = false;
        if (options.data.fromCache) {
            const data = generateDataFromCache(report);
            $scope.noResults = !data || data.topApplications.length === 0;
            $scope.totals = setTotals(data);
            return options.success(data.topApplications);
        } else {
            topApplicationsService
                .getApplicationsAndTotals(generateParameters($scope.reportFilters, { showProductivity: true }))
                .then(function (result) {
                    const data = result.data;
                    $scope.noResults = !data || data.topApplications.length === 0;
                    report.mainGrid.cachedResults = data.topApplications;
                    const totals = generateTotals(data);
                    report.totalsCache = totals;
                    $scope.totals = setTotals(totals);
                    options.success(data.topApplications);
                })
                .catch(function (error) {
                    options.error(error);
                    if (error && error.message) {
                        $scope.$emit('showNotification', {
                            message: error.message,
                            color: 'danger'
                        });
                    }
                    console.error(error);
                });
        }
    });

    $scope.topApplicationsSource = report.dataSource;

    function setDetailsHeight() {
        $scope.detailsHeight = atHelperFunctions.getGridHeight($scope.detailsGridHeight(), 400);
        return $scope.detailsHeight;
    }

    $scope.applicationClicked = function (row) {
        if (report.lastRowUid !== row.uid) {
            $scope.isUsageChartReady = false;
        }

        report.itemClicked(row);
    };

    $scope.changeDetailsGrid = function (mode) {
        report.setDetailsMode(mode);
        if (accountSettings.username) {
            localStorageService.set('TopApplicationsDetailsMode-' + accountSettings.username, mode);
        }
    };

    function setTabOptions(isRangeLessThan30Days) {
        return {
            tabs: [
                {
                    heapId: 'id_6d7da4b5-8ee6-4299-99ad-615711e87e3a',
                    text: 'titles',
                    value: 'Titles',
                    show: activityAllowed
                },
                {
                    heapId: 'id_4c78da22-9090-4ed6-b920-fe80f9e40930',
                    text: 'users',
                    value: 'Users',
                    show: isRangeLessThan30Days
                },
                {
                    heapId: 'id_00a6d752-97ce-40bc-90d6-3339bc783e93',
                    text: 'stats',
                    value: 'Usage'
                }
            ],
            onChange: $scope.changeDetailsGrid
        };
    }

    $scope.bindReport = function (filters) {
        $scope.reportFilters = filters ?? $scope.reportFilters;
        const { dates } = $scope.reportFilters;
        const { toDate, fromDate } = dates.getDates();
        $scope.fromDate = fromDate;
        $scope.toDate = toDate;
        $scope.timelineReady = false;
        if (!report.isItemSelected()) {
            report.setSelectedItem({ id: parseInt($state.params.app) });
        }
        if ($state.params.tab) {
            report.setDetailsMode($state.params.tab);
            $state.params.tab = null;
        }
        $scope.fromCache = false;
        const isRangeLessThan30Days = moment($scope.toDate).diff(moment($scope.fromDate), 'days') < 30;
        addUserDetails(isRangeLessThan30Days);
        $scope.tabOptions = setTabOptions(isRangeLessThan30Days);
        report.dataSource.read();
    };

    function updateUsageChartConfig() {
        function chartReadyCallback(value) {
            $scope.isUsageChartReady = value;
        }

        $scope.usageChartConfig = report.createChart({
            chartService,
            fullscreenChart: $scope.fullscreenChart,
            height: setDetailsHeight(),
            chartReadyCallback,
            isMobile: $scope.isMobile
        });
    }

    $scope.quickStatsCollapsed = function (collapsed) {
        const offset = collapsed ? 0 : 71;
        report.headerHeight = report.defaultHeaderHeight + offset;
    };

    function setTotals(data) {
        const totals = [];

        if ($scope.viewMode === 'summaryView') {
            totals.push(report.createQuickStat(data.totalProductiveTime, ProductivityStatus.Productive, false));
            totals.push(report.createQuickStat(data.totalUnproductiveTime, ProductivityStatus.Unproductive, false));
            totals.push(report.createQuickStat(data.totalUndefinedTime, ProductivityStatus.Undefined, false));
        } else {
            totals.push(report.createQuickStat(data.totalProductiveActiveTime, ProductivityStatus.Productive, true));
            totals.push(
                report.createQuickStat(data.totalProductivePassiveTime, ProductivityStatus.ProductivePassive, true)
            );
            totals.push(
                report.createQuickStat(data.totalUnproductiveActiveTime, ProductivityStatus.Unproductive, true)
            );
            totals.push(
                report.createQuickStat(data.totalUnproductivePassiveTime, ProductivityStatus.UnproductivePassive, true)
            );
            totals.push(report.createQuickStat(data.totalUndefinedActiveTime, ProductivityStatus.Undefined, true));
            totals.push(
                report.createQuickStat(data.totalUndefinedPassiveTime, ProductivityStatus.UndefinedPassive, true)
            );
        }

        return totals;
    }

    $scope.showUrlButtons = function (e, item, isSecondElement) {
        const options = report.showUrlButtons(e, item, isSecondElement, $scope.fullscreenChart);

        if (options) {
            $rootScope.$broadcast('atUpdateUrlButtons', options);
        }
    };

    $scope.toggleFullscreenChart = function (value) {
        $scope.fullscreenChart = value;
        $scope.reloadTrigger++;
        const tooltips = $document.find('[data-role=tooltip]');
        tooltips.each(function () {
            const tooltip = $(this).data('kendoTooltip');
            if (tooltip) {
                tooltip.hide();
            }
        });
        updateUsageChartConfig();
    };

    $scope.gotoClassificationPage = function (searchText, searchId) {
        if (searchId) {
            $state.go('app.settings.classification.id', { type: 'App', id: searchId });
        }
    };

    const detailsModeSubscription = report.detailsMode.subscribe(function (mode) {
        $scope.detailsMode = mode;
        $scope.isUsageChartReady = false;
    });

    const selectedItemSubscription = report.selectedItem.subscribe(function (selectedItem) {
        $scope.selectedApplication = selectedItem;
    });

    function updateGrid(gridName) {
        const reportGrid = report[gridName];
        const scopeGrid = $scope[gridName];
        const optionsField = gridName + 'Options';
        if (!reportGrid || !scopeGrid) {
            return;
        }

        $scope[optionsField] = reportGrid.updateOptions();
        scopeGrid.setOptions($scope[optionsField]);
        reportGrid.dataSource.read({ fromCache: $scope.fromCache });
    }

    function refreshDetails() {
        switch ($scope.detailsMode) {
            case 'titles':
                updateGrid('titlesGrid');
                break;
            case 'users':
                updateGrid('usersGrid');
                break;
            case 'stats':
                report.stats.refresh({ fromCache: $scope.fromCache });
                break;
        }
    }

    const viewModeSubscription = report.viewMode.subscribe(function (viewMode) {
        $scope.viewMode = viewMode;
        $scope.fromCache = true;
        updateGrid('mainGrid');

        if (accountSettings.username) {
            localStorageService.set('TopApplicationsViewMode-' + accountSettings.username, viewMode);
        }
    });

    $scope.viewToggleOptions = report.createViewToggleOptions(
        'ac430d02-347e-4153-a8d8-7f4165cde21f',
        '7ec902ee-28d9-4bd1-b859-0415c6399052'
    );

    const isLoadingSubscription = report.isLoading.subscribe(function (isLoading) {
        $scope.isLoading = isLoading;
        atHelperFunctions.safeApply($rootScope);
    });

    $scope.$on('$destroy', function () {
        detailsModeSubscription.unsubscribe();
        selectedItemSubscription.unsubscribe();
        isLoadingSubscription.unsubscribe();
        viewModeSubscription.unsubscribe();
        report.destroy();
    });

    report.createGrid('mainGrid', {
        autoBind: false,
        dataSource: report.dataSource,
        columnDefinitions: [
            {
                title: msg.get('prod'),
                field: 'productivity',
                width: '45px',
                attributes: {
                    class: 'text-center productivity'
                },
                template: report.getProductivityTemplate($scope.hasViewLevel('edit'), iconResolverServiceFunctions),
                filterable: false,
                sortable: 'productivity'
            },
            {
                field: 'title',
                title: msg.get('application'),
                template: report.getRowTemplate('apps'),
                attributes: {
                    class: 'text-nowrap'
                },
                filterable: true,
                sortable: 'string'
            },
            {
                title: msg.get('duration'),
                field: 'duration',
                width: '100px',
                attributes: {
                    class: 'text-center'
                },
                template: report.getDurationTemplate('duration'),
                filterable: false,
                sortable: 'duration',
                isHidden: function () {
                    return $scope.viewMode === 'detailedView';
                }
            },
            {
                title: 'Active',
                field: 'activeDuration',
                width: '100px',
                attributes: {
                    class: 'text-center'
                },
                template: report.getDurationTemplate('activeDuration'),
                filterable: false,
                sortable: 'duration',
                isHidden: function () {
                    return $scope.viewMode !== 'detailedView';
                }
            },
            {
                title: 'Passive',
                field: 'passiveDuration',
                width: '100px',
                attributes: {
                    class: 'text-center'
                },
                template: report.getDurationTemplate('passiveDuration'),
                filterable: false,
                sortable: 'duration',
                isHidden: function () {
                    return $scope.viewMode !== 'detailedView';
                }
            },
            {
                title: '%',
                field: 'percentage',
                width: '55px',
                attributes: {
                    class: 'text-center'
                },
                filterable: false,
                sortable: 'duration'
            }
        ],
        filterable: {
            mode: 'row'
        },
        selectable: 'row',
        sortable: true,
        scrollable: true,
        height: atHelperFunctions.getGridHeight($scope.mainGridHeight()),
        autoSelectOnDataBind: true,
        dataBoundFunction: function () {
            refreshDetails();

            const exportParams = {
                hasDataForTable: !$scope.noResults
            };

            setExportParamsStore(exportParams);
        }
    });

    if (activityAllowed) {
        report.createGrid('titlesGrid', {
            autoBind: false,
            dataSource: report.titles.dataSource,
            columnDefinitions: [
                {
                    field: 'title',
                    title: msg.get('title'),
                    attributes: {
                        class: 'break-word'
                    },
                    template: report.getRowTemplate('titles'),
                    filterable: true,
                    sortable: 'string'
                },
                {
                    title: msg.get('duration'),
                    field: 'duration',
                    width: '100px',
                    attributes: {
                        class: 'text-center'
                    },
                    template: report.getDurationTemplate('duration'),
                    filterable: false,
                    sortable: 'duration'
                },
                {
                    field: 'percentage',
                    title: '%',
                    width: '55px',
                    attributes: {
                        class: 'text-center'
                    },
                    filterable: false,
                    sortable: 'duration'
                },
                {
                    hidden: report.isTeamViewerRole,
                    template:
                        "#if (title !== 'Other') {#" +
                        "<a ui-sref=\"app.screenshots.history({title: dataItem.title, executable: selectedApplication.executable, description: selectedApplication.description})\" kendo-tooltip k-options=\"{ content: 'Search Screenshots', position: 'left', animation: { open: { effects: 'fade:in' }, close: { effects: 'fade:out' } } }\" ng-class=\"{'m-r-10': hasViewLevel('edit')}\"><i class=\"fa icon-at-screenshot\"></i></a>" +
                        "<a ng-if=\"hasViewLevel('edit')\" ng-click=\"state.go('app.alarms.details', {id: 'new', title: dataItem.title, executable: selectedApplication.executable, description: selectedApplication.description})\" kendo-tooltip k-options=\"{ content: 'Create Alarm', position: 'left', animation: { open: { effects: 'fade:in' }, close: { effects: 'fade:out' } } }\"><i class=\"fa fa-bell-o\"></i></a>" +
                        '#}#',
                    width: '65px',
                    attributes: {
                        class: 'text-center text-nowrap'
                    }
                }
            ],
            filterable: {
                mode: 'row'
            },
            sortable: true,
            scrollable: true,
            height: setDetailsHeight()
        });
    }

    $scope.mainGridOptions = report.mainGrid.getOptions();
    $scope.titlesGridOptions = activityAllowed ? report.titlesGrid.getOptions() : {};

    // Bind window resizing
    $scope.$on('atWindowResized', function () {
        setSelectedApplicationHeaderWidth();
        setStackControls();
        setDetailsHeight();
    });

    $scope.$on('sidebarToggledDelay', function () {
        setSelectedApplicationHeaderWidth();
        setStackControls();
    });

    $scope.$on('sidebarToggled', function () {
        setSelectedApplicationHeaderWidth();
        setStackControls();
    });

    // Export...
    exportInfoService.setExportInfo({
        mainDataSource: report.dataSource,
        getSecondaryParameters: function () {
            return {
                filter: report.dataSource.filter()
            };
        },
        getSortParameters: function () {
            return {
                sort: report.dataSource.sort()
            };
        }
    });
}
