import { createStore } from 'zustand';
import { UserIdentityGetProps } from '../models/UserIdentityGetProps';
import { defaultIdentitySearch } from '../constants/identitySearchTypes';

export interface IIdentitySearchStore {
  userIdentityParams: UserIdentityGetProps;
  lastUserSearchResultIds: number[];
}

export const IDENTITY_SEARCH_STORE_DEFAULT: IIdentitySearchStore = {
  userIdentityParams: defaultIdentitySearch,
  lastUserSearchResultIds: []
};

export const identitySearchStore = createStore<IIdentitySearchStore>(() => ({
  ...IDENTITY_SEARCH_STORE_DEFAULT
}));

// get the current state of the identity store
export const getIdentitySearchStore = (): IIdentitySearchStore =>
  identitySearchStore.getState();

export const setIdentitySearchState = (
  newIdentityState: IIdentitySearchStore
): void => {
  const current = identitySearchStore.getState();
  identitySearchStore.setState({ ...current, ...newIdentityState });
};

export const getIdentitySearchState = (): IIdentitySearchStore => {
  return identitySearchStore.getState();
};

/**
 * Resets the identity store to its default state.
 */
export const resetIdentitySearchStore = (): void =>
  identitySearchStore.setState(IDENTITY_SEARCH_STORE_DEFAULT);
