import { ApiRoutes } from '../constants/routesApi';
import { fetchData } from '../helpers';
import {
  ILiveReportSettingsDto,
  ILiveReportSettingsStore
} from '../models/ILiveReportSettingsStore';

const mapSettingsDtoToStore = (
  dto: ILiveReportSettingsDto
): ILiveReportSettingsStore => {
  return {
    includeTodaysDate: !(dto.liveReportExcludeTodaysDate ?? true),
    canUpdate: !(dto.dateControlLocked ?? true)
  };
};

export const fetchSettings = async (): Promise<ILiveReportSettingsStore> => {
  const liveReportSettings = await fetchData<ILiveReportSettingsDto>({
    path: ApiRoutes.liveReports.liveReports
  });
  return mapSettingsDtoToStore(liveReportSettings);
};
