import { userServiceFunctions } from '_app/serviceFunctions/userServiceFunctions';
import { reset as dataStoreManagerReset } from '_app/datastoreManager/DatastoreManager';
import { authenticatedUserService, inMemoryStore } from 'activtrak-ui-utilities';
import { atHelperFunctions } from './atHelperFunctions';
import { logout as logoutService } from '../serviceFunctions/logoutApiService';
import { removeDataDogContext, stopDataDogRumSessionRecording } from '../../_reactivtrak/src/common/config/dataDog';
import { logout as logoutV2 } from '../../_reactivtrak/src/common/helpers/authentication';
import { getAccountSettings } from '../../_reactivtrak/src/common/helpers/accountSettings/accountSettingsStore';
import { profileStore } from '../../_reactivtrak/src/common/services/Profile/useProfileState';
import authorization from '../../_reactivtrak/src/common/helpers/authorization';
import { Role } from '../../_reactivtrak/src/common/enums';

const constructor = () => {
    let _globals;
    let _eventListeners = {};
    let globalsSubscription;
    let _isLoggingOut = false;

    const clearScope = () => {
        for (let listener in _eventListeners) {
            if (_eventListeners[listener] && typeof _eventListeners[listener] === 'function') {
                _eventListeners[listener]();
            }
        }

        _globals = undefined;
        authenticatedUserService.clearGlobals();
    };

    const logout = async (params) => {
        if (_isLoggingOut) {
            return;
        }

        _isLoggingOut = true;
        const $injector = window.angular.element(document.getElementById('core-app')).injector();
        const notificationService = $injector.get('notificationService');

        try {
            // Make api calls to tell the backend the session is terminated
            try {
                await logoutService();
            } catch (e) {
                console.error('ActivTrak Error: Error occurred calling the Logout API', e);
            }
            const { account } = getAccountSettings();
            const { username } = profileStore.getState().profile;
            const teamPulseDataControl = inMemoryStore.get('teamPulseDataControl');

            localStorage.removeItem('productBoard-' + username);
            // Cleans out all local states
            notificationService.cancelPolling();
            const analyticsService = $injector.get('analyticsService');
            analyticsService.accountLogout();
            stopDataDogRumSessionRecording();
            removeDataDogContext();
            teamPulseDataControl.resetSubjectDefaults();
            dataStoreManagerReset();
            if (globalsSubscription) {
                globalsSubscription.unsubscribe();
            }
            clearScope();
            // Removes local storage and local session information
            localStorage.removeItem('msp.backup');
            localStorage.removeItem('activTrak.support.backup');

            // Removing key 'teamNotSetDate_' at logout so that the team modal can appear on the next login regardless of the date
            const currentAccountId = account;
            localStorage.removeItem('teamNotSetDate_' + currentAccountId);
            clearLastLogin();

            // Call this last, to allow background calls to complete like post_login
            authorization.clearAuthorizations();
            logoutV2(params);
            _isLoggingOut = false;
        } catch (e) {
            notificationService.showNotification(e, 'danger');
            console.error('ActivTrak Error: Error occured during the logout process', e);
            _isLoggingOut = false;
        }
    };

    inMemoryStore.set('logout', logout);

    const clearLastLogin = () => {
        authenticatedUserService.clearLastLogin();
    };

    const getGlobals = () => {
        return _globals;
    };

    const setGlobals = (globals, doNotStore) => {
        let inError = null;
        if (!doNotStore) {
            authenticatedUserService.setGlobals(globals);
        }

        if (globals.currentUser && globals.currentUser.token) {
            inError = userServiceFunctions.setUserToken(globals.currentUser.token);
        }
        _globals = globals;

        return inError;
    };

    const loadGlobals = () => {
        try {
            if (globalsSubscription) {
                globalsSubscription.unsubscribe();
            }

            const globalsObservable = authenticatedUserService.getGlobals();
            globalsSubscription = globalsObservable.subscribe((globals) => {
                if (
                    globals.currentUser &&
                    globalsObservable.value.currentUser &&
                    globals.currentUser.token !== globalsObservable.value.currentUser.token
                ) {
                    setGlobals(globals, true);
                } else if (atHelperFunctions.isEmpty(globals) && !atHelperFunctions.isEmpty(_globals)) {
                    logout({ next: `${window.location.pathname}${window.location.search}`.replace(/&/g, '%26') });
                }
            });
            _globals = globalsObservable.value;

            if (_globals.currentUser && _globals.currentUser.token) {
                userServiceFunctions.setUserToken(_globals.currentUser.token);
            }
        } catch (err) {
            console.error(`ActivTrak Error: Error loading globals.\nError:`, err);

            logout({
                logoutReason: 'An error occurred while loading the application.'
            });
        }
    };

    function getUrlParameter(name) {
        name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(window.location.href);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    const getUsername = () => {
        const accountId = getUrlParameter('accountId');
        if (accountId) {
            //get token
            var child = window.localStorage.getItem('activTrak.child-' + accountId);
            if (child) {
                var childProfile = JSON.parse(child);
                if (childProfile.username) {
                    return childProfile.username;
                }
            }
        }

        return _globals && _globals.currentUser && _globals.currentUser.username;
    };

    const isLoggedIn = () => {
        loadGlobals(); // AC-10910 Ensure we are using the local storage global object to verify login state
        return Boolean(_globals && !atHelperFunctions.isEmpty(_globals.currentUser));
    };

    const setUserToken = (token) => {
        _globals.currentUser.token = token;
        userServiceFunctions.setUserToken(token);
        authenticatedUserService.setGlobals(_globals);
    };

    const addEventListener = (name, listener) => {
        _eventListeners[name] = listener;
    };

    const appLoaded = (accountId, teamModalTemplate) => {
        let upgradeParams;
        const teamNotSetDateString = localStorage.getItem('teamNotSetDate_' + accountId);
        let isPastDelay = true;
        const teamModalDelay = moment.duration(60, 'minutes');

        if (teamNotSetDateString) {
            const teamNotSetDate = teamNotSetDateString ? moment(teamNotSetDateString) : null;
            const timeDiff = moment.utc().diff(teamNotSetDate, 'minutes');
            isPastDelay = timeDiff > teamModalDelay.asMinutes();
        }

        const teamNotSet = Boolean(localStorage.getItem('teamNotSet_' + accountId));

        // Here, we check if the team have not been set and if we are past the window refresh delay period
        if (teamNotSet && isPastDelay) {
            localStorage.setItem('teamNotSet_' + accountId, false);
            const $injector = window.angular.element(document.getElementById('core-app')).injector();
            const customUibModal = $injector.get('customUibModal');
            let teamModal = customUibModal.open({
                animation: false,
                template: teamModalTemplate,
                controller: 'signupTeamModalCtrl',
                size: 'md',
                backdrop: 'static',
                keyboard: false,
                windowClass: 'centered-modal'
            });

            teamModal.result.catch(angular.noop);
        } else if (localStorage.getItem('newAccount_' + accountId)) {
            // CJ Lead Event
            // TODO: Refactor this into analytics provider....
            upgradeParams = {
                oid: accountId,
                itemSku: 'FREE',
                amount: 0,
                quantity: 3,
                itemDiscount: 0,
                currency: 'USD',
                coupon: ''
            };
        }

        const setLocalStorageValues = () => {
            localStorage.setItem('newAccount_' + accountId, false);
            localStorage.setItem('teamNotSet_' + accountId, true);
            localStorage.setItem('teamNotSetDate_' + accountId, moment.utc().format());
        };

        if (localStorage.getItem('newAccount_' + accountId)) {
            setLocalStorageValues();
        }

        // Return CJ upgradeParams if set
        return upgradeParams;
    };

    const isRestrictedPage = () => {
        return ![
            '/login',
            '/signup',
            '/signup-login',
            '/verify',
            '/unsupportedBrowser',
            '/signup/google',
            '/chromebook/install'
        ].includes(window.location.pathname.split('?')[0]);
    };

    const isDifferentAccount = (url) => {
        let isDifferent;
        let userToken = userServiceFunctions.getUserToken();
        let accountId = userToken && userToken.decoded && userToken.decoded.AccountId;
        let urlParts = url.split('?');
        if (urlParts.length < 2) {
            return;
        }
        let params = urlParts[1].split(/[&]|%26/);
        params.forEach((param) => {
            let paramSplit = param.split('=');
            if (accountId && paramSplit[0].toLowerCase() === 'acctid') {
                isDifferent = decodeURIComponent(paramSplit[1]) !== accountId;
            }
        });

        return isDifferent === undefined
            ? undefined
            : {
                  isDifferent: isDifferent,
                  isAdmin: authorization.hasRole([Role.Admin, Role.SuperAdmin])
              };
    };

    const getLoginEmail = (url) => {
        let loginEmail;
        let urlParts = url.split('?');
        if (urlParts.length < 2) {
            return;
        }
        let params = urlParts[1].split(/[&]|%26/);
        params.forEach((param) => {
            let paramSplit = param.split('=');

            if (paramSplit[0].toLowerCase() === 'email') {
                loginEmail = decodeURIComponent(paramSplit[1]);
            }
        });

        return loginEmail;
    };

    const validate = () => {
        let restrictedPage = isRestrictedPage();
        const $injector = window.angular.element(document.getElementById('core-app')).injector();
        const state = $injector.get('$state');

        if (window.location.pathname.includes('/account/verify')) {
            state.go('verify');
        }

        let url = `${window.location.pathname}${window.location.search}`;
        // If URL parameter account id does not match logged in account id,
        // redirect to login and populate email field.
        // Used for deep linking from alarm webhooks.
        let diffObject = isDifferentAccount(url);
        if (isLoggedIn() && diffObject && (diffObject.isDifferent || !diffObject.isAdmin)) {
            let params = {
                next: url.replace(/&/g, '%26')
            };

            let loginEmail = getLoginEmail(url);
            if (loginEmail) {
                params.email = loginEmail;
            }

            logout(params);
        } else if (restrictedPage && !isLoggedIn()) {
            // If not logged in and going to restricted page, redirect to login
            if (url) {
                let email = getLoginEmail(url);
                state.go('login', { next: url.replace(/&/g, '%26'), email: email });
            } else {
                state.go('login');
            }
        }
    };

    let enableAccountWizard = () => {
        return userServiceFunctions.enableAccountWizard();
    };

    return {
        clearScope,
        logout,
        clearLastLogin,
        getGlobals,
        setGlobals,
        loadGlobals,
        getUsername,
        isLoggedIn,
        setUserToken,
        addEventListener,
        appLoaded,
        isRestrictedPage,
        isDifferentAccount,
        getLoginEmail,
        validate,
        enableAccountWizard
    };
};

export const loginServiceFunctions = inMemoryStore.get('loginServiceFunctions') || constructor();
inMemoryStore.set('loginServiceFunctions', loginServiceFunctions);
