import { getAccountSettings } from '../../../../_reactivtrak/src/common/helpers/accountSettings/accountSettingsStore';
import Report from '../../../../_app/reports/Report';
import { categoryServiceFunctions } from '../../../../_app/classification/categoryServiceFunctions';
import { generateParameters } from '../../../../_reactivtrak/src/common/components/ReportFilters/utils/generateParameters.ts';

// Register report component
import './topcategories.component.js';

angular.module('app').controller('TopCategoriesCtrl', TopCategoriesCtrl);

TopCategoriesCtrl.$inject = [
    '$scope',
    '$state',
    '$rootScope',
    'messagesService',
    'topCategoriesService',
    'localStorageService',
    'atHelperFunctions',
    'templateServiceFunctions',
    'authorizationService'
];

function TopCategoriesCtrl(
    $scope,
    $state,
    $rootScope,
    msg,
    topCategoriesService,
    localStorageService,
    atHelperFunctions,
    templateServiceFunctions,
    authorizationService
) {
    const accountSettings = getAccountSettings();
    $scope.usedLicenses = accountSettings.usedLicenses;
    $scope.download = $rootScope.download;
    $scope.isDetailsLoading = true;
    $scope.reportFilters = {};

    const report = new Report('Categories', {
        localStorageService,
        username: accountSettings.username,
        timeFormat: accountSettings.timeFormat,
        headerHeight: 232,
        detailsHeaderHeightOffset: 126,
        detailsControlHeightOffset: 43,
        detailsMode: 'sitesAndApps',
        offsetHorizontal: -10,
        isTeamViewerRole: authorizationService.hasRole(authorizationService.roles.viewer)
    });

    report.addDetail('sitesAndApps', {
        pageSize: 50,
        dataFunction: function (options) {
            if (options.data.fromCache && report.sitesAndAppsGrid.cachedResults) {
                return options.success(report.sitesAndAppsGrid.cachedResults);
            } else {
                $scope.isDetailsLoading = true;
                const itemId = report.selectedItemId();
                if (
                    categoryServiceFunctions.isValidCategory(itemId) ||
                    itemId === categoryServiceFunctions.categoryGuid.totalUncategorized
                ) {
                    topCategoriesService
                        .getTitles(itemId, generateParameters($scope.reportFilters, {}))
                        .then(function (result) {
                            report.sitesAndAppsGrid.cachedResults = result.data;
                            options.success(result.data);
                            $scope.isDetailsLoading = false;
                        })
                        .catch(function (error) {
                            options.error(error);
                            $scope.isDetailsLoading = false;
                            if (error?.message) {
                                $scope.$emit('showNotification', {
                                    message: error.message,
                                    color: 'danger'
                                });
                            }
                            console.error(
                                'ActivTrak Error: Error loading Top Categories Report activity details tab.',
                                error
                            );
                        });
                } else {
                    options.success([]);
                    $scope.isDetailsLoading = false;
                }
            }
        }
    });

    report.addDetail('users', {
        pageSize: 50,
        dataFunction: function (options) {
            if (options.data.fromCache && report.usersGrid.cachedResults) {
                return options.success(report.usersGrid.cachedResults);
            } else {
                $scope.isDetailsLoading = true;
                const itemId = report.selectedItemId();
                if (
                    categoryServiceFunctions.isValidCategory(itemId) ||
                    itemId === categoryServiceFunctions.categoryGuid.totalUncategorized
                ) {
                    topCategoriesService
                        .getUsers(itemId, generateParameters($scope.reportFilters, {}))
                        .then(function (result) {
                            report.usersGrid.cachedResults = result.data;
                            options.success(result.data);
                            $scope.isDetailsLoading = false;
                        })
                        .catch(function (error) {
                            options.error(error);
                            $scope.isDetailsLoading = false;
                            if (error?.message) {
                                $scope.$emit('showNotification', {
                                    message: error.message,
                                    color: 'danger'
                                });
                            }
                            console.error('ActivTrak Error: Error loading Top Categories Report users tab.', error);
                        });
                } else {
                    options.success([]);
                    $scope.isDetailsLoading = false;
                }
            }
        }
    });

    $scope.tooltipTimeFormat = report.tooltipTimeFormat;

    $scope.canEditClassification = authorizationService.hasRouteByName('app.settings.classification.tab');

    $scope.durationTemplate = templateServiceFunctions.durationTemplate;

    $scope.mainGridHeight = function () {
        return report.headerHeight;
    };

    $scope.detailsGridHeight = function () {
        return (
            report.headerHeight +
            report.detailsHeaderHeightOffset +
            ($scope.stackControls ? report.detailsControlHeightOffset : 0)
        );
    };

    $scope.changeState = function (stateName, stateParams) {
        $state.go(stateName, stateParams);
    };

    report.setDataSource(function (options) {
        report.isLoading.next(true);
        if (options.data.fromCache && report.mainGrid.cachedResults) {
            const data = report.mainGrid.cachedResults;
            $scope.noResults = !(data && data.length > 0);
            return options.success(data);
        } else {
            topCategoriesService
                .getTopCategories(generateParameters($scope.reportFilters, {}))
                .then(function (result) {
                    const data = result.data;
                    $scope.noResults = !(data && data.length > 0);
                    report.mainGrid.cachedResults = data;
                    options.success(data);
                })
                .catch(function (error) {
                    options.error(error);
                    if (error?.message) {
                        $scope.$emit('showNotification', {
                            message: error.message,
                            color: 'danger'
                        });
                    }
                    console.error('ActivTrak Error: Error loading Top Categories Report main grid data.', error);
                });
        }
    });
    $scope.topCategoriesSource = report.dataSource;

    $scope.categoryClicked = function (row) {
        report.itemClicked(row);
    };

    $scope.changeDetailsGrid = function (mode) {
        report.setDetailsMode(mode);
        if (accountSettings.username) {
            localStorageService.set('TopCategoriesDetailsMode-' + accountSettings.username, mode);
        }
    };

    $scope.tabOptions = {
        tabs: [
            {
                heapId: 'id_6db8831c-7bbb-4389-b6ff-bd519471fcd3',
                text: 'sitesAndApps',
                value: 'Activities'
            },
            {
                heapId: 'id_31f7e746-5d68-43cc-bf1c-6dd7c9887669',
                text: 'users',
                value: 'Users'
            }
        ],
        onChange: $scope.changeDetailsGrid
    };

    $scope.showUrlButtons = function (e, item) {
        const options = report.showUrlButtons(e, item, false, $scope.fullscreenChart);

        if (options) {
            $rootScope.$broadcast('atUpdateUrlButtons', options);
        }
    };

    $scope.gotoClassificationPage = function () {
        $state.go('app.settings.classification.tab', { activeTab: 'settings' });
    };

    $scope.bindReport = function (filters) {
        $scope.reportFilters = filters ?? $scope.reportFilters;
        if (!report.isItemSelected() || !categoryServiceFunctions.isValidCategory(report.selectedItemId())) {
            report.setSelectedItem({ title: $state.params.category });
        }
        if ($state.params.tab) {
            report.setDetailsMode($state.params.tab);
            $state.params.tab = null;
        }
        $scope.fromCache = false;
        report.dataSource.read();
    };

    const detailsModeSubscription = report.detailsMode.subscribe(function (mode) {
        $scope.detailsMode = mode;
    });

    const selectedItemSubscription = report.selectedItem.subscribe(function (selectedItem) {
        $scope.selectedCategory = selectedItem;
    });

    function updateGrid(gridName) {
        const reportGrid = report[gridName];
        const scopeGrid = $scope[gridName];
        const optionsField = gridName + 'Options';
        if (!reportGrid || !scopeGrid) {
            return;
        }

        $scope[optionsField] = reportGrid.updateOptions();
        scopeGrid.setOptions($scope[optionsField]);
        reportGrid.dataSource.read({ fromCache: $scope.fromCache });
    }

    function refreshDetails() {
        updateGrid($scope.detailsMode);
    }

    const viewModeSubscription = report.viewMode.subscribe(function (viewMode) {
        $scope.viewMode = viewMode;
        $scope.fromCache = true;
        updateGrid('mainGrid');
        updateGrid('sitesAndAppsGrid');
        updateGrid('usersGrid');

        if (accountSettings.username) {
            localStorageService.set('TopCategoriesViewMode-' + accountSettings.username, viewMode);
        }
    });

    $scope.viewToggleOptions = report.createViewToggleOptions(
        '21050a2b-0a8c-48e2-9ed7-dd99a16d800a',
        'f424297c-0f7f-4344-b5b1-17a520fdfb3b'
    );

    const isLoadingSubscription = report.isLoading.subscribe(function (isLoading) {
        $scope.isLoading = isLoading;
    });

    $scope.$on('$destroy', function () {
        detailsModeSubscription.unsubscribe();
        selectedItemSubscription.unsubscribe();
        isLoadingSubscription.unsubscribe();
        viewModeSubscription.unsubscribe();
        report.destroy();
    });

    report.createGrid('mainGrid', {
        autoBind: false,
        dataSource: report.dataSource,
        columnDefinitions: [
            {
                field: 'title',
                title: msg.get('category'),
                filterable: true,
                sortable: 'string'
            },
            {
                title: msg.get('duration'),
                field: 'duration',
                width: '100px',
                attributes: {
                    class: 'text-center'
                },
                template: report.getDurationTemplate('duration'),
                filterable: false,
                sortable: 'duration',
                isHidden: function () {
                    return $scope.viewMode === 'detailedView';
                }
            },
            {
                title: 'Active',
                field: 'activeDuration',
                width: '100px',
                attributes: {
                    class: 'text-center'
                },
                template: report.getDurationTemplate('activeDuration'),
                filterable: false,
                sortable: 'duration',
                isHidden: function () {
                    return $scope.viewMode !== 'detailedView';
                }
            },
            {
                title: 'Passive',
                field: 'passiveDuration',
                width: '100px',
                attributes: {
                    class: 'text-center'
                },
                template: report.getDurationTemplate('passiveDuration'),
                filterable: false,
                sortable: 'duration',
                isHidden: function () {
                    return $scope.viewMode !== 'detailedView';
                }
            },
            {
                title: '%',
                field: 'percentage',
                width: '55px',
                attributes: {
                    class: 'text-center'
                },
                filterable: false,
                sortable: 'duration'
            }
        ],
        filterable: {
            mode: 'row'
        },
        selectable: 'row',
        sortable: true,
        scrollable: true,
        height: atHelperFunctions.getGridHeight($scope.mainGridHeight()),
        autoSelectOnDataBind: true,
        dataBoundFunction: function () {
            refreshDetails();
        }
    });

    report.createGrid('sitesAndAppsGrid', {
        autoBind: false,
        dataSource: report.sitesAndApps.dataSource,
        columnDefinitions: [
            {
                field: 'title',
                title: msg.get('title'),
                attributes: {
                    class: 'break-word'
                },
                template: report.getRowTemplate('sitesAndApps'),
                filterable: true,
                sortable: 'string'
            },
            {
                title: msg.get('duration'),
                field: 'duration',
                width: '100px',
                attributes: {
                    class: 'text-center'
                },
                template: report.getDurationTemplate('duration'),
                filterable: false,
                sortable: 'duration',
                isHidden: function () {
                    return $scope.viewMode === 'detailedView';
                }
            },
            {
                title: 'Active',
                field: 'activeDuration',
                width: '100px',
                attributes: {
                    class: 'text-center'
                },
                template: report.getDurationTemplate('activeDuration'),
                filterable: false,
                sortable: 'duration',
                isHidden: function () {
                    return $scope.viewMode !== 'detailedView';
                }
            },
            {
                title: 'Passive',
                field: 'passiveDuration',
                width: '100px',
                attributes: {
                    class: 'text-center'
                },
                template: report.getDurationTemplate('passiveDuration'),
                filterable: false,
                sortable: 'duration',
                isHidden: function () {
                    return $scope.viewMode !== 'detailedView';
                }
            }
        ],
        filterable: {
            mode: 'row'
        },
        scrollable: {
            virtual: true
        },
        height: atHelperFunctions.getGridHeight($scope.detailsGridHeight())
    });

    report.createGrid('usersGrid', {
        autoBind: false,
        dataSource: report.users.dataSource,
        columnDefinitions: [
            {
                field: 'userName',
                title: msg.get('users'),
                filterable: true,
                sortable: 'string'
            },
            {
                title: msg.get('duration'),
                field: 'duration',
                width: '100px',
                attributes: {
                    class: 'text-center'
                },
                template: report.getDurationTemplate('duration'),
                filterable: false,
                sortable: 'duration',
                isHidden: function () {
                    return $scope.viewMode === 'detailedView';
                }
            },
            {
                title: 'Active',
                field: 'activeDuration',
                width: '100px',
                attributes: {
                    class: 'text-center'
                },
                template: report.getDurationTemplate('activeDuration'),
                filterable: false,
                sortable: 'duration',
                isHidden: function () {
                    return $scope.viewMode !== 'detailedView';
                }
            },
            {
                title: 'Passive',
                field: 'passiveDuration',
                width: '100px',
                attributes: {
                    class: 'text-center'
                },
                template: report.getDurationTemplate('passiveDuration'),
                filterable: false,
                sortable: 'duration',
                isHidden: function () {
                    return $scope.viewMode !== 'detailedView';
                }
            }
        ],
        filterable: {
            mode: 'row'
        },
        sortable: true,
        scrollable: {
            virtual: true
        },
        height: atHelperFunctions.getGridHeight($scope.detailsGridHeight())
    });

    $scope.mainGridOptions = report.mainGrid.getOptions();
    $scope.sitesAndAppsGridOptions = report.sitesAndAppsGrid.getOptions();
    $scope.usersGridOptions = report.usersGrid.getOptions();
}

angular.module('app').service('topCategoriesService', TopCategoriesService);

TopCategoriesService.$inject = ['$http', 'envConfig'];

function TopCategoriesService($http, envConfig) {
    this.getApiUrl = function (subpage, id) {
        let url = envConfig.reportingServiceUrl() + '/reports/v1/topcategories';

        if (subpage !== undefined) {
            if (id !== undefined) {
                url += '/' + id;
            }
            url += '/' + subpage;
        }

        return url + '?';
    };
    this.getTopCategories = function (parameters) {
        return $http.get(this.getApiUrl() + parameters);
    };

    this.getTitles = function (id, parameters) {
        return $http.get(this.getApiUrl('activities', id) + parameters);
    };

    this.getUsers = function (id, parameters) {
        return $http.get(this.getApiUrl('users', id) + parameters);
    };
}
