import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ToggleIconSC } from '../../Filter/NesterCheckboxFilter.styles';
import { regularFontWeight } from '../../../constants';
import { ReportType } from '../../../enums/ReportType';
import { IExportMenuOption } from '../constants/exportMenuOptions';

interface IAsyncExportMenuItemProps {
  option: IExportMenuOption;
  reportType?: ReportType;
  exportInProgress?: boolean;
  handleSaveExportClick?: (
    exportDestination: string,
    menuOption?: string
  ) => void;
  exportType?: string;
}

export const AsyncExportMenuItem = (props: IAsyncExportMenuItemProps) => {
  const {
    option,
    exportInProgress,
    handleSaveExportClick,
    reportType,
    exportType
  } = props;

  const [subMenuOpen, setSubMenuOpen] = useState<string>(null);

  const toggleSubMenuOpen = (key: string) => {
    setSubMenuOpen((prevState) => (prevState === key ? null : key));
  };

  // RECURSIVE CONDITION
  if (option.subItems?.length) {
    return (
      <>
        <ToggleIconSC
          onClick={() => toggleSubMenuOpen(option.key)}
          sx={{ py: '4px', fontWeight: regularFontWeight }}
          id={option.id(reportType)}
        >
          {option.label}
          <ListItemIcon>
            {subMenuOpen === option.key ? <ExpandLess /> : <ExpandMore />}
          </ListItemIcon>
        </ToggleIconSC>
        <Collapse in={subMenuOpen === option.key} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {option.subItems.map((subItem) => (
              <AsyncExportMenuItem
                option={subItem}
                exportType={option.exportType}
                key={`export-menu-item-${subItem.key}-collapsed`}
                reportType={reportType}
                exportInProgress={exportInProgress}
                handleSaveExportClick={handleSaveExportClick}
              />
            ))}
          </List>
        </Collapse>
      </>
    );
  }

  // BASE CONDITION
  return (
    <ListItemButton
      key={option.key}
      sx={{ py: '8px' }}
      onClick={() => handleSaveExportClick(option.key, exportType)}
      disabled={!option.subItems && exportInProgress}
      id={option.id(reportType)}
    >
      {option.icon && (
        <ListItemIcon
          sx={{
            minWidth: '32px',
            '& .MuiSvgIcon-root': { fontSize: '20px', margin: 'auto' }
          }}
        >
          {option.icon}
        </ListItemIcon>
      )}
      <ListItemText primary={option.label} />
    </ListItemButton>
  );
};
