'use strict';

import { getAccountSettings } from '../../_reactivtrak/src/common/helpers/accountSettings/accountSettingsStore';

angular.module('verify', ['ui.router', 'ui.router.state.events', 'config', 'utils']);
angular.module('verify').controller('VerifyCtrl', VerifyCtrl);

VerifyCtrl.$inject = ['$scope', '$state', 'verificationApiService', 'analyticsService', 'loginService'];

function VerifyCtrl($scope, $state, verificationApiService, analyticsService, loginService) {
    $scope.vtoken = $state.params.vtoken;

    $scope.verifyResult = function (result) {
        var email = $state.params.email;

        // If logged in and email in url query does not match logged in email, logout user
        if (loginService.isLoggedIn() && email !== loginService.getUsername()) {
            loginService.clearScope();
        }
        var verifyResult = result ? 'verifySuccess' : 'verifyError';

        var stateParams = {
            email: email
        };
        stateParams[verifyResult] = true;
        var stateName;

        const { usedLicenses } = getAccountSettings();
        if (!loginService.isLoggedIn()) {
            stateName = 'login';
        } else if (usedLicenses === 0) {
            stateName = 'app.agentactivation';
        } else {
            stateName = 'app.dashboard';
        }

        $state.go(stateName, stateParams);
    };

    verificationApiService
        .verifyByToken($scope.vtoken)
        .then(function (result) {
            analyticsService.accountVerified(result && result.data && result.data.accountId);
            $scope.verifyResult(true);
        })
        .catch(function () {
            $scope.verifyResult(false);
        });
}
