import { createStore } from 'zustand';
import { IRealtimeSettingsStore } from '../models/IRealtimeSettingsStore';
import { fetchSettings } from '../utils/realtimeSettingsStore.utils';

export const REALTIME_SETTINGS_STORE_DEFAULT_STATE: IRealtimeSettingsStore = {
  thumbnailPollDelay: 1000,
  messageDelayThreshold: 5000,
  messageTimeoutThreshold: 10000,
  lastSeenThreshold: 2000,
  realtimePollDelay: 2000,
  realtimePollAfterError: 10000
};

export const realtimeSettingsStore = createStore<IRealtimeSettingsStore>(
  () => REALTIME_SETTINGS_STORE_DEFAULT_STATE
);

/**
 * Fetches the export from the API and sets it in the export store.
 */
export const fetchRealtimeSettings =
  async (): Promise<IRealtimeSettingsStore> => {
    const response = await fetchSettings();
    const currentSettings = realtimeSettingsStore.getState();
    realtimeSettingsStore.setState({ ...currentSettings, ...response });
    return realtimeSettingsStore.getState();
  };

/**
 * Returns the current export store state.
 */
export const getRealtimeSettingsStore = (): IRealtimeSettingsStore =>
  realtimeSettingsStore.getState();

/**
 * Resets the export store to its default state.
 */
export const resetRealtimeSettingsStore = (): void =>
  realtimeSettingsStore.setState(REALTIME_SETTINGS_STORE_DEFAULT_STATE);
