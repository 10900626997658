import { ApiRoutes } from '../constants/routesApi';
import { BundleFlag } from '../enums/BundleFlag';
import { Role } from '../enums/Roles';
import { fetchData } from '../helpers';
import { formatDate } from './datetime/datetimeFormatters';
import authorizationService from '../helpers/authorization';
import { getPrivacySettings } from '../hooks/privacySettingsStore';
import {
  IScreenshotSafeSearchInfoDto,
  IScreenshotSafeSearchSettingsStore,
  IScreenshotSafeSearchThresholdsDto
} from '../models/IScreenshotSafeSearchSettingsStore';
import { SCREENSHOT_SAFE_SEARCH_SETTINGS_STORE_DEFAULT } from '../stores/screenshotSafeSearchSettingsStore';
import { formatBytes } from './numberFormat.utils';

const mapSettings = (
  safeSearchInfo: IScreenshotSafeSearchInfoDto,
  safeSearchThresholds: IScreenshotSafeSearchThresholdsDto
): IScreenshotSafeSearchSettingsStore => {
  const { quotaLimit, quotaUsage, quotaRemaining, renewDate } = safeSearchInfo;

  const settings: IScreenshotSafeSearchSettingsStore = {
    enabled: safeSearchInfo.enabled,
    quotaLimit: safeSearchInfo.quotaLimit,
    quotaUsage: safeSearchInfo.quotaUsage,
    quotaRemaining: safeSearchInfo.quotaRemaining,
    renewDate: safeSearchInfo.renewDate,
    quotaUsageLabel: formatBytes(quotaUsage, 'screenshots'),
    quotaLimitLabel: formatBytes(quotaLimit, 'screenshots'),
    quotaRemainingLabel: formatBytes(quotaRemaining, 'screenshots'),
    renewDateLabel: formatDate(renewDate),
    adultThreshold: safeSearchThresholds.adultThreshhold,
    medicalThreshold: safeSearchThresholds.medicalThreshhold,
    racyThreshold: safeSearchThresholds.racyThreshhold,
    violenceThreshold: safeSearchThresholds.violenceThreshhold
  };

  return settings;
};

export const fetchSettings =
  async (): Promise<IScreenshotSafeSearchSettingsStore> => {
    const { screenshotsAllowed = false } = getPrivacySettings();
    if (
      screenshotsAllowed &&
      authorizationService.hasFeature(BundleFlag.ScreenshotFlagging) &&
      authorizationService.hasAnyRole([
        Role.Admin,
        Role.SuperAdmin,
        Role.SupportBasic
      ])
    ) {
      try {
        const safeSearchInfo = await fetchData<IScreenshotSafeSearchInfoDto>({
          path: ApiRoutes.settings.safeSearchInfo
        });
        const safeSearchThresholds =
          await fetchData<IScreenshotSafeSearchThresholdsDto>({
            path: ApiRoutes.settings.safeSearchThresholds
          });

        return mapSettings(safeSearchInfo, safeSearchThresholds);
      } catch (error) {
        console.error(
          'ActivTrak: Error fetching Screenshot Redaction Settings',
          error
        );
      }
    }

    return SCREENSHOT_SAFE_SEARCH_SETTINGS_STORE_DEFAULT;
  };
