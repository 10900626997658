import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useUIRouterHistory } from '../../common/hooks/useUIRouterHistory';
import { IIdentityDetailState } from '../models/IIdentityDetailState';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useEffect, useState } from 'react';
import { DetailNavButton, NavStatus } from '../styles/UserDetail.view.styles';
import { backToUsersButton } from '../constants/ButtonLabels';
import { PageDirection } from '../models/PageDirection';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { GetNextUserIdFromArray } from '../utils/DetailEmailEmployeeIdUtils';
import { getIdentitySearchState } from '../../common/stores/identitySearchStore';
import { IUserSearchState } from '../models/IUserSearchState';

type ComponentProps = {
  userSearchState: IUserSearchState;
  identityDetailState: IIdentityDetailState;
};

export const DetailNavHeader = (props: ComponentProps) => {
  const { userSearchState } = props;
  const { isLoadingDetail, selectedIdentity } = props.identityDetailState;
  const { identities, isLoadingIdentities } = userSearchState;
  const history = useUIRouterHistory();

  const handleIdentityBackClick = () => {
    const backRoute = 'app.settings.identity';
    history.push(backRoute);
  };

  const [userIndex, setUserIndex] = useState<number>(-1);
  const [userTotal, setUserTotal] = useState<number>(-1);
  const [nextUserId, setNextUserId] = useState<number>(-1);
  const [prevUserId, setPrevUserId] = useState<number>(-1);

  const navigateToIdentity = (id: number) => {
    resetIdsAndTotal();
    history.push('app.settings.identity_id', { id });
  };

  const resetIdsAndTotal = () => {
    setUserIndex(-1);
    setUserTotal(-1);
    setNextUserId(-1);
    setPrevUserId(-1);
  };

  useEffect(() => {
    const searchIds = getIdentitySearchState().lastUserSearchResultIds;
    const tempUserTotal = searchIds?.length ?? 0;
    setUserTotal(tempUserTotal);
    //if we came here directly, there will be no saved search ids
    if (tempUserTotal == 0 && !isLoadingIdentities && !identities) {
      userSearchState.initializeUserIdentities();
    }
    setUserIndex(1 + searchIds.findIndex((val) => val == selectedIdentity?.id));
    setNextUserId(
      GetNextUserIdFromArray(
        PageDirection.Next,
        searchIds,
        selectedIdentity?.id
      )
    );
    setPrevUserId(
      GetNextUserIdFromArray(
        PageDirection.Previous,
        searchIds,
        selectedIdentity?.id
      )
    );
  }, [identities, isLoadingIdentities, selectedIdentity, userSearchState]);

  return (
    <Grid
      container
      spacing={3}
      sx={{ marginBottom: '12px' }}
      justifyContent="space-between"
    >
      <Grid item>
        <Button
          onClick={handleIdentityBackClick}
          startIcon={<KeyboardBackspaceIcon />}
        >
          {backToUsersButton}
        </Button>
      </Grid>
      <Grid item>
        <NavStatus>
          {' '}
          {!isLoadingDetail && userIndex !== -1 && userTotal > 0 && (
            <>
              <strong>#{userIndex}</strong> of <strong>{userTotal}</strong>{' '}
              Users
            </>
          )}
        </NavStatus>
        <DetailNavButton
          color="secondary"
          disabled={prevUserId == -1 || isLoadingDetail}
          onClick={() => {
            navigateToIdentity(prevUserId);
          }}
        >
          <ArrowBackIosIcon fontSize="inherit" />
        </DetailNavButton>
        <DetailNavButton
          color="secondary"
          disabled={nextUserId == -1 || isLoadingDetail}
          onClick={() => {
            navigateToIdentity(nextUserId);
          }}
        >
          <ArrowForwardIosIcon fontSize="inherit" />
        </DetailNavButton>
      </Grid>
    </Grid>
  );
};
