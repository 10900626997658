import menuAction from './menuAction';
import menuRule from './menuRule';
class menuItem {
    constructor({
        menu: { id, label, action, icon = {}, rules = [], beta, earlyAccess } = {},
        submenus,
        parentId = null,
        index = null,
        level = null
    } = {}) {
        // Log error if missing essential menu information
        if (!id) {
            console.error('ActivTrak Warning: No menu id given.', label);
        }

        if (!label) {
            console.error('ActivTrak Warning: No menu label given.', label);
        }

        if (!action) {
            console.error('ActivTrak Warning: No menu action given.', action);
        }

        // Remove icon from menu item if not on top level.
        if (icon !== {} && level !== 0) {
            icon = {};
        }

        this.id = id;
        this.parentId = parentId;
        this.analyticId = `id_${this.id}`;
        this.index = index;
        this.level = level;
        this.label = label;
        this.submenus = submenus;
        this.icon = icon;
        this.action = new menuAction(action);
        this.rules = rules.map((rule) => new menuRule(rule));
        this.submenuOpen = false;
        this.activeSubmenu = false;
        this.active = false;
        this.hasUpdates = false;
        this.isBeta = beta;
        this.isEarlyAccess = earlyAccess;
    }

    /**
     * Return on click options
     */
    onClick() {
        const { type, options } = this.action;
        switch (type) {
            case 'event':
                return { type: 'event', eventName: options.eventName };
            case 'toggleSubmenu':
                return { type: 'toggleSubmenu' };
            case 'internal':
                return {
                    type: 'stateChange',
                    stateName: options.ref,
                    modelId: options.modelId,
                    pageId: options.pageId,
                    subPageId: options.initialSubPageId
                };
            case 'external':
                return { type: 'link', url: options.ref };
            default:
                return {};
        }
    }

    /**
     * Return true if menu item has an icon defined.
     */
    hasIcon() {
        return Boolean(this.icon.type && this.icon.value);
    }

    /**
     * Return true if menu item icon is material type
     */
    useMaterialIcon() {
        return this.icon.type === 'material';
    }

    /**
     * Return true if menu item icon is activTrak type
     */
    useActivTrakIcon() {
        return this.icon.type === 'activTrak';
    }

    /**
     * Return true if menu item has submenus defined
     */
    hasSubmenus() {
        return Boolean(this.submenus);
    }

    /**
     * Return true if menu item is an internal action on click
     */
    isInternalAction() {
        return this.action.isInternalAction();
    }

    /**
     * Return true if menu item is an external action on click
     */
    isExternalAction() {
        return this.action.isExternalAction();
    }

    /**
     * Return true if menu item is an event action on click
     */
    isEventAction() {
        return this.action.isEventAction();
    }

    /**
     * Return true if menu item is a toggle submenu action on click
     */
    isToggleSubmenuAction() {
        return this.action.isToggleSubmenuAction();
    }

    /**
     * Return true if the menu item passes all rules to show
     *
     * @param {Object} ruleFunctions
     */
    show(ruleFunctions = {}) {
        if (typeof ruleFunctions.hasRouteByName !== 'function') {
            console.error(
                'ActivTrak Error: Unable to verify menu route due to missing rule function:',
                ruleFunctions.hasRouteByName
            );
            return false;
        }

        if (typeof ruleFunctions.hasFeature !== 'function') {
            console.error(
                'ActivTrak Error: Unable to verify menu rules due to missing rule function:',
                ruleFunctions.hasFeature
            );
            return false;
        }

        let showMenu = true;

        // If internal route, check to see if user has access to page
        if (this.action.isInternalAction()) {
            showMenu = ruleFunctions.hasRouteByName(this.action.options.ref);
        }

        _.forEach(this.rules, (rule) => (showMenu = showMenu && rule.pass(ruleFunctions)));

        return showMenu;
    }

    /**
     * Return true if a submenu is in the active state
     *
     * @param {Object} currentState
     */
    isSubmenuActive(currentState = {}) {
        return Boolean(
            _.find(
                this.submenus,
                (submenu) => submenu.action.isActiveMenu(currentState) || submenu.isSubmenuActive(currentState)
            )
        );
    }

    /**
     * Return true if a submenu is open
     *
     * @param {String} openId
     */
    isSubmenuOpen(openId) {
        return Boolean(
            _.find(this.submenus, (submenu) => {
                submenu.setSubmenuOpen(openId);
                return submenu.submenuOpen;
            })
        );
    }

    /**
     * Return the update string
     */
    getUpdateString() {
        return this.action.options.updateString;
    }

    /**
     * Set if submenus are active and return set state
     *
     * @param {Object} currentState
     */
    setActiveSubmenu(currentState) {
        this.activeSubmenu = this.isSubmenuActive(currentState);
        return this.activeSubmenu;
    }

    /**
     * Set if submenus are open and return set state
     *
     * @param {String} openId
     */
    setSubmenuOpen(openId) {
        this.submenuOpen = this.id === openId || this.isSubmenuOpen(openId);
        return this.submenuOpen;
    }

    /**
     * Set if menu item is active and return set state
     *
     * @param {Object} currentState
     */
    setActive(currentState = {}) {
        this.active = this.action.isActiveMenu(currentState);
        return this.active;
    }

    /**
     * Set active, active submenu, and submenu open on state change
     *
     * @param {Object} currentState
     */
    onStateChange(currentState, openMenuId) {
        this.setActive(currentState);
        this.submenuOpen = this.setActiveSubmenu(currentState);

        if (this.submenuOpen || this.active) {
            const nextOpenMenuId = this.isToggleSubmenuAction() ? this.id : this.parentId;
            openMenuId.next(nextOpenMenuId);
        }
    }

    /**
     * Return the count of submenu items.
     * Used for the animation of the submenu.
     */
    submenuCount() {
        return (this.submenus && this.submenus.length) || 0;
    }

    /**
     * Return the height string based on submenu open states.
     * Used for the animation of the submenu.
     */
    submenuHeight() {
        const countOpenSubmenus = (menu, count = 0) => {
            _.forEach(menu.submenus, (submenu) => {
                if (submenu.submenuOpen) {
                    count += submenu.submenuCount();

                    if (submenu.submenus) {
                        count = countOpenSubmenus(submenu, count);
                    }
                }
            });

            return count;
        };

        return `${countOpenSubmenus(this, this.submenuCount()) * 51}px`;
    }

    /**
     * Return the amount the menu indents based on its hierarchy level
     */
    indent() {
        if (!this.level) {
            return 0;
        }

        if (this.level === 1) {
            return '20px';
        }

        return `${this.level * 20}px`;
    }

    getSubNavigation() {
        return this.action.getSubNavigation();
    }

    isFallbackDashboard() {
        return this.action.isFallbackDashboard();
    }

    getGoalSettings() {
        return this.action.getGoalSettings();
    }

    getTag() {
        return this.action.getTag();
    }

    getDefaultPage() {
        return this.action.getDefaultSubPage();
    }

    getRouteName() {
        return this.action.getRouteName();
    }
}

export default menuItem;
