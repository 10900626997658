import viewTemplate from 'views/widgets/dashboard/topCategories.html?raw';
import { profileStore } from '../../../../../_reactivtrak/src/common/services/Profile/useProfileState';

angular.module('app').directive('topCategories', topCategories);

function topCategories() {
    return {
        restrict: 'E',
        scope: {
            parameters: '=',
            forceTitle: '@',
            categories: '@',
            printPage: '='
        },
        template: viewTemplate,
        controller: [
            '$scope',
            '$state',
            '$window',
            'chartService',
            'dashboardApiService',
            'atDashboardWidgetService',
            'trackEventService',
            'templateServiceFunctions',
            'browserServiceFunctions',
            'authorizationService',
            'localStorageService',
            function (
                $scope,
                $state,
                $window,
                chartService,
                dashboardApiService,
                atDashboardWidgetService,
                trackEventService,
                templateServiceFunctions,
                browserServiceFunctions,
                authorizationService,
                localStorageService
            ) {
                $scope.topCategoriesOptions = $scope.categories || 'All';
                $scope.hasData = true;
                $scope.widgetTitle = $scope.forceTitle || 'Top Categories';
                var { username } = profileStore.getState().profile;

                if (!$scope.printPage) {
                    localStorageService.set(
                        'topCategoriesDashboardWidgetOptions-' + username,
                        $scope.topCategoriesOptions
                    );
                }

                $scope.hasViewLevel = function (levels) {
                    return authorizationService.hasAuthorizationLevel(levels, 'app.settings.categories');
                };

                var bindWidget = function () {
                    $scope.topCategoriesChartConfig = {
                        getData: function () {
                            var promise = dashboardApiService.getCategories(
                                $scope.parameters,
                                $scope.topCategoriesOptions
                            );
                            promise.success(function (data) {
                                $scope.hasData = data.length > 0;
                                return data;
                            });
                            return promise;
                        },
                        buildChart: function (data) {
                            var barMax = Math.max.apply(
                                null,
                                data.map(function (item) {
                                    return item.total;
                                })
                            );

                            return chartService.buildColumnChart({
                                series: data.map(function (item) {
                                    return {
                                        name: item.category,
                                        data: [item.total],
                                        border: {
                                            width: 0
                                        }
                                    };
                                }),
                                tooltipTemplate: $window.kendo.template(function (data) {
                                    return (
                                        browserServiceFunctions.htmlEscape(data.series.name) +
                                        ' (' +
                                        templateServiceFunctions.friendlyViewTimeFormat(data.value) +
                                        ')'
                                    );
                                }),
                                labelTooltipTemplate: kendo.template(labelTooltipTemplate),
                                data: data,
                                height: $scope.printPage ? 320 : undefined,
                                majorUnit: templateServiceFunctions.calculateTimeMajorUnit(barMax),
                                seriesClick: function (e) {
                                    if (!browserServiceFunctions.isMobileAgent()) {
                                        trackEventService.track('tc-graph');
                                        $state.go('app.reports.topcategories', {
                                            category: e.series.name
                                        });
                                    }
                                },
                                delayUpdate: browserServiceFunctions.isIeOrEdgeBrowser()
                            });
                        }
                    };
                };

                function labelTooltipTemplate(item) {
                    return kendo.toString(templateServiceFunctions.friendlyViewTimeFormat(item.value));
                }

                function handleFilterChange(selection) {
                    if (!$scope.printPage) {
                        localStorageService.set('topCategoriesDashboardWidgetOptions-' + username, selection.text);
                    }

                    $scope.topCategoriesOptions = selection.text;
                    bindWidget();
                }

                $scope.filterButtonOptions = {
                    type: 'filter',
                    dynamicLabel: true,
                    heapId: 'id_9c83b57c-8146-4b40-a790-4715b7cd140f',
                    selectionList: [
                        {
                            heapId: 'id_ccb5f665-6380-442d-9a3b-440c8c0c552e',
                            text: 'All',
                            value: 'All'
                        },
                        {
                            heapId: 'id_7361d7f5-173b-4028-8edb-cc09c53157a2',
                            text: 'Applications',
                            value: 'Applications'
                        },
                        {
                            heapId: 'id_6c78f6e6-ea6d-440e-bcf3-350413cc7c54',
                            text: 'Websites',
                            value: 'Websites'
                        }
                    ],
                    onChange: handleFilterChange
                };

                function gotoSettingsPage() {
                    $state.go('app.settings.classification.tab', { activeTab: 'settings' });
                }

                $scope.settingsButtonOptions = {
                    label: 'Define Categories',
                    onClick: gotoSettingsPage
                };

                atDashboardWidgetService.registerWidget('topCategories', 7, bindWidget);
            }
        ]
    };
}
