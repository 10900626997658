import React, { useCallback } from 'react';
import { ReportViewTypes } from '../../common/enums/reports.enums';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useScheduleAdherenceViewStore } from '../hooks/useScheduleAdherenceViewStore';
import { setScheduleAdherenceView } from '../stores/scheduleAdherenceViewStore';

export const ScheduleAdherenceViewToggle = () => {
  const { view } = useScheduleAdherenceViewStore((s) => s);

  const handleToggleChange = useCallback(
    (e: React.MouseEvent<HTMLElement>, value: ReportViewTypes) => {
      if (value !== null) {
        setScheduleAdherenceView(value);
      }
    },
    []
  );
  return (
    <ToggleButtonGroup
      color="primary"
      value={view}
      exclusive
      onChange={handleToggleChange}
      aria-label="Report View"
      sx={{ mr: 2 }}
    >
      <ToggleButton value={ReportViewTypes.SUMMARY}>Summary</ToggleButton>
      <ToggleButton value={ReportViewTypes.DETAILED}>Detailed</ToggleButton>
    </ToggleButtonGroup>
  );
};
