import React from 'react';
import { IGridColumn } from '../../common/models';
import { TimeAgo } from '../../common/components/TimeAgo';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DropdownOptionsButton } from '../../common/components/DropdownOptionsButton';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { formatDate } from '../../common/utils/datetime/datetimeFormatters';
import { getTimeLapsedSinceNowString } from '../../common/utils/datetime/datetimeCalculations';

const GridRowActionButtonContainer = styled(Box)`
  button {
    border-radius: 50%;
    min-width: 32px;
  }
`;

export const ReassignToExistingUser = 'Reassign to existing user';
export const ReassignToNewUser = 'Reassign to new user';

export const useDetailAgentColumns = (
  handleChangeAgent: (event, option, dataItem) => void,
  isReadOnly: boolean
): IGridColumn[] => {
  const gridColumns: IGridColumn[] = [
    {
      field: 'userName',
      headerName: 'Username',
      isSortable: true,
      type: 'string',
      width: '27%'
    },
    {
      field: 'logonDomain',
      headerName: 'Logon Domain',
      type: 'string',
      isSortable: true,
      width: '27%'
    },
    {
      field: 'firstLog',
      headerName: 'First Log',
      type: 'date',
      isSortable: true,
      width: '15%',
      template: (dataItem) => {
        return <>{formatDate(dataItem.firstLog)}</>;
      }
    },
    {
      field: 'lastLog',
      headerName: 'Last Log',
      type: 'date',
      isSortable: true,
      width: '20%',
      template: (dataItem) => {
        const timeAgo = getTimeLapsedSinceNowString(dataItem.lastLog);
        return (
          <TimeAgo
            lastLog={dataItem.lastLog}
            learnMoreLink="https://support.activtrak.com/"
            timeAgo={timeAgo}
          />
        );
      }
    },
    {
      field: 'menu',
      headerName: '',
      type: 'string',
      isSortable: false,
      width: '7%',
      template: (dataItem) => {
        return (
          <GridRowActionButtonContainer>
            <DropdownOptionsButton
              id="ec9e9a0d-fd52-45e4-8302-12fd07d88fdf"
              icon={<MoreVertIcon />}
              dropDownOptions={[
                { label: ReassignToExistingUser },
                { label: ReassignToNewUser }
              ]}
              onChangeOption={(event, option) =>
                handleChangeAgent(event, option, dataItem)
              }
              hideSelectedOption
              disableSelectedItem
              disabled={isReadOnly}
              sx={{ width: '24px', minWidth: 'auto' }}
            />
          </GridRowActionButtonContainer>
        );
      }
    }
  ];

  return gridColumns;
};
