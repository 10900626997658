import { userServiceFunctions } from '../../../../_app/serviceFunctions/userServiceFunctions';
import template from 'views/widgets/screenshotViewer.html?raw';
import { profileStore } from '../../../../_reactivtrak/src/common/services/Profile/useProfileState';
import { BundleFlag } from '../../../../_reactivtrak/src/common/enums/BundleFlag';
import { getScreenshotSafeSearchSettingsStore } from '../../../../_reactivtrak/src/common/stores/screenshotSafeSearchSettingsStore';

angular
    .module('app')
    .controller('screenshotViewerCtrl', ScreenshotViewerCtrl)
    .directive('screenshotViewer', ScreenshotViewer);

function ScreenshotViewer() {
    return {
        restrict: 'E',
        scope: {
            config: '='
        },
        template,
        controller: 'screenshotViewerCtrl'
    };
}

ScreenshotViewerCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$location',
    '$timeout',
    '$document',
    '$state',
    'messagesService',
    '$window',
    'realtimeScreenshotService',
    'FileSaver',
    'localStorageService',
    'atHelperFunctions',
    'browserServiceFunctions',
    'authorizationService'
];

function ScreenshotViewerCtrl(
    $rootScope,
    $scope,
    $location,
    $timeout,
    $document,
    $state,
    msg,
    $window,
    realtimeScreenshotService,
    FileSaver,
    localStorageService,
    atHelperFunctions,
    browserServiceFunctions,
    authorizationService
) {
    var self = this;
    var { username } = profileStore.getState().profile;

    $scope.hasViewLevel = function (levels) {
        return authorizationService.hasAuthorizationLevel(levels, 'app.settings.productivity');
    };

    $scope.canEditClassification = authorizationService.hasRouteByName('app.settings.classification.tab');

    $scope.onButtonPress = function () {
        $scope.showUnsafe.temporary = true;
    };

    $scope.onButtonRelease = function () {
        $scope.showUnsafe.temporary = false;
    };

    $scope.onButtonPanEnd = function () {
        $scope.showUnsafe.temporary = false;
    };

    $scope.toggleShowFullSize = function () {
        localStorageService.set('showRealtimeFullSize-' + username, $scope.showFullSize);
    };

    $scope.isLoading = true;
    $scope.showingFullscreenImage = false;
    $scope.safeSearchFeatureFlag = authorizationService.hasFeature(BundleFlag.ScreenshotFlagging);
    $scope.isMobile = browserServiceFunctions.isMobileAgent();
    $scope.isIeOrEdgeBrowser = browserServiceFunctions.isIeOrEdgeBrowser();
    $scope.isDisconnected = false;
    $scope.isRealtime = $scope.config ? $scope.config.isRealtime : false;
    $scope.showFullSize = localStorageService.get('showRealtimeFullSize-' + username);
    $scope.showUnsafe = {
        current: false,
        temporary: false,
        all: false
    };

    $scope.nextIndex = -1;
    $scope.imageIndex = $scope.isRealtime ? 0 : -1;
    $scope.isSlideshow = false;
    $scope.navigateWithKeyboard = true;
    $scope.showingImage = false;
    var defaultSlideshowInterval = 6;
    $scope.slideshowInterval = defaultSlideshowInterval;
    var isLogPage = $state.is('app.reports.activitylog') || $state.is('app.reports.alarmlog');
    var seenPages = [];

    $scope.isPaused = false;
    $scope.isErrored = false;
    $scope.isFirstImageLoaded = false;
    $scope.connectionAttempt = 0;
    $scope.maxConnectionAttempts = 10;
    $scope.screenshotStorage = [];
    $scope.indexHidden = false;
    $scope.showScreenshotTitleConfig = false;
    $scope.isFullscreenButtonInvisible = false;
    var maxImagesInStorage = 10;
    var timerStopper;
    var lastRequestDate = new Date();
    var hideIndexTimeout;
    var loadingFullSize = false;

    var likelihoodMap = {
        Unknown: 0,
        VeryUnlikely: 1,
        Unlikely: 2,
        Possible: 3,
        Likely: 4,
        VeryLikely: 5
    };

    var likelihoodLabelMap = {
        1: 'Very unlikely',
        2: 'Unlikely',
        3: 'Possibly',
        4: 'Likely',
        5: 'Very likely'
    };

    var indexNames = ['adultThreshold', 'medicalThreshold', 'racyThreshold', 'violenceThreshold'];

    $scope.showDetailTitle = localStorageService.get('screenshotDetailTitle-' + username);
    if ($scope.showDetailTitle === null) {
        $scope.showDetailTitle = true;
    }

    function hideIndex() {
        $scope.indexHidden = false;

        if (hideIndexTimeout) {
            $timeout.cancel(hideIndexTimeout);
        }

        hideIndexTimeout = $timeout(function () {
            if (!($scope.isRealtime || $scope.isSlideshow) || ($scope.isRealtime && $scope.isPaused)) {
                $scope.indexHidden = true;
            }
        }, 3000);
    }

    $scope.unsafeReason = function (safeSearchObject) {
        if (!safeSearchObject) {
            return '<div>This screenshot has been flagged unsafe.</div>';
        }
        var { adultThreshold, medicalThreshold, racyThreshold, violenceThreshold } =
            getScreenshotSafeSearchSettingsStore();
        var thresholds = { adultThreshold, medicalThreshold, racyThreshold, violenceThreshold };

        var text =
            '<div class="m-b-20"><div class="m-b-15">This screenshot was flagged unsafe for the following reasons</div>';

        for (var key in indexNames) {
            var likelihoodLabel = safeSearchObject[indexNames[key]];
            var thresholdLabel = thresholds[indexNames[key]];
            var threshold = likelihoodMap[thresholdLabel];
            if (likelihoodLabel) {
                var likelihood = likelihoodMap[likelihoodLabel];
                if (likelihood >= threshold && likelihood <= 5) {
                    var category = indexNames[key];
                    text +=
                        '<div class="blur-image-text-fullscreen-reason"><i class="fa fa-circle m-r-5"></i>' +
                        likelihoodLabelMap[likelihood] +
                        ' contains ' +
                        category +
                        ' material<div>';
                }
            }
        }
        return text + '</div>';
    };

    $scope.temporarilyShowUnsafe = function () {
        $scope.showUnsafe.current = true;
        $scope.showUnsafe.temporary = true;
    };

    $scope.mouseup = function () {
        if ($scope.showUnsafe.temporary) {
            $scope.showUnsafe = false;
            $scope.showUnsafe.temporary = false;
        }
    };

    $scope.showDetailTitleChange = function () {
        $scope.showDetailTitle = !$scope.showDetailTitle;
        localStorageService.set('screenshotDetailTitle-' + username, $scope.showDetailTitle);
    };

    $scope.screenshotTitleConfigChange = function () {
        $scope.showScreenshotTitleConfig = !$scope.showScreenshotTitleConfig;
    };

    $scope.ignoreMovementRestrictions = function () {
        return $scope.config ? !!$scope.config.ignoreMovementRestrictions : false;
    };

    var imageCounter = 0;
    $scope.$watch('imageUrl', function (imageUrl) {
        var img;

        if (imageUrl) {
            $scope.isDisconnected = false;
            var innerCounter = ++imageCounter;
            var onLoad = function () {
                $timeout(function () {
                    if (innerCounter !== imageCounter || !$scope.showingImage) {
                        return;
                    }
                    $scope.imageBackgroundStyle = {
                        'background-image': 'url(' + img.attr('src').replace(/(\r\n|\n|\r)/gm, '') + ')'
                    };
                    img.remove();
                    img = null;
                    $scope.isLoading = false;
                    $scope.$emit('imageLoaded');
                });
            };

            img = angular.element('<img>').attr('src', imageUrl);
            img.on('load', onLoad);
            img.on('error', function () {
                $scope.$emit('imageLoadingError');
                $scope.isDisconnected = true;
            });
        } else {
            $scope.imageBackgroundStyle = {
                'background-image': null
            };
            $scope.isLoading = true;
            $scope.$emit('imageHidden');
        }
    });

    $scope.$watch('nextImageUrl', function (imageUrl) {
        var img;

        if (imageUrl) {
            var onLoad = function () {
                $timeout(function () {
                    $scope.nextImageBackgroundStyle = {
                        'background-image': 'url(' + img.attr('src').replace(/(\r\n|\n|\r)/gm, '') + ')'
                    };
                    img.remove();
                    img = null;
                });
            };

            img = angular.element('<img>').attr('src', imageUrl);
            img.on('load', onLoad);
        } else {
            $scope.nextImageBackgroundStyle = {
                'background-image': null
            };
        }
    });

    $scope.openFullscreen = function () {
        if (
            !$scope.imageUrl ||
            $scope.isDisconnected ||
            ($scope.isRealtime && !($scope.image && $scope.image.isFullSize)) ||
            ($scope.image && !$scope.image.isSafe && !$scope.showUnsafe.current)
        ) {
            return;
        }

        $scope.showingFullscreenImage = true;
        angular.element('body').addClass('fullscreen-view');

        if ($scope.isMobile) {
            $document.off('touchmove', '.fullscreen-view');
        }

        $scope.onFullSize();
    };

    $scope.closeFullscreen = function () {
        $scope.showingFullscreenImage = false;
        angular.element('body').removeClass('fullscreen-view');

        if ($scope.isMobile) {
            $document.on('touchmove', '.fullscreen-view', function (e) {
                e.preventDefault();
            });
        }
    };

    $scope.$watch('showingImage', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            if (newValue) {
                $rootScope.app.layout.fullscreenView = true;
            } else {
                $scope.imageUrl = null;

                $rootScope.app.layout.fullscreenView = false;

                $scope.onHideViewer();
            }
        }
    });

    $rootScope.$on('$locationChangeStart', function (e) {
        if ($scope.showingImage) {
            e.preventDefault();
            $scope.showingImage = false;

            if ($scope.showingFullscreenImage) {
                $scope.closeFullscreen();
            }
        }
    });

    if ($scope.navigateWithKeyboard && $scope.isMobile) {
        $document.on('touchmove', '.fullscreen-view', function (e) {
            e.preventDefault();
        });
    }

    $document.bind('keydown', function (e) {
        $scope.showScreenshotTitleConfig = false;
        $timeout(function () {
            if (!$scope.showingImage) {
                return;
            }

            if (e.which === 37 && !$scope.showingFullscreenImage && $scope.navigateWithKeyboard) {
                $scope.showPreviousImage();
            } else if (e.which === 39 && !$scope.showingFullscreenImage && $scope.navigateWithKeyboard) {
                $scope.showNextImage();
            } else if (e.which === 27) {
                if ($scope.showingFullscreenImage) {
                    $scope.closeFullscreen();
                } else {
                    $scope.showingImage = false;
                }
            }
        });
    });

    function storeScreenshot(screenshot) {
        $scope.screenshotStorage.push(screenshot);

        if ($scope.screenshotStorage.length > maxImagesInStorage) {
            $scope.screenshotStorage.shift();
        }
    }

    function clearStorage() {
        $scope.screenshotStorage = [];
        $scope.imageIndex = 0;
    }

    $scope.getIndexNumber = function () {
        if ($scope.isRealtime) {
            return $scope.screenshotStorage.length - $scope.imageIndex;
        } else {
            return isLogPage ? 0 : imageNumber();
        }
    };

    $scope.getIndexTotal = function () {
        if ($scope.isRealtime) {
            return $scope.screenshotStorage.length;
        } else {
            return isLogPage ? 0 : imageTotal();
        }
    };

    $scope.getIndex = function () {
        return $scope.getIndexNumber() + ' of ' + $scope.getIndexTotal();
    };

    function imageHandler(data, fromStorage) {
        if (data && data.Result) {
            $scope.isDisconnected = false;
            $scope.connectionAttempt = 0;
            self.showImage({
                data: data,
                fromStorage: fromStorage
            });
        }

        var timeDelta = new Date() - lastRequestDate;
        setPollTimeout(Math.max($scope.slideshowInterval * 1000 - timeDelta, 0));
    }

    function poll() {
        if ($scope.showingImage && !$scope.isPaused) {
            lastRequestDate = new Date();
            var containerSize = $scope.showFullSize
                ? null
                : {
                      width: $window.innerWidth
                  };
            loadingFullSize = $scope.showFullSize;
            realtimeScreenshotService.requestViewerImage($scope.data.item, containerSize, imageHandler);
        }
    }

    function setPollTimeout(delay) {
        if ($scope.showingImage && !$scope.isPaused) {
            timerStopper = $timeout(poll, delay);
        }
    }

    $scope.$on('showRealtimeScreenshotEvent', function (event, data) {
        $scope.isPaused = false;
        $scope.isDisconnected = false;
        $scope.isErrored = false;
        $scope.showingImage = true;
        $scope.data = data;
        poll();
    });

    $scope.switchPause = function () {
        if ($scope.isPaused) {
            unpause();
        } else {
            pause();
        }
    };

    $scope.downloadScreenshot = function () {
        if (!$scope.isRealtime) {
            var canvas = document.createElement('canvas');
            var img = document.createElement('img');

            img.crossOrigin = 'anonymous';
            angular.element(img).attr('src', $scope.imageUrl);

            angular.element(img).on('load', function () {
                canvas.height = img.naturalHeight;
                canvas.width = img.naturalWidth;
                var ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                canvas.toBlob(function (blob) {
                    FileSaver.saveAs(blob, $scope.getFileName());
                });
            });
        } else {
            var blob;
            var imageData = atob($scope.imageUrl.split(',')[1] || $scope.imageUrl);

            // eslint-disable-next-line
            var arrayBuffer = new ArrayBuffer(imageData.length);
            // eslint-disable-next-line
            var view = new Uint8Array(arrayBuffer);

            for (var i = 0; i < imageData.length; i++) {
                view[i] = imageData.charCodeAt(i) % 256;
            }

            try {
                blob = new Blob([arrayBuffer], {
                    type: 'application/octet-stream'
                });
            } catch (e) {
                var BlobBuilder = $window.WebKitBlobBuilder || $window.MozBlobBuilder;
                var bb = new BlobBuilder();
                bb.append(arrayBuffer);
                blob = bb.getBlob('application/octet-stream');
            }

            FileSaver.saveAs(blob, $scope.getFileName());
        }
    };

    $scope.getFileName = function () {
        // Error if image not found
        if (!$scope.image) {
            console.error('ActivTrak Error: get image, image index does not exist.', $scope.config);
            return;
        }

        try {
            var date = atHelperFunctions.convertTimezone($scope.image.Time || $scope.image.time);
            var dateFormat = 'yyyy-MM-dd_HH-mm-ss';
            return (
                ($scope.data ? $scope.data.item.user : $scope.image.user) +
                '_' +
                kendo.toString(date, dateFormat) +
                'U.png'
            );
        } catch (error) {
            console.error('ActivTrak Error: screenshots get image ', error);
        }
    };

    function pause() {
        if ($scope.isPaused) {
            return;
        }
        $scope.isPaused = true;
        if (timerStopper) {
            $timeout.cancel(timerStopper);
            timerStopper = null;
        }
        $scope.isDisconnected = false;
        $scope.connectionAttempt = 0;
    }

    function unpause() {
        if (!$scope.isPaused) {
            return;
        }
        $scope.isPaused = false;
        poll();
    }

    function isSmallScreen() {
        return window.innerWidth <= 768;
    }

    this.showImage = function (config, showUnsafe) {
        var newIndex = config.newIndex;
        var data = config.data;
        var fromStorage = config.fromStorage;
        var imageUrl;

        $scope.showUnsafe.current = showUnsafe;

        try {
            if ($scope.isRealtime) {
                if ($scope.isPaused && !fromStorage) {
                    return;
                }

                $scope.image = data && data.Result && data.Result.Screenshot;

                // Error if image not found
                if (!$scope.image) {
                    console.error('ActivTrak Error: show image, image index does not exist.', $scope.config);
                    return;
                }

                $scope.image.isSafe = true;
                $scope.image.isFullSize = loadingFullSize;
                loadingFullSize = false;
                $scope.agent = data.Result.AgentId;
                var item = data && data.Item;

                imageUrl = $scope.image.Data;
                if (imageUrl && imageUrl.indexOf('base64') < 0) {
                    imageUrl = 'data:image/jpg;base64,' + imageUrl;
                }
                var timestamp = atHelperFunctions.formatDate(atHelperFunctions.convertTimezone($scope.image.Time), {
                    span: false
                });
                $scope.imageTitle = timestamp + ' | ' + $scope.agent.UserName + ' | ' + $scope.image.Title;
                $scope.imageInfo = [
                    {
                        title: 'Time',
                        value: timestamp
                    },
                    {
                        title: 'Computer',
                        value: item.computerAlias
                            ? item.computerAlias + ' (' + item.computerName + ')'
                            : item.computerName
                    },
                    {
                        title: 'User',
                        value: item.userAlias || item.username
                    },
                    {
                        title: 'Title',
                        value: $scope.image.Title
                    },
                    {
                        title: 'URL',
                        value: $scope.image.URL
                    },
                    {
                        title: 'Executable',
                        value: $scope.image.Executable
                    },
                    {
                        title: 'Description',
                        value: $scope.image.Description
                    }
                ];
                $scope.isFirstImageLoaded = true;

                $scope.imageUrl = imageUrl;
                if (!fromStorage) {
                    storeScreenshot(data);
                    $scope.imageIndex = 0;
                }
            } else {
                $scope.isDisconnected = false;
                $scope.imageIndex = newIndex;
                if (newIndex === undefined || newIndex < 0) {
                    return;
                }

                if ($scope.config.images[newIndex + 1]) {
                    $scope.nextImage = $scope.config.images[newIndex + 1];
                    $scope.nextImageUrl = $scope.nextImage.fullsize || '';
                }

                $scope.image = $scope.config.images[newIndex];

                if ($scope.image === null && $scope.direction !== undefined) {
                    if ($scope.direction !== 'next') {
                        $scope.showPreviousImage();
                    } else {
                        $scope.showNextImage();
                    }
                    return;
                }

                // Error if image not found
                if (!$scope.image) {
                    console.error('ActivTrak Error: show image, image index does not exist.', $scope.config);
                    return;
                }

                $scope.image.isSafe = $scope.image.isSafe || !$scope.image.safeSearchObject;
                $scope.showingImage = true;

                // Add class after time period to allow transition only after initial blur
                $scope.addUnsafeTransition = false;
                if (!$scope.image.isSafe && !$scope.showUnsafe.current) {
                    $timeout(function () {
                        $scope.addUnsafeTransition = true;
                    }, 1000);
                }

                var returnedUrl = $scope.image.fullsize || '';
                var trimmedUrl = returnedUrl.split('?')[0];
                imageUrl = trimmedUrl + '?token=' + userServiceFunctions.getEncodedUserToken();

                $scope.imageTitle = $scope.image.time + ' | ' + $scope.image.user + ' | ' + $scope.image.titlebar;
                $scope.imageInfo = [
                    {
                        title: 'Time',
                        value: $scope.image.time
                    },
                    {
                        title: 'Computer',
                        value: $scope.image.computer
                    },
                    {
                        title: 'User',
                        value: $scope.image.user
                    },
                    {
                        title: 'Title',
                        value: $scope.image.titlebar
                    },
                    {
                        title: 'URL',
                        value: $scope.image.url
                    },
                    {
                        title: 'Executable',
                        value: $scope.image.executable
                    },
                    {
                        title: 'Description',
                        value: $scope.image.description
                    }
                ];

                if (!$scope.image.isSafe && $scope.image.safeSearchObject) {
                    $scope.imageTitle =
                        ($scope.image.isSafe ? '' : '<span class="text-danger">Unsafe</span>') +
                        ' | ' +
                        $scope.imageTitle;

                    var { adultThreshold, medicalThreshold, racyThreshold, violenceThreshold } =
                        getScreenshotSafeSearchSettingsStore();
                    var thresholds = { adultThreshold, medicalThreshold, racyThreshold, violenceThreshold };
                    for (var key in indexNames) {
                        var info = {};
                        var likelihoodLabel = $scope.image.safeSearchObject[indexNames[key]];
                        var thresholdLabel = thresholds[indexNames[key]];
                        var threshold = likelihoodMap[thresholdLabel];
                        if (likelihoodLabel) {
                            var likelihood = likelihoodMap[likelihoodLabel];
                            if (likelihood >= 0 && likelihood <= 5 && threshold < 6) {
                                var category = indexNames[key];
                                info.title = 'Flagged Category - ' + atHelperFunctions.capitalizeFirstLetter(category);
                                info.value = likelihoodLabelMap[likelihood];
                                $scope.imageInfo.push(info);
                            }
                        }
                    }
                }

                $scope.titleTooltipCondition = function (element) {
                    var isMobile = browserServiceFunctions.isMobileAgent();
                    var elementCopy = element
                        .clone()
                        .css({
                            display: 'inline',
                            width: 'auto',
                            visibility: 'hidden'
                        })
                        .appendTo('body');
                    var elementCopyWidth = elementCopy.width();
                    elementCopy.remove();

                    var elementCssWidth = element.css('width');
                    var elementMaxWidth = 0;

                    if (elementCssWidth.indexOf('%') > 0) {
                        elementMaxWidth = Math.round((window.innerWidth / 100) * parseInt(elementCssWidth) - 10);
                    } else {
                        elementMaxWidth = parseInt(elementCssWidth);
                    }

                    return elementCopyWidth > elementMaxWidth && !isMobile;
                };

                $scope.isFirstImageLoaded = true;
                $scope.imageShowProdButtons =
                    $scope.hasViewLevel('edit') &&
                    $scope.image.prod === 0 &&
                    ($scope.image.appId || $scope.image.webId) &&
                    $scope.config.dataSource;

                $scope.imageUrl = imageUrl;
                $scope.nextIndex = -1;
                seenPages = [];
            }
            hideIndex();
        } catch (error) {
            console.error('ActivTrak Error: show image', error);
        }
    };

    $scope.$on('showScreenshotEvent', function (event, data) {
        self.showImage(
            {
                newIndex: data.index
            },
            data.showUnsafe
        );
    });

    $scope.$on('imageLoaded', function () {
        $scope.isDisconnected = false;
        if ($scope.isSlideshow) {
            $scope.scheduleNextImage();
        }
    });

    $scope.$on('imageLoadingError', function () {
        $scope.isDisconnected = true;
    });

    $scope.$on('imageHidden', function () {
        $scope.imageTitle = msg.get('loading');
        $scope.imageShowProdButtons = false;
    });

    $scope.$watch('config', function (config) {
        if (config === undefined) {
            return;
        }

        if ($scope.nextIndex >= 0) {
            if ($scope.config.images.length === 0) {
                $scope.showingImage = false;
            }

            $scope.nextIndex = Math.min($scope.nextIndex, $scope.config.images.length - 1);
            self.showImage({
                newIndex: $scope.nextIndex
            });
        }
    });

    $scope.stopSlideshow = function () {
        $scope.cancelNextImage();
        $scope.isSlideshow = false;
    };

    $scope.cancelNextImage = function () {
        if ($scope.isSlideshow && $scope.slideshowTimeout) {
            $timeout.cancel($scope.slideshowTimeout);
        }
    };

    $scope.scheduleNextImage = function () {
        $scope.slideshowTimeout = $timeout(function () {
            $scope.showNextImage();
        }, (isSmallScreen() ? defaultSlideshowInterval : $scope.slideshowInterval) * 1000);
    };

    $scope.onHideViewer = function () {
        $scope.showScreenshotTitleConfig = false;
        if ($scope.isRealtime) {
            $scope.isPaused = false;
            $scope.isDisconnected = false;
            $scope.isErrored = false;
            $scope.isFirstImageLoaded = false;
            $scope.connectionAttempt = 0;
            $scope.imageInfo = null;
            clearStorage();
            realtimeScreenshotService.cancelViewerImage();
            $scope.config.closeViewer();
        } else {
            $scope.nextIndex = -1;
            $scope.imageIndex = -1;
            $scope.direction = undefined;
            $scope.showUnsafe.all = false;

            if ($scope.isSlideshow) {
                $scope.stopSlideshow();
            }
        }
    };

    var openPage = function (page) {
        if (seenPages.indexOf(page) !== -1) {
            $scope.showingImage = false;
            return;
        }
        seenPages.push(page);
        $scope.config.dataSource.page(page);
    };

    function getLastImageIndex(pageSize) {
        return Math.min(pageSize - 1, $scope.config.images.length - 1);
    }

    $scope.showNextImage = function () {
        $scope.direction = 'next';

        if (!$scope.canMove($scope.direction) || $scope.showingFullscreenImage) {
            return;
        }

        if ($scope.isRealtime) {
            var length = $scope.screenshotStorage.length;

            pause();
            $scope.imageIndex = $scope.imageIndex === 0 ? length - 1 : $scope.imageIndex - 1;

            self.showImage({
                data: $scope.screenshotStorage[length - $scope.imageIndex - 1],
                fromStorage: true
            });
        } else {
            $scope.imageUrl = null;
            $scope.image = null;
            $scope.cancelNextImage();

            $timeout(function () {
                if ($scope.config.dataSource) {
                    var source = $scope.config.dataSource;
                    var pageSize = source.pageSize();
                    var page = source.page();
                    var pages = source.totalPages();

                    if ($scope.imageIndex === getLastImageIndex(pageSize)) {
                        $scope.imageIndex = $scope.nextIndex = 0;

                        if (page < pages) {
                            page++;
                        }

                        openPage(page);
                    } else {
                        self.showImage({
                            newIndex: $scope.imageIndex + 1
                        });
                    }
                } else {
                    if ($scope.imageIndex === $scope.config.total - 1) {
                        self.showImage({
                            newIndex: 0
                        });
                    } else {
                        self.showImage({
                            newIndex: $scope.imageIndex + 1
                        });
                    }
                }
            });
        }
        hideIndex();
    };

    $scope.showPreviousImage = function () {
        $scope.direction = 'previous';

        if (!$scope.canMove($scope.direction) || $scope.showingFullscreenImage) {
            return;
        }

        if ($scope.isRealtime) {
            var length = $scope.screenshotStorage.length;

            pause();
            $scope.imageIndex = $scope.imageIndex === length - 1 ? 0 : $scope.imageIndex + 1;

            self.showImage({
                data: $scope.screenshotStorage[length - $scope.imageIndex - 1],
                fromStorage: true
            });
        } else {
            $scope.imageUrl = null;
            $scope.image = null;
            $scope.cancelNextImage();

            $timeout(function () {
                if ($scope.config.dataSource) {
                    var source = $scope.config.dataSource;
                    var pageSize = source.pageSize();
                    var page = source.page();

                    if ($scope.imageIndex === 0) {
                        $scope.imageIndex = $scope.nextIndex = pageSize - 1;

                        if (page > 1) {
                            page--;
                        }

                        openPage(page);
                    } else {
                        self.showImage({
                            newIndex: $scope.imageIndex - 1
                        });
                    }
                } else {
                    if ($scope.imageIndex === 0) {
                        self.showImage({
                            newIndex: $scope.config.total - 1
                        });
                    } else {
                        self.showImage({
                            newIndex: $scope.imageIndex - 1
                        });
                    }
                }
            });
        }
        hideIndex();
    };

    function imageNumber() {
        if ($scope.config) {
            if ($scope.config.dataSource) {
                var source = $scope.config.dataSource;

                return (source.page() - 1) * source.pageSize() + ($scope.imageIndex + 1);
            }

            return $scope.imageIndex + 1;
        } else {
            return 0;
        }
    }

    function imageTotal() {
        if ($scope.config) {
            if ($scope.config.dataSource) {
                return $scope.config.dataSource.total();
            }
            return $scope.config.total;
        } else {
            return 0;
        }
    }

    $scope.canMove = function (direction) {
        if ($scope.isRealtime) {
            return $scope.screenshotStorage.length > 1;
        }

        if ($scope.ignoreMovementRestrictions()) {
            return true;
        }

        var imageExists = $scope.showingImage && $scope.image;
        var pageAllowed = true;

        if ($scope.config && $scope.config.dataSource) {
            var source = $scope.config.dataSource;
            var page = source.page();
            var pageSize = source.pageSize();
            var pages = source.totalPages();

            pageAllowed =
                direction === 'previous'
                    ? page > 1 || $scope.imageIndex > 0
                    : direction === 'next'
                    ? page < pages || $scope.imageIndex < getLastImageIndex(pageSize)
                    : true;
        }

        return imageExists && pageAllowed;
    };

    $scope.onFullSize = function () {
        if ($scope.isSlideshow) {
            $scope.stopSlideshow();
        }
        if ($scope.isRealtime) {
            pause();
        }
        showFullscreenButton();
    };

    $scope.pauseOrSlideshowSwitch = function () {
        if ($scope.isRealtime) {
            if ($scope.isPaused) {
                unpause();
            } else {
                pause();
            }
        } else {
            if ($scope.isSlideshow) {
                $scope.stopSlideshow();
            } else {
                $scope.scheduleNextImage();
                $scope.isSlideshow = true;
            }
        }
        hideIndex();
    };

    $scope.gotoClassificationPage = function (image) {
        $rootScope.app.layout.fullscreenView = false;
        $scope.closeFullscreen();

        $timeout(function () {
            var activityType = image.website ? 'Website' : 'App';
            var activityId = image.webId ? image.webId : image.appId;
            $location.url('app.settings.classification.id', { type: activityType, id: activityId });
            $state.go('app.settings.classification.id', { type: activityType, id: activityId });
        });
    };

    var fullscreenButtonInvisibleTimeout = null;

    function startFullscreenButtonInvisibleTimeout() {
        fullscreenButtonInvisibleTimeout = $timeout(function () {
            $scope.isFullscreenButtonInvisible = true;
        }, 2000);
    }

    function showFullscreenButton() {
        if (fullscreenButtonInvisibleTimeout) {
            $timeout.cancel(fullscreenButtonInvisibleTimeout);
        }

        $scope.isFullscreenButtonInvisible = false;
        startFullscreenButtonInvisibleTimeout();
    }

    var pos = { top: 0, left: 0, x: 0, y: 0 };
    $scope.isGrabbing = false;
    var screenshotFullsizeParent = null;

    $scope.mouseDownHandler = function (e) {
        $scope.isGrabbing = true;

        if (!screenshotFullsizeParent) {
            screenshotFullsizeParent = angular.element('.screenshot-fullsize-parent')[0];
        }

        pos = {
            left: screenshotFullsizeParent.scrollLeft,
            top: screenshotFullsizeParent.scrollTop,
            x: e.clientX,
            y: e.clientY
        };
    };

    $scope.mouseMoveHandler = function (e) {
        showFullscreenButton();

        if (!$scope.isGrabbing) {
            return;
        }

        var dx = e.clientX - pos.x;
        var dy = e.clientY - pos.y;

        screenshotFullsizeParent.scrollTop = pos.top - dy;
        screenshotFullsizeParent.scrollLeft = pos.left - dx;
    };

    $scope.mouseUpHandler = function () {
        $scope.isGrabbing = false;
    };

    angular.element('.hidden-while-load').removeClass('hidden-while-load');
}
