import React, { useCallback } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { IScheduledTimelineFilters } from '../models/ScheduledTimeline.types';
import { SCHEDULED_TIMELINE_DEFAULT_FILTERS } from '../constants/scheduleAdherence.const';
import { ScheduledHourSelector } from './ScheduledHourSelector';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useScheduledTimelineStore } from '../hooks/useScheduledTimelineStore';
import { setScheduledTimeline } from '../stores/scheduledTimelineStore';

const ScheduledTimelineFilter = () => {
  const shiftTimes = useScheduledTimelineStore((s) => s);

  const setFilterValues = useCallback(
    (value: Partial<IScheduledTimelineFilters>) => {
      setScheduledTimeline(value);
    },
    []
  );

  const handleStartDate = useCallback(
    (value) => {
      setFilterValues({
        shiftStart: value
      });
    },
    [setFilterValues]
  );

  const handleEndDate = useCallback(
    (value) => {
      setFilterValues({
        shiftEnd: value
      });
    },
    [setFilterValues]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box sx={{ display: 'inline-block' }}>
          <ScheduledHourSelector
            id="TBD"
            value={shiftTimes?.shiftStart}
            onChange={handleStartDate}
            defaultValue={SCHEDULED_TIMELINE_DEFAULT_FILTERS.shiftStart}
          />
          <ScheduledHourSelector
            id="TBD"
            value={shiftTimes?.shiftEnd}
            onChange={handleEndDate}
            defaultValue={SCHEDULED_TIMELINE_DEFAULT_FILTERS.shiftEnd}
            containerStyles={{ ml: 1 }}
          />
        </Box>
      </Stack>
    </LocalizationProvider>
  );
};

export default ScheduledTimelineFilter;
