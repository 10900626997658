import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { GTEUserAction, IGTEUsersColumnHeaderProps } from '../models';
import { IGridColumn } from '../../common/models';
import { GTEUsersDetails } from '../constants';

export const useGTEUsersColumns = ({
  handleOpenSetUsername,
  isSupport
}: IGTEUsersColumnHeaderProps): IGridColumn[] => {
  return [
    {
      field: 'username',
      headerName: 'Username',
      type: 'string',
      width: '40%',
      isSortable: false,
      align: 'left'
    },
    {
      field: 'logondomain',
      headerName: 'Logon Domain',
      type: 'string',
      width: '40%',
      isSortable: false,
      align: 'left',
      template: () => 'Any'
    },
    {
      field: 'actions',
      headerName: '',
      type: 'actions',
      width: '20%',
      isSortable: false,
      align: 'right',
      template: (row) =>
        isSupport ? (
          <IconButton disabled={true}>
            <DeleteIcon />
          </IconButton>
        ) : (
          <Tooltip title={GTEUsersDetails.delete.button}>
            <IconButton
              onClick={(e) =>
                handleOpenSetUsername(e, row?.username, GTEUserAction.Delete)
              }
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )
    }
  ];
};
