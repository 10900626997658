import { createStore } from 'zustand';
import { IScreenshotSafeSearchSettingsStore } from '../models/IScreenshotSafeSearchSettingsStore';
import { fetchSettings } from '../utils/screenshotSafeSearchSettingsStore.utils';
import { ThresholdLikelihood } from '../constants/thresholdLikelihood';

export const SCREENSHOT_SAFE_SEARCH_SETTINGS_STORE_DEFAULT: IScreenshotSafeSearchSettingsStore =
  {
    enabled: false,
    quotaLimit: 0,
    quotaUsage: 0,
    quotaRemaining: 0,
    renewDate: null,
    quotaUsageLabel: '',
    quotaLimitLabel: '',
    quotaRemainingLabel: '',
    renewDateLabel: null,
    adultThreshold: ThresholdLikelihood.Unknown,
    medicalThreshold: ThresholdLikelihood.Unknown,
    racyThreshold: ThresholdLikelihood.Unknown,
    violenceThreshold: ThresholdLikelihood.Unknown
  };

/**
 * The store for the Safe Search Settings (aka Screenshot Flagging).
 */
export const screenshotSafeSearchSettingsStore =
  createStore<IScreenshotSafeSearchSettingsStore>(
    () => SCREENSHOT_SAFE_SEARCH_SETTINGS_STORE_DEFAULT
  );

/**
 * Fetches the Safe Search Settings (aka Screenshot Flagging) and updates the store.
 */
export const fetchScreenshotSafeSearchSettings =
  async (): Promise<IScreenshotSafeSearchSettingsStore> => {
    const response = await fetchSettings();
    const currentSettings = screenshotSafeSearchSettingsStore.getState();
    screenshotSafeSearchSettingsStore.setState({
      ...currentSettings,
      ...response
    });
    return screenshotSafeSearchSettingsStore.getState();
  };

/**
 * Returns the current Safe Search Settings Store (aka Screenshot Flagging) state.
 */
export const getScreenshotSafeSearchSettingsStore =
  (): IScreenshotSafeSearchSettingsStore =>
    screenshotSafeSearchSettingsStore.getState();

/**
 * Resets the Safe Search Settings Store (aka Screenshot Flagging) to its default state.
 */
export const resetScreenshotSafeSearchSettingsStore = (): void =>
  screenshotSafeSearchSettingsStore.setState(
    SCREENSHOT_SAFE_SEARCH_SETTINGS_STORE_DEFAULT
  );
