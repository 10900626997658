// jscs:disable requireCamelCaseOrUpperCaseIdentifiers
'use strict';

angular.module('app').service('messagesService', MessagesService);

MessagesService.$inject = ['$sce', 'browserServiceFunctions', 'envService'];
var INSIGHTS_UPGRADE_PLAN_LABEL =
    require('../../../_reactivtrak/src/common/constants/plans').INSIGHTS_UPGRADE_PLAN_LABEL;
function MessagesService($sce, browserServiceFunctions, envService) {
    var messages = {
        reportLoadingError: 'An error occurred while loading the report.',
        itemUpdatingError: 'An error occurred while updating this item.',
        userUpdatingError: 'An error occurred while updating this user.',
        groupUpdatingError: 'An error occurred while updating the group.',
        creatingUserError: 'There was an error while creating the user.',
        chromebookUserAddingError: 'There was an error while adding this ChromeBook user.',
        chromebookUserInvitingError: 'There was an error while inviting this ChromeBook user.',
        pageRetrievingError: 'An error occurred while retrieving this page.',
        pageNotAvailableError: 'The requested page is not available. Please try again later.',
        userAuthenticationError:
            'Unable to authenticate current user. Please log out and log back in again to access this page.',
        dataDeletingError: 'There was an error while deleting the data.',
        changesApplyingError: 'There was an error while applying the changes',
        exportError: 'An error occurred during export',
        unexpectedErrorOccurred: 'An error occurred. Please try again later.',
        creatingTicketError: 'An error occurred while creating the ticket.',
        postingError: 'An error occurred while posting comment.',
        errorDownloadingVideo: 'An error occurred while downloading video',
        duplicateComputerAlias: 'Cannot save duplicate computer alias.  Computer aliases must be unique.',
        screenshotsResponseError: 'Error: Screenshots response undefined',
        screenshotsRetrievingError: 'There was an error while retrieving the screenshots, please refresh your browser.',
        allScreenshotsRetrievingError:
            'There was an error while retrieving the screenshots number, please refresh your browser.',
        videosRetrievingError: 'There was an error while retrieving the videos, please refresh your browser.',
        realtimeRetrievingError: 'There was an error while retrieving the realtime data, please try again later.',
        activityTimezoneRetrievingError: 'Unable to retrieve activity date and time zone setting from server.',
        activityTimezoneUpdatingError: 'Unable to save activity time and time zone preference. Please try again later.',
        verificationEmailSent: 'Verification email sent. Check your inbox.',
        updateSuccessful: 'Update successful!',
        passwordChanged: 'Password changed!',
        newUserCreated: 'New user created!',
        userDeleted: 'User deleted!',
        errorDeletingUser: 'An error occurred while deleting the user.',
        cantDeleteATID: 'Not allowed to delete the ActivTrak ID.',
        userGroupsUpdated: 'User groups updated!',
        userViewableAccountsUpdated: 'Accounts updated!',
        activityTimezoneUpdated: 'Successfully saved activity time and time zone preference!',
        alarmActivated: 'Alarm activated.',
        alarmDeactivated: 'Alarm deactivated.',
        alarmDeleted: 'Alarm deleted.',
        changesSaved: 'Changes have been saved!',
        deleteComplete: 'Delete complete!',
        mergeScheduled: 'User merge is scheduled',
        mergeScheduleError: 'User merge schedule error',
        delayedDeleteScheduled: 'Deletion has been scheduled!',
        removeScheduledMerge: 'Scheduled merge has been removed',
        groupDeleted: 'Group deleted!',
        domainAdded: 'Domain added!',
        applicationAdded: 'Application added!',
        applicationDeleted: 'Application deleted!',
        scheduleSaved: 'Schedule Saved!',
        scheduleDeleted: 'Schedule Deleted!',
        errorDeletingSchedule: 'There was an error deleting the schedule',
        categoryCreated: 'Category created!',
        categoryRemoved: 'Category removed!',
        websiteDeleted: 'Website deleted!',
        noScreenshotsForDeletion: 'There are no screenshots selected for deletion.',
        allScreenshotsDeleted: 'All screenshots deleted!',
        selectedScreenshotsDeleted: 'The selected screenshots have been deleted!',
        allVideosDeleted: 'All videos deleted!',
        groupCannotBeDeleted: 'Group cannot be deleted!',
        timezoneSettingsSaved: 'Time zone settings saved!',
        areYouSure: 'Are you sure?',
        deleteAlarm: function (type, name) {
            return (
                'Do you want to delete this' +
                (type ? ' ' + type + ' ' : ' ') +
                'alarm' +
                (name ? ' named "' + browserServiceFunctions.htmlEscape(name) + '"' : '') +
                '?'
            );
        },
        upgradeProcessedSuccessfully:
            'Your upgrade has been processed successfully. It may take several minutes to fully process your upgrade and for the changes to be reflected. You will receive an email shortly with your invoice information.',
        switchToMatchAll: function (type) {
            return (
                "Conditions on the '" +
                type +
                "' field are not allowed when the alarm 'Match Any' condition is selected.  Do you want to change to 'Match All'?"
            );
        },
        matchAnyRestrictionMessage: function (fields) {
            var msgText = fields[0];
            var i;
            for (i = 1; i < fields.length; i++) {
                if (i === fields.length - 1) {
                    msgText += (fields.length === 2 ? ' ' : ', ') + 'and ' + fields[i];
                } else {
                    msgText += ', ' + fields[i];
                }
            }
            msgText += fields.length > 1 ? ' fields are' : ' field is';

            return (
                'Conditions on the ' +
                msgText +
                " not allowed when the alarm 'Match Any' condition is selected.  All restricted conditions must be removed before 'Match Any' can be selected"
            );
        },
        underDefinedAlarm: function (requiredFields) {
            var msg = 'Alarm should have at least one condition on';
            var lines = 1;
            if (requiredFields.length === 1) {
                msg = "'" + requiredFields[0] + "',";
            } else {
                requiredFields.forEach(function (field, key) {
                    msg += (key === requiredFields.length - 1 ? ' or ' : ' ') + "'" + field + "',";
                    if (msg.length > 80 * lines) {
                        msg += '<br/>';
                        lines++;
                    }
                });
            }
            return msg + " so that it isn't triggered for every new entry.";
        },
        missingSafeSearchCondition:
            'There must be at least one Screenshot Flagging category configured in the conditions.',
        missingAlarmCondition: 'There must be at least one condition defined.',
        popupEnabledWithGroup:
            'Pop-up messages can only be used when "All Users and Computers"<br/>has been selected under the "Group Alarm" section.',
        terminateEnabledWithGroup:
            'Terminate client applications can only be used when "All Users and Computers"<br/>has been selected under the "Group Alarm" section.',
        noActiveAction: 'There must be at least one action selected.',
        switchToMatchAllLabel: "Switch to 'Match All'",
        deleteCategory: 'Do you want to delete this category?',
        cancelInvitation: 'Do you want to cancel invitation for this ChromeBook user?',
        userWillBeRemoved: 'User will be removed.',
        scheduleWillBeDeletedTitle: 'The selected schedule will be deleted.',
        scheduleWillBeDeleted:
            'All assigned users will be moved to the Default Schedule. Are you sure you want to delete this schedule?',
        youHaveUnsavedChanges: 'You have unsaved changes. Do you want to leave this page without saving?',
        deleteUser: function (userEmail) {
            return "Delete ActivTrak ID '" + userEmail + "'?";
        },
        deleteUsers: "Do you want to delete the selected ActivTrak ID's?",
        deleteLogsAndScreenshots:
            'Do you want to delete all logs, screenshots, and videos associated with your account?',
        typeDeleteToProceedScreenshots:
            "Type 'delete' below to delete every screenshot from all users in your account.",
        deleteAllScreenshots: function (screenshots) {
            return 'Are you sure you want to delete all ' + screenshots + ' screenshots from all users?';
        },
        deleteAllScreenshotsLabel: function (screenshots) {
            return (
                'This action will remove all ' +
                screenshots +
                ' screenshots from all users in your account and they will not be retrievable.'
            );
        },
        deleteAllVideos: 'Do you want to delete all stored videos?',
        deleteAllVideosConfirm: 'Delete all stored videos for your account',
        storageDeleteSelected: function (usersSelected, multipleSelected) {
            var type = usersSelected ? 'user' : 'computer';
            var suffix = multipleSelected ? 's' : '';
            return 'Do you want to delete all data associated with the selected ' + type + suffix + '?';
        },
        timeZonePreaggregationConfirmation:
            'Changing the time zone might slow things down for a few minutes while your data is being updated. Are you sure you want to continue?',
        cannotRemoveApplication: 'Cannot remove application since it is associated with other records.',
        cannotRemoveApplications:
            'Cannot remove one or more applications since they are associated with other records.',
        cannotRemoveWebsite: 'Cannot remove website since it is associated with other records.',
        cannotRemoveWebsites: 'Cannot remove one or more websites since they are associated with other records.',
        cannotRemoveDomain: $sce.trustAsHtml(
            'Cannot remove this domain due to activity or screenshot data being associated with it. <br/> For help, <a target="_blank" href="#/app/help/tickets">contact support</a>.'
        ),
        thisFileIsTooLarge: 'This file is too large to upload.',
        thisAppFileIsTooLarge:
            'This file is too large to upload. You can add the application by running the executable on a monitored computer.',
        saveConditionsBeforeAlarm:
            'You have unsaved changes in conditions. Save or cancel them before saving the alarm.',
        selectConditions: 'Select the conditions that will trigger the alarm.',
        missingAlarmName: 'A valid name for the alarm must be provided.',
        agentDownloadFailed: $sce.trustAsHtml(
            'There are problems with downloading agent. Try again in a few minutes or <a target="_blank" href="#/app/help/tickets">contact support</a>.'
        ),
        fiveMinutes: '5 min',
        fifteenMinutes: '15 min',
        thirtyMinutes: '30 min',
        oneHour: '1 hour',
        twoHours: '2 hours',
        all: 'All',
        any: 'Any',
        Usb: 'USB',
        safeSearch: 'Screenshot Flagging',
        productive: 'Productive',
        unproductive: 'Unproductive',
        undefined: 'Undefined',
        mustBeGreaterThan: 'Must be "Greater Than" for "Duration"',
        canNotBeGreaterThan: 'Cannot be "Greater Than" for this field',
        onlyDigits: 'Can only have digits',
        onlyOneDuration: 'Only one "Duration" condition is allowed',
        mustBeAtLeast: function (n) {
            return 'Must be at least ' + n.toString() + ' seconds.';
        },
        valueIsTooLarge: 'Value is too large',
        mustBeAnInteger: 'Must be an integer',
        exportToGoogleSheetsFinished: function (url) {
            return $sce.trustAsHtml(
                'Export finished. <a target="_blank" href="' + url + '" style="color: #fff">Open Google Sheets</a>'
            );
        },
        exportToGoogleDriveFinished: function (url) {
            return $sce.trustAsHtml(
                'Export finished. <a target="_blank" href="' + url + '" style="color: #fff">Open Google Drive</a>'
            );
        },
        filterHasBeenDeleted: function (reportFilter) {
            var type = reportFilter.userType === 'AdGroup' ? 'Group' : reportFilter.userType;
            return type + ' "' + reportFilter.userName + '" has been deleted. Please refresh your browser.';
        },
        groupAlarmsPaidPlanAvailableOnly: 'Group alarms are not available for your plan, upgrade today.',
        webhookPaidPlanAvailableOnly: 'External Notifications are not available for your plan, upgrade today.',
        usbStorageDevicesAreVeryAccessible:
            '<span class="span-paragraph">USB storage devices are very accessible, which increases the risk of data loss and breaches due to a copying of sensitive files. ActivTrak ' +
            INSIGHTS_UPGRADE_PLAN_LABEL +
            ' Plan includes USB Alarms, typically used to protect your sensitive data against potential threats.</span><span class="span-paragraph">Monitoring for USB threats is a requirement for compliance regulations, such as PCI DSS. USB alarms help to prevent users from copying files to unauthorized USB drives. ActivTrak creates an event whenever USB device is inserted or removed, and when a device is in reading/writing mode. It allows you to create any number of custom alarms based on those events, triggering actions, such as email notification, and others.</span><span>USB Alarms are available to ' +
            INSIGHTS_UPGRADE_PLAN_LABEL +
            ' Plans only,</span>',
        usbAlarmPaidPlanAvailableOnly: 'USB Alarms are available to ' + INSIGHTS_UPGRADE_PLAN_LABEL + ' Plans only,',
        auditAlarmPaidPlanAvailableOnly:
            'Audit Alarms are available to ' + INSIGHTS_UPGRADE_PLAN_LABEL + ' Plans only,',
        riskLevelPaidPlanAvailableOnly: 'Custom risk levels are not available for your plan, upgrade today.',
        multipleSSPaidPlanAvailableOnly: 'Multiple screenshots per alarm are available to Paid Plans only,',
        simpleAlarmTooltip: function (screenshotsAllowed, alarmsAllowed) {
            var baseText = `<div style="white-space: nowrap; word-wrap: normal;">Activity Alarms can be triggered by a flexible combination of conditions<br/>and allow you to generate emails`;
            var screenshotsText = screenshotsAllowed ? ', screenshots' : '';
            var alarmsText = alarmsAllowed
                ? ', webhooks, and popup<br/>messages and/or terminate the related application'
                : ', and webhooks';

            return `${baseText}${screenshotsText}${alarmsText}.</div>`;
        },
        usbAlarmTooltip:
            '<div style="white-space: nowrap; word-wrap: normal;">USB Alarms are available with a paid plan and<br/>allow you to generate emails, screenshots, webhooks, or<br/>popup messages when a USB device is inserted or written to.</div>',
        auditAlarmTooltip:
            '<div style="white-space: nowrap; word-wrap: normal;">Audit Alarms are available with a paid plan<br/>and allow you to generate emails or webhooks for events<br/>written to the Security Audit Log, which records all<br/>administrator or user activity to the account.</div>',
        safeSearchAlarmTooltip:
            '<div style="white-space: nowrap; word-wrap: normal;">Screenshot Flagging Alarms are available as an ' +
            INSIGHTS_UPGRADE_PLAN_LABEL +
            ' Plan<br/>Add-On and allow you to generate emails or webhooks based<br/>on the Screenshot Flagging results.</div>',
        uninstallComplete: 'Agent remote uninstall message sent.',
        uninstallError: $sce.trustAsHtml(
            'There was an error sending the remote uninstall message to the agent. Try again in a few minutes or <a target="_blank" href="#/app/help/tickets">contact support</a>.'
        ),
        videoAlarmRestrictionsNotMet:
            'Video capture restrictions are no longer being met.  Video capture has been turned off for this alarm.',
        alarmMatchesOnlyUserName: function (name) {
            return (
                '"' +
                name +
                '" matches both a user name and an alias. Alarms will be triggered only for the real user name.'
            );
        },
        ticketSuccessfullyCreated: 'New ticket was successfully created. It will be shown in a few minutes.',
        commentSuccessfullyAdded: 'The comment was successfully added.',
        ignore: 'Ignore',
        minor: 'Low',
        normal: 'Default',
        major: 'High',
        weightTooltip: 'The alarm risk level is used to determine the level of risk of a triggered alarm.',
        addingWebhook: 'Receive alarm notifications in messaging apps or any tool that accepts webhooks.',
        notSaved: 'Not saved',
        newSchedule: 'New Schedule',
        planMissingError: $sce.trustAsHtml(
            'Error getting account plan. For help, <a href="#/app/help/tickets?id=new&subject=Error getting account plan">contact support</a>.'
        ),
        errorAddingProfile: function (username, reason) {
            return 'Unable to add "' + username + '" because ' + reason.toLowerCase();
        },

        // breadcrumbs
        settings: 'Settings',
        productivity: 'Productivity',
        alarms: 'Alarms',
        edit: 'Edit',
        duplicate: 'Duplicate',
        groups: 'Groups',
        storage: 'Storage',
        screenshots: 'Screenshots',
        videos: 'Videos',
        screenshotsLowercase: 'screenshots',
        latestPerUser: 'Latest per User',
        login: 'Login',
        audit: 'Audit',
        riskLevel: 'Risk Level',
        riskScore: 'Risk Score',
        riskPoints: 'Risk Points',
        riskScoreTooltip: 'Score: ',
        riskPointsTooltip: 'Points: ',
        riskPointsTotal: 'Total Risk Points',
        riskLevelWidgetAverage: 'Organization Score',
        riskScoreAndPointsExplanation: function (w) {
            var width = w || 420;
            return (
                '<div style="width: ' +
                width +
                'px;">The alarm risk score is a calculated number that can be compared from period to period, group to group, and user to user.<br/><br/>' +
                'The risk score is based on the total risk points of a user divided by the maximum number of activity log records for a single user in the organization during the given time period.<br/><br/>' +
                "Risk points are a sum of the triggered alarms' risk levels multiplied by the number of times the alarm was triggered by a user during the given time period.</div>"
            );
        },
        riskScoreExplanation: function (w) {
            var width = w || 420;
            return (
                '<div style="width: ' +
                width +
                'px;">The alarm risk score is a calculated number that can be compared from period to period, group to group, and user to user.<br/><br/>' +
                'The risk score is based on the total risk points of a user divided by the maximum number of activity log records for a single user in the organization during the given time period.</div>'
            );
        },
        riskPointsExplanation: function (w) {
            var width = w || 420;
            return (
                '<div style="width: ' +
                width +
                'px;">Risk points are a sum of the triggered alarms\' risk levels multiplied by the number of times the alarm was triggered by a user during the given time period.</div>'
            );
        },
        role: 'Role',

        // report names
        activityLogReportName: 'activitylog',
        alarmLogReportName: 'alarmlog',
        topApplicationsReportName: 'topapplications',
        topUsersReportName: 'topusers',
        topGroupsReportName: 'topgroups',
        topWebsitesReportName: 'topwebsites',
        auditReportName: 'auditlog',
        riskLevelReportName: 'risklevel',
        workingHoursReportName: 'workinghours',
        storageReportName: 'storage',
        videosReportName: 'video',

        // report filters
        today: 'Today',
        yesterday: 'Yesterday',
        last7Days: 'Last 7 Days',
        last14Days: 'Last 14 Days',
        last30Days: 'Last 30 Days',
        customDateRange: 'Custom Range',
        thisWeek: 'This Week',
        lastWeek: 'Last Week',
        thisMonth: 'This Month',
        lastMonth: 'Last Month',
        currentAndPreviousMonths: 'Current & Previous Month',
        currentQuarter: 'Current Quarter',
        previousQuarter: 'Previous Quarter',
        currentAndPreviousQuarter: 'Current & Previous Quarter',
        currentYear: 'This Year',
        previousYear: 'Last Year',
        currentAndPreviousYears: 'This & Last Year',
        export: 'Export',
        exportScreenshots: 'Export Screenshots',

        // grid titles
        applications: 'applications',
        websites: 'websites',
        computers: 'computers',
        agents: 'agents',
        data: 'data',
        domains: 'domains',
        activities: 'activities',
        entries: 'entries',

        // grid messages
        itemsPerPage: function (items) {
            return messages[items].toLowerCase() + ' per page';
        },
        itemsDisplay: function (items, limit) {
            return `
                <div>
                   <span style="display:inline">{0:n0} - {1:n0} of {2:n0} ${messages[items].toLowerCase()}</span>
                    ${
                        limit > 0
                            ? `<span style="position: relative; display: inline;">
                                <span style="padding-left: 5px; padding-bottom: 5px" class="text-danger">Limited</span>
                                <span id="tooltip-container" style="width: 300px"></span>
                            </span>`
                            : ''
                    }
                </div>
            `;
        },
        noItemsToDisplay: function (items) {
            return 'No ' + messages[items].toLowerCase() + ' to display';
        },

        // grid titles and other
        alarm: 'Alarm',
        alarmType: 'Alarm Type',
        count: 'Count',
        usbAlarm: 'USB Alarm',
        activityAlarm: 'Activity Alarm',
        auditAlarm: 'Security Audit Alarm',
        safeSearchAlarm: 'Screenshot Flagging Alarm',
        screenshot: 'Screenshot',
        none: 'None',
        popupAlarm: 'Popup Alarm',
        emailAlarm: 'Email Alarm',
        webhookAlarm: 'External Notification Alarm',
        terminate: 'Terminate',
        webhook: 'External',
        dateTime: 'Date/Time',
        computer: 'Computer',
        computersTitle: 'Computers',
        primaryDomain: 'Primary Domain',
        IpAddressPublic: 'Public IP Address',
        IpAddressPrivate: 'Private IP Address',
        publicIp: 'Public IP',
        privateIp: 'Private IP',
        DurationSeconds: 'Duration',
        user: 'User',
        User: 'User',
        atUser: 'ActivTrak ID',
        atUserLogin: 'ActivTrak ID (email)',
        group: 'Group',
        showIdle: 'Show Passive Agents',
        hideIdle: 'Hide Passive Agents',
        agentFilter: 'Agent Status',
        session: 'Session',
        duration: 'Duration',
        title: 'Title',
        executable: 'Executable',
        description: 'Description',
        url: 'URL',
        alarmName: 'Alarm Name',
        durationHMMSS: 'Duration h:mm:ss',
        percentage: 'Percentage',
        sitesAndApps: 'Sites & Apps',
        titles: 'Titles',
        users: 'Users',
        days: 'Days',
        changeCategory: 'Change Category',
        changeProductivity: 'Change Productivity',
        productiveTime: 'Productive<br/>Time',
        unproductiveTime: 'Unproductive<br/>Time',
        undefinedTime: 'Undefined<br/>Time',
        activeTime: 'Active<br/>Time',
        productivityRatio: 'Productivity<br/>Ratio',
        totalUsage: 'Total<br/>Time',
        idle: 'Passive',
        website: 'Website',
        subpages: 'Subpages',
        prod: 'Prod',
        allGroups: 'All Groups',
        allUsersAndGroups: 'All Users and Groups',
        admin: 'Admin',
        viewableGroups: 'Viewable Groups',
        viewableAccounts: 'Viewable Accounts',
        field: 'Field',
        operator: 'Operator',
        value: 'Value',
        select: 'Select ...',
        caseSensitive: 'Case Sensitive',
        status: 'Status',
        conditions: 'Conditions',
        alarmWeightRisk: 'Risk',
        alarmWeightLevel: 'Level',
        alarmCount: 'Alarm Count',
        userCount: 'User Count',
        email: 'Email',
        notifications: 'Notifications',
        screenCaptures: 'Screen Captures',
        display: 'Display',
        popup: 'Popup',
        delete: 'Delete',
        deleteUserButton: 'Delete user',
        goToSubsLabel: 'Go to Subscriptions',
        goToSubsTarget: 'app.reports.insightsSubscriptionMgmt',
        logonDomain: 'Logon Domain',
        userId: 'User ID',
        alias: 'User Alias',
        computerAlias: 'Computer Alias',
        groupName: 'Group Name',
        domain: 'Domain',
        logonPrimaryDomain: 'Logon or Primary Domain',
        memberName: 'Member Name (Alias)',
        schedule: 'Schedule',
        scheduleName: 'Schedule Name',
        name: 'Name',
        version: 'Version',
        firstLogRecord: 'First Log Record',
        lastLogRecord: 'Last Log Record',
        lastScreenshot: 'Last Screenshot',
        records: 'Records',
        agent: 'Agent',
        numOfLog: '# of Log',
        date: 'Date',
        loading: 'Loading...',
        os: 'OS',
        osVersion: 'OS Version',
        snid: 'SNID',
        category: 'Category',
        usage: 'Usage',
        application: 'Application',
        confirmAction: 'Confirm',
        cancelConfirm: 'Cancel changes without applying?',
        filterScreenshots: 'Filter Screenshots',
        filterVideos: 'Filter Videos',
        advancedFilterAlarms: 'Advanced Alarm Filters',
        active: 'Active',
        inactive: 'Inactive',
        state: 'State',
        activity: 'Activity',
        type: 'Type',
        multipleScreenshots: 'Multiple screenshots',
        video: 'Video',
        userGroup: 'User/Group',
        event: 'Event',
        actionType: 'Action Type',
        actionData: 'Action Data',
        success: 'Success',
        accountMaintenance: 'Account Maintenance',
        sorryForTheInconvenience:
            'Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment. We&rsquo;ll be back online shortly!',
        agentsContinuingRecordingData: 'Rest assured that your agents are continuing to record data in the interim.',
        activTrakTeam: '&mdash; ActivTrak Team',
        activTrakSite: 'www.activtrak.com',
        newAdministrator: 'New Administrator',
        appName: 'ActivTrak',
        dateSliderHint: 'Drag this to select date and time',
        weightSliderHint: 'Select Alarm Weight',
        weightSliderDecrease: 'Lower Risk Level',
        weightSliderIncrease: 'Raise Risk Level',
        paymentDescription: 'ActivTrak account upgrade',
        resend: 'Resend',
        googleId: 'Google ID',
        chromebookUserStatusInvited: 'Invite sent',
        chromebookUserStatusRegistered: 'Registered',
        newChromebookUserAdded: 'New ChromeBook user added!',
        chromebookUserInviteWasSentAgain: 'Invite email for this ChromeBook user was sent again',
        doYouWantToChangeChromeAgentTitle: 'Your Google ID is already associated with another ActivTrak account.',
        doYouWantToChangeChromeAgentBody:
            'Do you want to change your Chrome agent to report to the current ActivTrak account?',
        googleIsUnreachable: 'Unable to authenticate with Google Drive. Please try again later.',
        cancel: 'Cancel',
        chromebookUserInvitationCancelled: 'Invitation for this ChromeBook user was cancelled',
        changesWillLost: 'All changes will be lost.',
        saveChanges: 'Save and Leave',
        submitChanges: 'Submit and Leave',
        discardChanges: 'Leave without Saving',
        discardSubmitting: 'Leave without Submitting',
        unsavedChanges: 'There are unsaved changes.',
        unsubmittedChanges: 'Your changes have not been submitted.',
        leaveAction: "There have been changes made that haven't been saved. How would you like to proceed?",
        changesWillBeLost: 'Changes will be lost when closing the window. How would you like to proceed?',
        enableDataConnect: 'Enable Data Connect',
        gotoConsole: 'Go to Console',
        add: 'Add',
        remove: 'Remove',
        yes: 'Yes',
        no: 'No',
        items: 'Items',
        attempts: 'Attempts',
        payload: 'Payload',
        webhookPayload: 'Webhook Payload',
        response: 'Response',
        limitedTooltip: function (limitLabel, limit) {
            return `
                <div style="width: 260px">
                    <div style="text-align:left">
                        <p>${limitLabel} limited to ${limit.toLocaleString()} records. Adjust the filters to narrow results.</p>
                    </div>
                    <div style="width:100%; text-align:right">
                        <span>
                            <a class="text-productive" href="https://support.activtrak.com/hc/en-us/articles/360028334711" target="_blank">LEARN MORE</a>
                        </span>
                    </div>
                </div>`;
        },
        addingUserFromDNTTitle: function () {
            return 'User(s) has been successfully added to the Do Not Track List.';
        },
        removeUserFromDNTTitle: function () {
            return $sce.trustAsHtml('<strong>Remove user from the Do Not Track List?</strong>');
        },
        removeUserFromDNTBody: function (userName) {
            return $sce.trustAsHtml(
                '<span>Removing <strong>' +
                    userName +
                    '</strong> from the Do Not Track List will allow tracking to begin the next time the user generates activity.</span><br/>'
            );
        },
        dntUserRemoved: function (userName) {
            return 'User ' + userName + ' has been successfully removed from the Do Not Track List.';
        },
        dtnErrorRemovingUser: function (userName) {
            return $sce.trustAsHtml(
                'An error ocurred while trying to remove ' +
                    userName +
                    'from the Do Not Track List.  Try again or <a target="_blank" href="#/app/help/tickets">contact support</a>.'
            );
        },
        addUserToDNTTitle: function () {
            return $sce.trustAsHtml('Do you want to add this user to the Do Not Track List?');
        },
        addUserToDNTBody: function (users) {
            var ret = '<strong>';
            var i;
            for (i = 0; i < users.length; i++) {
                if (i === 0) {
                    ret += browserServiceFunctions.htmlEscape(users[i].userName);
                } else if (i === users.length - 1 && users.length === 2) {
                    ret += '</strong> and <strong>' + browserServiceFunctions.htmlEscape(users[i].userName);
                } else if (i === users.length - 1) {
                    ret += '</strong>, and <strong>' + browserServiceFunctions.htmlEscape(users[i].userName);
                } else {
                    ret += '</strong>, <strong>' + browserServiceFunctions.htmlEscape(users[i].userName);
                }
            }
            ret +=
                '</strong> will be added to the Do Not Track list. ' +
                'All data associated with ' +
                (users.length > 1 ? 'these users' : 'this user') +
                ' will be permanently deleted. This action cannot be reversed.</label>';
            return $sce.trustAsHtml(ret);
        },
        addGlobalUserToDNTBody: function (user, domains) {
            var ret =
                '<label><strong>' +
                browserServiceFunctions.htmlEscape(user) +
                '</strong>  will be added to the Do Not Track list. ';
            ret +=
                '<span>All data associated with this user will be permanently deleted.  This action cannot be reversed.</span>';

            if (domains && domains.length > 0) {
                ret +=
                    '</br><label>This user has recorded data in the following Logon ' +
                    (domains.length > 1 ? 'Domains' : 'Domain') +
                    ': <strong>';
                ret += domains.join('</strong>, <strong>');
                ret += '</strong>.</label>';
            }

            return $sce.trustAsHtml(ret);
        },
        deletionAcknowledgement: 'I acknowledge this action will delete all data associated with the selected user.',
        userDeletionAcknowledgement:
            'I acknowledge that deleting this ActivTrak ID will remove app access for this user. This action is irreversible.',
        userDeletionAcknowledgementPremium:
            'I acknowledge that deleting this ActivTrak ID will remove app access for this user and delete all of their subscriptions. This action is irreversible.',
        subscriptionsBody:
            'To retain this user’s subscriptions before deleting their ActivTrak ID, go to Subscriptions to reassign them.',
        chromeUserDeletionAcknowledgement: 'I acknowledge the selected agents will stop gathering data.',
        storageDeleteSelectedAcknowledgement: function (usersSelected, multipleSelected) {
            var type = usersSelected ? 'user' : 'computer';
            var suffix = multipleSelected ? 's' : '';
            return (
                'I acknowledge all associated data including all logs, screenshots,' +
                ' and videos will be deleted. If the agent is still installed, the ' +
                type +
                suffix +
                ' will reappear and new data will be collected.'
            );
        },
        storageDeleteWithWaitAcknowledgement: function (usersSelected, multipleSelected) {
            var type = usersSelected ? 'user' : 'computer';
            var suffix = multipleSelected ? 's' : '';
            return $sce.trustAsHtml(
                '<label>*Please allow up to 12 hours for the deletion to complete. ' +
                    '<strong>' +
                    type.replace(/\b\w/g, function (l) {
                        return l.toUpperCase();
                    }) +
                    suffix +
                    ' data will be reflected in other reports until this action ' +
                    'is complete.</strong></label>'
            );
        },
        cannotAddSystemToDNT: 'SYSTEM user cannot be added to the Do Not Track list.',
        alarmScreenshotSettingsTooltip: 'The screenshot capture feature cannot be combined with video capture feature.',
        alarmVideoSettingsTooltip: function (restrictions, limit, total) {
            var conditionCount = 0;
            conditionCount += restrictions.screenshot ? 1 : 0;
            conditionCount += restrictions.orFlag ? 1 : 0;
            conditionCount += restrictions.conditions ? 1 : 0;

            if (!restrictions.limitExceeded && conditionCount === 0) {
                return null;
            }

            var screenshotText = 'cannot be combined with screenshot capture feature';
            var ofFlagText = 'cannot be used with the "Match Any" conditional operation';
            var conditionsText =
                'must have multiple conditions defined and must contain at least one condition<br/>that isn\'t "User", "Computer", "IP Address", or "Duration"';

            var html = '<div class="text-left">';

            if (restrictions.limitExceeded) {
                var totalText = total > 1 ? ' are ' + total + ' active alarms ' : ' is ' + total + ' active alarm ';
                html +=
                    '<div>The maximum number of alarms have been defined with the video capture feature<br/>turned on.  There' +
                    totalText +
                    'with the video capture feature.  The limit is ' +
                    limit +
                    '.</div>';
            } else if (conditionCount > 1) {
                html += 'The video capture feature:<ul>';
                html += restrictions.screenshot ? '<li>' + screenshotText + '</li>' : '';
                html += restrictions.orFlag ? '<li>' + ofFlagText + '</li>' : '';
                html += restrictions.conditions ? '<li>' + conditionsText + '</li>' : '';
                html += '</ul>';
            } else if (conditionCount > 0) {
                html += 'The video capture feature ';
                html += restrictions.screenshot ? screenshotText + '.' : '';
                html += restrictions.orFlag ? ofFlagText + '.' : '';
                html += restrictions.conditions ? conditionsText + '.' : '';
                html += '';
            }

            html += '</div>';
            return html;
        },
        alarmConfigDisableSaveTooltip: function (reasons, isNew) {
            if (!reasons || reasons.length === 0) {
                return;
            }
            var html =
                '<div class="text-left">Cannot ' +
                (isNew ? 'create' : 'save') +
                ' the alarm for the following reasons:<ul>';

            reasons.forEach(function (reason) {
                html += '<li>' + reason + '</li>';
            });

            return html + '</ul></div>';
        },
        annualDLPStorageLimitTooltip:
            "The plan limit includes your Screenshot Redaction Plan's storage limit<br/>plus the unused portion from the previous period's storage limit.",
        annualSafeSearchStorageLimitTooltip:
            "The plan limit includes your Plan's Screenshot Flagging limit plus<br/>the unused Screenshot Flagging units from the previous period's limit.",
        enableDLPTooltip:
            'Enabling/Disabling redaction services only affects<br/>the redaction processing of screenshots.<br/>Screenshot Redaction billing will not be affected.',
        enableSafeSearchTooltip:
            'Enabling/Disabling Screenshot Flagging services only affects<br/>the Screenshot Flagging processing of screenshots.<br/>Screenshot Flagging billing will not be affected.',
        planTooltip: function (usedLicenses, totalLicenses, planType, isChildAccount) {
            return (
                usedLicenses +
                ' Reporting Users in your' +
                (isChildAccount ? ' ' : '<br>' + totalLicenses + ' User ') +
                (planType ? planType + ' ' : '') +
                'Plan'
            );
        },
        clickButtonSqlEnable: 'Click the button below to enable Data Connect.',
        clickButtonSqlGoToConsole: 'Click the button below to go to the data connect.',
        uninstallComputerBody: function (computerName) {
            var ret =
                'All data associated with the computer ' +
                computerName +
                ' will be permanently deleted during the uninstall process.  This action cannot be reversed.';
            return $sce.trustAsHtml(ret);
        },
        uninstallAcknowledgement:
            'I acknowledge this action will delete all data associated with the selected computer and uninstall the ActivTrak agent from the computer.',
        selectGroup: 'Select a group…',
        selectDomain: 'Select a domain…',
        blockedDomains: 'Blocked Domains',
        domainChildHeader: 'Included Groups',
        makeAdminTooltip:
            'Admins will have full access to the ActivTrak application and your data.<br>Non-admins will need to have viewable groups assigned to them in order to see any data.<br>Viewable groups can be assigned on the Access Page under the Account menu.',
        videoAlarmLimitReached: function (limit) {
            return (
                'Unable to activate alarm. The limit of ' +
                limit +
                ' active alarms with video capture enabled has been reached.'
            );
        },
        webhookFailure: function (code) {
            return code ? 'Webhook failed with code ' + code : 'Webhook failure';
        },
        sqlDataTicketSubject: function (id) {
            return 'Acct' + id + ' [Data Connect Email Setup] - ActivTrak App';
        },
        sqlDataTicketDescription: function (id) {
            return 'Acct' + id + ' needs an email address to be attached to their Data Connect.';
        },

        // Login Error Messages
        loginErrorMessage_verifySuccess: 'Your ActivTrak account was successfully verified.',

        loginErrorMessage_accountIdUnverifiedError: 'Unable to reset password due to the unverified account Id.',
        loginErrorMessage_invalid: 'Invalid ActivTrak ID or password',
        loginErrorMessage_lockout:
            'Your account has been locked from too many invalid login attempts.  Reset your password or <a href="http://support.activtrak.com" target="_blank">contact our support</a> to get help.',
        loginErrorMessage_accountNotFound: 'Account does not exist',

        loginErrorMessage_isOffline:
            'You are currently offline. Please check your Internet connection and try to sign in again.',
        loginErrorMessage_accountKilled:
            'Your account is disabled. <a href="http://support.activtrak.com" target="_blank">Contact our support</a> to get help.',
        loginErrorMessage_googleAccountNotExists:
            'Oops! Your Google account was not found in ActivTrak, please <a href="' +
            envService.signUpAppUrl() +
            '"class="bold">Sign Up</a> to create one.',
        loginErrorMessage_invalidTemporaryCode: 'Your temporary code has been used or it is expired!',
        loginErrorMessage_problemsResettingPassword:
            'There are problems with resetting your password. Try again in a few minutes or <a target="_blank" href="https://support.activtrak.com/hc/en-us">contact support</a>.',
        loginErrorMessage_resetPasswordSsoEnabled:
            "Your ActivTrak login and password is controlled by your company’s SSO identity provider. Contact your identity provider's administrator to change or reset your password.",

        loginErrorMessage_forgotPasswordConfirmation:
            'If you have an ActivTrak account, you will soon receive a password reset email.  Follow the link in the email to reset your password.',
        loginErrorMessage_loginFailed: 'Login failed.',
        loginErrorMessage_nullToken: 'An error occurred while generating an authorization token.',
        loginErrorMessage_verifyError: 'Invalid token. Try to follow the link from email again.',
        loginErrorMessage_verifyVPN: 'Verify VPN is connected. ',
        loginErrorMessage_apiServerUnavailable:
            'We are currently experiencing a technical problem, our staff has been notified. Please check back in a few minutes. You can monitor our operational status on our <a href="http://status.activtrak.com" target="_blank">status page.</a>',
        loginErrorMessage_maintenanceMode: function (reason) {
            return reason;
        },
        loginErrorMessage_logoutReason: function (reason) {
            return (
                reason +
                ' For more information, <a href="http://support.activtrak.com" target="_blank">contact our support.</a>'
            );
        },

        // Signup Error Messages
        signupErrorMessage_memberExists: 'Email is already part of your account',
        signupErrorMessage_memberExistsOnAnother: 'Email is already on another account',
        signupErrorMessage_isMember: 'You are already a part of this account',
        signupErrorMessage_duplicateMember: 'Duplicate email',
        signupErrorMessage_temporaryEmailService: 'Can not use temporary email addresses',
        signupErrorMessage_invalidEmail: 'Enter a valid email',

        // Notification Alert Messages
        barChartDataTooBig: 'The bar chart will be unreadable with this selected dates range.',
        areaChartDataTooLow: 'Area chart needs at least two data points.',
        alertMessage_exportTooLarge: function (minRows) {
            return (
                'The generated export will be too large. Google Sheets has a limit of 2 million cells per document. Please filter the report to less than ' +
                minRows +
                ' records.'
            );
        },
        userName: 'Username',

        // Add AT ID Modal
        ccAdminRoleLabel:
            'CC Admin: Full access and complete control over Command Center including adding new users to the Command Center, assigning viewable accounts, adding trials, and full access to all customer accounts.',
        adminRoleLabel:
            'Admin: Full access to all account data and settings. Report access can be configured via "Role Access.',
        configuratorRoleLabel:
            'Configurator: Access to Configure Groups & view all User data. Limited settings access to manage teams and classifications and alarms activity. Report access can be configured via "Role Access".',
        powerUserRoleLabel:
            'Power User: Recommended for team managers. Default View-only access to assigned groups & reports, including Activity Log and screenshots. Report access can be configured via "Role Access".',
        powerUserRoleLabelPrivate:
            'Power User: Recommended for team managers. Default View-only access to assigned groups & reports, including Activity Log. Report access can be configured via "Role Access".',
        viewerRoleLabel:
            'Viewer: Recommended for employees to view personal data. View-only access to assigned groups data & reports. Report access can be configured via "Role Access".',
        ccUserRoleLabel:
            'CC User: Viewable access to assigned accounts within Command Center and admin impersonation access to assigned viewable accounts. No access to adding new users or trials within the Command Center.',

        // Access Roles Modal
        adminRoleLabelAccess: '<strong>Admin</strong> - Full access to all account data and settings.',
        ccAdminRoleLabelAccess:
            '<strong>CC Admin</strong> - Full access and complete control over Command &nbsp;&nbsp;&nbsp;&nbsp;<br />Center including adding new users to the Command Center,   <br />assigning viewable accounts, adding trials, and full access to   <br />all customer accounts.',
        configuratorRoleLabelAccess:
            '<strong>Configurator</strong> - Access to Configure Groups & view all <br/>User data. Limited settings access to manage teams<br/>and classifications and alarms activity. Report access can be<br/>configured via "Role Access".',
        powerUserRoleLabelAccess:
            '<strong>Power User</strong> - Recommended for team managers. Default<br/>View-only access to assigned groups & reports, including<br/>Activity Log and screenshots. Report access can be<br/>configured via "Role Access".',
        powerUserRoleLabelPrivateAccess:
            '<strong>Power User</strong> - Recommended for team managers. Default<br/>View-only access to assigned groups & reports, including<br/>Activity Log. Report access can be<br/>configured via "Role Access".',
        viewerRoleLabelAccess:
            '<strong>Team Viewer</strong> - Recommended for employees to view<br/>personal data. View-only access to assigned groups<br/>data & reports. Report access can be configured<br/>via "Role Access".',
        ccUserRoleLabelAccess:
            '<br /><br /><strong>CC User</strong> - Viewable access to assigned accounts within <br />Command Center and admin impersonation access to <br />assigned viewable accounts. No access to adding new users <br />or trials within the Command Center.',
        ccLearnMoreLabelAccess:
            '<br/><br/><div style="text-align:right"><strong><span style="font-size: 12px"><a class="text-productive" href="https://support.activtrak.com/hc/en-us/articles/360059164872-User-Access-Roles-Inside-Command-Center">LEARN MORE </a></span></strong></div>',

        // Integrate Page
        calendarHeader:
            "View meeting data from employees' calendars alongside digital activity in your ActivTrak account",
        ocalIntegrationRequirementsHTML:
            "<ul><li>A current paid ActivTrak subscription or an active trial</li><li>At least one deployed ActivTrak Agent starting to collect activity data</li><li>An active Azure AD instance instance</li><li>A Microsoft 365 Azure Cloud setup</li><li>Administrative role access to your organization's Microsoft 365 account</li></ul>",
        gcalIntegrationRequirementsHTML:
            '<ul><li>A current paid ActivTrak subscription or an active trial</li><li>At least one deployed ActivTrak Agent starting to collect activity data</li><li>An active Google Cloud instance</li><li>An active project set up within Google Cloud Platform</li><li><a href="https://support.google.com/a/answer/172176" target="_blank" rel="noopener">Super administrator permissions</a> to your active Google Workspace instance <ul><li>Super admin is required for domain-wide access to all users\' calendar data</li></ul></li></ul>',
        hrdcIntegrationRequirements:
            'Available for ActivTrak paid plans. To use this integration you must have Administrative role access to your data source.',
        aTSalesforceConfiguration:
            '<li>Visit the <a href="https://support.activtrak.com/hc/en-us/articles/4409163130267-ActivTrak-Salesforce-Configuration-Guide" target="_blank" rel="noopener">ActivTrak for Salesforce Configuration Guide</a> to get started.</li>',
        powerBITemplate:
            'Install the <a href="https://appsource.microsoft.com/en-us/product/power-bi/birchgrovesoftwareincdbaactivtrak1607377475506.activtrak_for_salesforce" target="_blank" rel="noopener">ActivTrak for Salesforce</a> Power BI template from the Microsoft AppSource to view and customize this dashboard',
        contactProductHTML:
            '<ul><li>Contact the <a href="mailto:product@activtrak.com">ActivTrak Product Team</a> to learn more.</li></ul>',
        hrdcContactProductHTML:
            '<ul><li>Learn more and sign up by visiting the <a href="https://www.activtrak.com/product/integrations/automated-user-management-hr-data-connector" target="_blank" rel="noopener">beta sign-up page</a>.</li></ul>',
        somethingWrong: 'Unfortunately, something went wrong. Please contact ActivTrak Support if this persists'
    };

    this.get = function (messageName) {
        var message = messages[messageName];
        return typeof message === 'function' ? message.apply(this, Array.prototype.slice.call(arguments, 1)) : message;
    };
}
