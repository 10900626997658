import { userServiceFunctions } from '_app/serviceFunctions/userServiceFunctions';
import { getAccountSettings } from '../../../../_reactivtrak/src/common/helpers/accountSettings/accountSettingsStore';
angular.module('app').controller('TrialReminderModalCtrl', TrialReminderModalCtrl);

TrialReminderModalCtrl.$inject = ['$scope', '$uibModalInstance', '$state', 'localStorageService'];

function TrialReminderModalCtrl($scope, $uibModalInstance, $state, localStorageService) {
    $scope.done = function () {
        $uibModalInstance.close();
    };

    $scope.goToUpgrade = function () {
        $uibModalInstance.close();
        $state.go('app.account.upgrade');
    };

    $scope.doNotShowChanged = function () {
        // Keep local storage setting as a backup to the last seen system
        const { account } = getAccountSettings();
        localStorageService.set('activtrak-ui-do-not-show-expired_' + account, $scope.doNotShow);
    };

    $scope.$on('$destroy', function () {
        if ($scope.doNotShow) {
            // Update expired_trial_do_not_show do not show
            userServiceFunctions.setUserLastSeen('expired_trial_do_not_show');
        }
    });
}
