import { createStore } from 'zustand';
import { ColDef } from 'ag-grid-community';
import { IScheduleAdherenceUsersDto } from '../models/ScheduleAdherence.types';

export interface IScheduleAdherenceReportStore {
  columnSelectorFields: ColDef<IScheduleAdherenceUsersDto>[] | null;
  isLoading: boolean;
  currentPage: number;
}

export const scheduleAdherenceReportStore =
  createStore<IScheduleAdherenceReportStore>(() => ({
    columnSelectorFields: [],
    isLoading: false,
    currentPage: 1
  }));

export const setScheduleAdherenceReportStore = (
  newState: Partial<IScheduleAdherenceReportStore>
) => scheduleAdherenceReportStore.setState(newState);
