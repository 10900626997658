import viewTemplate from 'views/widgets/accountSuccess.html?raw';
import { getAccountSettings } from '../../../../_reactivtrak/src/common/helpers/accountSettings/accountSettingsStore';

angular.module('app').directive('accountSuccess', accountSuccess);

function accountSuccess() {
    return {
        restrict: 'E',
        scope: false,
        template: viewTemplate,
        controller: AccountSuccessCtrl
    };
}

AccountSuccessCtrl.$inject = ['$scope', 'localStorageService', 'widgetsApiService'];

function AccountSuccessCtrl($scope, localStorageService, widgetsApiService) {
    var { username } = getAccountSettings();
    var localStorageKey = 'realtime-accounts-reporting-' + username;
    var storedValue = localStorageService.get(localStorageKey);

    if (
        storedValue !== null &&
        storedValue !== undefined &&
        new Date() - new Date(storedValue.timestamp) < 2 * 60 * 1000
    ) {
        $scope.accountsReporting = storedValue.value;
    } else {
        widgetsApiService.getRealtimeCount().success(function (result) {
            $scope.accountsReporting = result.total;
            localStorageService.set(localStorageKey, {
                value: result.total,
                timestamp: new Date()
            });
        });
    }
}
