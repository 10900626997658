'use strict';
import { browserServiceFunctions } from '_app/serviceFunctions/browserServiceFunctions';
import { getDateSettingsStore } from '../../_reactivtrak/src/common/stores/dateSettingsStore';
import { getDateFormat, getTimeFormat } from '../../_reactivtrak/src/common/utils/datetime/datetimeFormatStrings';
import { isTodayByTimezone } from '../../_reactivtrak/src/common/utils/datetime/isToday';
import { isEmpty as lodashIsEmpty } from 'lodash';

let _sidebarOpen;

const getGridHeight = (headerSize, minHeight) => {
    headerSize = headerSize || 230;
    minHeight = minHeight || 180;
    return Math.max(window.innerHeight - headerSize, minHeight);
};

const getColumnWidth = (grid) => {
    if (!grid || !grid.element || typeof grid.element.find !== 'function') {
        return 0;
    }

    let gridContent = grid.element.find('.k-grid-content');
    return gridContent && gridContent[0].clientWidth;
};

const getElementWidth = (element) => {
    if (!element || !element.innerWidth || typeof element.innerWidth !== 'function') {
        return 0;
    }

    return element.innerWidth();
};

const getContentWidth = () => {
    let uiView = window.document.querySelectorAll('[ui-view]');
    let containerFluid = uiView && uiView[0].getElementsByClassName('container-fluid');
    return containerFluid && containerFluid[0].clientWidth;
};

const truncateString = (string, maxSize, addEllipsis) => {
    if (typeof string === 'string') {
        if (string.length > maxSize) {
            string = string.substring(0, maxSize - (addEllipsis ? 1 : 0)) + (addEllipsis ? '…' : '');
        }
    }
    return string;
};

const fixPrecision = (number, places = 2) => {
    if (!number || typeof number !== 'number') {
        return number;
    }
    return number.toFixed(places);
};

const getElementOffset = (elm) => {
    if (!elm) {
        console.error('ActivTrak Error: Cannot get element offset without an element.');
        return {
            left: 0,
            top: 0,
            right: 0,
            bottom: 0
        };
    }

    let rawDom = elm[0];
    let _left = 0;
    let _top = 0;
    let _right = 0;
    let _bottom = 0;
    let body = window.document.documentElement || window.document.body;
    let scrollX = window.pageXOffset || body.scrollLeft;
    let scrollY = window.pageYOffset || body.scrollTop;
    let rect = rawDom.getBoundingClientRect();
    let sidebarOffset = _sidebarOpen ? 70 : 0;
    let navigationOffsetLeft = window.innerWidth < 1200 ? 70 : 0;
    let navigationOffsetTop = window.innerWidth < 1200 ? 62 : 0;
    // console.log('body: ', body);
    // console.log('scrollX: ', scrollX);
    // console.log('scrollY: ', scrollY);
    // console.log('rect: ', rect);

    _left = rect.left + scrollX + sidebarOffset + navigationOffsetLeft;
    _top = rect.top + scrollY + navigationOffsetTop;
    _right = _left + rect.width;
    _bottom = _top + rect.height;
    //console.log('left, top, right, bottom: ', _left + ', ' + _top + ', ' + _right + ', ' + _bottom);
    return {
        left: _left,
        top: _top,
        right: _right,
        bottom: _bottom
    };
};

const isElementOnScreen = (element, partial, useVerticalScrollBarOffset, useHorizontalScrollBarOffset) => {
    if (!element || !element[0]) {
        return false;
    }

    var box = element[0].getBoundingClientRect();
    var scrollbarSize = 20;

    //let's save window size
    var win = {
        h: $(window).height(),
        w: $(window).width()
    };

    //firstly we check one axis
    //for example we check if left edge of element is between left and right edge of scree (still might be above/below)
    var topEdgeInRange = box.top >= 0 && box.top <= win.h;
    var bottomEdgeInRange = box.bottom >= 0 && box.bottom <= win.h - (useHorizontalScrollBarOffset ? scrollbarSize : 0);

    var leftEdgeInRange = box.left >= 0 && box.left <= win.w;
    var rightEdgeInRange = box.right >= 0 && box.right <= win.w - (useVerticalScrollBarOffset ? scrollbarSize : 0);

    //here we check if element is bigger then window and 'covers' the screen in given axis
    var coverScreenHorizontally = box.left <= 0 && box.right >= win.w;
    var coverScreenVertically = box.top <= 0 && box.bottom >= win.h;

    //now we check 2nd axis
    var topEdgeInScreen = topEdgeInRange && (leftEdgeInRange || rightEdgeInRange || coverScreenHorizontally);
    var bottomEdgeInScreen = bottomEdgeInRange && (leftEdgeInRange || rightEdgeInRange || coverScreenHorizontally);

    var leftEdgeInScreen = leftEdgeInRange && (topEdgeInRange || bottomEdgeInRange || coverScreenVertically);
    var rightEdgeInScreen = rightEdgeInRange && (topEdgeInRange || bottomEdgeInRange || coverScreenVertically);

    //now knowing presence of each edge on screen, we check if element is partially or entirely present on screen
    var isPartiallyOnScreen = topEdgeInScreen || bottomEdgeInScreen || leftEdgeInScreen || rightEdgeInScreen;
    var isEntirelyOnScreen = topEdgeInScreen && bottomEdgeInScreen && leftEdgeInScreen && rightEdgeInScreen;

    return partial ? isPartiallyOnScreen : isEntirelyOnScreen;
};

const convertTimezone = (dateString) => {
    const { timezoneKey } = getDateSettingsStore();
    const dateFormat = getDateFormat()?.replace('DD', 'dd').replace('YYYY', 'yyyy');
    if (!dateString || !timezoneKey) {
        return '';
    }

    let date = kendo.parseDate(dateString);

    // Only apply format if date is null
    if (!date) {
        date = kendo.parseDate(dateString, dateFormat);
    }

    let offset = moment.tz.zone(timezoneKey).utcOffset(date);
    return kendo.timezone.apply(date, offset);
};

const formatBytes = (bytes, divisor, maxBytes) => {
    return _formatUnits(bytes, divisor, maxBytes, 'B', true, 0);
};

const formatUnits = (units, divisor, maxUnits, unitString, useSpacer, formatMinLength) => {
    return _formatUnits(units, divisor, maxUnits, unitString, useSpacer, formatMinLength);
};

const formatDate = (dateString, options) => {
    //options {span, compact, onlyDate, onlyTime, noSeconds, addTimezone}
    const date = kendo.parseDate(dateString);
    if (!date) {
        return '';
    }
    const { timezoneKey } = getDateSettingsStore();
    const _dateFormat = getDateFormat();
    const _timeFormat = getTimeFormat();

    const dateFormat =
        options && options.compact
            ? _dateFormat.replace('DD', 'dd').replace('YYYY', 'yy')
            : _dateFormat.replace('DD', 'dd').replace('YYYY', 'yyyy');
    const timeFormat =
        options && options.noSeconds
            ? _timeFormat.replace(':ss', '').replace('A', 'tt')
            : _timeFormat.replace('A', 'tt');
    let dateStr = kendo.toString(date, dateFormat);
    let timeStr = kendo.toString(date, timeFormat);
    if (options && options.compact) {
        timeStr = timeStr.replace(/[ M]/g, '').toLowerCase(); // do not remove the space in /[ M]/g
    }

    if (options && options.onlyDate) {
        timeStr = '';
    } else if (options && options.onlyTime) {
        dateStr = '';
    }

    if (options && options.addTimezone) {
        timeStr += ' ' + moment.tz.zone(timezoneKey).abbr(date);
    }

    // span is ON by default
    if (options && options.span === false) {
        return dateStr + ' ' + timeStr;
    } else {
        return dateStr + ' <span style="white-space: nowrap">' + timeStr + '</span>';
    }
};

const isToday = (dateToCompare) => isTodayByTimezone(dateToCompare);

const isEmpty = (value) => {
    if (typeof value === 'boolean') {
        return false;
    }

    if (Array.isArray(value)) {
        let notEmpty = false;
        value.forEach((v) => (notEmpty = notEmpty || !isEmpty(v)));

        return !notEmpty;
    }

    if (typeof value === 'object') {
        return lodashIsEmpty(value);
    }

    value = !value ? value : value.toString();
    return !value || value.length === 0 || !value.trim();
};

const capitalizeFirstLetter = (string) => {
    if (typeof string !== 'string') {
        return string;
    }

    return string.slice(0, 1).toUpperCase() + string.slice(1);
};

const capitalizeFirstLetterEachWord = (string) => {
    if (typeof string !== 'string') {
        return string;
    }

    let split = string.split(' ');
    split.forEach((s, i) => {
        split[i] = capitalizeFirstLetter(s);
    });

    return split.join(' ');
};

const camelCaseToString = (string) => {
    if (typeof string !== 'string') {
        return string;
    }

    return string.slice(0, 1).toUpperCase() + string.slice(1).replace(/([A-Z][a-z])/g, ' $1');
};

const getTimeStamp = (t) => {
    const _timeFormat = getTimeFormat();
    if (!t || !t.format) {
        console.error('ActivTrak Error: Cannot generate time stamp due to invalid input.');
        return '';
    }
    return t.format(_timeFormat);
};

const getMomentTimeStamp = (t) => {
    const _timeFormat = getTimeFormat();
    if (!t || !t.format) {
        console.error('ActivTrak Error: Cannot generate time stamp due to invalid input.');
        return '';
    }
    let format = _timeFormat.replace('tt', 'A');
    return t.format(format);
};

const getDuration = (a, b) => {
    if (!a || !b) {
        return 0;
    }
    return parseInt(b.diff(a, 'seconds'));
};

const filterTime = (t, $filter) => {
    if (isNaN(t)) {
        return '00:00';
    }
    return $filter('date')(t, 'mm:ss');
};

const dateTemplate = kendo.template((data) => {
    if (!data || !data.time) {
        return '';
    }
    return formatDate(data.time);
});

const compactDateTemplate = window.kendo.template((data, timeField) => {
    timeField = timeField || 'time';

    if (!data || !data[timeField]) {
        return '';
    }

    return formatDate(data[timeField], {
        span: false,
        compact: true
    });
});

const formatUrl = (input) => {
    let url = !input ? '' : browserServiceFunctions.htmlEscape(input);
    return '<a target="_blank" href="' + url + '">' + url + '</a>';
};

const urlTemplate = window.kendo.template((data) => {
    if (!data) {
        return '';
    }

    return formatUrl(data.url);
});

const isSmallWindow = () => {
    return window.innerWidth < 992;
};

const isExtraSmallWindow = () => {
    return window.innerWidth < 768;
};

const isExtraSmallWindowForUpgrades = () => {
    return window.innerWidth < 640;
};

const getWindowSize = () => {
    return { width: window.innerWidth, height: window.innerHeight };
};

const updateTitle = ($rootScope, $state) => {
    if (!$rootScope) {
        console.error('ActivTrak Error:  Cannot set app title due to missing rootScope.');
        return;
    }

    if ($state && $state.current && $state.current.data && $state.current.data.pageTitle) {
        let pageTitle = $state.current.data.pageTitle;
        document.title = (pageTitle && pageTitle.length > 0 ? pageTitle + ' | ' : '') + $rootScope.app.name;
    } else {
        document.title = $rootScope.app.name;
    }
};

let componentToHex = (c) => {
    if (!c || typeof c.toString !== 'function') {
        return '00';
    }

    let hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
};

let interpolateColor = (color1, color2, factor) => {
    let result = color1.slice();
    for (let i = 0; i < 3; i++) {
        result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }
    return '#' + componentToHex(result[0]) + componentToHex(result[1]) + componentToHex(result[2]);
};

const interpolateColors = (color1, color2, steps) => {
    if (!color1) {
        console.error('ActivTrak Error: Cannot interpolate colors due to missing color1.');
        return [];
    }
    if (!color2) {
        console.error('ActivTrak Error: Cannot interpolate colors due to missing color2.');
        return [];
    }
    if (!steps || steps < 2) {
        console.error('ActivTrak Error: Cannot interpolate colors due to missing steps or steps less than 2.');
        return [];
    }

    let stepFactor = 1 / (steps - 1);
    let interpolatedColorArray = [];

    color1 = color1.match(/\d+/g).map(Number);
    color2 = color2.match(/\d+/g).map(Number);

    for (let i = 0; i < steps; i++) {
        interpolatedColorArray.push(interpolateColor(color1, color2, stepFactor * i));
    }

    return interpolatedColorArray;
};

const colorLuminance = (hex, lum) => {
    if (!hex) {
        console.error('ActivTrak Error: Cannot create color luminance due to missing hex value.');
        return;
    }
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    let rgb = '#';
    let c;
    let i;

    for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
        rgb += ('00' + c).substr(c.length);
    }

    return rgb;
};

const safeApply = ($rootScope, fn) => {
    if (!$rootScope) {
        console.error('ActivTrak Error: Unable to safe apply due to missing rootScope.');
        return;
    }

    let phase = $rootScope.$$phase;
    if (phase === '$apply' || phase === '$digest') {
        if (fn && typeof fn === 'function') {
            fn();
        }
    } else {
        $rootScope.$apply(fn);
    }
};

function _formatUnits(units, divisor, maxUnits, unitString = '', useSpacer = false, formatMinLength = 0) {
    if (typeof units === 'undefined' || units === null) {
        return 'NaN';
    } else if (typeof units === 'string') {
        units = parseFloat(units);
    }

    if (isNaN(units)) {
        return 'NaN';
    }
    let spacer = useSpacer ? ' ' : '';

    if (units === 0 || (formatMinLength > units && (!maxUnits || formatMinLength > maxUnits))) {
        return units.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + spacer + unitString;
    }

    divisor = divisor || 1000;
    let sign = units < 0 ? -1 : 1;
    units = Math.abs(units);
    let Suffix = ['', 'K', 'M', 'G', 'T', 'P', 'E'];
    let i;
    let value = units;
    let unitStop = maxUnits || units;
    for (i = 0; i < Suffix.length && unitStop >= divisor; i++) {
        units = units / divisor;
        unitStop = units;
        if (maxUnits) {
            maxUnits = maxUnits / divisor;
            unitStop = maxUnits;
        }
        value = units;
    }

    return (value.toFixed(2) * sign).toString() + spacer + Suffix[i] + unitString;
}

/*jslint bitwise: true */
const generateGuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        let r = (Math.random() * 16) | 0;
        let v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};
/*jslint bitwise: false */

function niceNum(range, round) {
    let exponent; /** exponent of range */
    let fraction; /** fractional part of range */
    let niceFraction; /** nice, rounded fraction */

    exponent = Math.floor(Math.log10(range));
    fraction = range / Math.pow(10, exponent);

    if (round) {
        if (fraction < 1.5) {
            niceFraction = 1;
        } else if (fraction < 3) {
            niceFraction = 2;
        } else if (fraction < 7) {
            niceFraction = 5;
        } else {
            niceFraction = 10;
        }
    } else {
        if (fraction <= 1) {
            niceFraction = 1;
        } else if (fraction <= 2) {
            niceFraction = 2;
        } else if (fraction <= 5) {
            niceFraction = 5;
        } else {
            niceFraction = 10;
        }
    }

    return niceFraction * Math.pow(10, exponent);
}

const getScale = (minPoint, maxPoint, maxTicks) => {
    if (typeof minPoint !== 'number') {
        console.error('ActivTrak Error: Cannot get nice number scale due to missing minPoint.');
        return {};
    }

    if (typeof maxPoint !== 'number') {
        console.error('ActivTrak Error: Cannot get nice number scale due to missing maxPoint.');
        return {};
    }

    let tickSpacing;
    let range;
    let niceMax;

    maxTicks = maxTicks || 10;
    range = niceNum(maxPoint - minPoint, false);
    tickSpacing = niceNum(range / (maxTicks - 1), true);
    niceMax = Math.ceil(maxPoint / tickSpacing) * tickSpacing;
    return {
        range: range,
        tickSpacing: tickSpacing,
        niceMax: niceMax
    };
};

let matches;

const getClosest = (elem, selector) => {
    // Element.matches() polyfill
    if (!Element.prototype.matches) {
        Element.prototype.matches =
            Element.prototype.matchesSelector ||
            Element.prototype.mozMatchesSelector ||
            Element.prototype.msMatchesSelector ||
            Element.prototype.oMatchesSelector ||
            Element.prototype.webkitMatchesSelector ||
            function (s) {
                let i = 0;
                (matches = (this.document || this.ownerDocument).querySelectorAll(s)), (i = matches.length);
                while (--i >= 0 && matches.item(i) !== this) return i > -1;
            };
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
        if (elem.matches(selector)) {
            return elem;
        }
    }
    return null;
};

const setCondition = (conditions, field, value) => {
    if (!conditions) {
        return;
    }

    let condition = conditions.filter((item) => {
        return item.field === field;
    })[0];

    if (condition) {
        condition.value = value;
    }
};

const setSidebarOpen = (state) => {
    _sidebarOpen = state;

    return _sidebarOpen;
};

/**
 * Accepts a hex color (#48B0F7) and returns a hex color that is lighter (percent is positive) or darker (percent is negative).
 * ctrl+c ctrl+v https://stackoverflow.com/a/13532993
 * @param {string} color
 * @param {number} percent
 */
const shadeColor = (color, percent) => {
    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = parseInt((R * (100 + percent)) / 100);
    G = parseInt((G * (100 + percent)) / 100);
    B = parseInt((B * (100 + percent)) / 100);

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    var RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
    var GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
    var BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

    return '#' + RR + GG + BB;
};

/**
 * Accepts a hex color as a string ('#48B0F7') and returns an array
 * of the given size with hex values ranging from 100% lighter to 100% darker than
 * the given color.
 * @param {string} color
 * @param {number} size
 */
const buildGradient = (color, size) => {
    var gradient = [];
    var diff = Math.floor(200 / size);
    var percent = diff * size - 100;
    for (var i = 0; i < size; i++) {
        gradient.push(shadeColor(color, percent));
        percent = percent - diff;
    }
    return gradient;
};

const filterDefaultColumns = (columns) => {
    return columns.filter((col) => !col.removeColumn);
};

const formatUtcOffset = (utcString) => {
    var hasDoubleZero = utcString.includes('00');

    if (utcString.includes('(') && utcString.includes(')')) {
        utcString = utcString.slice(4, 10);
    }

    if (utcString[1] == 0) {
        if (utcString.includes(':') && !hasDoubleZero) {
            return utcString.substr(0, 1) + utcString.slice(2, utcString.length);
        } else if (!hasDoubleZero) {
            return utcString.substr(0, 1) + utcString.slice(2, 3) + ':' + utcString.slice(3, utcString.length);
        }
        return utcString.substr(0, 1) + utcString.slice(2, 3);
    } else {
        if (utcString.includes(':') && !hasDoubleZero) {
            return utcString;
        } else if (!hasDoubleZero) {
            return utcString.substr(0, 3) + ':' + utcString.substr(3, utcString.length);
        }
        return utcString.slice(0, 3);
    }
};

export const atHelperFunctions = {
    getGridHeight,
    getColumnWidth,
    getElementWidth,
    getContentWidth,
    truncateString,
    fixPrecision,
    getElementOffset,
    isElementOnScreen,
    convertTimezone,
    formatBytes,
    formatUnits,
    formatDate,
    isToday,
    isEmpty,
    capitalizeFirstLetter,
    capitalizeFirstLetterEachWord,
    camelCaseToString,
    getTimeStamp,
    getMomentTimeStamp,
    getDuration,
    filterTime,
    dateTemplate,
    compactDateTemplate,
    formatUrl,
    urlTemplate,
    isSmallWindow,
    isExtraSmallWindow,
    isExtraSmallWindowForUpgrades,
    getWindowSize,
    updateTitle,
    interpolateColors,
    colorLuminance,
    safeApply,
    generateGuid,
    getScale,
    getClosest,
    setCondition,
    setSidebarOpen,
    buildGradient,
    filterDefaultColumns,
    formatUtcOffset
};

export default atHelperFunctions;
