import { createStore } from 'zustand';
import { persist } from 'zustand/middleware';
import { ReportNames, ReportViewTypes } from '../../common/enums/reports.enums';
import { REPORT_VIEW_STORAGE_PREFIX } from '../../common/constants/reports.const';
import { IReportViewStoreProps } from '../models/WorkingHours.types';

export const scheduleAdherenceViewStore = createStore<IReportViewStoreProps>()(
  persist(
    () => ({
      view: ReportViewTypes.SUMMARY
    }),
    {
      name: REPORT_VIEW_STORAGE_PREFIX + ReportNames.SCHEDULE_ADHERENCE
    }
  )
);

export const setScheduleAdherenceView = (view: ReportViewTypes) => {
  scheduleAdherenceViewStore.setState({ view });
};

export const getScheduleAdherenceView = () =>
  scheduleAdherenceViewStore.getState();
