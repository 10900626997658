import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { AutocompleteSearchBar } from '../../common/components/AutocompleteSearchBar';
import CustomGrid from '../../common/components/Grid/CustomGrid';
import { ICoreCategory } from '../models';
import {
  filterCoreCategoriesBySpecificParamsAndQuery,
  getDefaultCoreCategory,
  getFilteredCoreCategoriesForAutocomplete,
  getGridColumns
} from '../utils/TimeOnTask.view.utils';
import { useCoreCategoriesState } from '../hooks/useCoreCategoriesState';
import { sorter } from '../../common/helpers';
import { GetStartedDialog } from '../components/TimeOnTask/GetStartedDialog';
import { Step1Dialog } from '../components/TimeOnTask/Step1Dialog';
import { Step2Dialog } from '../components/TimeOnTask/Step2Dialog';
import { Step3Dialog } from '../components/TimeOnTask/Step3Dialog';
import { SuccessDialog } from '../components/TimeOnTask/SuccessDialog';
import { useNotifications } from '../../common/services/Notifications';
import { EditDialog } from '../components/TimeOnTask/EditDialog';
import { DeleteConfirmDialog } from '../components/TimeOnTask/DeleteConfirmDialog';
import { TotCss } from '../styles/TimeOnTask.view.styles';
import {
  GET_STARTED_DIALOG,
  STEP1_DIALOG,
  STEP2_DIALOG,
  STEP3_DIALOG,
  SUCCESS_DIALOG,
  EDIT_DIALOG,
  DELETE_CONFIRM_DIALOG
} from '../utils/TimeOnTask.view.utils';
import { useGroupsStore } from '../../common/hooks';

export const TimeOnTaskConfigurationView = () => {
  const {
    init,
    isLoading,
    coreCategories,
    createCoreCategory,
    editCoreCategory,
    deleteCoreCategories
  } = useCoreCategoriesState();

  useEffect(() => {
    init();
  }, [init]);

  const groups = useGroupsStore((s) => s.insightsFilters);
  const hasEditableGroups = groups?.some((g) => g.setGoals);
  useEffect(() => {
    if (coreCategories?.length === 0 && hasEditableGroups) {
      setCurrentDialog(GET_STARTED_DIALOG);
    }
  }, [coreCategories, hasEditableGroups]);

  const notificationService = useNotifications();

  const [selectedCoreCategories, setSelectedCoreCategories] = useState<
    ICoreCategory[]
  >([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [currentDialog, setCurrentDialog] = useState<string>('');
  const [itemCount, setItemCount] = useState<number>(null);
  const [isSingleDelete, setIsSingleDelete] = useState<boolean>(false);
  const [teamName, setTeamName] = useState<string>('');
  const [orderBy, setOrderBy] = useState();
  const [orderDirection, setOrderDirection] = useState();
  const coreCategoryRef = useRef<ICoreCategory>(null);
  const editCoreCategoryRef = useRef<ICoreCategory>(null);
  const deleteCoreCategoryRef = useRef<ICoreCategory>(null);

  const deleteCoreCategoryCallback = (coreCategoryDelete: ICoreCategory) => {
    setIsSingleDelete(true);
    deleteCoreCategoryRef.current = coreCategoryDelete;
    setItemCount(1);
    setCurrentDialog(DELETE_CONFIRM_DIALOG);
  };

  const editCoreCategoryCallback = (coreCategoryEdit: ICoreCategory) => {
    editCoreCategoryRef.current = coreCategoryEdit;
    setCurrentDialog(EDIT_DIALOG);
  };

  const onDeleteConfirm = async () => {
    setCurrentDialog('');
    const toDeleteList = isSingleDelete
      ? [deleteCoreCategoryRef.current]
      : selectedCoreCategories;
    try {
      await deleteCoreCategories(toDeleteList);
    } catch (error) {
      notificationService.error('Error deleting configuration(s)');
      return;
    }
    init();
    setIsSingleDelete(false);
    setSelectedCoreCategories([]);
    notificationService.success('Successfully deleted configuration(s)');
  };

  const onSaveConfiguration = async () => {
    setCurrentDialog('');
    setTeamName(coreCategoryRef.current.groupName);
    try {
      await createCoreCategory(coreCategoryRef.current);
    } catch (error) {
      notificationService.error('Error saving configuration');
      return;
    }
    init();
    coreCategoryRef.current = null;
    setCurrentDialog(SUCCESS_DIALOG);
  };

  const onEditSaveConfiguration = async () => {
    setCurrentDialog('');
    setTeamName(editCoreCategoryRef.current.groupName);
    try {
      await editCoreCategory(editCoreCategoryRef.current);
    } catch (error) {
      notificationService.error('Error editing configuration');
      return;
    }
    init();
    editCoreCategoryRef.current = null;
    setCurrentDialog(SUCCESS_DIALOG);
  };

  const gridColumns = getGridColumns(
    editCoreCategoryCallback,
    deleteCoreCategoryCallback
  );

  const onSortOrder = useCallback((newOrderDirection, newOrderBy) => {
    setOrderBy(newOrderBy);
    setOrderDirection(newOrderDirection);
  }, []);

  const searchBarFilterParams = ['groupName'];

  const filteredCoreCategories = useMemo(() => {
    const filtered = filterCoreCategoriesBySpecificParamsAndQuery(
      coreCategories,
      searchBarFilterParams,
      searchTerm
    );
    return sorter(filtered, orderDirection, orderBy);
  }, [coreCategories, searchTerm, orderDirection, orderBy]);

  const handleChangeAutocompleteSearchBar = (value: string) => {
    setSearchTerm(value);
  };

  const optionsForAutocomplete = getFilteredCoreCategoriesForAutocomplete(
    coreCategories,
    gridColumns,
    searchBarFilterParams,
    searchTerm
  );

  const getSelectedGroupIds = () => {
    return coreCategories?.map((cc) => cc.groupId);
  };

  const handleBulkDelete = () => {
    setItemCount(selectedCoreCategories.length);
    setCurrentDialog(DELETE_CONFIRM_DIALOG);
  };

  const selectAll = (selected: React.SetStateAction<ICoreCategory[]>) => {
    //skip readOnly items
    const editableSelected = [];
    for (const i in selected) {
      const o = selected[i];
      o.selected = o.setGoals;
      if (o.setGoals) {
        editableSelected.push(o);
      }
    }
    setSelectedCoreCategories(editableSelected);
  };

  const isReadOnly = (dataItem: ICoreCategory) => {
    return !dataItem.setGoals;
  };

  const hasEditableCoreCategoryGroups = coreCategories?.some(
    (cc) => cc.setGoals
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}></Grid>
        <Grid item xs={6} sx={TotCss.search}>
          <AutocompleteSearchBar
            autocompleteOptions={optionsForAutocomplete}
            searchLabelPlaceholder="Search for Team"
            onChange={handleChangeAutocompleteSearchBar}
          />
        </Grid>
        <Grid item xs={12} sx={TotCss.addDelete}>
          <Button
            color="primary"
            onClick={() => setCurrentDialog(GET_STARTED_DIALOG)}
            disabled={isLoading || !hasEditableGroups}
            variant="contained"
          >
            Add configuration
          </Button>
          <Button
            startIcon={<DeleteIcon />}
            onClick={handleBulkDelete}
            disabled={
              isLoading ||
              !selectedCoreCategories.length ||
              !hasEditableCoreCategoryGroups
            }
            color="secondary"
            sx={{ marginTop: '20px' }}
          >
            Delete
          </Button>
        </Grid>
        <Grid item xs={12}>
          {(isLoading || filteredCoreCategories) && (
            <CustomGrid
              isVirtualized={true}
              isLoading={isLoading}
              rowDisableCondition={isReadOnly}
              data={filteredCoreCategories}
              columns={gridColumns}
              uniqueRowProp="groupId"
              hasBulkSelection
              onSelectAllClick={selectAll}
              initialSortField="groupName"
              onSortOrder={onSortOrder}
            />
          )}
        </Grid>
      </Grid>
      <GetStartedDialog
        open={currentDialog === GET_STARTED_DIALOG}
        onClose={() => setCurrentDialog('')}
        getStarted={() => {
          coreCategoryRef.current = getDefaultCoreCategory();
          setCurrentDialog('');
          setCurrentDialog(STEP1_DIALOG);
        }}
      />
      <Step1Dialog
        open={currentDialog === STEP1_DIALOG}
        onClose={() => setCurrentDialog('')}
        onGoToStep2={() => {
          setCurrentDialog('');
          setCurrentDialog(STEP2_DIALOG);
        }}
        coreCategoryRef={coreCategoryRef}
        selectedGroupIds={getSelectedGroupIds()}
      />
      <Step2Dialog
        open={currentDialog === STEP2_DIALOG}
        onClose={() => setCurrentDialog('')}
        onBackToStep1={() => {
          setCurrentDialog('');
          setCurrentDialog(STEP1_DIALOG);
        }}
        onGoToStep3={() => {
          setCurrentDialog('');
          setCurrentDialog(STEP3_DIALOG);
        }}
        coreCategoryRef={coreCategoryRef}
      />
      <Step3Dialog
        open={currentDialog === STEP3_DIALOG}
        onClose={() => setCurrentDialog('')}
        backToStep2={() => {
          setCurrentDialog('');
          setCurrentDialog(STEP2_DIALOG);
        }}
        onSaveConfiguration={onSaveConfiguration}
        coreCategoryRef={coreCategoryRef}
      />
      <SuccessDialog
        open={currentDialog === SUCCESS_DIALOG}
        teamName={teamName}
        onClose={() => setCurrentDialog('')}
        onConfigureAnother={() => {
          coreCategoryRef.current = getDefaultCoreCategory();
          setCurrentDialog('');
          setCurrentDialog(STEP1_DIALOG);
        }}
      />
      <EditDialog
        open={currentDialog === EDIT_DIALOG}
        onClose={() => setCurrentDialog('')}
        editCoreCategoryRef={editCoreCategoryRef}
        onEditSaveConfiguration={onEditSaveConfiguration}
      />
      <DeleteConfirmDialog
        open={currentDialog === DELETE_CONFIRM_DIALOG}
        itemCount={itemCount}
        onClose={() => setCurrentDialog('')}
        onDeleteConfirm={onDeleteConfirm}
      />
    </>
  );
};

export const TimeOnTaskConfigurationComponent = () => (
  <ReactivTrakComponentWrapper>
    <TimeOnTaskConfigurationView />
  </ReactivTrakComponentWrapper>
);
