import React, { useEffect } from 'react';
import { useUIRouterHistory } from '../../../common/hooks/useUIRouterHistory';
import { Role } from '../../../common/enums';
import { ActivityDashboardHeaderProps } from '../../models/ActivityDashboardTypes';
import { RoleAccessKeys } from '../../../common/enums';
import authorization from '../../../common/helpers/authorization';
import { DownloadProvider } from '../../../common/services';
import { setReportFilters } from '../../../common/components/ReportFilters/hooks/reportFiltersStore';
import { UserComputerFilter } from '../../../common/components/ReportFilters/components/UserComputerFilter';
import { DateRangeFilter } from '../../../common/components/ReportFilters/components/DateRangeFilter';
import { RefreshButton } from '../../../common/components/ReportFilters/components/RefreshButton';
import { AcademyButton } from '../../../common/components/ReportFilters/components/AcademyButton';
import { QuickStats } from '../../../common/components/ReportFilters/components/QuickStats';
import { DownloadAgentButton } from '../../../common/components/DownloadAgentButton';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {
  ReportHeaderInline,
  ReportHeaderLeftColumn,
  ReportHeaderRightColumn
} from '../../../common/styles/app.component.styles';
import { getAccountSetting } from '../../../common/helpers/accountSettings/accountSettingsStore';

export const ActivityDashboardHeader = (
  props: ActivityDashboardHeaderProps
) => {
  const { reportFilters, isRefreshing, onRefresh } = props;
  const { dates, users } = reportFilters;

  const [showPrint, setShowPrint] = React.useState<boolean>(false);
  const [showAgentDownload, setShowAgentDownload] =
    React.useState<boolean>(false);

  const history = useUIRouterHistory();

  useEffect(() => {
    const showPrintButton = (): boolean => {
      const requiredAccessKeys = [
        RoleAccessKeys.TeamPulse,
        RoleAccessKeys.ActivityLog,
        RoleAccessKeys.Productivity,
        RoleAccessKeys.TopUsers,
        RoleAccessKeys.TopApplications,
        RoleAccessKeys.TopWebsites,
        RoleAccessKeys.TopCategories
      ];

      return requiredAccessKeys.every((role) =>
        authorization.hasRoleAccess(role)
      );
    };

    const showAgentDownloadButton = (): boolean => {
      const hasExcludedRole = authorization.hasAnyRole([
        Role.CommandCenter,
        Role.CommandCenterEnterprise,
        Role.SupportAdvanced
      ]);
      const { isDirectAgentDownloadable } = getAccountSetting(
        'agentDownloadSettings'
      );
      const isNotEnterpriseParent =
        getAccountSetting('subscriptionType') !== 'Enterprise - Parent';

      return (
        isDirectAgentDownloadable && !hasExcludedRole && isNotEnterpriseParent
      );
    };

    setShowPrint(showPrintButton());
    setShowAgentDownload(showAgentDownloadButton());
  }, []);

  const ignoreDateRestrictions: boolean = authorization.isSupportOrSuperAdmin();

  const goToPrintDashboard = () => {
    history.push('app.print-dashboard');
  };

  return (
    <Box>
      <ReportHeaderLeftColumn>
        <ReportHeaderInline>
          <DateRangeFilter
            dates={dates}
            onSubmitFilter={setReportFilters}
            ignoreRestrictions={ignoreDateRestrictions}
          />
        </ReportHeaderInline>
        <ReportHeaderInline>
          <UserComputerFilter onSubmitFilter={setReportFilters} users={users} />
        </ReportHeaderInline>
        <ReportHeaderInline>
          <RefreshButton onRefresh={onRefresh} isRefreshing={isRefreshing} />
        </ReportHeaderInline>
      </ReportHeaderLeftColumn>
      <ReportHeaderRightColumn>
        <ReportHeaderInline>
          <AcademyButton />
        </ReportHeaderInline>
        <ReportHeaderInline sx={{ mr: { xs: 0, md: 1 } }}>
          <QuickStats reportFilters={reportFilters} />
        </ReportHeaderInline>
        {showAgentDownload && (
          <ReportHeaderInline
            sx={{ display: { xs: 'none', md: 'inline-block' } }}
          >
            <DownloadProvider>
              <DownloadAgentButton />
            </DownloadProvider>
          </ReportHeaderInline>
        )}
        {showPrint && (
          <ReportHeaderInline
            sx={{ display: { xs: 'none', md: 'inline-block' }, mr: 0 }}
          >
            <Button
              id="da179092-d02f-45e2-80d8-6df4eed39053"
              variant="outlined"
              onClick={() => goToPrintDashboard()}
            >
              Print Page
            </Button>
          </ReportHeaderInline>
        )}
      </ReportHeaderRightColumn>
    </Box>
  );
};
