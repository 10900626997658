import moment, { Moment } from 'moment-timezone';
import {
  getAccountSetting,
  getAccountSettings
} from '../../../helpers/accountSettings/accountSettingsStore';
import {
  DateRangeLabel,
  DateRanges,
  presetGroups
} from '../constants/dateRanges';
import { PresetLabel } from '../models/IDateRangePicker';
import { DateTime } from '../../../third-party/litepicker/models/ILitepicker';
import { isValidTimestamp } from './reportFilterValidation.utils';
import { getLiveReportSettingsStore } from '../../../stores/liveReportsSettingsStore';
import {
  DateFormat,
  TimeFormat
} from '../../../utils/datetime/enums/dateTimeFormats';
import authorization from '../../../helpers/authorization';

const createGetDatesFunction = (
  range: DateRanges,
  fromDate?: Moment,
  toDate?: Moment
): (() => { fromDate: string; toDate: string }) => {
  if (range === DateRanges.Custom) {
    return () => {
      const today = getTodayInAccountTimeZone().format(
        DateFormat.ApiDateFormat
      );
      return {
        fromDate: fromDate ? fromDate.format(DateFormat.ApiDateFormat) : today,
        toDate: toDate ? toDate.format(DateFormat.ApiDateFormat) : today
      };
    };
  }

  return () => {
    const { fromDate, toDate } = getDateRangeFromName(range);
    return {
      toDate: toDate.format(DateFormat.ApiDateFormat),
      fromDate: fromDate.format(DateFormat.ApiDateFormat)
    };
  };
};

export const createDatesObject = (
  range: DateRanges,
  fromDate?: Moment,
  toDate?: Moment
) => {
  if (Object.values(DateRanges).indexOf(range) === -1) {
    console.error(
      `ActivTrak Error: Invalid date range of ${range} when creating date filter`
    );
    range = DateRanges.Custom;
  }

  const getDates = createGetDatesFunction(range, fromDate, toDate);
  const { fromDate: _fromDate, toDate: _toDate } = getDates();
  return {
    range,
    getDates,
    _fromDate,
    _toDate
  };
};

export const getDateInAccountTimeZone = (
  date: string,
  currentIanaTimeZone: string
): Moment => {
  return moment(date).tz(currentIanaTimeZone);
};

export const getTodayInAccountTimeZone = (): Moment => {
  const timezone = getAccountSetting('currentIanaTimeZone') ?? 'UTC';
  return getDateInAccountTimeZone(moment().format(), timezone);
};

export const getDateRange = (
  option: PresetLabel,
  includeTodaysDate: boolean,
  startingDate: Moment
): { fromDate: Moment; toDate: Moment } => {
  const endDayOffset = !includeTodaysDate && option?.canExcludeToday ? 1 : 0;
  const startDayOffset = option?.canAddPreviousDay ? endDayOffset : 0;
  const start = moment(startingDate).subtract(startDayOffset, 'days');
  const end = moment(startingDate).subtract(endDayOffset, 'days');
  let dateRange: { fromDate: Moment; toDate: Moment };

  switch (option?.value) {
    case DateRanges.Today:
      dateRange = {
        fromDate: start,
        toDate: end
      };
      break;
    case DateRanges.Yesterday:
      dateRange = {
        fromDate: start.subtract(1, 'days'),
        toDate: end.subtract(1, 'days')
      };
      break;
    case DateRanges.Last7Days:
      dateRange = {
        fromDate: moment(end).subtract(6, 'days'),
        toDate: end
      };
      break;
    case DateRanges.ThisWeek:
      dateRange = {
        fromDate: moment(startingDate).startOf('week'),
        toDate: end
      };
      break;
    case DateRanges.LastWeek:
      dateRange = {
        fromDate: moment(end).subtract(1, 'week').startOf('week'),
        toDate: moment(end).subtract(1, 'week').endOf('week')
      };
      break;
    case DateRanges.Last30Days:
      dateRange = {
        fromDate: moment(end).subtract(29, 'days'),
        toDate: end
      };
      break;
    case DateRanges.ThisMonth:
      dateRange = {
        fromDate: moment(startingDate).startOf('month'),
        toDate: end
      };
      break;
    case DateRanges.LastMonth:
      dateRange = {
        fromDate: moment(end).subtract(1, 'month').startOf('month'),
        toDate: moment(end).subtract(1, 'month').endOf('month')
      };
      break;
    case DateRanges.ThisYear:
      dateRange = {
        fromDate: moment(startingDate).startOf('year'),
        toDate: end
      };
      break;
    case DateRanges.LastYear:
      dateRange = {
        fromDate: moment(end).subtract(1, 'year').startOf('year'),
        toDate: moment(end).subtract(1, 'year').endOf('year')
      };
      break;
    default:
      console.error(
        `ActivTrak Error: Invalid date range option when generating preset range:\n\t${JSON.stringify(
          option
        )}`
      );
      dateRange = {
        fromDate: start,
        toDate: end
      };
  }

  if (dateRange.fromDate.isAfter(dateRange.toDate)) {
    dateRange.toDate = dateRange.fromDate;
  }

  return dateRange;
};

export const getPresetDateRangeList = (): PresetLabel[] => {
  const { settings } = getAccountSettings();
  const dataRetentionInMonths =
    settings?.dataRetentionSettings?.['term-months'] ?? null;

  //get dynamic list of date ranges based on roleDateFilters
  const roleDateFilters: string[] = getAccountSetting('roleDateFilters') ?? [];

  const filterPresets = authorization.isSupportOrSuperAdmin()
    ? Object.keys(presetGroups)
    : roleDateFilters;

  const list = filterPresets.reduce((acc, item) => {
    const filteredPresets = presetGroups[item]?.filter(
      (preset: PresetLabel) =>
        dataRetentionInMonths === null ||
        preset.dataRetentionMinMonths <= dataRetentionInMonths
    );
    return acc.concat(filteredPresets);
  }, []);

  //sort list by sortOrder
  list.sort((a, b) => a.sortOrder - b.sortOrder);

  return list;
};

export const getPresetOption = (dateRange: DateRanges): PresetLabel => {
  const presetList = getPresetDateRangeList();
  return presetList.find((item) => item.value === dateRange);
};

export const getDateRangeFromName = (
  dateRange: DateRanges
): { fromDate: Moment; toDate: Moment } => {
  const presetOption = getPresetOption(dateRange);
  const { includeTodaysDate } = getLiveReportSettingsStore();
  const currentIanaTimeZone = getAccountSetting('currentIanaTimeZone');
  const now = currentIanaTimeZone ? moment().tz(currentIanaTimeZone) : moment();
  return getDateRange(presetOption, includeTodaysDate, now);
};

export const getAllDateRangeList = (): PresetLabel[] => {
  const list: PresetLabel[] = Object.values(presetGroups).flatMap(
    (group) => group
  );

  // Sort list by sortOrder
  list.sort((a, b) => a.sortOrder - b.sortOrder);

  return list;
};

export const isCustomAllowed = (): boolean => {
  //check if custom date range is allowed based on roleDateFilters
  const roleDateFilters: string[] = getAccountSetting('roleDateFilters') || [];
  return roleDateFilters.includes('CustomRange');
};

export const getCustomDates = (
  toDate: DateTime,
  ignoreRestrictions: boolean
) => {
  const { unixCreationTime, settings } = getAccountSettings();
  const dateFormat = 'MM/DD/YYYY';
  const creationDateObject = moment.unix(unixCreationTime);
  const dataRetentionInMonths =
    settings?.dataRetentionSettings?.['term-months'] ?? null;
  let customMinDate = undefined;

  if (dataRetentionInMonths && !ignoreRestrictions) {
    customMinDate = moment()
      .subtract(dataRetentionInMonths, 'M')
      .format(dateFormat);
    customMinDate = moment(customMinDate).isBefore(creationDateObject)
      ? creationDateObject.format(dateFormat)
      : customMinDate;
  } else {
    customMinDate = creationDateObject
      ? creationDateObject.format(dateFormat)
      : undefined;
  }

  const customEndDate = moment(toDate).isBefore(customMinDate)
    ? customMinDate
    : moment(toDate);

  return {
    customMinDate,
    customEndDate
  };
};

export const getPresetLabel = (dateRange: DateRanges): DateRangeLabel => {
  const dateRangeLabel = getPresetOption(dateRange)?.label;

  // Date range should always be valid, if an invalid date range is passed, log an error and return a default value
  if (!dateRangeLabel) {
    console.error(
      `ActivTrak Error: Invalid date range of ${dateRange} when getting button value`
    );

    return DateRangeLabel.Error;
  }

  return dateRangeLabel;
};

export const getCustomRangeLabel = (
  fromDate: Moment,
  toDate: Moment
): string => {
  if (!fromDate || !toDate) {
    console.error(
      'ActivTrak Error: Invalid custom date range when getting custom date range label'
    );

    return 'Custom Date Range';
  }

  const dateFormat = getAccountSetting('dateFormat').toUpperCase();
  const fromDateFormatted = fromDate.format(dateFormat);
  const toDateFormatted = toDate.format(dateFormat);

  if ([fromDateFormatted, toDateFormatted].includes('Invalid date')) {
    console.error(
      `ActivTrak Error: Invalid custom date range of ${fromDateFormatted} - ${toDateFormatted} when getting button value`
    );
    return 'Custom Date Range';
  }

  return `${fromDateFormatted} - ${toDateFormatted}`;
};

export const getButtonValue = (
  dateRange: DateRanges,
  fromDate?: Moment,
  toDate?: Moment
): DateRangeLabel | string => {
  if (dateRange === DateRanges.Custom) {
    return getCustomRangeLabel(fromDate, toDate);
  }

  return getPresetLabel(dateRange);
};

export const getUnixDateString = (
  unix: number,
  timezone: string,
  dateFormat: string
): string => {
  if (!isValidTimestamp(unix)) {
    console.error(
      'ActivTrak Error: Invalid unix timestamp when getting date string'
    );
    return '';
  }

  return moment(unix).tz(timezone).format(dateFormat.toUpperCase());
};

export const getUnixTimeString = (
  unix: number,
  timezone: string,
  isTimeFormat24Hour: boolean,
  dateFormat: string
): string => {
  if (!isValidTimestamp(unix)) {
    console.error(
      'ActivTrak Error: Invalid unix timestamp when getting time string'
    );
    return '';
  }

  //12-hour vs 24-hour time format
  const hourFormat = isTimeFormat24Hour ? 'HH:mm' : 'hh:mm A';
  const concatFormat = `${dateFormat.toUpperCase()} ${hourFormat}`;

  return moment(unix).tz(timezone).format(concatFormat);
};

export const getStartOfDayTimestamp = (
  date: string,
  timezone: string
): number => {
  if (!date) {
    console.error(
      'ActivTrak Error: Invalid date string when getting start of day timestamp'
    );
    return 0;
  }

  const startOfDay = moment.tz(date, timezone).startOf('day');
  return startOfDay.valueOf();
};

export const getEndOfDayTimestamp = (
  date: string,
  timezone: string
): number => {
  if (!date) {
    console.error(
      'ActivTrak Error: Invalid date string when getting end of day timestamp'
    );
    return 0;
  }

  const endOfDay = moment.tz(date, timezone).endOf('day');
  return endOfDay.valueOf();
};

export const getFormattedFromTimestamp = (
  timestamp: number,
  timezone: string
): string => {
  return timestamp && timezone && isValidTimestamp(timestamp)
    ? moment(timestamp).tz(timezone).format('YYYY-MM-DD HH:mm')
    : '';
};

export const shouldEnableTimeSlider = (fromDate: string, toDate: string) => {
  return moment(toDate).diff(fromDate, 'days') <= 7;
};

type GetHoursValue = {
  hour: number;
  minutes: number;
  format: string;
};
export const getHoursValue = (props?: Partial<GetHoursValue>) => {
  const h = props?.hour ?? 0;
  const m = props?.minutes ?? 0;
  const f = props?.format ?? TimeFormat.TwelveHourNoSeconds;

  return moment({ h, m }).format(f);
};
