'use strict';

/**
 * @ngdoc overview
 * @name atbuildApp
 * @description
 * # atbuildApp
 *
 * Main module of the application.
 */

angular.module('app', [
    'config',
    'ui.router',
    'ui.router.state.events',
    'ngAnimate',
    'ngCookies',
    'ngResource',
    'ngSanitize',
    'ngTouch',
    'kendo.directives',
    'ui.bootstrap',
    'ui.select',
    'LocalStorageModule',
    'googlechart',
    'ngFileUpload',
    'ngFileSaver',
    'com.2fdevs.videogular',
    'com.2fdevs.videogular.plugins.controls',
    'com.2fdevs.videogular.plugins.overlayplay',
    'com.2fdevs.videogular.plugins.poster',
    'angular-loading-bar',
    'angular-bind-html-compile',
    'login',
    'signupLogin',
    'verify',
    'chromebook',
    'ngWebSocket'
]);
